import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React from "react";
import { NO_RECORDS_FOUND } from "../../../../../../../utils/constants";
import { AppUtils } from "../../../../../../../utils/appUtils";

const StaffList = ({ props }) => {
  return (
    <>
      <TableContainer>
        <Table
          classes={{ root: "table-bordered" }}
          size="small"
          aria-label="Staff Details Table"
        >
          <TableHead>
            <TableRow>
              <TableCell>क्र.सं.</TableCell>
              <TableCell>स्वास्थ्यकर्मीको नाम</TableCell>
              <TableCell>स्वास्थ्यकर्मीको पद</TableCell>
              <TableCell>सक्रिय / निष्क्रिय</TableCell>
              <TableCell>सम्पादन</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {
              props?.tableData?.length > 0 ? (
                props?.tableData?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{row.healthWorkerName || "-"}</TableCell>
                    <TableCell>{row.healthWorkerPost || "-"}</TableCell>
                    <TableCell>{row.active || "-"}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <IconButton aria-label="edit" onClick={() => props.onEditRow(row.id)}>
                          <Edit fontSize="small" />
                        </IconButton>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color='secondary' onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="5" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StaffList;
