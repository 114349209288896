import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

const NewOldPatientCount = ({ tableData }) => {

  const ageGroups = [
    { label: "०-५ बर्ष", id: "0-6" },
    { label: "६-१० वर्ष", id: "6-11" },
    { label: "११-१९ वर्ष", id: "11-20" },
    { label: "२०-५९ बर्ष", id: "20-59" },
    { label: "५९-६९ बर्ष", id: "59-69" },
    { label: "≥ ७० बर्ष", id: "> 70" }
  ];

  return (
    <TableContainer classes={{ root: "fixed-head-table-container" }}>
      <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="New-Old-Patient-Count">
        <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
          <TableRow>
            <TableCell rowSpan={2}>उमेर समूह</TableCell>
            <TableCell colSpan={2}>नयाँ सेवाग्राही संख्या</TableCell>
            <TableCell colSpan={2}>पुनरावृत सेवाग्राही संख्या</TableCell>
            <TableCell colSpan={2}>प्रेषण गरिएको संख्या</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>महिला</TableCell>
            <TableCell>पुरुष</TableCell>
            <TableCell>महिला</TableCell>
            <TableCell>पुरुष</TableCell>
            <TableCell>महिला</TableCell>
            <TableCell>पुरुष</TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {ageGroups.map((group) => {
            const rowData = tableData.find((item) => item._id === group.id) || {};
            return (
              <TableRow key={group.id}>
                <TableCell>{group.label}</TableCell>
                <TableCell>{rowData.FEMALE_NEW_PATIENT || 0}</TableCell>
                <TableCell>{rowData.MALE_NEW_PATIENT || 0}</TableCell>
                <TableCell>{rowData.FEMALE_PREVIOUS_PATIENT || 0}</TableCell>
                <TableCell>{rowData.MALE_PREVIOUS_PATIENT || 0}</TableCell>
                <TableCell>{rowData.SENT_FROM_ORGANIZATION_FEMALE || 0}</TableCell>
                <TableCell>{rowData.SENT_FROM_ORGANIZATION_MALE || 0}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NewOldPatientCount;
