import { useForm } from "react-hook-form";
import AHMIS, { API_URL } from "../../api/api";
import { useState } from "react";
import { SOMETHING_WENT_WRONG, SUCCESS } from "../../utils/constants";
import AddAlertMessage from "../alert/Alert";
import { Box, Button, Grid, Paper, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";


const SearchException = () => {
  const { handleSubmit, register } = useForm();
  const [stackTrace, setStackTrace] = useState(null);

  const onSubmit = data => {
    AHMIS.get(API_URL.businessException + "?exceptionId=" + data.exceptionId).then(response => {
      if (response.data?.type === SUCCESS) {
        setStackTrace(response.data?.data);
      }
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    })
  }

  return (<>
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                type="number"
                label="Business Exception ID"
                name="exceptionId"
                size="small"
                variant="outlined"
                fullWidth
                {...register("exceptionId")}
              />
            </Grid>
          </Grid>
          <Button startIcon={<Search />} type="submit" color="primary" variant="contained">
            खोज्नुहोस्
          </Button>
        </Box>
      </form>
    </Paper>
    {
      stackTrace && (
        <Paper>
          <Box mt={4}>
            {stackTrace}
          </Box>
        </Paper>)
    }
  </>)
}
export default SearchException;