export const CURRENT_OFFICE = "currentOffice";
export const OTHER_OFFICE = "otherOffice";
export const MALE = "MALE";
export const FEMALE = "FEMALE";
export const NEW_PATIENT = "NEW_PATIENT";
export const PREVIOUS_PATIENT = "PREVIOUS_PATIENT";
export const MUL_DARTA_NUMBERS_LIST = "MUL_DARTA_NUMBERS_LIST";
export const VILLAGE_DARTA_NUMBERS_LIST = "VILLAGE_DARTA_NUMBERS_LIST";
export const ANC_PATIENT_LIST = "ANC_PATIENT_LIST";
export const INPATIENT_NUMBERS_LIST = "INPATIENT_NUMBER_LIST";
export const DEFAULT = "DEFAULT";
export const OTHER = "OTHER";
export const HEALTH_POST = "HEALTH_POST";
export const GENERAL = "GENERAL";
export const DANGER = "DANGER";
export const CRITICAL = "CRITICAL";
export const FIRST_DOSE = "FIRST_DOSE";
export const SECOND_DOSE = "SECOND_DOSE";
export const THIRD_DOSE = "THIRD_DOSE";
export const FOURTH_DOSE = "FOURTH_DOSE";
export const FIFTH_DOSE = "FIFTH_DOSE";
export const SIXTH_DOSE = "SIXTH_DOSE";
export const SEVENTH_DOSE = "SEVENTH_DOSE";
export const GRANTED = "GRANTED";
export const NOT_GRANTED = "NOT_GRANTED";
export const NEPALI_MONTH_BY_FISCAL_YEAR = ["SHRAWAN", "BHADAU", "ASWIN", "KARTIK", "MANSIR", "POUSH", "MAGH", "FALGUN", "CHAITRA", "BAISHAKH", "JESTHA", "ASAR"];
export const GENTAMYCIN_DOSES = [FIRST_DOSE, SECOND_DOSE, THIRD_DOSE, FOURTH_DOSE, FIFTH_DOSE, SIXTH_DOSE, SEVENTH_DOSE];
export const OTHER_LOCATION = "OTHER_LOCATION";
export const OUT_PATIENT_MAIN_REGISTER_SERVICE_CODE = "1";
export const IMCI_MAIN_REGISTER_SERVICE_CODE = "2";
export const BALBALIKA_POSAN_MAIN_REGISTER_SERVICE_CODE = "3";
export const SAFE_MOTHERHOOD_MAIN_REGISTER_SERVICE_CODE = "4";
export const PARIWAAR_NIYOJAN_MAIN_REGISTER_SERVICE_CODE = "5";
export const TUBERCULOSIS_MAIN_REGISTER_SERVICE_CODE = "6";
export const LEPROSY_MAIN_REGISTER_SERVICE_CODE = "7";
export const KEETJANYA_ROG_MAIN_REGISTER_SERVICE_CODE = "8";
export const BALBALIKA_POSAN_VILLAGE_CLINIC_SERVICE_CODE = "2";
export const PARIWAAR_NIYOJAN_VILLAGE_CLINIC_SERVICE_CODE = "4";
export const PRATHAAMIK_UPACHAR_VILLAGE_CLINIC_SERVICE_CODE = "5";
export const SAFE_MOTHERHOOD_VILLAGE_CLINIC_SERVICE_CODE = "3";
export const REPRODUCTION_HEALTH_SERVICE_CODE = "9";
export const NON_COMMUNICABLE_DISEASE_SERVICE_CODE = "10";
export const OTHER_SERVICE_CODE = "11";
export const SENIOR_CITIZEN_SERVICE = "SENIOR_CITIZEN";
export const HIV_SERVICE = "HIV";
export const KHOP_SERVICE = "KHOP";
export const TD_KHOP_SERVICE = "TD_KHOP";
export const LAB_SERVICE = "LAB";
export const SANITARY_PAD_DETAIL_SERVICE = "SANITARY_PAD_DETAIL"
export const OST_SERVICE = "OST";
export const STI_SERVICE = "STI";
export const HOSPITAL_ADMISSION_SERVICE = "HOSPITAL_ADMISSION";
export const UPT_TEST_SERVICE = "UPT_TEST";
export const COVID_VACCINE_SERVICE = "COVID_VACCINE_SERVICE"
export const FORTIFIED_FLOUR_DISTRIBUTION = "FORTIFIED_FLOUR_DISTRIBUTION";
export const OCMC_SERVICE = "OCMC_SERVICE"
export const ASRH_SERVICE = "ASRH_SERVICE"
export const REPRODUCTIVE_HEALTH = "REPRODUCTIVE_HEALTH";
export const SNCU_NICU_KMCU_SERVICE = "SNCU_NICU_KMCU";
export const REHABILITATION_SERVICE = "REHABILITATION";
export const ANTIBIOTIC = "ANTIBIOTIC";
export const VALID_AGE_FOR_CHILDREN = "बालरोग व्यवस्थापनको लागि उमेर ५९ महिना भन्दा कम हुनुपर्दछ";
export const POSITIVE = "POSITIVE";
export const NEGATIVE = "NEGATIVE";
export const THIRD = "THIRD";
export const FOURTH = "FOURTH";
export const FIFTH = "FIFTH";
export const SIXTH = "SIXTH";
export const FIFTH_SIXTH = "FIFTH_SIXTH";
export const SEVENTH = "SEVENTH";
export const EIGHTH = "EIGHTH";
export const NINTH = "NINTH";
export const TENTH = "TENTH";
export const ELEVENTH = "ELEVENTH";
export const NEPALI_MONTH_LIST = ["BAISHAKH", "JESTHA", "ASAR", "SHRAWAN", "BHADAU", "ASWIN", "KARTIK", "MANSIR", "POUSH", "MAGH", "FALGUN", "CHAITRA"];
export const DEPO = "DEPO";
export const FCHV = "FCHV";
export const HOME = "HOME";
export const INSTITUTION = "INSTITUTION";
export const TREATMENT = "TREATMENT";
export const REFERRED = "REFERRED";
export const INVALID_BLOOD_PRESSURE_MESSAGE = "Invalid BP. Example: 120/80";

export const VACCINE_TYPES = [
  { value: "BCG_VACCINE", label: "बि.सि.जि" },
  { value: "DPT_HEPABI_HIB_VACCINE", label: "डि.पि.टि./हेप बि/हिब" },
  { value: "POLIO_VACCINE", label: "पोलियो(OPV)" },
  { value: "PCV_VACCINE", label: "पि.सि.भि.(PCV)" },
  { value: "ROTA_VACCINE", label: "रोटा" },
  { value: "FIPV_VACCINE", label: "एफ.आई.पि.भि." },
  { value: "DADURA_RUBELA_VACCINE", label: "दादुरा/रुबेला" },
  { value: "JE_VACCINE", label: "जे.ई." },
  { value: "HPV", label: "एच.पी.भी (HPV)" },
  { value: "TYPHOID", label: "टाईफाईड (TCV)" }
];

export const BREAST_FEEDING_BEFORE_AND_ABOVE_SIX_MONTH = [
  { value: "YES", label: "गराएको" },
  { value: "NO", label: "नगराएको" }
]

export const AGE_CATEGORY = [
  { value: "LESS_THAN_OR_EQUAL_TO_28_DAYS", label: "<= 28 Days" },
  { value: "BETWEEN_1_AND_11_MONTH", label: "1-11 Months" },
  { value: "MORE_THAN_ONE_YEAR", label: "> 1 Year" },
];

export const LESS_THAN_OR_MORE_THAN_TWENTY_EIGHT_DAYS = [
  { value: "LESS_THAN_TWENTY_NINE_DAYS", label: "२९ दिन भन्दा कम" },
  { value: "TWENTY_NINE_TO_FIFTY_NINE_DAYS", label: "२९ दिन देखी ५९ दिन सम्म" },
  { value: "TWO_TO_FIFTY_NINE_MONTHS", label: "२ देखि ५९ महिना सम्म" }
];

export const IRON_FOLIC_TAKEN_PLACE = [
  { value: FCHV, label: "FCHV" },
  { value: OTHER, label: "अन्य संस्था" }
]

export const SOURCE_OF_ADMISSION = [
  { value: "EMERGENCY_DEPARTMENT", label: "आकस्मित विभाग(१)" },
  { value: "OPD_DEPARTMENT", label: "बहिरंग विभाग(२)" },
  { value: OTHER, label: "अन्य संस्थाबाट प्रेषण भई आएको(३)" },
];

export const SON_OR_DAUGHTER = [
  { value: "MALE", label: "छोरा" },
  { value: "FEMALE", label: "छोरी" }
];

export const BIRTH_CONDITION = [
  { value: "BIRTH_AFTER_ALLOCATED_TIME", label: "अवधि पुगेको" },
  { value: "BIRTH_BEFORE_ALLOCATED_TIME", label: "अवधि नपुगेको" },
]

export const DEATH_CHILDREN_TYPE = [
  { value: "FRESH", label: "Fresh" },
  { value: "MACERATED", label: "Macerated" }
]

export const CHILD_CONDITION = [
  { value: "NORMAL", label: "Normal" },
  { value: "INFECTION", label: "Infection" },
  { value: "ASPHYXIATED", label: "Asphyxiated" },
  { value: "HYPOHTERMIA", label: "Hypohtermia" },
  { value: "JAUNDICE", label: "Jaundice" }
];

export const DEVICE_USED_FOR_FAMILY_PLANNING = [
  { value: "TSA", label: "TSA" },
  { value: "TLA", label: "TLA" },
  { value: "PERM", label: "Perm" }
]

export const OTHER_SERVICE_GIVEN_TO_MOTHER = [
  { value: "BLOOD_DONATED", label: "रगत दिएको" },
  { value: "OXYTOCIN_INJECTION_GIVEN", label: "अक्सिटोसिन सुइ दिएको" },
  { value: "KMC_SERVICE_TAKEN", label: "KMC सेवा दिएको" },
  { value: "BREAST_FEEDING_WITHIN_ONE_HOUR", label: "जन्मेको १ घण्टा भित्र स्तनपान गराएक" },
  { value: "NABHI_MALAM_USED", label: "नाभी मलमको प्रयोग" },
]

export const FAMILY_PLANNING_AFTER_CHILD_BIRTH_TIME = [
  { value: "WITHIN_FORTY_EIGHT_HOUR", label: "सुत्केरी भएको ४८ घण्टा भित्र" },
  { value: "BETWEEN_FORTY_NINE_HOUR_TO_FORTY_TWO_DAYS", label: "सुत्केरी भएको ४९ घण्टा देखि ४२ दिन भित्र" }
]

export const SERVICE_GIVEN_PLACE_TO_MOTHER_AFTER_BIRTH = [
  { value: HOME, label: "घर" },
  { value: INSTITUTION, label: "यहि संस्था" },
  { value: "OTHER_INSTITUTION", label: "अन्य संस्था (घर/संस्था)" },
]

export const NEPALI_MONTHS = [
  { value: "BAISHAKH", label: "बैशाख" },
  { value: "JESTHA", label: "जेठ" },
  { value: "ASAR", label: "असार" },
  { value: "SHRAWAN", label: "श्रावण" },
  { value: "BHADAU", label: "भदौ" },
  { value: "ASWIN", label: "आश्विन" },
  { value: "KARTIK", label: "कार्तिक" },
  { value: "MANSIR", label: "मंसिर" },
  { value: "POUSH", label: "पुष" },
  { value: "MAGH", label: "माघ" },
  { value: "FALGUN", label: "फाल्गुन" },
  { value: "CHAITRA", label: "चैत्र" }
];

export const TREATMENT_OR_REFERRED = [
  { value: TREATMENT, label: "उपचार" },
  { value: REFERRED, label: "प्रेषण गरेको" }
];

export const SEWA_CATEGORY = [
  { value: "PATHOLOGY", label: "प्याथोलोजी" },
  { value: "RADIOLOGY", label: "रेडियोलोजी" },
  { value: "EMERGENCY", label: "इमरजेन्सि" },
  { value: "OPD", label: "ओ.पी.डी" },
];

export const KhakarParikshanKaran = [
  { value: "ROGNIDAN", label: "रोग निदान" },
  { value: "isRRTBMDR", label: "RR TB/MDR हो" },
  { value: "notRRTBMDR", label: "RR TB/MDR होइन" }
];

export const KhakarParikshanPahilaLiyekoNaLiyeko = [
  { value: "PAHILALIYEKO", label: "पहिले लिएको" },
  { value: "PAHILANALIYEKO", label: "पहिले नलिएको" },
  { value: "THAHANAVAYEKO", label: "थाहा नभएको" }
];

export const PRESAN_OPTIONS = [
  { value: "P", label: "निजी स्वास्थ्य संस्था (P)" },
  { value: "C", label: "समुदाय(C)" },
  { value: "T", label: "सम्पर्क परीक्षण (T)" }
];

export const TB_ROG_OPTIONS = [
  { value: "PBC", label: "PBC" },
  { value: "PCD", label: "PCD" },
  { value: "EP", label: "EP" }
];

export const TB_PATIENT_ENTRY_CATEGORIES = [
  { value: "NEW", label: "New" },
  { value: "RELAPSE", label: "Relapse" },
  { value: "TREATMENT_AFTER_FAILURE", label: "Treatment After Failure" },
  { value: "TREATMENT_AFTER_LOST_TO_FOLLOW_UP", label: "Treatment After Lost to Follow‐up" },
  { value: "OTHERS_PREVIOUSLY_TREATED", label: "Others Previously Treated" },
  { value: "PREVIOUS_TREATMENT_HISTORY_UNKNOWN", label: "Previous Treatment History Unknown" }
];

export const BASIS_OF_ADMISSION = [
  { value: "MUAC", label: "एम.यु.ए.सि(मि.मि.)" },
  { value: "Z_SCORE", label: "उ.अ. तौल(Z-Score)" },
  { value: "BOTH_LEGS_SWOLLEN", label: "दुवै खुट्टा सुन्निएको" }
]

export const VACCINE_TYPES_FOR_SEARCH_PARAM = [
  { value: "bcgDate", label: "बि.सि.जि" },
  { value: "pcvFirstTime", label: "पि.सि.भि. -१" },
  { value: "pcvSecondTime", label: "पि.सि.भि. -२" },
  { value: "pcvThirdTime", label: "पि.सि.भि. -३" },
  { value: "polioFirstTime", label: "पोलियो-१" },
  { value: "polioSecondTime", label: "पोलियो-२" },
  { value: "polioThirdTime", label: "पोलियो-३" },
  { value: "rotaFirstTime", label: "रोटा-१" },
  { value: "rotaSecondTime", label: "रोटा-२" },
  { value: "fipvFirstTime", label: "एफ.आई.पि.भि-१" },
  { value: "fipvSecondTime", label: "एफ.आई.पि.भि-२" },
  { value: "dptHepBFirstTime", label: "डि.पि.टि./हेप बि/हिब-१" },
  { value: "dptHepBSecondTime", label: "डि.पि.टि./हेप बि/हिब-२" },
  { value: "dptHepBThirdTime", label: "डि.पि.टि./हेप बि/हिब-३" },
  { value: "jeAfterTwelveMonth", label: "जे.ई. (१२ महिना)" },
  { value: "daduraRubelaNineToElevenMonth", label: "दादुरा/रुबेला-पहिलो (९ म)" },
  { value: "daduraRubelaAfterTwelveMonth", label: "दादुरा/रुबेला-दोस्रो (१५ म)" },
  { value: "hpvOneTakenDate", label: "एच.पी.भी- १" },
  { value: "hpvTwoTakenDate", label: "एच.पी.भी- २" },
  { value: "tcvTakenDate", label: "टाईफाईड" },
  { value: "purnaKhopLayeko", label: "पूर्ण खोप लगाएको" },
]

export const COUNSELLING_TO_MOTHER_FOR_CHILD_ABOVE_TWO_MONTH = [
  { value: "REGULAR_FOLLOW_UP", label: "Regular Follow up" },
  { value: "IMMEDIATE_VISIT", label: "Immediate Visit" },
  { value: "FLUID", label: "Fluid" },
  { value: "FOOD", label: "Food" },
];

export const COUNSELLING_TO_MOTHER_FOR_CHILD_BELOW_TWO_MONTH = [
  { value: "REGULAR_FOLLOW_UP", label: "Regular Follow up" },
  { value: "IMMEDIATE_VISIT", label: "Immediate Visit" },
  { value: "KEEP_WARM", label: "Keep Warm" },
  { value: "BREAST_FEEDING", label: "Breast Feeding" }
]

export const TB_TREATMENT_CATEGORY = [
  { value: "FIRST_LINE_DRUG_CAT_I", label: "First Line Drugs (Cat I)" },
  { value: "RE_TREATMENT_FIRST_LINE_DRUG_CAT_II", label: "Retreatment First Line Drugs (Cat II)" },
  { value: "CAT_I", label: "Cat I" },
  { value: "CAT_II", label: "Cat II" },
  { value: "CAT_III", label: "Cat III" },
  { value: "SECOND_LINE_TREATMENT_REGIMEN", label: "Second Line Treatment Regimen" }
];

export const TREATMENT_OUTCOME_OPTIONS = [
  { value: "CURED", label: "Cured" },
  { value: "TREATMENT_COMPLETED", label: "Treatment Completed" },
  { value: "TREATMENT_FAILURE", label: "Treatment Failure" },
  { value: "DIED", label: "Died" },
  { value: "LOST_TO_FOLLOW_UP", label: "Loss to follow up" },
  { value: "NOT_EVALUATED", label: "Not Evaluated" },
];

export const EMERGENCY_SERVICE_OUTCOME_OPTIONS = [
  { value: "RECOVERED", label: "Recovered (1)" },
  { value: "NOT_IMPROVED", label: "Not Improved (2)" },
  { value: "REFERRED", label: "Reffered (3)" },
  { value: "DOR_OR_LAMA_OR_DAMA", label: "DOR/LAMA/DAMA (4)" },
  { value: "ABSCONDED", label: "Absconded (5)" },
  { value: "ADMITTED_IN_INTIMATE_ROOM", label: "Admitted in Intimate Room (6)" },
  { value: "DEATH", label: "Death (7)" }
];

export const INVESTIGATION_MONTH = [
  { value: "AFTER_TWO_MONTHS", label: "दोस्रो महिना" },
  { value: "AFTER_THREE_MONTHS", label: "तेस्रो महिना" },
  { value: "AFTER_FIVE_MONTHS", label: "पाचौ महिना" },
  { value: "AT_LAST", label: "अन्त्यमा" }
];

export const RELATION_TYPES = [
  { value: "SELF", label: "आफु" },
  { value: "SON", label: "छोरा" },
  { value: "DAUGHTER", label: "छोरी" },
  { value: "HUSBAND", label: "श्रीमान" },
  { value: "WIFE", label: "श्रीमति" },
  { value: "FATHER", label: "बुबा" },
  { value: "MOTHER", label: "आमा" },
  { value: "BROTHER", label: "दाजुभाइ" },
  { value: "SISTER", label: "दिदी बहिनी" },
  { value: "GRANDFATHER", label: "हजुरबुबा" },
  { value: "GRANDMOTHER", label: "हजुरआमा" },
  { value: "GRANDSON", label: "नाति" },
  { value: "GRANDDAUGHTER", label: "नातिनी" },
  { value: "FRIEND", label: "साथी" },
  { value: "UNCLE_AUNT", label: "अंकल／आन्टी" },
  { value: "NEPHEW", label: "भतिज／भतिजी" },
  { value: OTHER, label: "अन्य" },
];

export const COMPLICATION_TYPE_FOR_MOTHER = [
  { value: "ECTOPIC_PREGNANCY", label: "Ectopic Pregnancy" },
  { value: "ABORTION_COMPLICATION", label: "Abortion Complication" },
  { value: "PREG_INDUCED_HYPERTENSION", label: "Preg- Induced Hypertension" },
  { value: "GESTATIONAL_HYPERTENSION", label: "Gestational Hypertension" },
  { value: "SEVERE_PRE_ECLAMPSIA", label: "Severe/Pre-eclampsia" },
  { value: "ECLAMPSIA", label: "Eclampsia" },
  { value: "HYP_GRAVIDARUM", label: "Hyp. gravidarum" },
  { value: "APH", label: "APH" },
  { value: "PROLONGED_LABOUR", label: "Prolonged labour" },
  { value: "OBSTRUCTED_LABOR", label: "Obstructed Labor" },
  { value: "RUPTURED_UTERUS", label: "Ruptured uterus" },
  { value: "PPH", label: "PPH" },
  { value: "RETAINED_PLACENTA", label: "Retained placenta" },
  { value: "PUEPERAL_SEPSIS", label: "Pueperal Sepsis" },
  { value: "CS_WOUND_INFECTION", label: "C/S Wound Infection" },
]

export const PHASE_DURING_CHILD_BIRTH = [
  { value: "PREGNANT", label: "गर्भवती" },
  { value: "PRASUTI", label: "प्रसुती" },
  { value: "SUTKERI", label: "सुत्केरी" },
]

export const smearCultureXpert = [
  { value: "Smear", label: "Smear" },
  { value: "Culture", label: "Culture" },
  { value: "Xpert", label: "Xpert" }
];

export const STATUS_KNOWN = [
  { value: "YES", label: "भएको" },
  { value: "NO", label: "नभएको" },
  { value: "UNKNOWN", label: "थाहा नभएको" }
];

// TODO: Sandeep - YES_NO_OPTIONS constant is already present in the constants/index.js. Since labels are different we have to add this one too. Once the YES_NO_OPTION component is made, the single constant will remain.
export const YES_OR_NO_OPTION = [
  { value: "YES", label: "हो" },
  { value: "NO", label: "होइन" },
];

export const CONGENITAL_ANOMALIES = [
  { value: "mastiskakoPuraBhagNabhayeko", label: "मस्तिष्कको पुरा भाग नभएको" },
  { value: "othKhude", label: "ओठ खुँडे हुनु" },
  { value: "taluKhude", label: "तालु खुँडे हुनु" },
  { value: "othAndTaluKhude", label: "ओठ र तालु खुँडे हुनु" },
  { value: "bandaGudadwar", label: "बन्द गुदद्वार" },
  { value: "ghodeKhude", label: "घोडे खुँडे" },
  { value: "moningomailosil", label: "मोनिङ्गोमाइलोसिल" },
  { value: "hydrokefalas", label: "हाइड्रोकेफालस" }
];

export const CHILD_LOCATION = [
  { value: "cephalic", label: "Cephalic" },
  { value: "breech", label: "Breech" },
  { value: "shoulder", label: "Shoulder" }
]

export const LABOR_PAIN_STATUS = [
  { value: "spontaneous", label: "Spontaneous" },
  { value: "augmentation", label: "Augmentation" },
  { value: "induced_labour", label: "Induced labour" }
]

export const PRASUTI_TYPES = [
  { value: "spontaneous", label: "Spontaneous" },
  { value: "vacuum", label: "Vacuum" },
  { value: "forceps", label: "Forceps" },
  { value: "cs", label: "CS" },
]

export const STAFF_FOR_PRASUTI = [
  { value: "dakhsaPrasutikarmi", label: "दक्ष प्रसूतीकर्मी" },
  { value: "dakhsaSwasthyakarmi", label: "दक्ष स्वास्थ्यकर्मीबाट" },
  { value: "anyaPrasutikarmi", label: "दक्ष प्रसूतीकर्मी बाहेक अन्य स्वास्थ्यकर्मीबाट" }
]

export const LEPROSY_TYPES = [
  { value: "MB", label: "कुष्ठरोग एम.वि" },
  { value: "PB", label: "कुष्ठरोग पी.वि" }
];

export const DISEASE_DETECTED_METHODS = [
  { value: "VOLUNTARILY", label: "Voluntarily" },
  { value: "REFERRED", label: "Refer" },
  { value: "CONTACT_EXAMINATION", label: "Contact Examination" },
  { value: "SKIN_CAMP", label: "Skin Camp" },
  { value: OTHER, label: "Others" }
];

export const KUSTHAROG_PATIENT_TYPES = [
  { value: "NEW", label: "New Patient" },
  { value: "RELAPSE", label: "Relapse" },
  { value: "RE_STARTER", label: "Re-starter" },
  { value: "TRANSFER_IN", label: "Transfer in" },
  { value: "CLASSIFICATION_CHANGE", label: "Classification change" },
  { value: "OTHER", label: "Other" }
];

export const LEPRA_REACTION_TYPES = [
  { value: "TYPE_I", label: "Type I" },
  { value: "TYPE_II", label: "Type II" }
]

export const MARITAL_STATUS = [
  { value: "UNMARRIED", label: "Unmarried" },
  { value: "MARRIED", label: "Married" },
  { value: "WIDOWED", label: "Widow" },
  { value: "DIVORSED", label: "Divorced" },
  { value: "LIVING_TOGETHER", label: "Living Together" },
  { value: "SEPERATED", label: "Seperated" },
  { value: "NOT_APPLICABLE", label: "Not Applicable" }
]

export const DISEASE = [
  { value: "NON_COMMUNICABLE_DISEASE", label: "नसर्ने रोग" },
  { value: "MENTAL_HEALTH", label: "मानसिक स्वास्थ्य" },
  { value: "INJURY", label: "चोट" },
  { value: "DISABILITY", label: "असक्षमता" }
];

export const TREATMENT_STATUS = [
  { value: "REGULAR", label: "नियमित छ" },
  { value: "NOT_REGULAR", label: "नियमित छैन" }
];

export const IMPROVEMENT_STATUS = [
  { value: "IMPROVED", label: "सुधार छ" },
  { value: "NOT_IMPROVED", label: "सुधार छैन" }
];

export const STI_DIAGNOSIS_OPTIONS = [
  { value: "UDS", label: "Urethral Discharge Syndrome (UDS)" },
  { value: "SSS", label: "Scrotal Swelling Syndrome (SSS)" },
  { value: "VDS", label: "Vaginal Discharge Syndrome (VDS)" },
  { value: "LAPS", label: "Lower Abdominal Pain Syndrome (LAPS)" },
  { value: "GUDS", label: "Genital Ulcer Disease Syndrome (GUDS)" },
  { value: "IBS", label: "Inguinal Bubo Syndrome (IBS)" },
  { value: "NCS", label: "Neonatal Conjunctivitis Syndrome," },
  { value: "CERICITIS", label: "Cericitis" },
  { value: "VAGINITIS", label: "Vaginitis" },
  { value: "HERPES_GENITALIS", label: "Herpes Genitalis" },
  { value: "OTHER", label: "Other" },
]

export const EDUCATIONAL_STATUS = [
  { value: "LITERATE", label: "Literate" },
  { value: "ILLITERATE", label: "Illiterate" }
]

export const KUSTHAROG_UNCOOPERATION_LEVEL = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "NOT_CHECKED", label: "नाजाँचेको" }
];

export const KUSTHAROG_PATIENT_REMOVAL_OPTIONS = [
  { value: "RFT", label: "Release From Treatment-RFT" },
  { value: "TO", label: "Transfer Out-TO" },
  { value: "DF", label: "Defaulter- DF" },
  { value: "OD", label: "Other Deduction-OD" }
];

export const KUSTHAROG_SMEAR_RESULTS = [
  { value: "ONE_PLUS", label: "+" },
  { value: "TWO_PLUS", label: "++" },
  { value: "THREE_PLUS", label: "+++" },
  { value: "FOUR_PLUS", label: "++++" },
  { value: "FIVE_PLUS", label: "+++++" },
  { value: "SIX_PLUS", label: "++++++" },
  { value: "NEGATIVE", label: "-ve" }
];

export const MOTHER_SITUATION = [
  { value: "garbhawastha", label: "गर्भावस्था" },
  { value: "sutkeri", label: "सुत्केरी अवस्था" },
  { value: "prasuti", label: " प्रसूती अवस्था" }
];

export const MOTHER_AND_CHILD_DEATH_PLACE = [
  { value: "HEALTH_INSTITUTION", label: "स्वास्थ्य संस्था" },
  { value: "OTHER_THAN_HEALTH_INSTITUTION", label: "स्वास्थ्य संस्था बाहेक" }
]

export const HIV_STATUS = [
  { value: "POSITIVE", label: "+ve" },
  { value: "NEGATIVE", label: "-ve" },
  { value: "UNKNOWN", label: "UNKNOWN" }
]

export const CONDITION_DURING_TOOL_PLACEMENT = [
  { value: "WITHIN_FORTY_EIGHT_HOURS_OF_DELIVERY", label: "सुत्केरी भएको ४८ घण्टा भित्र" },
  { value: "WITHIN_FORTY_EIGHT_TO_ONE_YEAR_AFTER_PREGNANT", label: "सुत्केरी भएको ४८ घण्टा देखि १ वर्ष भित्र" },
  { value: OTHER, label: "अन्य" }
]

export const PLACED_ORGANIZATION = [
  { label: "यहि", value: CURRENT_OFFICE },
  { label: "अन्य", value: OTHER_OFFICE }
]

export const POSITIVE_NEGATIVE_OR_UNKNOWN = [
  { value: "POSITIVE", label: "+ve" },
  { value: "NEGATIVE", label: "-ve" },
  { value: "UNKNOWN", label: "UNKNOWN" }
]

export const SEWA_TYPES = [
  { label: "IUCD", value: "iucd" },
  { label: "Implant", value: "implant" }
]

export const PILLS_DEPO_SEWA_TYPES = [
  { label: "पिल्स", value: "PILLS" },
  { label: "डिपो", value: "DEPO" },
  { label: "आकस्मिक चक्की", value: "AKASMIK_CHAKI" },
  { label: "सयना प्रेस", value: "SAYANA_PRESS" }
]

export const ORGANIZATION_TYPE = [
  { label: "सरकारी", value: "GOVERNMENTAL" },
  { label: "गैर सरकारी", value: "NON_GOVERNMENTAL" }
]

export const SERVICE_PROVIDERS = [
  { label: "संस्था", value: "ORGANIZATION" },
  { label: "शिविर", value: "CAMP" }
]

export const TB_LAB_RESULT_TIME_PERIOD = [
  { label: "At The Beginning", value: "At The Beginning" },
  { label: "2 or 3 Months", value: "2 or 3 Months" },
  { label: "5 Months", value: "5 Months" },
  { label: "At The End", value: "At The End" }
]

export const FREE_SERVICE_CODE = [
  { label: "अति गरिव/गरिव (१)", value: "1" },
  { label: "असहाय (२)", value: "2" },
  { label: "अपाङ्ग (३)", value: "3" },
  { label: "जेष्ठ नागरिक (४)", value: "4" },
  { label: "म.स्वा.स्व.से (५)", value: "5" },
  { label: "अन्य (६)", value: "6" }
]

export const FUNCTIONING_OPTIONS = [
  { label: "बुझ्ने र बुझाउने क्षमता", value: "ABILITY_TO_UNDERSTAND_AND_EXPLAIN" },
  { label: "गमनशिलता (हिँडाइ र चहलपहल)", value: "ABILITY_FOR_MOVING_AND_WALKING" },
  { label: "स्वहेरचाह (आफ्नो स्वच्छता, लवाइ, खुवाइ र एक्लै गर्न सक्ने)", value: "SELF_CARE" },
  { label: "मेलमिलाप (अरु मानिस सँगको अन्तक्रिया)", value: "RECONCILIATION" },
  { label: "जीवन क्रियाकलाप (घरायसी तथा विद्यालय कार्य)", value: "LIFE_ACTIVITIES" },
  { label: "सहभागिता (सामाजिक क्रियाकलाप)", value: "PARTICIPATION_AND_SOCIAL_ACTIVITIES" },
]

export const DISEASE_OPTIONS = [
  { label: "जन्मजात(०१)", value: "CONGENITAL" },
  { label: "दुर्घटना/चोटपटक(०२)", value: "ACCIDENTAL" },
  { label: "हिंसा (०३)", value: "VIOLENCE" },
  { label: "नसर्ने रोग (०४)", value: "NON_TRANSMITTED_DISEASE" },
  { label: "सर्ने रोग(०५)", value: "TRANSMITTED_DISEASE" },
  { label: "हड्डी खिइनु (०६)", value: "BONE_PAIN" },
  { label: "अन्य (०७)", value: "OTHER" },
  { label: "थाहा नभएको (०८)", value: "NOT_DETECTED" },
]

export const REQUIRED_SUPPORTING_MATERIAL_CODE_OPTIONS = [
  { label: "गमनशिलता सम्बन्धि(०१)", value: "ABOUT_MOBILITY" },
  { label: "दृष्टि सम्वन्धि(०२)", value: "ABOUT_VISION" },
  { label: "सुनाई सम्वन्धि(०३)", value: "ABOUT_HEARING" },
  { label: "संचार सम्बन्धि(०४)", value: "ABOUT_COMMUNICATION" },
  { label: "बुझ्ने र बुझाउने सम्वन्धि(०५)", value: "ABOUT_UNDERSTAND_AND_EXPLAIN" },
  { label: "वातावरण सम्बन्धि(०६)", value: "ABOUT_ENVIRONMENT" },
]

export const RECEIVED_SUPPORTING_MATERIAL_CODE_OPTIONS = [
  { label: "आफै किनेको(०१)", value: "SELF_PURCHASED" },
  { label: "अस्पतालबाट(०२)", value: "FROM_HOSPITAL" },
  { label: "अन्य सरकारी कार्यलयबाट(०३)", value: "FROM_OFFICE" },
  { label: "पुनस्थापना केन्द्रबाट(०४)", value: "FROM_REHABILITATION_CENTER" },
  { label: "अन्य संस्थाबाट(०५)", value: "FROM_OTHER_OFFICE" },
]

export const REFERRED_TO_ORGANIZATION_SEWA_CODE_OPTIONS = [
  { label: "OPD(01)", value: "OPD" },
  { label: "IPD(02)", value: "IPD" },
  { label: "CBIMNCI(03)", value: "CBIMNCI" },
  { label: "Nutrition(04)", value: "NUTRITION" },
  { label: "Immunization(05)", value: "IMMUNIZATION" },
  { label: "Safe Motherhood(06)", value: "SAFE_MOTHERHOOD" },
  { label: "PEN(07)", value: "PEN" },
  { label: "PHC/ORC(08)", value: "PHC/ORC" },
  { label: "Other(09)", value: "OTHER" },
]

export const CONDOM_USE_OPTIONS = [
  { label: "कन्डम प्रयोग गरेको", value: "CONDOM_USED" },
  { label: "कन्डम प्रयोग नगर्ने गरेको", value: "NOT_USED" },
  { label: "थाहा नभएको", value: "UNKNOWN" },
  { label: "सोध्न नै उपयुक्त नभएको", value: "NOT_ASKED" },
]

export const HISTORY_OF_TREATMENT_FOR_TB = [
  { label: "बिरामीले यदि यस अघि उपचार लिएको", value: "PREVIOUSLY_CHECKED" },
  { label: "बिरामीले यदि यस अघि उपचार नलिएको", value: "PREVIOUSLY_NOT_CHECKED" },
  { label: "बिरामी हाल उपचाररत भएको", value: "UNDER_TREATMENT" },
  { label: "नयाँ उपचारमा आएको", value: "NEW_TREATMENT" },
  { label: "पुनः उपचारमा आएको", value: "RE_TREATMENT" },
  { label: "नयाँ वा पुनः उपचार बाहेक अन्य भएको", value: "OTHER" },
]

export const RETROVIRUS_STATUS = [
  { label: "एच. आई. भी सङ्क्रमण भएको", value: "HIV_INFECTED" },
  { label: "एच. आई. भी सङ्क्रमण नभएको", value: "HIV_NOT_INFECTED" },
  { label: "थाहा नभएको", value: "NOT_KNOWN" },
]

export const SPECIMEN_TYPE = [
  { label: "परीक्षण गरीने नमूना खकार भएको", value: "KHAKAR_PRESENT" },
  { label: "अन्य", value: "OTHER" },
]

export const MICROSCOPY = [
  { label: "Microscopy विधिबाट क्षयरोग निदान वा अनुगमन गर्न परीक्षण गर्न लागिएको", value: "TREATED_WITH_MICROSCOPY" },
  { label: "रोग निदानका लागि खकार परीक्षण गरेको", value: "DIAGNOSIS" },
  { label: "रोग अनुगमनका लागि खकार परीक्षण गरेको", value: "FOLLOW_UP" },
]

export const XPERT_MTB_RIF = [
  { label: "Xpert MTB/RIF विधिबाट क्षयरोग निदान तथा औषधी प्रतिरोधी अवस्था थाहा पाउन परीक्षण गरिएको", value: "TREATED_WITH_XPERT_MTB_RIF" },
  { label: "रोग निदानका लागि परीक्षण गरेको", value: "DIAGNOSIS" },
  { label: "औषधी प्रतिरोध क्षमता (RR) विकसित भए नभएको थाहा पाउन", value: "RR_DETECTION" },
]

export const XPERT_MTB_XDR = [
  { label: "Xpert MTB/XDR विधिबाट औषधी प्रतिरोधी अवस्था थाहा पाउन Second Line DST परीक्षण गरिएको", value: "SECOND_LINE_DST" },
  { label: "INH resistance थाहा पाउन परीक्षण गरिएको", value: "INH_RESISTANCE" },
]

export const LPA = [
  { label: "LPA विधिबाट औषधी प्रतिरोधी अवस्था थाहा पाउन Second Line DST परीक्षण गरिएको", value: "SECOND_LINE_DST" },
  { label: "INH resistance थाहा पाउन परीक्षण गरिएको", value: "INH_RESISTANCE" },
]

export const CULTURE_DST = [
  { label: "क्षयरोगका बिरामीको DST परीक्षण अनुरोध गरिएको", value: "SECOND_LINE_DST" },
  { label: "Presumptive DRTB Case भएको", value: "PRESUMPTIVE_DRTB_CASE" },
  { label: "Follow-up Case भएको", value: "FOLLOW_UP" },
]

export const KHAKAR_SAMPLE = [
  { label: "खकारको नमूना पहिलो भएको", value: "SAMPLE_A" },
  { label: "खकारको नमूना दोस्रो भएको", value: "SAMPLE_B" }
]

export const KHAKAR_VISUAL_APPEARANCE = [
  { label: "खकारमा रगत मिसिएको", value: "BLOOD_MIXED" },
  { label: "खकार पहेलो र ढिक्का परेको", value: "YELLOW_AND_THICK" },
  { label: "र्याल मात्रै भएको", value: "ONLY_SALIVA" },
]

export const KHAKAR_RESULT = [
  { label: "Neg", value: "NEG" },
  { label: "Scanty", value: "SCANTY" },
  { label: "1+", value: "ONE_PLUS" },
  { label: "2+", value: "TWO_PLUS" },
  { label: "3+", value: "THREE_PLUS" },
]

export const REFERRAL_OPTIONS = [
  { label: "अन्य संस्थाबाट यस संस्थामा सेवाग्राही प्रेषण गरेको", value: "SENDING_CLIENTS_FROM_OTHER_ORGANIZATIONS_TO_THIS_ORGANIZATION" },
  { label: "सेवाग्राहीलाई अन्य संस्थामा प्रेषण गरेको", value: "SENDING_THE_SERVICE_RECIPIENT_TO_ANOTHER_INSTITUTION" },
]

export const SEWA_TYPE_OPTIONS = [
  { label: "फिजियोथेरापी सेवा(०१)", value: "PHYSIOTHERAPY_SERVICE" },
  { label: "वाक् तथा श्रवण सेवा(०२)", value: "SPEECH_AND_HEARING_SERVICE" },
  { label: "अकुपेशनथेरापी सेवा(०३)", value: "OCCUPATIONAL_THERAPY" },
  { label: "सहायक सामग्री सम्बन्धी सेवा(०४)", value: "RELATED_AUXILIARY_MATERIALS_SERVICE" },
  { label: "पि.एम. पि.एम.(५.१)", value: "PM_PM" },
  { label: "अन्य(५.२)", value: "OTHER" },
  { label: "नर्सिङ्ग(६.१)", value: "NURSING" },
  { label: "स्वास्थ्यकर्मी(६.२)", value: "HEALTH_WORKER" },
  { label: "परामर्श सेवा(०७)", value: "CONSULTATION_SERVICE" },
  { label: "अन्य सेवा(०८)", value: "OTHER_SERVICE" },
]

export const REFERRED_FROM_OTHER_ORGANIZATION_OR_PERSON_CODE = [
  { label: "केन्द्रीय/प्रादेशिक अस्पताल(०१)", value: "CENTRAL_HOSPITAL" },
  { label: "द्वितिय अस्पताल(०२)", value: "SECOND_HOSPITAL" },
  { label: "प्राथमिक स्वास्थ्य केन्द्र/आधारभूत अस्पताल(०३)", value: "PRIMARY_HEALTH_CENTER/BASIC_HOSPITAL" },
  { label: "स्वास्थ्य चौकी(०४)", value: "HEALTH_POST" },
  { label: "शहरी स्वास्थ्य क्लिनिक(०५)", value: "URBAN_HEALTH_CLINIC" },
  { label: "सामुदायिक स्वास्थ्य क्लिनिक(०६)", value: "COMMUNITY_HEALTH_CLINIC" },
  { label: "महिला स्वास्थ्य स्वयंसेविका(०७)", value: "WOMEN'S_HEALTH_VOLUNTEER" },
  { label: "पुनस्थापना केन्द्र(०८)", value: "REHABILITATION_CENTER" },
  { label: "अपाङ्गता भएका व्यक्तिहरुको संस्था(०९)", value: "ORGANIZATION_FOR_PERSONS_WITH_DISABILITIES" },
  { label: "अन्य सेवाग्राही(१०)", value: "OTHER_SERVICE_RECIPIENTS" },
  { label: "अन्य(११)", value: "OTHER" },
]

export const DISEASE_CODE = [
  { label: "अर्श", value: "ARSHA" },
  { label: "भगन्दर", value: "VAGANDAR" },
  { label: "Pilonidal Sinus", value: "PILONIDALSINUS" },
  { label: "Perianal wart", value: "PERIANALWART" },
  { label: "अन्य", value: "OTHER" }
]

export const PROGRAM_NAME = [
  { label: "पन्चकर्म पूर्वकर्म कार्यक्रम", value: "PANCHAKARMA_PURBAKARMA_KARYAKRAMA" },
  { label: "नागरिक आरोग्य  सेवा तथा अभियान कार्यक्रम ", value: "NAGARIKA_SVASTHYA_SEVA_AKHIYANA_KARYAKRAMA" },
  { label: "विद्यालय आयुर्वेद तथा योग शिक्षा कार्यक्रम", value: "VIDYALAYA_AYUVEDA_TATHA YOGA_SIKSA_KARYAKRAMA" },
  { label: "प्रयोगशाला सन्चालन कार्यक्रम ", value: "PRAYOGSALA_SANCHAALAN_KARYAKRAMA" },
  { label: "औषधी खरीद कार्यक्रम", value: "MEDICINE_KHARIDA_KARYAKRAMA" },
  { label: "जेष्ठ नागरिकका लागि स्वास्थ्य प्रवर्धन सेवा कार्यक्रम", value: "JESTHA_NAGARIKAKA_LAGISWASTHYA_SANVARDHANA_SEWA_KARYAKRAMA" },
  { label: "स्तनपानी आमालाई मातृशिशु सुरक्षार्थ दुग्धवर्धक जडिबुटी तथा अन्य औसधि वितरण कार्यक्रम", value: "STANPANI_AMALAI_MATRISHISHU_SURAKSHARTHA_DUGDHAVARDHAKA_JADIBUTI_TATHA_ANYA_AUSHADHI_VIVARANA_KARYAKRAMA" }
]

export const EMPLOYEE_TYPE = [
  { label: "स्थाई", value: "स्थाई" },
  { label: "अस्थायी", value: "अस्थायी" },
  { label: "करार", value: "करार" },
  { label: "अन्य", value: "अन्य" }
]

export const ICD_CODE_OPTIONS = [
  { label: "Jwar (Fever)", value: "1" },
  { label: "Swash vikar (Respiratory disorder)", value: "2" },
  { label: "Swash (Asthm and COAD)", value: "3" },
  { label: "Kas (Coughing disorder)", value: "4" },
  { label: "Others", value: "5" },
  { label: "Amlapitta (APD)", value: "6" },
  { label: "Atisar (Diarrhoea)", value: "7" },
  { label: "Grahani (IBS,IBD,Ulcerative colitis, Crohn's disease, Sprue)", value: "8" },
  { label: "Mumps(1D80)", value: "9" },
  { label: "Pravahika (Dysnetry)", value: "10" },
  { label: "Others", value: "11" },
  { label: "Udarroga (Abdominal disorder)", value: "12" },
  { label: "Prameha/Madhumeha", value: "13" },
  { label: "Prameha", value: "14" },
  { label: "Madhumeha (Diabetes mellitus)", value: "15" },
  { label: "Kamala", value: "16" },
  { label: "Kosthashrita kamala (Non obstructive jaundice)", value: "17" },
  { label: "Shakhashrita kamala (Obstructive jaundice)", value: "18" },
  { label: "Pandu (Anaemia)", value: "19" },
  { label: "Hridaya roga (Cardiac disorder)", value: "20" },
  { label: "Raktachap (Blood pressure disorder)", value: "21" },
  { label: "Uchharaktachap (Hypertension)", value: "22" },
  { label: "Nyunaraktachap (Hypotension)", value: "23" },
  { label: "Shotha (Oedema)", value: "24" },
  { label: "Krimi (Worm infestation)", value: "25" },
  { label: "Twak vikar (Skin disorder)", value: "26" },
  { label: "Ekkustha/Kitibha (Psoriasis)", value: "27" },
  { label: "Pama", value: "28" },
  { label: "Vyanga/Nyachha (Blemishes)", value: "29" },
  { label: "Others", value: "30" },
  { label: "Abhighataj vikar (Traumatic disorder)", value: "31" },
  { label: "Vata vyadhi (Skeleto-muscular and nervous system disease)", value: "32" },
  { label: "Manyastambha (Torticollis)", value: "33" },
  { label: "Avabahuka (Frozen shoulder)", value: "34" },
  { label: "Mutravikar (Urinary disorder)", value: "35" },
  { label: "Mutrasmari (Renal calculi)", value: "36" },
  { label: "Mutrakrichhra (Dysuria)", value: "1C15" },
  { label: "Mutraghat (Retnetion/suppression of urine)", value: "37" },
  { label: "Prasutivikar (Postpartum disorder)", value: "38" },
  { label: "Stanya vikar (Breast milk dosorder)", value: "39" },
  { label: "Garbhashayachhyuta (Uterine prolapse)", value: "40" },
  { label: "Others", value: "41" },
  { label: "Striroga (Gynaecological disorder)", value: "41" },
  { label: "Raktapradar (Menstrual problem)", value: "42" },
  { label: "Swetapradar (Leucorrhoea)", value: "44" },
  { label: "Kashtartawa (Dysmenorrhoea)", value: "43" },
  { label: "Anartawa (Amenorrhoea)", value: "44" },
  { label: "Yoniroga (…..)", value: "46" },
  { label: "Others", value: "47" },
  { label: "Gudavikar", value: "48" },
  { label: "Arsha (Haemorrhoids)", value: "48" },
  { label: "Bhagandar (Fistula in ano)", value: "49" },
  { label: "Pilonidal sinus", value: "50" },
  { label: "Perianal wart", value: "51" },
  { label: "Parikartika (Fissure)", value: "52" },
  { label: "Others", value: "53" },
  { label: "Netraroga (Ophthamological disorder)", value: "54" },
  { label: "Abhisyandi (Conjunctivitis)", value: "55" },
  { label: "Athimantha (Glaucoma)", value: "56" },
  { label: "Timira (Cataract)", value: "57" },
  { label: "Others", value: "58" },
  { label: "Karna-nasa-mukha-danta-kantha roga", value: "59" },
  { label: "Karna roga (Disease related to ear)", value: "60" },
  { label: "Nasa roga (Disease related to nose)", value: "61" },
  { label: "Mukha roga (Oral disease)", value: "62" },
  { label: "Danta roga(Dental disease)", value: "63" },
  { label: "Kantha roga (Disease related to throat)", value: "64" },
  { label: "Shiroroga (Disease related to head)", value: "65" },
  { label: "Manasvikar (Psychological disorder)", value: "66" },
]

export const GENE_EXPERT_RESULTS = [
  { label: "MTB Detected RIF Sensitive", value: "MTB_DETECTED_RIF_SENSITIVE" },
  { label: "MTB Detected RIF Resistant", value: "MTB_DETECTED_RIF_RESISTANT" },
  { label: "MTB Not Detected", value: "MTB_NOT_DETECTED" },
  { label: "Invalid/Errors/No result", value: "NO_RESULT" },
  { label: "Test Indeterminate", value: "TEST_INDETERMINATE" }
];

export const RESISTIVITY_LEVEL_OPTIONS = [
  { label: "उच्च जोखिम क्षेत्र (१)", value: "HIGH_RISK" },
  { label: "मध्य जोखिम क्षेत्र (२)", value: "MODERATE_RISK" },
  { label: "न्युन जोखिम क्षेत्र (३)", value: "NOMINAL_RISK" },
  { label: "जोखिम नभएको (४)", value: "NO_RISK" }
];

export const SAMPLE_SOURCE_FOR_MALARIA_OPTIONS = [
  { label: "ACD", value: "ACD" },
  { label: "PCD", value: "PCD" }
];
export const MALARIA_KALAAZAR_TREATMENT_OPTIONS = [
  { label: "Microscopy", value: "MICROSCOPY" },
  { label: "RDT", value: "RDT" },
  { label: "rk-39", value: "RK_39" }
];

export const KALAAZAR_TESTS = [
  { label: "BM", value: "BM" },
  { label: "SP", value: "SP" },
  { label: "Other", value: "OTHER" },
]

export const PV_OR_PF_PMIX = [
  { label: "Pv", value: "Pv" },
  { label: "Pf", value: "Pf" },
  { label: "P Mix", value: "Pmix" }
]

export const MALARIA_TYPE = [
  { label: "Pv", value: "Pv" },
  { label: "Pf", value: "Pf" },
  { label: "RELAPSE", value: "Relapse" }
];

export const MALARIA_CATEGORY_CODES = [
  { label: "Indigenous(1)", value: "INDIGENOUS" },
  { label: "Imported(2)", value: "IMPORTED" }
];

export const MALARIA_MEDICATIONS = [
  { label: "Chloroquine", value: "CHLOROQUINE" },
  { label: "Primaquine", value: "PRAMIQUINE" },
  { label: "Coartem", value: "COARTEM" },
  { label: "Artesunate", value: "ARTESUNATE" },
  { label: "Other(specify)", value: OTHER }
];

export const KALAAZAR_MEDICATIONS = [
  { label: "Lipsomal amphotericin B/Miltefosine", value: "LIPSOMAL_AMPHOTERICIN_B_MILTEFOSINE" },
  { label: "Other(specify)", value: OTHER }
];

export const SEVERE_MALARIA_PATIENT_OPTIONS = [
  { label: "सम्भावित", value: "SPECULATED" },
  { label: "प्रमाणित", value: "PROVEN" }
];

export const FCHV_ACTIVITIES = [
  {
    label: "(क) गर्भवति सेवा",
    options: [
      { label: "१. आफ्नो क्षेत्रमा भेट गरिएका गर्भवति महिलाहरुको संख्या (जना)", value: "AAFNO_CHHETRAMA_BHET_GARIYEKA_GARBHAWATI_MAHILA_COUNT" },
      { label: "२. गर्भ जाचँको लागि स्वास्थ संस्थामा प्रेषण गरेको महिलाहरुको संख्या (जना)", value: "GHARBHA_JACHAKO_LAGI_SWASTHYA_SASTHAMA_PRESAN_GAREKO_MAHILA_COUNT" },
      { label: "३. आमावाट बच्चामा सर्ने एचआइभि सम्बन्धी सुचना दिएका गर्भवति महिलालाई रक्त परीक्षणका लागि रेफर गरेको संख्या (जना)", value: "HIV_RAKTAPARIKHSYANKA_LAGI_REFER_FEMALE_COUNT" },
      { label: "४. पहिलो पटक स्वास्थ संस्थामा गर्भ जाचँ गरेको सुनिश्चित गरेको महिलाहरुको संख्या (जना)", value: "PAHILO_PATAK_SWASTHYA_SASTHAMA_GARBHA_JACH_GAREKA_MAHILA_COUNT" },
      { label: "५. दोहोर्याइ आएको वेला आइरन चक्की वितरण गरेको गर्भवति महिलाहरुको संख्या (जना)", value: "DOHORYAI_AAYEKO_BELA_IRON_CHAKKI_WITARAN_GAREKO_GARBHAWATI_MAHILA_COUNT" },
      { label: "६. प्रसूति सेवाको लागि स्वास्थ संस्थामा प्रेषण गरेको गर्भवति महिलाहरुको संख्या (जना)", value: "PRASUTI_SEWAKA_LAGI_SWASTHYA_SASTHAMA_PRESHAN_GAREKO_GARBHAWATI_MAHILA_COUNT" },
      { label: "७. स्वास्थकर्मी बिना घरमै सुत्केरी भई मातृसुरक्षा चक्की ( मिसोप्रोस्टोल ) खाएको सुनिश्चित गरेको महिलाहरुको संख्या (जना)", value: "SWASTHYAKARMI_BINA_GHARAMAI_SUTKERI_BHAI_MATRISURAKHSYA_CHAKKI_KHAYEKA_MAHILAKO_COUNT" },
      { label: "८. सुरक्षित मातृत्व र नवशिशु सम्बन्धि सामग्री (फिलप चार्ट / पोस्टर / श्रब्य दृश्य सामग्री) प्रयोग गरी स्वास्थ्य शिक्षा पाएका संख्या (जना)", value: "SURAKSHIT_MATRITWO_NAWASISU_BIBARAN_SAMBANDHI_SAMAGRI_PRAYOG_GARI_SWASTHYA_SIKSHYA_PAYEKA_MAHILA_COUNT" }
    ]
  },
  {
    label: "(ख) घरमा जन्मेका शिशुहरुको जन्म अवस्था",
    options: [
      { label: "९. जिवित जन्म भएका शिशुहरु (जना)", value: "JIWIT_JANMA_BHAYEKA_SHISHUHARU" },
      { label: "१०. मृत जन्म भएका शिशुहरु (जना)", value: "MRITA_JANMA_BHAYEKA_SHISHUHARU" },
      { label: "११. जन्मेको २४ घण्टासम्म ननुहाएको सुनिश्चित गरिएको नवजात शिशुहरुको संख्या (जना)", value: "JANMEKO_24_GHANTASAMMA_NANUHAYEKO_NAWAJAAT_SHISHUKO_SANKHYAA" },
    ]
  },
  {
    label: "(ग) आमा र नवजात शिशु स्वास्थ्य",
    options: [
      { label: "१२. शिशु तथा बाल्यकालिन पोषण व्यवहार सम्बन्धी सल्लाह दिएको आमाहरुको संख्या (जना)", value: "SHISHU_TATHA_BALLYAKALIN_POSHAN_WYAWAHAR_SAMBANDHI_SALLAHA_DIYEKO_AAMAHARUKO_COUNT" },
      { label: "१३. सुत्केरी जाचँको लागि प्रेषण गरेको महिलाहरुको संख्या (जना)", value: "SUTKERI_JACHAKO_LAGI_PRESHAN_GAREKO_MAHILA_COUNT" },
      { label: "१४. घरमा प्रसुती भएका सुत्केरीलाई ४५ वटा आइरन चक्की वितरण गरेको महिलाहरुको संख्या (जना)", value: "GHARAMA_PRASUTI_BHAYEKA_SUTKERILAI_45_IRON_CHAKKI_WITARAN_GAREKO_MAHILA_COUNT" },
      { label: "१५. भिटामिन ए दिएको सुत्केरी महिलाहरुको संख्या (जना)", value: "VITAMIN_A_DIYEKO_SUTKERI_MAHILA_COUNT" }
    ]
  },
  {
    label: "(घ) खोप कार्यक्रम",
    options: [
      { label: "१६. खोप क्लिनिकमा सहभागी भई सघाएको (पटक)", value: "KHOP_CLINICMA_SAHABHAGI_BHAI_SAGHAYEKO" },
      { label: "१७. खोप लगाउन पठाएको नयाँ बच्चाको संख्या (जना)", value: "KHOP_LAGAUNA_PATHAYEKA_NAYA_BACHHAKO_COUNT" },
      { label: "१८. २३ महिना भित्रमा पूर्ण खोप प्राप्त गरेको बच्चा संख्या (जना)", value: "23_MAHINA_BHITRAMA_PURNA_KHOP_PRAPTA_BACHHAHARUKO_COUNT" },
      { label: "१९. गाउँघर क्लिनिकमा सहभागी भई सघाएको (पटक)", value: "GHAUGHAR_CLINICMA_SAHABHAGI_BHAI_SAGHAYEKO)" }
    ]
  },
  {
    label: "(ङ) २ महिना मुनिको बिरामी शिशुको उपचार तथा प्रेषण",
    options: [
      { label: "२०. ०-७ दिन सम्मका विरामी शिशुहरूको संख्या (जना)", value: "0_TO_7_DIN_SAMMAKA_BIRAMI_SHISHUHARUKO_COUNT" },
      { label: "२१. ८-२८ दिनसम्मका विरामी शिशुहरूको संख्या (जना)", value: "8_TO_28_DIN_SAMMAKA_BIRAMI_SHISHUHARUKO_COUNT" },
      { label: "२२. २९-५९ दिन सम्मका विरामी शिशुहरूको संख्या (जना)", value: "29_TO_59_DIN_SAMMAKA_BIRAMI_SHISHUHARUKO_COUNT" },
      { label: "२३. ०-७ दिन भित्र मृत्यु भएका नवजात शिशु संख्या (जना)", value: "0_TO_7_DIN_BHITRA_MRITU_VAYEKA_NAWAJAT_SISHUKO_COUNT" },
      { label: "२४. ८-२८ दिन भित्र मृत्यु भएका नवजात शिशु संख्या (जना)", value: "8_TO_28_DIN_BHITRA_MRITU_VAYEKA_NAWAJAT_SISHUKO_COUNT" },
      { label: "२५. २९-५९ दिन भित्र मृत्यु भएका बच्चा संख्या (जना)", value: "29_TO_59_DIN_BHITRA_MRITU_VAYEKA_BACHHAKO_COUNT" }
    ]
  },
  {
    label: "(च) २-५९ महिना भित्रका शिशु/ बालबालिकाको मृत्यु विवरण",
    options: [
      { label: "२६. २-११ महिना भित्र मृत्यु भएका बच्चा संख्या (जना)", value: "2_TO_11_MAHINA_BHITRA_MRITU_VAYEKA_BACHHAKO_COUNT" },
      { label: "२७. १२-५९ महिना भित्र मृत्यु भएका बालबालिकाको संख्या (जना)", value: "12_TO_59_MAHINA_BHITRA_MRITU_VAYEKA_BACHHAKO_COUNT" }
    ]
  },
  {
    label: "(छ) २-५९ महिना सम्मका बिरामी शिशुको उपचार तथा प्रेषण: झाडापखाला",
    options: [
      { label: "२८. झाडापखाला लागेका २ महिनादेखी ५ वर्षमुनिका जम्मा बिरामी बच्चाहरुको संख्या (जना)", value: "JHADAPAKHALA_LAGEKA_2_DEKHI_5_BARSHAMUNIKA_JAMMA_BACHCHAHARUKO_COUNT" },
      { label: "२९. पुनर्जलीय झोल र जिंक चक्कीबाट उपचार गरेका बच्चाहरुको संख्या (जना) ", value: "PUNARJALIYA_JHOL_RA_JINK_CHAKKIBATA_UPACHAR_GAREKO_BACHCHAHARUKO_COUNT" },
      { label: "३०. ५ वर्षमुनिका बच्चाहरुलाई वितरण गरेको पुनर्जलीय झोलको पुरिया संख्या (प्याकेट)", value: "5_BARSAMUNIKA_BACHCHAHARULAI_WITARAN_GAREKO_PUNARJALIYA_JHOLAKO_PURIYA_COUNT" },
      { label: "३१. ५ वर्ष भन्दा माथिका मानिसहरुलाई वितरण गरेको पुनर्जलीय झोलको पुरिया संख्या (प्याकेट)", value: "5_BARSA_BHANDA_MATHIKA_MANISHHARULAI_WITARAN_GARIYEKO_PUNARJALIYA_JHOLAKO_PURIYA_COUNT" },
      { label: "३२. वितरण गरेको जिंक चक्की संख्या (ट्याब्लेट)", value: "WITARAN_GAREKO_JINK_CHAKKI_COUNT" },
      { label: "३३. झाडापखाला लागेका २ महिनादेखी ५ वर्षसम्मका बिरामी बच्चाहरुलाई प्रेषण गरेको संख्या (जना)", value: "JHADAPAKHALA_LAGEKO_2_MAHINADEKHI_5_BARSHASAMMAKA_BIRAMI_BACHCHAHARULAI_PRESHAN_GAREKO_COUNT" }
    ]
  },
  {
    label: "(ज) २-५९ महिना सम्मका बिरामी शिशुको उपचार तथा प्रेषण: श्वासप्रश्वास रोग",
    options: [
      { label: "३४. श्वासप्रश्वास रोग लागेका २ देखी ५९ महिनाका बिरामी बच्चाहरुको संख्या (जना)", value: "SWASPRASWAS_ROG_LAGEKA_2_DEKHI_59_MAHINAKA_BIRAMI_BACHCHAHARUKO_COUNT" },
      { label: "३५. निमोनिया नभएको ( रुघाखोकी भएका ) ५ वर्ष मुनिका बच्चालाई घरेलु उपचार सल्लाह दिएको बच्चाहरुको संख्या (जना)", value: "NIMONIYA_NABHAYEKO_5_BARSA_MUNIKA_BACHCHALAI_GHARELU_UPACHAR_SALLAHA_DIYEKO_BACHCHAHARUKO_COUNT" },
      { label: "३६. श्वासप्रश्वास रोग भई स्वास्थ संस्थामा प्रेषण गरिएका २ देखी ५९ महिनाका बालबालिकाहरुको संख्या (जना)", value: "SWASPRASWAS_ROG_BHAI_SWASTHYA_SASTHAMA_PRESHAN_GARIYEKA_2_DEKHI_59_MAHINAKA_BAALBAALIKAHARUKO_COUNT" }
    ]
  },
  {
    label: "(झ) प्रेषण",
    options: [
      { label: "३७. सुरक्षित गर्भपतनको लागी स्वास्थ संस्थामा प्रेषण गरेका महिलाहरुको संख्या (जना)", value: "SURAKSHIT_GARBHAPATANKO_LAGI_SWASTHYA_SASTHAMA_PRESHAN_GAREKA_MAHILA_COUNT" },
      { label: "३८. स्वास्थ संस्थामा सेवा लिन प्रेषण गरिएका किशोर कीशोरीहरुको संख्या (जना)", value: "SWASTHYA_SASTHAMA_SEWA_LINA_PRESHAN_GARIYEKA_KISOR_KISORIHARUKO_COUNT" },
      { label: "३९. लगातार २ हप्तासम्म खोकी लागी स्वास्थ संस्थामा प्रेषण गरेका बिरामीहरुको संख्या (जना)", value: "LAGATAR_2_HAPTASAMMA_KHOKI_LAGI_SWASTHYA_SASTHAMA_PRESHAN_GAREKA_BIRAMIHARUKO_COUNT" },
      { label: "४०. प्राथमिक उपचार गरेको (जना)", value: "PRATHAMIK_UPACHAR_GAREKO" },
      { label: "४१. प्राथमिक उपचारको क्रममा प्रेषण गरेको बिरामीहरुको संख्या (जना)", value: "PRATHAMIK_UPACHARAKO_KRAMAMAA_PRESHAN_GAREKO_BIRAMIHARUKO_COUNT" }
    ]
  },
  {
    label: "(ञ) परिवार नियोजन",
    options: [
      { label: "४२(A). खाने चक्की पिल्स वितरण गरिएका महिलाहरुको संख्या (जना)", value: "KHANE_CHAKKI_PILLS_WITARAN_GARIYEKA_FEMALE_COUNT" },
      { label: "४२(B). आकस्मिक गर्भनिरोधक चक्की वितरण गरेका महिलाको संख्या (जना)", value: "WITARAN_GAREKO_AAKASMIK_CHAKKI_TO_FEMALE_COUNT" },
      { label: "४३(A). वितरण गरेको खाने चक्की पिल्स (साइकल)", value: "WITARAN_GAREKO_KHANE_CHAKKI_PILLS_COUNT" },
      { label: "४३(B). वितरण गरेको आकस्मिक चक्की (डोज)", value: "WITARAN_GAREKO_AAKASMIK_CHAKKI_CYCLE_COUNT" },
      { label: "४४. कण्डम वितरण गरेको (जना)", value: "CONDOM_WITARAN_GAREKO" },
      { label: "४५. वितरण गरेको कण्डमको संख्या (गोटा)", value: "WITARAN_GAREKO_CONDOM_COUNT" },
      { label: "४६. परिवार नियोजन सेवाको लागी स्वास्थ संस्थामा प्रेषण गरेको दम्पतिहरुको संख्या", value: "PARIWAR_NIYOJAN_SEWAKO_LAGI_SWASTHYA_SASTHAMA_PRESAN_GAREKO_DAMPATIHARUKO_COUNT" },
      { label: "४७. परिवार नियोजन सम्बन्धि सामग्री (फ्लिप चार्ट / पोस्टर/ श्रब्य दृश्य) प्रयोग गरी स्वास्थ्य शिक्षा पाएको संख्या", value: "PARIWAR_NIYOJAN_SAMBANDHI_SAMAGRI_PRAYOG_GARI_SWASTHYA_SIKSHYA_PAYEKO_COUNT" }
    ]
  },
  {
    label: "(ट) शिघ्र कुपोषणको  एकीकृत व्यवस्थापन एम. यु. ए. सी. छनौट",
    options: [
      { label: "४८. हरियो हिस्टपुस्ट (जना) खुशी परिवार", value: "HARIYO_HRISTAPUSTA_KHUSI_PARIWAAR" },
      { label: "४९. पहेलो मध्यम शिघ्र कुपोषण (जना) घरमा महिला स्वास्थ स्वयंसेवीकाद्वारा परामर्श ", value: "PAHELO_MADHYAM_SHIGRA_KUPOSHAN_GHARAMA_MAHILA_SWASTHYA_SWAIM_SEWIKA_DWARA_PARAMARSA" },
      { label: "५०. रातो अति कडा (जना) स्वास्थ संस्थामा प्रेषण", value: "RATO_ATI_KADA_SWASTHYA_SASTHAMA_PRESHAN" },
      { label: "५१. फुकेनास (जना) स्वास्थ संस्थामा प्रेषण", value: "FUKENAS_SWASTHYA_SASTHAMA_PRESHAN" }
    ]
  },
  {
    label: "(ठ) शिघ्र कुपोषणको  एकीकृत व्यवस्थापन: घरभेट र अनुगमन",
    options: [
      { label: "५२. रातो कडा शिघ्र कुपोषित बच्चा (जना) उपचार पछी निको भएको", value: "RATO_KADA_SHIGRA_KUPOSHIT_BACHCHA_UPACHAR_PACHHI_NIKO_BHAYEKO" },
      { label: "५३. रातो कडा शिघ्र कुपोषित बच्चा (जना) उपचार गरिरहदा पनि तौल वृद्धि नभएको", value: "RATO_KADA_SHIGRA_KUPOSHIT_BACHCHA_UPACHAR_GARIRAHADA_PANI_TAUL_BRIDHI_NABHAYEKO" },
      { label: "५४. रातो कडा शिघ्र कुपोषित बच्चा (जना) उपचार गर्दा गर्दै स्वास्थ संस्था जान छाडेका ", value: "RATO_KADA_SHIGRA_KUPOSHIT_BACHCHA_UPACHAR_GARDA_GARDAI_SWASTHYA_SASTHA_JANA_CHHADEKHA" }
    ]
  },
  {
    label: "(ठ) एकीकृत शिशु तथा बाल्यकालीन पोषण र बालभिटा समुदाय प्रवर्धन कार्यक्रम",
    options: [
      { label: "५५. ६ देखी ११ महिना: पहिलो पटक", value: "BAAL_VITA_DISTRIBUTED_SIX_TO_ELEVEN_MONTH_FIRST_TIME" },
      { label: "५६. १२ देखी १७ महिना: पहिलो पटक", value: "BAAL_VITA_DISTRIBUTED_TWELVE_TO_SEVENTEEN_MONTH_FIRST_TIME" },
      { label: "५७. १२ देखी १७ महिना: दोस्रो पटक ", value: "BAAL_VITA_DISTRIBUTED_TWELVE_TO_SEVENTEEN_MONTH_SECOND_TIME" },
      { label: "५८. १८ देखी २३ महिना: पहिलो पटक ", value: "BAAL_VITA_DISTRIBUTED_EIGHTEEN_TO_TWENTY_THREE_MONTH_FIRST_TIME" },
      { label: "५९. १८ देखी २३ महिना: दोस्रो पटक ", value: "BAAL_VITA_DISTRIBUTED_EIGHTEEN_TO_TWENTY_THREE_MONTH_SECOND_TIME" },
      { label: "६०. १८ देखी २३ महिना: तेस्रो पटक ", value: "BAAL_VITA_DISTRIBUTED_EIGHTEEN_TO_TWENTY_THREE_MONTH_THIRD_TIME" }
    ]
  },
  {
    label: "(ढ) विविध",
    options: [
      { label: "६१. आमा समुहको बैठक वसेको पटक", value: "AMA_SAMUHAKO_BAITHAK_BASEKO_COUNT" },
      { label: "६२. आमा समुहको बैठक सहभागी संख्या (जना)", value: "AMA_SAMUHAKO_BAITHAKMA_SAHABGAHI_COUNT" },
      { label: "६३. आमा समुहको बैठकमा स्वास्थ्यकर्मी सहभागी भएको पटक", value: "AMA_SAMUHAKO_BAITHAKMA_SWASTHYAKARMI_SAHABGAHI_BHAYEKO_COUNT" },
      { label: "६४. गर्भवती अवस्थामा मातृ मृत्यु संख्या (स्वास्थ्य संस्थामा बाहेक अन्य स्थानमा भएको मात्र) (जना)", value: "SWASTHYA_SANSTHA_BAHEK_ANYA_SANSTHAMA_GARBHAWATI_AWASTHAMA_MATRI_MRITU_COUNT" },
      { label: "६५. प्रसव अवस्थामा मातृ मृत्यु संख्या (स्वास्थ्य संस्थामा बाहेक अन्य स्थानमा भएको मात्र) (जना)", value: "SWASTHYA_SANSTHA_BAHEK_ANYA_SANSTHAMA_PRASAB_AWASTHAMA_MATRI_MRITU_COUNT" },
      { label: "६६. सुत्केरी अवस्थामा मातृ मृत्यु संख्या (स्वास्थ्य संस्थामा बाहेक अन्य स्थानमा भएको मात्र) (जना)", value: "SWASTHYA_SANSTHA_BAHEK_ANYA_SANSTHAMA_SUTKERI_AWASTHAMA_MATRI_MRITU_COUNT" },
      { label: "६७. २९-५९ दिन सम्मको बच्चाको मृत्यु संख्या (स्वास्थ्य संस्थामा बाहेक अन्य स्थानमा भएको मात्र) (जना)", value: "SWASTHYA_SANSTHA_BAHEK_ANYA_SANSTHAMA_29_TO_59_DIN_SAMMAKO_BACHHAKO_MRITU_COUNT" },
      { label: "६८. २ महिना देखि ५९ महिनासम्मका बालवालिकाको मृत्यु संख्या (स्वास्थ्य संस्थामा बाहेक) (जना)", value: "SWASTHYA_SANSTHA_BAHEK_ANYA_SANSTHAMA_2_TO_59_MAHINA_SAMMAKO_BALBALIKAKO_MRITU_COUNT" },
      { label: "६९. बाल स्वास्थ्य सम्बन्धि स्वास्थ्य शिक्षा सामग्री (फिलप चार्ट / पोस्टर / श्रब्य दृश्य सामग्री) प्रयोग गरी स्वास्थ्य शिक्षा पाएका संख्या", value: "BAAL_SWASTHYA_SAMBANDHI_SAMAGRI_PRAYOG_GARI_SWASTHYA_SIKSHYA_PAYEKA_COUNT" },
      { label: "७०. स्वस्थ्य जीवनशैलीको लागि नसर्ने रोगका जोखिम तत्व र रोकथामको बारेमा फिलप चार्ट/पोस्टर/ श्रब्य दृश्य सामग्री प्रयोग गरी स्वास्थ्य शिक्षा दिएको (पटक)", value: "NASARNE_ROGKO_JOKHIM_TATWA_ROKTHAMKO_BAREMA_FILLUP_CHART_POSTER_SHRABYA_DRISHYA_SAMAGRI_PRAYOG_GARI_SWASTHYA_SIKSHYA_DIYEKO_COUNT" },
      { label: "७१. स्वस्थ्य जीवनशैलीको लागि नसर्ने रोगका जोखिम तत्वको बारेमा फिलप चार्ट /पोस्टर/ श्रब्य दृश्य सामग्री प्रयोग गरी स्वास्थ्य शिक्षाबाट लाभान्वित संख्या", value: "NASARNE_ROGKO_JOKHIM_TATWA_ROKTHAMKO_BAREMA_FILLUP_CHART_POSTER_SHRABYA_DRISHYA_SAMAGRI_PRAYOG_GARI_SWASTHYA_SIKSHYA_BATA_LABHANBIT_COUNT" },
      { label: "७२. आफनो क्षेत्रका शंकास्पद क्षयरोगका बिरामीको प्रेषण गरेको संख्या (जना)", value: "AFNO_CHETRAKA_SANKASPAD_CHAYAAROGKA_BIRAMIKO_PRESAN_GAREKO_COUNT" },
      { label: "७३. आफनो क्षेत्रका शंकास्पद कुष्ठरोगका बिरामीको प्रेषण गरेको संख्या(जना)", value: "AFNO_CHETRAKA_SANKASPAD_KUSTHAROGKA_BIRAMIKO_PRESAN_GAREKO_COUNT" },
      { label: "७४. नसर्ने रोग(मधुमेह, मृगौला, दीर्घ श्वासप्रश्वास अर्बुदरोग बुद्ररोग) का बिरामीको प्रेषण गरेको संख्या(जना)", value: "NASARNE_ROG_MADHUMEHA_MIRGAULA_DIRGA_SWASPRASWAS_ARBUDAROG_BRUDHAROGKA_BIRAMIKO_PRESAN_GAREKO_COUNT" },
      { label: "७५. मानसिक स्वास्थ्य समस्या भएका बिरामीको प्रेषण गरेको संख्या(जना)", value: "MANASIK_SWASTHYA_SAMASYA_BHAYEKA_BIRAMIKO_PRESAN_GAREKO_COUNT" },
      { label: "७६. पाठेघर खस्ने समस्या भएका आमाको प्रेषण गरेको संख्या(जना)", value: "PATHEGHAR_KHASNE_SAMASYA_BHAYEKA_AAMAKO_PRESAN_GAREKO_COUNT" },
      { label: "७७. पाठेघरको मुखको क्यान्सरको जांचको लागि प्रेषण गरेको संख्या(जना)", value: "PATHEGHARKO_MUKHKO_CANCERKO_JAANCHKO_LAGI_PRESAN_GAREKO_COUNT" },
      { label: "७८. स्वास्थ्य समस्या भएका जेस्ठ नागरिकको पहिचान गरी प्रेषण गरेको संख्या(जना)", value: "SWASTHYA_SAMASYA_BHAYEKA_JESTHA_NAGARIKKO_PAHICHAN_GARI_PRESAN_GAREKO_COUNT" },
      { label: "७९. महिला सामुदायिक स्वास्थ्य स्वमसेविका कोषमा जम्मा भएको रकम रु. (लगानी समेत)", value: "MAHILA_SAMUDAYIK_SWASTHYA_SWAYMSEWIKA_KOSHMA_BHAYEKO_LAGANI_SAMETKO_TOTAL_AMOUNT" },
      { label: "८०. नवजात शिशुको ३५ दिनभित्र जन्म दर्ताका लागि परामर्श दिएको परिवार संख्या (परिवार)", value: "NAWAJAT_SISHUKO_35_DINBHITRA_JANMA_DARTAKO_LAGI_PARAMARSHA_DIYEKO_PARIWAAR_COUNT" },
      { label: "८१. नवजात शिशुको जन्म दर्ता भएको सुनिश्चित गरिएको संख्या (जना)", value: "NAWAJAT_SISHUKO_JANMA_DARTA_BHAYEKO_SUNISCHIT_GARIYEKO_COUNT" },
      { label: "८२. मृत्यु भएको ३५ दिनभित्र मृत्यु दर्ताका लागि परामर्श दिएको परिवार संख्या (परिवार)", value: "MRITU_BAHYEKO_35_DINBHITRA_MRITU_DARTAKO_LAGI_PARAMARSHA_DIYEKO_PARIWAAR_COUNT" },
      { label: "८३. मृत्यु दर्ता भएको सुनिश्चित गरिएको संख्या (जना)", value: "MRITU_DARTA_BHAYEKO_SUNISCHIT_GARIYEKO_COUNT" },
      { label: "८४. १३ हप्तासम्म आइरन फोलिक एसिड चक्की पाएका किशोरीको संख्या (संख्या)", value: "13_HAFTASAMMA_IRONFOLIC_ACID_CHAKKI_PAYEKA_KISHORIKO_COUNT" },
      { label: "८५. २६ हप्तासम्म आइरन फोलिक एसिड चक्की पाएका किशोरीको संख्या (संख्या)", value: "26_HAFTASAMMA_IRONFOLIC_ACID_CHAKKI_PAYEKA_KISHORIKO_COUNT" },
    ]
  }
];

export const AEFI_WARGIKARAN_OPTIONS = [
  { value: "SIMPLE", label: "सामान्य" },
  { value: "RISK", label: "कडा" }
]

export const BAAL_VITA_PROVIDERS = [
  { value: "HEALTH_POST", label: "स्वास्थ संस्था" },
  { value: "FEMALE_HEALTH_VOLUNTEER", label: "महिला स्वास्थ स्वयम सेविका" }
]

export const PILLS_DEPO_TAKEN_FISCAL_YEAR = [
  { value: "FIRST", label: "पहिलो" },
  { value: "SECOND", label: "दोस्रो" },
  { value: "THIRD", label: "तेस्रो" }
];

export const SAFE_ABORTION_PROCEDURE_CODES = [
  { value: "MVA", label: "Manual Vacuum Aspiration (1)" },
  { value: "EVA", label: "Electric Vacuum Aspiration (2)" },
  { value: "MA", label: "Medical Abortion(3)" },
  { value: "MI", label: "Medical Induction (4)" },
  { value: "D_AND_E", label: "Dilatation and Evacuation (5)" },
  { value: "OTHER", label: "Misoprostol for incomplete abortion (6)" },
];

export const INCOMPLETE_ABORTION_REQUIRING_REPEAT_PROCEDURE_OPTIONS = [
  { value: "MVA", label: "Manual Vacuum Aspiration (1)" },
  { value: "MISOPROSTOL_FOR_INCOMPLETE_ABORTION", label: "Misoprostol for incomplete abortion (6)" },
]

export const SAFE_ABORTION_PAC_CLIENTS_DIAGNOSIS_CODES = [
  { value: "INCOMPLETE_ABORTION_INDUCED", label: "Incomplete Abortion (Induced)" },
  { value: "INCOMPLETE_ABORTION_SPONTANEOUS", label: "Incomplete Abortion (Spontaneous)" },
  { value: "HEAVY_BLEEDING_REQUIRING_BLOOD_TRANSFUSION", label: "Heavy Bleeding Requiring Blood Transfusion" },
  { value: "UTERINE_INTRA_ABDOMINAL_INJURY_REQUIRING_LAPROTOMY", label: "Uterine / Intra Abdominal Injury Requiring Laprotomy" },
  { value: "INFECTION_REQUIRING_HOSPITALIZATION_WITH_IV_ANTIBIOTICS", label: "Infection Requiring Hospitalization With IV Antibiotics" }
];

export const SAFE_ABORTION_ACCEPTED_FP_METHODS_CODES = [
  { value: "1", label: "Female Sterilization (1)" },
  { value: "2", label: "Male Sterilization (2)" },
  { value: "3", label: "Implant (3)" },
  { value: "4", label: "IUCD (4)" },
  { value: "5", label: "Depo Provera (5)" },
  { value: "6", label: "Sayana Press (6)" },
  { value: "7", label: "Pills (7)" },
  { value: "8", label: "Condom (8)" },
  { value: "9", label: "Others (eg. emergency contraceptive pills) (9)" }
];

export const SAFE_ABORTION_REFERRED_FROM_CODES = [
  { value: "1", label: "FCHV बाट प्रेषण भएमा(1)" },
  { value: "2", label: "Health Care Provider बाट प्रेषण भएमा(2)" },
  { value: "3", label: "Friend / Family बाट प्रेषण भएमा(3)" },
  { value: "4", label: "Pharmacy बाट प्रेषण भएमा(4)" },
  { value: "5", label: "Hotline बाट प्रेषण भएमा(5)" },
  { value: "6", label: "Other (Mothers group, Clubs etc.) बाट प्रेषण भएमा(6)" }
];

export const SAFE_ABORTION_CAC_PAC = [
  { value: "REFERRED_OUT", label: "Referred Out" },
  { value: "NORMAL", label: "Normal" },
  { value: "LAMA", label: "LAMA" },
  { value: "DEATH", label: "Death" },
];

export const AGE_UNITS = [
  { value: "YEAR", label: "वर्ष" },
  { value: "MONTH", label: "महिना" },
  { value: "DAY", label: "दिन" }
];

export const TREATMENT_MEDICINE_OPTIONS = [
  { value: "VITAMIN_A", label: "भिटामिन ए" },
  { value: "AMOXICILLIN", label: "एमोक्सिसिलिन" },
  { value: "MALARIA_TREATMENT", label: "औलोको उपचार" },
  { value: "ALBENDAZOLE", label: "अल्बेन्डाजोल(एम.जि.)" },
  { value: "RUTF", label: "RUTF" },
  { value: OTHER, label: "अन्य औषधी" }
];

export const MEDICINE_OPTIONS = [
  { value: "PARACETAMOL", label: "प्यारासिटामोल" },
  { value: "AMOXICILLIN", label: "Amoxicillin" },
  { value: "IV_FLUID", label: "IV Fluid" },
  { value: "ZINC", label: "Zinc" },
  { value: "ORS", label: "ORS" },
  { value: "ALBENDAZOLE", label: "Albendazole" },
  { value: "VITAMIN_A", label: "Vitamin A" },
  { value: "IRON_TABLET", label: "आइरन चक्की" },
  { value: "IV_AMPICILLIN", label: "IV Ampicillin" },
  { value: "HOME_TREATMENT", label: "घरेलु उपचार" },
  { value: ANTIBIOTIC, label: "Other Antibiotic" },
  { value: OTHER, label: "Other Medicine" },
];

export const MEDICINE_OPTIONS_FOR_CHILD_BELOW_TWO_MONTHS = [
  { value: "AMOXICILLIN", label: "Amoxicillin" },
  { value: "AMPICILLIN", label: "Ampicillin" },
  { value: ANTIBIOTIC, label: "Other Antibiotic" },
  { value: OTHER, label: "Other Medicine" }
];

export const MEDICINE_UNIT_OPTIONS = [
  { value: "PACKETS", label: "Packets", code: "pkt" },
  { value: "TABLET", label: "Tablet", code: "tab" },
  { value: "CAPSULE", label: "Capsule", code: "cap" },
  { value: "BOTTLE", label: "Syrup", code: "syp" },
  { value: "TUBE", label: "Tube", code: "tube" },
  { value: "PHIAL", label: "Phial", code: "syp" },
  { value: "VIAL", label: "Vial", code: "inj" },
  { value: "AMPOULE", label: "Ampoule", code: "inj" },
  { value: "PINT", label: "Pint", code: "inj" },
  { value: "DROP", label: "Drop", code: "drop" }
];

export const PATIENT_ADMISSION_TYPES = [
  { value: "NEW_ADMISSION", label: "नँया भर्ना" },
  { value: "RE_ADMISSION", label: "पुन: दर्ता भएको (२ महिना भित्र)" },
  { value: "REFERRED_ADMISSION", label: "बाहिरबाट आएको (बहिरङ्ग/विशेष उपचारकक्ष/पोषण पुनःस्थापना गृह बाट)" },
  { value: "AFTER_DEFAULTER_ADMISSION", label: "डिफल्टर पछी भर्ना" }
];

export const PRESAN_TYPES = [
  { value: "SELF", label: "आफै/स्वंम प्रेषण" },
  { value: "SPECIAL_TREATMENT_ROOM", label: "विशेष उपचार कक्ष" },
  { value: "VILLAGE_CLINIC", label: "गाउँघर क्लिनिक" },
  { value: "WOMEN_VOLUNTEER", label: "म.स्वा.स्वं.से." },
  { value: "NUTRITION_RE_ESTABLISHMENT_CENTER", label: "पोषण पुन:स्थापना केन्द्र" },
  { value: "OTHER_HEALTH_OFFICE", label: "अन्य स्वास्थ्य संस्था" }
];

export const POSAN_CONDITION = [
  { value: "MODERATE", label: "मध्यम" },
  { value: "TOUGH", label: "कडा" }
];

export const PLUS_SYMBOL_INDICATORS = [
  { value: "+", label: "+" },
  { value: "++", label: "++" },
  { value: "+++", label: "+++" },
  { value: "NO", label: "छैन" }
];

export const TB_TEST_TYPE = [
  { value: "DIAGNOSIS", label: "निदान" },
  { value: "FOLLOW_UP", label: "अनुगमन" }
];

export const VISIT_TYPE = [
  { value: "FIRST_TIME", label: "पहिलो" },
  { value: "FOLLOW_UP", label: "फलो-अप" }
];

export const SCREENING_TEST_FOR_UTERUS_CANCER = [
  { value: "VIA", label: "VIA" },
  { value: "HPV", label: "HPV" },
  { value: "PAP_SMEAR", label: "Pap Smear" }
]

export const SCREENING_METHOD_FOR_BREAST_CANCER = [
  { value: "CLINICAL_BREAST_EXAMINATION", label: "Clinical Breast Examination" },
  { value: "USG", label: "USG" },
  { value: "MAMMOGRAM", label: "Mammogram" }
]

export const RESULT_FOR_UTERUS_CANCER = [
  { value: "POSITIVE", label: "Positive" },
  { value: "NEGATIVE", label: "Negative" },
  { value: "SUSPECT_CANCER", label: "Suspect Cancer" }
]

export const IDENTIFICATION_RESULT_FOR_BREAST_CANCER = [
  { value: "NORMAL", label: "Normal" },
  { value: "SUSPICIOUS", label: "Suspicious" }
]

export const IDENTIFICATION_FOR_FALLING_LIMBS = [
  { value: "NO_PROLAPSE", label: "No Prolapse" },
  { value: "1_AND_2_DEGREE", label: "1 and 2 Degree" },
  { value: "3_DEGREE", label: "3 Degree" },
  { value: "4_DEGREE", label: "4 Degree" },
  { value: "VAULT_PROLAPSE", label: "Vault Prolapse" }
]

export const TREATMENT_METHOD_FOR_UTERUS_CANCER = [
  { value: "ABLATIVE_TREATMENT", label: "Ablative Treatment" },
  { value: "LEEP", label: "LEEP" },
  { value: OTHER, label: "Other" },
]

export const TREATMENT_METHOD_FOR_FALLING_LIMBS = [
  { value: "KEGEL_EXERCISE", label: "Kegel Exercise" },
  { value: "RING_PESSARY", label: "Ring Pessary" },
  { value: "VH_PFR", label: "VH ± PFR" },
  { value: OTHER, label: "Other" },
  { value: "REFERRED_FOR_SURGERY", label: "Referred For Surgery" }
]

export const IDENTIFICATION_FOR_FISTULA = [
  { value: "NORMAL", label: "Normal" },
  { value: "SUSPECTED_FISTULA", label: "Suspected Fistula" },
]

export const TREATMENT_METHOD_FOR_FISTULA = [
  { value: "VVF_REPAIR", label: "VVF Repair" },
  { value: "RVF_REPAIR", label: "RVF Repair" },
  { value: "VVF_WITH_RVF_REPAIR", label: "VVF with RVF Repair" },
  { value: "URETHRO_VAGINAL_FISTULA_REPAIR", label: "Urethro Vaginal Fistula Repair" },
  { value: OTHER, label: "Other" },
  { value: "REFERRED", label: "Referred" }
]

export const LAB_RESULT_OPTIONS = [
  { value: "NEGATIVE", label: "Negative" },
  { value: "POSITIVE", label: "Positive" },
];

export const SMEAR_LAB_RESULT_OPTIONS = [
  { value: "NEGATIVE", label: "Negative" },
  { value: "POSITIVE", label: "Positive" }
];

export const KALAAZAR_REPORT = [
  { value: "NEGATIVE", label: "Negative" },
  { value: "POSITIVE", label: "Positive" }
]

export const TYPE_OF_CARE = [
  { value: "GENERAL_MEDICINE", label: "General Medicine(1)" },
  { value: "GENERAL_SURGERY", label: "General Surgery(2)" },
  { value: "PAEDIATRICS", label: "Paediatrics(3)" },
  { value: "OBSTETRIC", label: "Obstetric(4)" },
  { value: "GYNECOLOGY", label: "Gynecology(5)" },
  { value: "ENT", label: "ENT(6)" },
  { value: "ORTHOPEDICS", label: "Orthopedics(7)" },
  { value: "PSYCHIATRIC", label: "Psychiatric(8)" },
  { value: "DENTAL", label: "Dental(9)" },
  { value: OTHER, label: "Others(10)" },
];

export const TYPE_OF_SURGERY = [
  { value: "MAJOR", label: "Major(1)" },
  { value: "INTERMEDIATE", label: "Intermediate(2)" },
  { value: "MINOR", label: "Minor(3)" },
];

export const OUTCOME_CODE = [
  { value: "RECOVERED_CURED", label: "Recovered/Cured(1)" },
  { value: "NOT_IMPROVED", label: "Not Improved(2)" },
  { value: "REFERRED", label: "Referred(3)" },
  { value: "DOR_LAMA_DAMA", label: "DOR/LAMA/DAMA(4)" },
  { value: "ABSCONDED", label: "Absconded(5)" },
  { value: "DEATH", label: "Death(6)" },
];

export const DISCHARGE_PATIENT_TYPE = [
  { value: "ULTRA_POOR_OR_DESTITUTE", label: "अति गरिब वा असहाय(१)" },
  { value: "POOR", label: "गरिब(२)" },
  { value: "DISABLED", label: "अपाङ्ग(३)" },
  { value: "SENIOR_CITIZEN", label: "ज्यष्ठ नागरिक > ६० वर्ष(४)" },
  { value: "FCHV", label: "म.स्वा.स्व.से.(५)" },
  { value: OTHER, label: "अन्य(६)" },
];

export const PATIENT_TYPE_CODE = [
  { value: "ULTRA_POOR", label: "अति गरिब वा असहाय(१)" },
  { value: "POOR", label: "गरिब(२)" },
  { value: "DISABLED", label: "अपाङ्ग(३)" },
  { value: "SENIOR_CITIZEN", label: "ज्यष्ठ नागरिक > ६० वर्ष(४)" },
  { value: "FCHV", label: "म.स्वा.स्व.से.(५)" },
  { value: "GENDER_VOILENCE", label: "लैङ्गिक हिंसा" },
  { value: OTHER, label: "अन्य(६)" },
]

// Lab Module Constats Start Here
export const REPORT_DEFAULT_TEMPLATES = [
  { value: "BIOCHEMICAL_TEST", label: "Biochemical Test" },
  { value: "DIRECT_SMEAR_TEST", label: "Direct Smear Test" },
  { value: "HEMATOLOGICAL_TEST", label: "Hematological Test" },
  { value: "SEMEN_TEST", label: "Semen Test" },
  { value: "SEROLOGICAL_TEST", label: "Serological Test" },
  { value: "STOOL_TEST", label: "Stool Test" },
  { value: "URINE_TEST", label: "Urine Test" },
  { value: "IMMUNOLOGY_TEST", label: "Immunology Test" },
  { value: "BACTERIOLOGY_TEST", label: "Bacteriology Test" },
  { value: "IMMUNO_HISTO_CHEMISTRY_TEST", label: "Immuno Histo Chemistry Test" },
  { value: "VIROLOGY_TEST", label: "Virology Test" },
  { value: "PARASITOLOGY_TEST", label: "Parasitology Test" },
  { value: "TEST_DURING_ANALYSIS", label: "Test During Analysis" },
  { value: "HORMONE_ENDOCRINE_TEST", label: "Hormone / Endocrine Test" },
  { value: "HISTOPATHOLOGY_CYTOLOGY_TEST", label: "Histopathology / Cytology Test" },
  { value: "MOLECULAR_LAB_AND_FLOWCYTOMETRY_TEST", label: "Molecular Lab and Flowcytometry Test" }
]
// Lab Module Constats Ends Here

export const SERVICES_AVAILABLE = [
  { value: "TREATMENT_SERVICE", label: "उपचार सेवा" },
  { value: "PANCHAKARMA_SERVICE", label: "पञ्चकर्म सेवा" },
  { value: "SURGERY_SERVICE", label: "शल्य सेवा" },
  { value: "YOGA_SERVICE", label: "योगसेवा" },
  { value: "SENIOR_CITIZEN_SERVICE", label: "जेष्ठ नागरिक सेवा" },
  { value: "MOTHER_CHILD_SERVICE", label: "स्तनपायी आमा तथा मातृशिशु सेवा" },
  { value: "GYNECOLOGY_SERVICE", label: "स्त्री प्रसूति सेवा" },
  { value: "SCHOOL_AYURVEDA_YOGA_EDUCATION", label: "विद्यालय आयुर्वेद तथा योग शिक्षा" },
];

export const MALARIA_KAALAZAR_REGISTER_TYPE = [
  { value: "MALARIA", label: "औलो" },
  { value: "LEPROSY", label: "कुष्ठ" },
  { value: "KALAAZAR", label: "कालाजार" },
]

export const CONTRACEPTIVE_DEVICES_USED_SITUATION = [
  { value: "WITHIN_FORTY_EIGHT_HOUR", label: "सुत्केरी भएको ४८ घण्टा भित्र" },
  { value: "WITHIN_FORTY_EIGHT_TO_ONE_YEAR_AFTER_PREGNANT", label: "सुत्केरी भएको ४८ घण्टा देखि १ वर्ष भित्र" },
  { value: OTHER, label: "अन्य" }
]

export const MALARIA_KAALAZAR_TREATMENT_REGISTER_TYPE = [
  { value: "ALL", label: "सबै" },
  { value: "MALARIA", label: "औलो" },
  { value: "KALAAZAR", label: "कालाजार" },
]

export const TB_TREATMENT_REFER = [
  { value: "SELF", label: "Self" },
  { value: "PRIVATE", label: "Private" },
  { value: "COMMUNITY", label: "Community" },
  { value: "CONTACT_INVEST", label: "Contact Invest" }
]

export const TB_TREATMENT_REGIMEN = [
  { value: "2HRZE_PLUS_4HR", label: "2HRZE + 4HR" },
  { value: "2HRZE_PLUS_7HRE", label: "2HRZE + 7HRE" },
  { value: "6HRZE", label: "6HRZE" },
  { value: "6HRZE_PLUS_Lfx", label: "6HRZE + Lfx" },
]

export const DST_STATUS = [
  { value: "XPERT_MTB_OR_RIF", label: "Xpert MTB/RIF" },
  { value: "LPA", label: "LPA" }
]

export const SMOKING_STATUS = [
  { value: "CURRENT_SMOKER", label: "Current Smoker(S)" },
  { value: "RELAPSED_SMOKER", label: "Relapsed Smoker(R)" },
  { value: "QUITTER", label: "Quitter(Q)" }
]

export const HIV_RESULT = [
  { value: "REACTIVE", label: "Reactive" },
  { value: "NON_REACTIVE", label: "Non Reactive" },
  { value: "UNKNOWN", label: "Unknown" }
]

export const MGIT_DST_OPTIONS = [
  { value: "R", label: "R" },
  { value: "H", label: "H" },
  { value: "Z", label: "Z" },
  { value: "AM", label: "Am" },
  { value: "BDQ", label: "Bdq" },
  { value: "CFZ", label: "Cfz" },
  { value: "LZD", label: "Lzd" },
  { value: "MFX_H", label: "Mfx(H)" },
  { value: "MFX_L", label: "Mfx(L)" },
  { value: "ETO", label: "Eto" },
  { value: "LFX", label: "Lfx" },
  { value: "DIM", label: "Dim" },
  { value: "OTHERS", label: "Others" },
]

export const SYPHILLIS_RESULT = [
  { value: "POSITIVE", label: "+ve" },
  { value: "NEGATIVE", label: "-ve" }
]

export const TEST_POSITIVE_REPORT_GRADE = [
  { value: "ONE_PLUS", label: "1+" },
  { value: "TWO_PLUS", label: "2+" },
  { value: "THREE_PLUS", label: "3+" },
  { value: "FOUR_PLUS", label: "4+" },
  { value: "FIVE_PLUS", label: "5+" }
]

export const SKIN_BLEMISHES = [
  { value: "ONE_TO_FIVE_BLEMISHES", label: "स्पर्श शक्ति हराएको १ देखि ५ वटा दाग/दागहरु" },
  { value: "SIX_OR_MORE_BLEMISHES", label: "स्पर्श शक्ति हराएको ६ वा शो भन्दा बढी दाग/दागहरु" },
]

export const SKIN_SMEAR_GERMS = [
  { value: "NO_GERMS_IN_SKIN_SMEAR", label: "स्किन स्मयरमा किटाणु नदेखिएको" },
  { value: "GERMS_IN_SKIN_SMEAR", label: "स्किन स्मयरमा किटाणु देखिएका" },
]

export const NERVES_AFFECTED = [
  { value: "ONE_NERVE_AFFECTED", label: "कुनै एक स्नायु प्रभावित भई क्षमतामा भएको" },
  { value: "TWO_OR_MORE_NERVE_AFFECTED", label: "२ वा सो भन्दा बढी स्नायु प्रभावित भई क्षमतामा भएको" },
]

export const PRESUMPTIVE_TB_TYPE = [
  { value: "SPUTUM", label: "Sputum" },
  { value: "PATIENT_REFEREE", label: "Patient Referee" },
]

export const CONTACT_INVESTIGATION_OUTCOME = [
  { value: "COMPLETED", label: "Completed" },
  { value: "DISCONTINUE", label: "Discontinue" },
  { value: "DIED", label: "Died" },
  { value: "NOT_EVALUATED", label: "Not Evaluated" }
]

export const DS_OR_DRTB = [
  { value: "DS", label: "DS" },
  { value: "DRTB", label: "DRTB" }
]

export const OPD_OR_PRESUMPTIVE = [
  { value: "OPD", label: "OPD" },
  { value: "PRESUMPTIVE", label: "Presumptive" }
]

export const CURRENT_TREATMENT = [
  { value: "NEW", label: "New" },
  { value: "RETREAT", label: "Retreat" }
]

export const PURPOSE_OF_XPERT_TEST = [
  { value: "DIAGNOSIS", label: "Diagnosis" },
  { value: "RR_DETECTION", label: "RR Detection" },
  { value: "SECOND_LINE_DST", label: "2nd Line DST" },
  { value: "INH_RESISTANCE", label: "INH Resistance" }
]

export const XPERT_SPECIMEN_TYPE = [
  { value: "SPUTUM", label: "खकार" },
  { value: "OTHER", label: "अन्य" }
]

export const TEST_RESULT_MTB = [
  { value: "DETECTED", label: "Detected" },
  { value: "NOT_DETECTED", label: "Not Detected" },
  { value: "INVALID_NO_RESULT", label: "Invalid / No result" },
  { value: "ERROR_CODE", label: "Error Code" }
]

export const TEST_RESULT_MTB_XDR = [
  { value: "HR", label: "High Level Resistance(Hr)" },
  { value: "LR", label: "Low Level Resistance(Lr)" },
  { value: "RND", label: "Resistance Not Dectected(RND)" },
  { value: "I", label: "Indeterminant(I)" },
]

export const RIF_RESISTANCE_TEST_RESULT = [
  { value: "DETECTED", label: "Detected" },
  { value: "NOT_DETECTED", label: "Not Detected" },
  { value: "INDETERMINATE", label: "Indeterminate" }
]

export const SCREENED_BY = [
  { value: "X_RAY", label: "X-Ray विधिबाट" },
  { value: "SYMPTOMS", label: "लक्षणको आधारमा" }
]

export const TB_LAB_RESULT = [
  { value: "MICROSCOPY", label: "Microscopy" },
  { value: "XPERT_MTB/RIFFF", label: "Xpert MTB/RIFFF" },
  { value: "CULTURE", label: "Culture" },
  { value: "LPA", label: "LPA" },
  { value: "OTHER", label: "Other" },
]

export const REQUESTED_FOR_DIAGNOSIS = [
  { value: "MICROSCOPY", label: "Microscopy परीक्षणलाई पठाइएको" },
  { value: "XPERT_MTB_OR_RIF", label: "Xpert MTB/RIF परीक्षणलाई पठाइएको" },
  { value: "CULTURE", label: "Culture परीक्षणलाई पठाइएको" },
  { value: "LPA", label: "LPA परीक्षणलाई पठाइएको" },
]

export const TB_DIAGNOSIS = [
  { value: "PBC", label: "PBC" },
  { value: "PCD", label: "PCD" },
  { value: "EP", label: "EP" },
  { value: "HR_TB", label: "HRTB" },
  { value: "DR_TB", label: "DR TB" },
]

export const KUSTHAROG_SPECIMEN_TYPES = [
  { value: "ONE_PLUS", label: "1+" },
  { value: "TWO_PLUS", label: "2+" },
  { value: "THREE_PLUS", label: "3+" },
  { value: "FOUR_PLUS", label: "4+" },
  { value: "FIVE_PLUS", label: "5+" },
  { value: "SIX_PLUS", label: "6+" },
  { value: "NEGATIVE", label: "-ve" },
]
export const USUAL_SIGNS_OF_DANGER = [
  { value: "POTENTIALLY_SERIOUS_BACTERIAL_INFECTION", label: "व्याक्टेरियाको सम्भावित गम्भिर संक्रमण" },
  { value: "LOCAL_INFECTION_OF_BACTERIA", label: "व्याक्टेरियाको स्थानिय संक्रमण" },
  { value: "NO_BACTERIAL_INFECTION", label: "व्याक्टेरियाको संक्रमण नभएको" },
]

export const JAUNDICE = [
  { value: "SEVERE_JAUNDICE", label: "कडा जण्डिस (कमलपित्त)" },
  { value: "JAUNDICE", label: "जण्डिस (कमलपित्त)" }
]

export const SITAANGA = [
  { value: "SEVERE_SITAANGA", label: "कडा सिताङ्ग" },
  { value: "SITAANGA", label: "सिताङ्ग" }
]

export const BREASTFEEDING_PROBLEMS_OR_LOW_BIRTH_WEIGHT = [
  { value: "BREASTFEEDING_PROBLEMS", label: "स्तनपान सम्बन्धी समस्या वा कम तौल" },
  { value: "NO_BREASTFEEDING_PROBLEMS", label: "स्तनपान सम्बन्धी समस्या नभएको" }
]

export const NUTRITION_RELATED = [
  { value: "SEVERE_ACUTE_MALNUTRITION", label: "कडा शिघ्र कुपोषण" },
  { value: "MODERATE_ACUTE_MALNUTRITION", label: "मध्यम शिघ्र कुपोषण" },
  { value: "NO_MALNUTRITION", label: "कुपोषण नभएको" },
  { value: "SEVERE_ANEMIA", label: "कडा रक्तअल्पता" },
  { value: "ANEMIA", label: "रक्तअल्पता" },
  { value: "NO_ANEMIA", label: "रक्तअल्पता नभएको" }
]

export const EAR_RELATED = [
  { value: "MASTOIDITIS", label: "मास्टोईडाइटिस" },
  { value: "ACUTE_EAR_INFECTIONS", label: "कानको एक्युट संक्रमण" },
  { value: "CHRONIC_EAR_INFECTIONS", label: "कानको दिर्घ संक्रमण" },
  { value: "NO_EAR_INFECTIONS", label: "कानको संक्रमण नभएको" },

]

export const MEASLES_RELATED = [
  { value: "SEVERE_COMPLEX_MEASLES", label: "कडा जटिल दादुरा" },
  { value: "MEASLES_WITH_EYE_AND_MOUTH_COMPLICATIONS", label: "आँखा मुखको जटिलता सहितका दादुरा" },
  { value: "DISEASES_LIKE_MEASLES", label: "दादुरा जस्तै रोग" }
]

export const MALARIA_RELATED = [
  { value: "SEVERE_FEVER_OR_SEVERE_MALARIA", label: "धेरै कडा ज्वरो जन्य रोग वा कडा जटिल औलो" },
  { value: "FALCIPARUM_MALARIA", label: "फाल्सीपेरम औलो" },
  { value: "MALARIA_WITHOUT_FALCIPARUM", label: "फाल्सीपेरम नभएको औलो" },
  { value: "NO_POSSIBILITY_OF_MALARIA", label: "ज्वरो: औलोको संभावना नभएको" },
  { value: "FEVER", label: "ज्वरो" }
]


export const DIARRHEA_RELATED = [
  { value: "STRICT_HYDROELECTRICITY", label: "कडा जलवीयोजन" },
  { value: "SOME_HYDRATION", label: "केही जलवीयोजन" },
  { value: "NO_HYDROELECTRICITY", label: "जलवीयोजन नभएको" },
  { value: "AUN_BLOOD", label: "आँउ रगत" },
  { value: "CHRONIC_DIARRHOEA", label: "दीर्घ झाडापखाला" },
]

export const BREATHING_RELATED = [
  { value: "SEVERE_PNEUMONIA", label: "कडा न्युमोनिया" },
  { value: "VERY_SEVERE_DISEASE", label: "धेरै कडा रोग" },
  { value: "PNEUMONIA", label: "न्युमोनिया" },
  { value: "NO_PNEUMONIA_COLD", label: "न्युमोनिया नभएको :रुघाखोकी" }
];

export const MAJOR_CLASSIFICATIONS_FOR_BELOW_TWO_MONTH = [
  { label: "सामान्यतया देखिने खतराका चिन्हहरु अनुसार", options: USUAL_SIGNS_OF_DANGER },
  { label: "जण्डिस", options: JAUNDICE },
  { label: "सिताङ्ग", options: SITAANGA },
  { label: "स्तनपान सम्बन्धी समस्या वा कम तौल", options: BREASTFEEDING_PROBLEMS_OR_LOW_BIRTH_WEIGHT },
  { label: "झाडापखाला", options: DIARRHEA_RELATED },
  { label: "श्वासप्रश्वास", options: BREATHING_RELATED },
];

export const MAJOR_CLASSIFICATIONS_FOR_ABOVE_TWO_MONTH = [
  { label: "पोषण सम्बन्धी", options: NUTRITION_RELATED },
  { label: "कान सम्बन्धी", options: EAR_RELATED },
  { label: "दादुरा सम्बन्धी", options: MEASLES_RELATED },
  { label: "औलो सम्बन्धी", options: MALARIA_RELATED },
  { label: "झाडापखाला", options: DIARRHEA_RELATED },
  { label: "श्वासप्रश्वास", options: BREATHING_RELATED },
];

export const DISCHARGE_RESULT = [
  { value: "RECOVERED", label: "निको भएको" },
  { value: "NOT_RECOVERED", label: "निको नभएको" },
  { value: "DEFAULTER", label: "डिफल्टर" },
  { value: "PRESAN", label: "प्रेषण(अस्पताल)" },
  { value: "DIED", label: "मृत्यु" },
  { value: "TRANSFERRED_TO_ANOTHER_PLACE", label: "स्थानान्तरण भइ अन्यत्र गएको" },
]

export const ORDINAL_NUMBERS = [
  { value: 1, label: "पहिलो" },
  { value: 2, label: "दोस्रो" },
  { value: 3, label: "तेस्रो" },
  { value: 4, label: "चौथो" },
  { value: 5, label: "पाँचौं" },
  { value: 6, label: "छैठौं" },
  { value: 7, label: "सातौं" },
  { value: 8, label: "आठौं" },
  { value: 9, label: "नवौं" },
]

export const EDUCATION_LEVEL = [
  { value: "ELEMENTARY", label: "आधारभूत" },
  { value: "SECONDARY", label: "माध्यमिक स्तर" },
  { value: "HIGHER_SECONDARY", label: "उच्च माध्यमिक स्तर" },
  { value: "BACHELORS_DEGREE", label: "स्नातक" },
  { value: "MASTERS_DEGREE", label: "स्नातकोत्तर" },
  { value: "DOCTORATE_DEGREE", label: "विद्यावारिधि(PhD)" }
]

export const EMPLOYMENT_STATUS = [
  { value: "UNEMPLOYED", label: "Unemployed" },
  { value: "EMPLOYED", label: "Employed" }
]

export const TYPE_OF_OST = [
  { value: "METHADONE", label: "Methadone" },
  { value: "BUPRENORPHINE", label: "Buprenorphine" }
]

export const MEDICAL_REASON_FOR_HIV_TESTING = [
  { value: "STI", label: "STI (1)" },
  { value: "TB", label: "TB (2)" },
  { value: "PREGNANCY", label: "Pregnancy (3)" },
  { value: "SURGERY", label: "Surgery (4)" },
  { value: "HIV_AIDS_SYMPTOMS", label: "HIV / AIDS symptoms (5)" },
  { value: "CHILD_DELIVERED_FROM_POSITIVE_MOTHER", label: "Child Delivered From Positive Mother (6)" },
  { value: "NONE_OF_THE_ABOVE", label: "None of the Above (7)" },
  { value: OTHER, label: "Other (8)" }
]

export const PREGNANCY_STATUS = [
  { value: "PREGNANT", label: "गर्भवती भएको" },
  { value: "NOT_PREGNANT", label: "गर्भवती नभएको" },
  { value: "NOT_KNOWN", label: "थाहा नभएको" },
  { value: "CHILD_MALE_OR_TRANSGENDER", label: "बच्चा, पुरुष वा तेस्रो लिङ्गि" }
]

export const RISK_GROUP_FOR_HIV = [
  { value: "SEX_WORKER", label: "Sex Worker (1)" },
  { value: "PEOPLE_WHO_INJECT_DRUGS", label: "People Who Inject Drugs (2)" },
  { value: "MSM", label: "MSM (3)" },
  { value: "BLOOD_OR_ORGAN_RECIPIENT", label: "Blood or Organ Recipient (4)" },
  { value: "CLIENTS_OF_SEX_WORKERS", label: "Clients of Sex Workers (5)" },
  { value: "MIGRANTS", label: "Migrants (6)" },
  { value: "SPOUSE_PARTNER_OF_MIGRANTS", label: "Spouse / Partner of Migrants (7)" },
  { value: "MALE_SEX_WORKER", label: "Male Sex Worker (8)" },
  { value: "TRANSGENDER", label: "Transgender (9)" },
  { value: "VERTICAL_TRANSMISSION", label: "Vertical Tramsmission (10)" },
  { value: "PRISON_INMATES", label: "Prison Inmates (11)" },
  { value: OTHER, label: "Other" }
];

export const RISK_GROUP_FOR_STI = [
  { value: "SEX_WORKER", label: "Sex Worker" },
  { value: "PEOPLE_WHO_INJECT_DRUGS", label: "People Who Inject Drugs" },
  { value: "MSM", label: "MSM" },
  { value: "BLOOD_OR_ORGAN_RECIPIENT", label: "Blood or Organ Recipient" },
  { value: "CLIENTS_OF_SEX_WORKERS", label: "Clients of Sex Workers" },
  { value: "MIGRANTS", label: "Migrants" },
  { value: "SPOUSE_PARTNER_OF_MIGRANTS", label: "Spouse / Partner of Migrants" },
  { value: "MALE_SEX_WORKER", label: "Male Sex Worker" },
  { value: "TRANSGENDER", label: "Transgender" },
  { value: "VERTICAL_TRANSMISSION", label: "Vertical Tramsmission" },
  { value: "PRISON_INMATES", label: "Prison Inmates" },
  { value: "PREGNANT_WOMAN", label: "Pregnant Woman" },
  { value: "HIV_INFECTED", label: "Hiv Infected" },
  { value: OTHER, label: "Other" }
];

export const ENTRY_POINT_FOR_HIV = [
  { value: "HTC_HIV_TESTING_AND_COUNSELLING_SERVICES", label: "HTC-HIV Testing and Counselling Services" },
  { value: "TB_TUBERCULOSIS_CONTROL_PROGRAMME", label: "TB Tuberculosis Control Programme" },
  { value: "OUTPATIENT_SERVICES", label: "Outpatient Services" },
  { value: "INPATIENT_SERVICES", label: "Inpatient Services" },
  { value: "PEDIATRIC_SERVICES", label: "Pediatric Services" },
  { value: "PMTCT_PREVENTION_OF_MOTHER_TO_CHILD_TRANSMISSION_SERVICES", label: "PMTCT Prevention of Mother to Child Transmission Services" },
  { value: "STI_SEXUALLY_TRANSMITTED_INFECTIONS_SERVICES", label: "STI Sexually Transmitted Infections Services" },
  { value: "PRIVATE_HEALTH_SERVICES", label: "Private health services" },
  { value: "NGO_NON_GOVERNMENTAL_ORGANIZATIONS", label: "NGO Non Governmental Organizations" },
  { value: "SELF_REFERRED", label: "Self Referred" },
  { value: "OUTREACH_SPECIAL_SERVICE", label: "Outreach/Special services for IDUs/Sex Workers, etc" }
]

export const MOTHER_ART_START_PERIOD = [
  { value: "BEFORE_PREGNANCY", label: "Before Pregnancy" },
  { value: "ANTENATAL", label: "Antenatal" },
  { value: "LABOR_AND_DELIVERY", label: "Labor and Delivary" },
  { value: "POSTNATAL", label: "Postnatal" }
]

export const DELIVERY_STATUS = [
  { value: "STILL_BIRTH", label: "Still Birth" },
  { value: "LIVE_BIRTH", label: "Live Birth (Exposed Baby)" }
]

export const WHO_STAGE = [
  { value: "ONE", label: "1" },
  { value: "TWO", label: "2" },
  { value: "THREE", label: "3" },
  { value: "FOUR", label: "4" }
]

export const HIV_CDN_FOLLOW_UP_MONTHS = [
  { value: "1", label: "At Start" },
  { value: "6", label: "6 Months" },
  { value: "12", label: "12 Months" },
  { value: "18", label: "18 Months" },
  { value: "24", label: "24 Months" },
  { value: "30", label: "30 Months" },
  { value: "36", label: "36 Months" },
  { value: "42", label: "42 Months" },
  { value: "48", label: "48 Months" },
  { value: "54", label: "54 Months" },
  { value: "60", label: "60 Months" },
  { value: "66", label: "66 Months" },
  { value: "72", label: "72 Months" },
]

export const HIV_TREATMENT_CARE_HISTORY_FOLLOW_UP_MONTH = [
  { value: "-2", label: "2 Week" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
]

export const TREATMENT_STATUS_DURING_FOLLOW_UP = [
  { value: "ON_TREATMENT", label: "1 (On Treatment)" },
  { value: "STOP_TREATMENT", label: "2 (Stop Treatment)" },
  { value: "TRANSFER_OUT", label: "3 (Transfer Out)" },
  { value: "MISSING", label: "4 (Missing)" },
  { value: "LOST_TO_FOLLOW_UP", label: "5 (Loss to Follow Up)" },
  { value: "DEATH", label: "6 (Death)" },
  { value: "RESTARTED", label: "7 (Restarted)" }
]

export const TREATMENT_STATUS_DURING_OPOID_FOLLOW_UP = [
  { value: "ON_TREATMENT", label: "1 (On Treatment on OST)" },
  { value: "DROP_OUT", label: "2 (Drop Out)" },
  { value: "RE_ENROLLMENT", label: "3 (Re-enrollment)" },
  { value: "DISCHARGE", label: "4 (Discharge)" },
  { value: "DEATH", label: "5 (Death)" },
  { value: "TRANSFER_OUT", label: "6 (Transfer Out)" },
]

export const TREATMENT_ADHERENCE = [
  { value: "A", label: "100% (A)" },
  { value: "B", label: "80-99% (B)" },
  { value: "C", label: "< 80% (C)" },
]

export const REASON_FOR_TREATMENT_SUBSTITUTED = [
  { value: "TOXICITY_SIDE_EFFECT", label: "Toxicity Side Effect" },
  { value: "PREGNANCY", label: "Pregnancy" },
  { value: "RISK_OF_PREGNANCY", label: "Risk of Pregnancy" },
  { value: "NEWLY_DIAGNOSED_TB", label: "Newly Diagnosed TB" },
  { value: "NEW_DRUG_AVAILABLE", label: "New Drug Available" },
  { value: "DRUG_OUT_OF_STOCK", label: "Drug Out of Stock" }
]

export const REASON_FOR_TREATMENT_SWITCHED = [
  { value: "CLINICAL_TREATMENT_FAILURE", label: "Clinical Treatment Failure" },
  { value: "IMMUNOLOGICAL_FAILURE", label: "Immunological Failure" },
  { value: "VIROLOGICAL_FAILURE", label: "Virological Failure" }
]

export const FOLLOW_UP_MONTH_FOR_BASELINE_INFORMATION_UPDATE = [
  { value: "12", label: "12 months" },
  { value: "24", label: "24 months" },
  { value: "36", label: "36 months" },
  { value: "48", label: "48 months" },
  { value: "60", label: "60 months" },
  { value: "72", label: "72 months" },
]

export const SIDE_EFFECT_OF_HIV_TREATMENT = [
  { value: "NO_SIDE_EFFECTS", label: "1 (No Side Effects)" },
  { value: "SKIN_RASHES", label: "2 (Skin Rashes)" },
  { value: "NAUSEA", label: "3 (Nausea)" },
  { value: "VOMITING", label: "4 (Vomiting)" },
  { value: "DIARRHOEA", label: "5 (Diarrhoea)" },
  { value: "NEUROPATHY", label: "6 (Neuropathy)" },
  { value: "CLINICAL_JAUNDICE", label: "7 (Clinical Jaundice)" },
  { value: "ANEMIA", label: "8 (Anemia)" },
  { value: "FATIGUE", label: "9 (Fatigue)" },
  { value: "HEADACHE", label: "10 (Headache)" },
  { value: "FEVER", label: "11 (Fever)" },
  { value: "HYPERSENSITIVITY", label: "12 (Hypersensitivity)" },
  { value: "DEPRESSION", label: "13 (Depression)" },
  { value: "PANCREATITIS", label: "14 (Pancreatitis)" },
  { value: "LIPODYSTROPHY", label: "15 (lipodystrophy)" },
  { value: "DROWSINESS", label: "16 (Drowsinwss)" },
  { value: "ITCHING", label: "17 (Itching)" },
  { value: "DIZZINESS", label: "18 (Dizziness)" },
  { value: "WEIGHT_GAIN", label: "19 (Weight Gain)" },
  { value: "INSOMNIA", label: "20 (Insomnia)" },
  { value: "DARK_COLOURED_URINE", label: "21 (Dark Coloured Urine)" },
  { value: "ABDOMINAL_PAIN", label: "22 (Abdominal Pain)" },
  { value: "MOUTH_SORES", label: "23 (Mouth Sores)" }
]

export const OI_DIAGNOSIS = [
  { value: "BACTORIAL_PNEUMONIA", label: "1 (Bactorial Pneumonia)" },
  { value: "TB", label: "2 (TB)" },
  { value: "CANDIDIASIS", label: "3 (Candidiasis)" },
  { value: "DIARRHOEA", label: "4 (Diarrhoea)" },
  { value: "CRYPTOCOCCAL_MENINGITIS", label: "5 (Cryptococcal Meningitis)" },
  { value: "PNEUMOCYTIS_CARINII_PNEUMONIA", label: "6 (Pneumocytis Carinii Pneumonia)" },
  { value: "CYTOMEGALO_VIRUS", label: "7 (Cytomegalo Virus)" },
  { value: "HERPES_ZOSTER", label: "8 (Herpes Zoster)" },
  { value: "GENITAL_HERPES", label: "9 (Genital Herpes)" },
  { value: "TOXOPLASMOSIS", label: "10 (Toxoplasmosis)" },
]

export const TBPT_CONTINUATION_IN_MONTHS = [
  { value: "ONE_MONTH", label: "1 month" },
  { value: "TWO_MONTH", label: "2 month" },
  { value: "THREE_MONTH", label: "3 month" },
  { value: "FOUR_MONTH", label: "4 month" },
  { value: "FIVE_MONTH", label: "5 month" },
  { value: "SIX_MONTH", label: "6 month" },
]

export const TYPE_OF_TB = [
  { value: "PBC_TB", label: "(PBC) TB" },
  { value: "EXTRAPULMONARY_TB", label: "Extrapulmonary TB (EP TB)" },
  { value: "PR/MDR TB", label: "PR_MDR_TB" },
  { value: "PRE_XDR_TB", label: "Pre-XDR TB" },
  { value: "XDR_TB", label: "XDR TB" },
]

export const DURATION_OF_ARV_PROPHYLAXIS_IN_WEEKS = [
  { value: "ONE_WEEK", label: "1 week" },
  { value: "TWO_WEEK", label: "2 week" },
  { value: "THREE_WEEK", label: "3 week" },
  { value: "FOUR_WEEK", label: "4 week" },
  { value: "FIVE_WEEK", label: "5 week" },
  { value: "SIX_WEEK", label: "6 week" },
  { value: "SEVEN_WEEK", label: "7 week" },
  { value: "EIGHT_WEEK", label: "8 week" },
  { value: "NINE_WEEK", label: "9 week" },
  { value: "TEN_WEEK", label: "10 week" },
  { value: "ELEVEN_WEEK", label: "11 week" },
  { value: "TWELVE_WEEK", label: "12 week" },
]

export const NON_COMMUNICABLE_DISEASE_REGISTER_RISK_FACTORS = [
  { value: "ALCOHOL_USE", label: "Alcohol Use" },
  { value: "SMOKING", label: "Smoking" },
  { value: "PHYSICAL_INACTIVITY", label: "Physical Inactivity" },
  { value: "UNHEALTHY_DIET", label: "Unhealthy Diet" },
];

export const NON_COMMUNICABLE_DISEASE_IMPROVEMENT_STATUS = [
  { value: "IMPROVED", label: "Improved" },
  { value: "STATIC", label: "Static" },
  { value: "WORSE", label: "Worse" },
  { value: "DIED", label: "Died" },
  { value: "LOSS_TO_FOLLOW_UP", label: "Loss to Follow Up" }
];

export const DISEASE_MANAGEMENT = [
  { value: "HYPERTENSION_WITH_BP_CONTROLLED", label: "Hypertension with BP controlled" },
  { value: "HYPERTENSION_WITH_BP_NOT_CONTROLLED", label: "Hypertension with BP Not Controlled" },
  { value: "DIABETES_WITH_BLOOD_SUGAR_CONTROLLED", label: "Diabetes with Blood Sugar controlled" },
  { value: "DIABETES_WITH_BLOOD_SUGAR_NOT_CONTROLLED", label: "Diabetes with Blood Sugar Not controlled" },
  { value: "COPD_CONDITION_STABLE", label: "COPD condition Stable" },
  { value: "COPD_CONDITION_NOT_STABLE", label: "COPD condition Not Stable" },
];

export const MAJOR_DIAGNOSTICS_FOR_NON_COMMUNICABLE_DISEASE = [
  { value: "HYPERTENSION", label: "Hypertension" },
  { value: "CVD", label: "CVD" },
  { value: "CANCER", label: "Cancer" },
  { value: "DIABETES", label: "Diabetes" },
  { value: "HYPERTENSION_AND_DIABETES", label: "Hypertension and Diabetes" },
  { value: "SUSPECTED_CERVICAL_CANCER", label: "Suspected Cervical Cancer" },
  { value: "SUSPECTED_BREAST_CANCER", label: "Suspected Breast Cancer" },
  { value: "COPD", label: "COPD" },
  { value: "ASTHMA", label: "Asthama" },
  { value: "CKD", label: "CKD" },
  { value: "RHD", label: "RHD" },
  { value: "STROKE", label: "Stroke" },
  { value: "HEART_ATTACK", label: "Heart Attack" },
  { value: "THYROID_DISEASES", label: "Thyroid Diseases" },
  { value: "CONGENTIAL_HEART_DISEASE", label: "Congential Heart Disease" },
  { value: "SICKLE_CELL_DISEASES_AND_OTHER_HAEMOGLOBINOPATHIES", label: "Sickle cell diseases and other haemoglobinopathies" },
  { value: "ALCOHOL_LIVER_DISEASES", label: "Alcohol Liver Diseases" },
  { value: "OBESITY", label: "Obesity" },
  { value: "CHRONIC_RESPIRATORY", label: "Chronic Respiratory" },
  { value: "SUICIDE", label: "Suicide" },
  { value: "OTHER_NCDS_CASES", label: "Other NCDs cases" },
];

export const MAJOR_DIAGNOSTICS_FOR_MENTAL_HEALTH = [
  { value: "DEPRESSION", label: "Depression" },
  { value: "SUICIDE_ATTEMPT", label: "Suicide Attempt" },
  { value: "EPILEPSY", label: "Epilepsy" },
  { value: "PSYCHOSIS", label: "Psychosis" },
  { value: "ANXIETY", label: "Anxiety" },
  { value: "EMOTIONAL_AND_BEHAVIOURAL_DISORDER_OF_CHILDREN_ALL_NCDS_AND_ADOLESCENTS", label: "Emotional and Behavioural Disorder of Children All NCDs and Adolescents" },
  { value: "DEMENTIA", label: "Dementia" },
  { value: "CONVERSION", label: "Conversion" },
  { value: "BIPOLAR_DISORDER", label: "Bipolar Disorder" },
  { value: "OTHER_MENTAL_DISORDER", label: "Other Mental Disorder" },
  { value: "ALCOHOL_USE_DISORDER", label: "Alcohol Use Disorder" },
  { value: "OTHER_SUBSTANCE_USE_DISORDERS", label: "Other Substance Use Disorders" },
]

export const MAJOR_DIAGNOSIS_FOR_INJURIES = [
  { value: "ROAD_TRAFFIC_INJURIES", label: "Road Traffic Injuries (RTI)" },
  { value: "FALL", label: "Fall" },
  { value: "BURN", label: "Burn" },
  { value: "DROWNING", label: "Drowning" },
  { value: "BITES", label: "Bites" },
  { value: "OCCUPATIONAL_INJURY", label: "Occupational Injury" },
  { value: "VIOLENCE", label: "Violence" },
  { value: "SELF_HARM", label: "Self-Harm" },
]

export const DISEASE_TYPE_FOR_SENIOR_CITIZEN = [
  { value: "STROKE", label: "Stroke" },
  { value: "CHRONIC_RESPIRATORY_DISEASE", label: "Chronic Respiratory Disease" },
  { value: "CORONARY_ARTERY_DISEASE", label: "Coronary Artery Disease" },
  { value: "HYPERTENSION", label: "Hypertension" },
  { value: "DIABETES_MELLITUS", label: "Diabetes mellitus" },
  { value: "CANCERS", label: "Cancers" },
  { value: "PARKINSONISM", label: "Parkinsonism" },
  { value: "DEMENTIA_AND_ALZHEIMER", label: "Dementia and Alzheimer's" },
  { value: "BENIGN_PROSTATIC_HYPERTROPHY", label: "Benign Prostatic Hypertrophy" },
  { value: "DEPRESSION", label: "Depression" },
  { value: "OSTEOARTHRITIS", label: "Osteoarthritis" },
  { value: "OSTEOPOROSIS", label: "Osteoporosis" },
  { value: "SARCOPENIA", label: "Sarcopenia" },
  { value: "PNEUMONIA", label: "Pneumonia" },
  { value: "URINARY_TRACT_INFECTION", label: "Urinary Tract infection" },
  { value: "OTHERS", label: "Others(Specify)" }
]

export const SEWA_TYPE_FOR_SENIOR_CITIZEN = [
  { value: "NORMAL_CHECK", label: "सामान्य परिक्षण" },
  { value: "NORMAL_TREATMENT", label: "सामान्य उपचार" },
  { value: "SURGERY", label: "शल्यक्रिया" },
  { value: "PALLIATIVE", label: "Palliative" },
  { value: "REHABILITATIVE", label: "Rehabilitative" },
]

export const EXPENSE_INCURRED_FOR_SENIOR_CITIZEN = [
  { value: "HEALTH_INSURENCE", label: "स्वास्थ्य बिमा कार्यक्रम" },
  { value: "SENIOR_CITIZEN_PROGRAM", label: "ज्येष्ठ नागरिक कार्यक्रम" },
  { value: "SSU", label: "SSU" },
  { value: "DEPRIVED_CITIZEN", label: "वञ्चित नागरिक" },
];

export const REFERRED_ORGANIZATION_FOR_SENIOR_CITIZEN = [
  { value: "UPPER_LEVEL_HOSPITAL", label: "माथिल्लो तहको अस्पताल" },
  { value: "LOWER_LEVEL_HOSPITAL", label: "तल्लो तहको अस्पताल वा स्वास्थ्य संस्था" },
  { value: "REHABILITATION_CENTER", label: "पुनस्थापना केन्द्र" },
  { value: "GERIATRIC_CENTER", label: "जेरियाट्रिक केयर सेन्टर" }
]

export const NCD_MH_OR_INJURY = [
  { value: "NCD", label: "NCD" },
  { value: "MH", label: "MH" },
  { value: "INJURY", label: "Injury" }
]

export const SEWA_TYPE_FOR_NCD = [
  { value: "MEDICINE", label: "औषधि" },
  { value: "PSYCHOSOCIAL_COUNSELLING", label: "मनोसामाजिक परामर्श" },
  { value: "COUNSELLING", label: "परामर्श" },
  { value: OTHER, label: "अन्य" }
]

export const SMS_STATUS = [
  { value: "WAITING", label: "Waiting" },
  { value: "SENT", label: "Sent" },
  { value: "FAILED", label: "Failed" },
  { value: "CANCELED", label: "Cancelled" },
  { value: "DUPLICATE", label: "Duplicate" }
]

export const FREQUENCY_LIST = [
  { value: "OD", label: "OD" },
  { value: "BD", label: "BD" },
  { value: "TDS", label: "TDS" },
  { value: "QID", label: "QID" },
  { value: "HS", label: "HS" },
  { value: "SOS", label: "SOS" },
  { value: "Stat", label: "Stat" }
]

export const MEDICINE_TIMING = [
  { value: "BEFORE_FOOD", label: "खाना अघि" },
  { value: "AFTER_FOOD", label: "खाना पछि" }
]

export const DHIS_TWO_REPORT_CONSTANT = [
  { value: "KhopProgram", label: "खोप कार्यक्रम", id: 'KHOP_TO_DHIS2_REPORT' },
  { value: "IMNCIReportLessThanTwoMonths", label: "IMNCI report for children below 2 months", id: 'IMNCI_LESS_THAN_TWO_MONTHS_TO_DHIS2_REPORT' },
  { value: "IMNCIReportTwoMonthsOrMore", label: "IMNCI report for children aged 2 to 59 months", id: 'IMNCI_TWO_MONTHS_OR_MORE_TO_DHIS2_REPORT' },
  { value: "PariwaarNiyojanProgram", label: "परिवार नियोजन कार्यक्रम", id: 'FAMILY_PLANNING_TO_DHIS2_REPORT' },
  { value: "OutPatientMorbidity", label: "Outpatient Morbidity", id: 'OUT_PATIENT_MORBIDITY_TO_DHIS2_REPORT' },
  { value: "MalariaReport", label: "Malaria Report", id: 'MALARIA_TO_DHIS2_REPORT' },
  { value: "KalaazarReport", label: "Kalaazar Report", id: 'KALAAZAR_TO_DHIS2_REPORT' },
  { value: "SurakshitMatrittoProgram", label: "Surakshit Matritto Program", id: 'SURAKSHIT_MATRITTO_TO_DHIS2_REPORT' },
  { value: "KustharogControlProgram", label: "Kustharog Control Program", id: 'KUSTHAROG_TO_DHIS2_REPORT' },
  { value: "NonCommunicableDiseaseReport", label: "Non-Communicable Disease Report", id: 'NON_COMMUNICABLE_DISEASE_TO_DHIS2_REPORT' },
  { value: "NutritionProgram", label: "Nutrition Program", id: 'POSAN_TO_DHIS2_REPORT' },
  { value: "GenderAndCasteWiseReport", label: "Gender And Castewise Report", id: 'GENDER_AND_CASTE_TO_DHIS2_REPORT' },
  { value: "TuberculosisReport", label: "Tuberculosis Report", id: 'TUBERCULOSIS_TO_DHIS2_REPORT' },
  { value: "VillageClinicAndCommunityLevelHealthProgram", label: "Village-Clinic And Community Level Health Program", id: 'VILLAGE_CLINIC_DHIS2_REPORT' },
  { value: "OrganizationProgressReport", label: "Organization Progress Report", id: "ORGANIZATIONPROGRESSREPORT_TO_DHIS2_REPORT" },
  { value: "MahilaSwasthyaSwamSewikaProgram", label: "महिला स्वास्थ्य स्वयंसेविका कार्यक्रम", id: "MAHILASWASTHYASWAMSEWIKAPROGRAM_TO_DHIS2_REPORT" },
  { value: "ReproductiveHealthReport", label: "Reproductive Health Report", id: "REPRODUCTIVEHEALTHREPORT_TO_DHIS2_REPORT" },
  { value: "HivTestAndCounsellingReport", label: "Hiv Test And Counselling Report", id: "HIVTESTANDCOUNSELLINGREPORT_TO_DHIS2_REPORT" },
  { value: "CovidReport", label: "COVAC Program", id: "COVID_REPORT_SERVICE_TO_DHIS2_REPORT" },
  { value: "CommonEmergencyDiseaseReport", label: "Common Emergency Disease Report", id: "COMMON_EMERGENCY_DISEASES_REPORT_TO_DHIS2_REPORT" },
  { value: "HospitalLevelMonthlyReportingForm", label: "Hospital Level Monthly Reporting Form", id: "HOSPITAL_LEVEL_MONTHLY_REPORT_TO_DHIS2_REPORT" },
  { value: "HospitalIndoorReport", label: "Hospital Indoor Report", id: "HOSPITAL_INDOOR_REPORT_TO_DHIS2_REPORT" },
  { value: "LabReport", label: "Lab Report", id: "LAB_REPORT_TO_DHIS2_REPORT" }
]

export const OPERATION_LIST_BEFORE_DEPLOYMENT = [];