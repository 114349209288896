import { Box, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../../components/register-header/RegisterHeader'
import AHMIS, { API_URL } from '../../../../api/api';
import { ERROR, ID, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, THERAPY, YOG_SEWA, YOG_SEWA_VIVARAN } from '../../../../utils/constants';
import AddAlertMessage from '../../../../components/alert/Alert';
import { AppUtils } from '../../../../utils/appUtils';
import { useForm } from 'react-hook-form';
import DeleteRegisterRecordConformation from '../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import CustomReactSelect from '../../../../components/custom-react-select/CustomReactSelect';
import NepaliCalender from '../../../../components/nepali-calender';
import CustomModal from '../../../../components/modal/CustomModal';
import YogSewaBibaranRegister from '../../../users/components/register/yog-sewa-register/helper/YogSewaBibaranRegister';

const YogSewaBibaranForm = () => {

  const [modalTitle, setModalTitle] = useState(" योग सेवा रजिष्टर  नयाँ रेकर्ड थप्नुहोस्");
  const [showYogSewaModal, setShowYogSewaModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [yogSewaSewaBibaranTableData, setYogSewaSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [showYogSewaDeleteModal, setShowYogSewaDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [yogSewaDeleteId, setYogSewaDeleteId] = useState(null);
  const yogSewaRegisterId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    showYogSewaModal && reset();
  }, [reset, showYogSewaModal]);

  const openYogSewaModal = () => {
    setShowYogSewaModal(true);
  };

  const closeYogSewaModal = () => {
    setShowYogSewaModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitParchaKarmaSewaBibaranRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    const url = `${API_URL.yogSewaService}?yogSewaServiceRegistrationId=${yogSewaRegisterId}`;
    AHMIS.post(url, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeYogSewaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getYogSewaSewaBibaranData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.yogSewaService}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&yogSewaServiceRegistrationId=${yogSewaRegisterId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setYogSewaSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const yogSewaSewaBibaranEdit = (id) => {
    AHMIS.get(API_URL.yogSewaService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setShowYogSewaModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeYogSewaDeleteModal = () => {
    setShowYogSewaDeleteModal(false);
    setYogSewaDeleteId(null);
  }

  const onDeleteYogSewaRow = (id) => {
    setShowYogSewaDeleteModal(true);
    setYogSewaDeleteId(id);
  }

  const deleteYogSewaSewaBibaranRow = (data) => {
    AHMIS.delete(API_URL.yogSewaService + "/" + yogSewaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowYogSewaDeleteModal(false);
          setYogSewaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getYogSewaSewaBibaranData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="सेवा विवरण"
          handleModalOpen={openYogSewaModal}
          getRegisterData={getYogSewaSewaBibaranData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showYogSewaModal}
        onModalSubmit={handleSubmit(submitParchaKarmaSewaBibaranRegister)}
        onModalClose={closeYogSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="मिति"
              name="dartaaMiti"
              defaultDate={modalDefaultValues?.dartaaMiti}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complication"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complication}
              {...register("complication")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण /सल्लाह"
              size="small"
              variant="outlined"
              name="testing"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("testing")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showYogSewaDeleteModal}
        closeDeleteModel={closeYogSewaDeleteModal}
        deleteRecord={deleteYogSewaSewaBibaranRow}
      />
      <YogSewaBibaranRegister
        onEditRow={yogSewaSewaBibaranEdit}
        tableData={yogSewaSewaBibaranTableData}
        deleteRow={onDeleteYogSewaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default YogSewaBibaranForm
