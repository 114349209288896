import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { ACUPUNCTURE_SEVA_PRAKAAR, CASTE_CODES, FEMALE, MALE, NO_RECORDS_FOUND } from '../../../../../utils/constants';
import { AppUtils } from '../../../../../utils/appUtils';
import { AppMisc } from '../../../../../misc/appMisc';
import { NEW_PATIENT, OTHER, PREVIOUS_PATIENT } from '../../../../../utils/constants/forms';
import { Delete, Edit, Person } from '@mui/icons-material';

const AcupunctureRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>क्र.सं.</TableCell>
                <TableCell rowSpan={2}>दर्ता मिति</TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={2}>जाती कोड</TableCell>
                </Tooltip>
                <TableCell colSpan={3}>उमेर</TableCell>
                <TableCell colSpan={5}>ठेगाना</TableCell>
                <TableCell rowSpan={2}>राष्ट्रिय परिचयपत्र नम्वर</TableCell>
                <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
                <TableCell rowSpan={2}>आईसिडि कोड</TableCell>
                <TableCell rowSpan={2}>निदानात्मक परीक्षण</TableCell>
                <TableCell rowSpan={2}>सम्भावित निदान</TableCell>
                <TableCell rowSpan={2}>उपचार र सल्लाह</TableCell>
                <TableCell rowSpan={2}>अकुपन्चर चिकित्सासेवा प्रकार</TableCell>
                <TableCell rowSpan={2}>लैङ्गिक हिंसा </TableCell>
                <TableCell colSpan={2}>प्रेषण भई</TableCell>
                <TableCell rowSpan={2} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पहिलो पटक</TableCell>
                <TableCell>पुनरावृत </TableCell>
                <TableCell>नाम</TableCell>
                <TableCell>थर</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>पुरुष</TableCell>
                <TableCell>अन्य</TableCell>
                <TableCell>जिल्ला</TableCell>
                <TableCell>नगर/गाउँपालिका</TableCell>
                <TableCell>वडा नं.</TableCell>
                <TableCell>टोल</TableCell>
                <TableCell>अन्य मुलुक</TableCell>
                <TableCell>आएको संस्थाको नाम</TableCell>
                <TableCell>गएको संस्थाको नाम</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData?.length > 0 ? (
                mainRegisterData?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                    <TableCell>{row?.patientType === NEW_PATIENT ? row?.mulDartaaNumber && AppUtils.replaceWithNepaliDigit(row.mulDartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.mulDartaaNumber && AppUtils.replaceWithNepaliDigit(row.mulDartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientFirstName || "-"}</TableCell>
                    <TableCell>{row?.patientLastName || "-"}</TableCell>
                    <TableCell>{row?.casteCode && AppUtils.replaceWithNepaliDigit(row.casteCode) || "-"}</TableCell>
                    <TableCell>{row?.gender === FEMALE ? AppUtils.replaceWithNepaliDigit(row?.age) : "-"}</TableCell>
                    <TableCell>{row?.gender === MALE ? AppUtils.replaceWithNepaliDigit(row?.age) : "-"}</TableCell>
                    <TableCell>{row?.gender === OTHER ? AppUtils.replaceWithNepaliDigit(row?.age) : "-"}</TableCell>
                    <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                    <TableCell>{row?.palikaName && AppMisc.getMunicipalityName(row?.palikaName)}</TableCell>
                    <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                    <TableCell>{row?.otherCountry || "-"}</TableCell>
                    <TableCell>{row?.otherCountry || "-"}</TableCell>
                    <TableCell>{row?.nid || "-"}</TableCell>
                    <TableCell>{row?.phoneNumber && AppUtils.replaceWithNepaliDigit(row?.phoneNumber)}</TableCell>
                    <TableCell>{row?.icdCode || "-"}</TableCell>
                    <TableCell>{row?.labTests || "-"}</TableCell>
                    <TableCell>{row?.provisionalDiagnosis || "-"}</TableCell>
                    <TableCell>{row?.tratementAndSuggestion || "-"}</TableCell>
                    <TableCell>{AppMisc.findLabelFromValue(ACUPUNCTURE_SEVA_PRAKAAR, row?.sewaType || "-")}</TableCell>
                    <TableCell>{row?.genderViolence || "-"}</TableCell>
                    <TableCell>{row?.referredIn || "-"}</TableCell>
                    <TableCell>{row?.referredOut || "-"}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        {/* <Tooltip title="प्रोफाइल कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <Box>
                            <a target="_blank" rel="noreferrer" href={`acupuncture-sewa-bibaran?id=${row.id}`}>
                              <Person fontSize="small" />
                            </a>
                          </Box>
                        </Tooltip> */}
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color="secondary" onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="41" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default AcupunctureRegister;
