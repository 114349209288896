import HMIS, { API_URL } from "../api/api";
import AddAlertMessage from "../components/alert/Alert";
import { ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../utils/constants";
import { SAVE_HEALTH_CLIENT_INFO } from "../utils/constants/redux";

export const getHealthClientAndPalikaInfoData = () => async (dispatch) => {
  try {
    let response = await HMIS.get(API_URL.healthClientAndPalikaInfo);
    let jsondata = response.data;
    if (jsondata.type === SUCCESS) {
      dispatch({ type: SAVE_HEALTH_CLIENT_INFO, payload: jsondata.data })
    } else {
      AddAlertMessage({ type: jsondata.type, message: jsondata.message })
    }
  } catch (error) {
    AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
  }
};