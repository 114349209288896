import { API_URL } from "../../api/api";
import { AppMisc } from "../../misc/appMisc";
import { AppUtils } from "../appUtils";
import { DateUtils } from "../dateUtils";
import { ASRH_SERVICE, COVID_VACCINE_SERVICE, FORTIFIED_FLOUR_DISTRIBUTION, GRANTED, HOSPITAL_ADMISSION_SERVICE, KHOP_SERVICE, LAB_SERVICE, NOT_GRANTED, OCMC_SERVICE, OTHER, OUT_PATIENT_MAIN_REGISTER_SERVICE_CODE, REHABILITATION_SERVICE, REPRODUCTIVE_HEALTH, SANITARY_PAD_DETAIL_SERVICE, SENIOR_CITIZEN_SERVICE, SNCU_NICU_KMCU_SERVICE, TD_KHOP_SERVICE, UPT_TEST_SERVICE } from "./forms/index";
export const REMEMBER_ME = "hmis-remember-me";
export const USER_ROLE = "role";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_USER = "ROLE_USER";
export const OUTPATIENT = "outpatient";
export const PANCHAKARMA = "panchakarma";
export const SALYACHIKITA = "salyachikitsa"
export const MEDICINEDISTRIBUTION = "medicinedistribution"
export const YOGSEWA = "yogsewa"
export const JESTHANAGARIK = "jesthanagarik"
export const STANPAIAAMASISHU = "stapaiaamasisu"
export const ACUPUNCTURE = "acupuncture"
export const PHYSIOTHERAPY = "physiotherapy"
export const HOMEOPATHY = "homeopathy"
export const NAGARIKAROGYA = "nagarikarogya"
export const ROLE_USER_MUNICIPALITY = "ROLE_USER_MUNICIPALITY";
export const ROLE_USER_MUNICIPALITY_BILLING = "ROLE_USER_MUNICIPALITY_BILLING";
export const ROLE_USER_KHOP = "ROLE_USER_KHOP";
export const ROLE_USER_MUNICIPALITY_KHOP = "ROLE_USER_MUNICIPALITY_KHOP";
export const ROLE_USER_PALIKA_INFO = "ROLE_USER_PALIKA_INFO";
export const ROLE_USER_LAB_ALL_FEATURE = "ROLE_USER_LAB_ALL_FEATURE";
export const ROLE_HOSPITAL_AND_HEALTH_FACILITY_USER = "ROLE_HOSPITAL_AND_HEALTH_FACILITY_USER";
export const ROLE_USER_PROVINCE = "ROLE_USER_PROVINCE";
export const ROLE_USER_DISTRICT = "ROLE_USER_DISTRICT";
export const ROLE_USER_CENTRAL = "ROLE_USER_CENTRAL";
export const LEFT_SIDEBAR_WIDTH = 240;
export const APP_BAR_HEIGHT = 64;
export const ID = "id";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SIDEBAR_OPENED = "SIDEBAR_OPENED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_INFO = "USER_INFO";
export const YES = "YES";
export const NO = "NO";
export const MALE = "MALE";
export const FEMALE = "FEMALE";
export const YEAR = "YEAR";
export const MONTH = "MONTH";
export const DAY = "DAY";
export const UNKNOWN = "UNKNOWN";
export const CURRENT_FISCAL_YEAR = DateUtils.getFiscalYearFromDateMilli(Date.now());
export const PERMISSIONS = "PERMISSIONS";
export const PROFILE_IMAGE_URL = "PROFILE_IMAGE_URL";
export const ROW_PER_PAGE = 50;
export const HEALTH_CLIENT_INFO = "HEALTH_CLIENT_INFO"
export const ROW_PER_PAGE_FOR_CUSTOM_TABLE = 10;
export const SEARCH_TIME_OUT = 1200;
export const ONE_MINUTE_TIME = 1 * 60 * 1000;
export const RADIAN = Math.PI / 180;
// export const API_WITHOUT_LOADER = [`${API_URL.mulDartaaRegister}/fromDateRangeAndPage`, `${API_URL.rehabilitationRegister}/fromDateRangeAndPage`, `${API_URL.outPatient}/fromDateRangeAndPage`, `${API_URL.khopSewa + "/khop-kendra"}`, API_URL.opdPatientForBilling, API_URL.balbalikaPosan, `${API_URL.mulDartaaRegister}/mul-dartaa-numbers?sewaType=${OUT_PATIENT_MAIN_REGISTER_SERVICE_CODE}`, `${API_URL.provinceDashboard}/notification`, `${API_URL.outPatient}/complaint`];


export const ROLE_HMIS_MULDARTAAREGISTER_GETMULDARTAABYPAGE = "ROLE_HMIS_MULDARTAAREGISTER_GETMULDARTAABYPAGE";
export const ROLE_HMIS_MUNICIPALITY_GETALLMUNICIPALITYINFOS = "ROLE_HMIS_MUNICIPALITY_GETALLMUNICIPALITYINFOS";
export const ROLE_AHMIS_APPLICATIONSETTING_SAVESETTING = "ROLE_AHMIS_APPLICATIONSETTING_SAVESETTING";
export const ROLE_HMIS_AUTHENTICATION_LOGOUT = "ROLE_HMIS_AUTHENTICATION_LOGOUT";
export const ROLE_HMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO = "ROLE_HMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO";
export const ROLE_HMIS_USER_GETUSERINFO = "ROLE_HMIS_USER_GETUSERINFO";
export const ROLE_AHMIS_AUTHENTICATION_LOGOUT = "ROLE_AHMIS_AUTHENTICATION_LOGOUT";
export const ROLE_AHMIS_PUBLIC_FORGETPASSWORD = "ROLE_AHMIS_PUBLIC_FORGETPASSWORD";
export const ROLE_AHMIS_AUTHENTICATION_CHANGEPASSWORD = "ROLE_AHMIS_AUTHENTICATION_CHANGEPASSWORD"
export const ROLE_AHMIS_USER_UPDATEUSERPROFILE = "ROLE_AHMIS_USER_UPDATEUSERPROFILE"
export const ROLE_AHMIS_MUNICIPALITY_SAVEMUNICIPALITY = "ROLE_AHMIS_MUNICIPALITY_SAVEMUNICIPALITY"
export const ROLE_AHMIS_MUNICIPALITY_GETALLMUNICIPALITYINFOS = "ROLE_AHMIS_MUNICIPALITY_GETALLMUNICIPALITYINFOS"
export const ROLE_AHMIS_MUNICIPALITY_GETMUNICIPALITYINFOFROMID = "ROLE_AHMIS_MUNICIPALITY_GETMUNICIPALITYINFOFROMID"
export const ROLE_AHMIS_HEALTHCLIENT_SAVEHEALTHCLIENT = "ROLE_AHMIS_HEALTHCLIENT_SAVEHEALTHCLIENT"
export const ROLE_AHMIS_HEALTHCLIENT_GETALLHEALTHCLIENTSINFO = "ROLE_AHMIS_HEALTHCLIENT_GETALLHEALTHCLIENTSINFO"
export const ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTSINFOBYMUNICIPALITY = "ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTSINFOBYMUNICIPALITY"
export const ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTINFOBYID = "ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTINFOBYID"
export const ROLE_AHMIS_HEALTHCLIENT_GETLOGGEDINUSEROFCINFO = "ROLE_AHMIS_HEALTHCLIENT_GETLOGGEDINUSEROFCINFO"
export const ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO = "ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO"
export const ROLE_AHMIS_USER_SAVEUSER = "ROLE_AHMIS_USER_SAVEUSER"
export const ROLE_AHMIS_USER_GETLOGGEDINUSERINFO = "ROLE_AHMIS_USER_GETLOGGEDINUSERINFO"
export const ROLE_AHMIS_USER_GETUSERINFO = "ROLE_AHMIS_USER_GETUSERINFO"
export const ROLE_AHMIS_MULDARTA_SAVEMULDARTA = "ROLE_AHMIS_MULDARTA_SAVEMULDARTA"
export const ROLE_AHMIS_MULDARTA_GETALLMULDARTAREGISTRATIONS = "ROLE_AHMIS_MULDARTA_GETALLMULDARTAREGISTRATIONS"
export const ROLE_AHMIS_MULDARTA_GETMULDARTAREGISTRATIONFROMID = "ROLE_AHMIS_MULDARTA_GETMULDARTAREGISTRATIONFROMID"
export const ROLE_AHMIS_OUTPATIENT_SAVEOUTPATIENT = "ROLE_AHMIS_OUTPATIENT_SAVEOUTPATIENT"
export const ROLE_AHMIS_OUTPATIENT_GETALLOUTPATIENT = "ROLE_AHMIS_OUTPATIENT_GETALLOUTPATIENT"
export const ROLE_AHMIS_OUTPATIENT_GETOUTPATIENTREGISRATIONFROMID = "ROLE_AHMIS_OUTPATIENT_GETOUTPATIENTREGISRATIONFROMID"
export const ROLE_AHMIS_MEDICINE_GETALLMEDICINEDISTRIBUTOR = "ROLE_AHMIS_MEDICINE_GETALLMEDICINEDISTRIBUTOR"
export const ROLE_AHMIS_MEDICINE_DISTRIBUTOR_SAVE = "ROLE_AHMIS_MEDICINE_DISTRIBUTOR_SAVE"
export const ROLE_AHMIS_MEDICINE_GETMEDICINEDISTRIBUTORFROMID = "ROLE_AHMIS_MEDICINE_GETMEDICINEDISTRIBUTORFROMID"
export const ROLE_AHMIS_ADDMEDICINE_SAVEADDMEDICINE = "ROLE_AHMIS_ADDMEDICINE_SAVEADDMEDICINE"
export const ROLE_AHMIS_ADDMEDICINE_GETALLADDMEDICINE = "ROLE_AHMIS_ADDMEDICINE_GETALLADDMEDICINE"
export const ROLE_AHMIS_ADDMEDICINE_GETALLMEDICINEBYID = "ROLE_AHMIS_ADDMEDICINE_GETALLMEDICINEBYID"
export const ROLE_AHMIS_PANCHAKARMA_SAVEPANCHAKARMA = "ROLE_AHMIS_PANCHAKARMA_SAVEPANCHAKARMA"
export const ROLE_AHMIS_PANCHAKARMA_GETALLPANCHAKARMA = "ROLE_AHMIS_PANCHAKARMA_GETALLPANCHAKARMA"
export const ROLE_AHMIS_PANCHAKARMA_GETPANCHAKARMABYID = "ROLE_AHMIS_PANCHAKARMA_GETPANCHAKARMABYID"
export const ROLE_AHMIS_KSHARSUTRA_SAVEKSHARSUTRA = "ROLE_AHMIS_KSHARSUTRA_SAVEKSHARSUTRA"
export const ROLE_AHMIS_KSHARSUTRA_GETALLKSHARSUTRA = "ROLE_AHMIS_KSHARSUTRA_GETALLKSHARSUTRA"
export const ROLE_AHMIS_KSHARSUTRA_GETKSHARSUTRAFROMID = "ROLE_AHMIS_KSHARSUTRA_GETKSHARSUTRAFROMID"
export const ROLE_AHMIS_KSHARSUTRA_DELETEKSHARSUTRA = "ROLE_AHMIS_KSHARSUTRA_DELETEKSHARSUTRA"
export const ROLE_AHMIS_THERAPEUTIC_GETTHERAPEUTICFROMID = "ROLE_AHMIS_THERAPEUTIC_GETTHERAPEUTICFROMID"
export const ROLE_AHMIS_THERAPEUTIC_GETALLTHERAPUTICREGISTRATION = "ROLE_AHMIS_THERAPEUTIC_GETALLTHERAPUTICREGISTRATION"
export const ROLE_AHMIS_THERAPEUTIC_DELETETHERAPEUTICREGISTRATION = "ROLE_AHMIS_THERAPEUTIC_DELETETHERAPEUTICREGISTRATION"
export const ROLE_AHMIS_THERAPEUTIC_SAVETHERAPEUTIC = "ROLE_AHMIS_THERAPEUTIC_SAVETHERAPEUTIC"
export const ROLE_AHMIS_THERAPEUTICREGISTER_GETTHERAPEUTICREGISTERABYPAGE = "ROLE_AHMIS_THERAPEUTICREGISTER_GETTHERAPEUTICREGISTERABYPAGE"
export const ROLE_AHMIS_ESTHANAGARIK_SAVEJESTHANAGARIK = "ROLE_AHMIS_ESTHANAGARIK_SAVEJESTHANAGARIK"
export const ROLE_AHMIS_ESTHANAGARIK_GETJESTHANAGARIKBYID = "ROLE_AHMIS_ESTHANAGARIK_GETJESTHANAGARIKBYID"
export const ROLE_AHMIS_ESTHANAGARIK_DELETEESTHANAGARIK = "ROLE_AHMIS_ESTHANAGARIK_DELETEESTHANAGARIK"
export const ROLE_AHMIS_JESTHANAGARIK_GETJESTHANAGARIKBYPAGE = "ROLE_AHMIS_JESTHANAGARIK_GETJESTHANAGARIKBYPAGE"
export const ROLE_AHMIS_GAUNGHARANDSAHARI_SAVEGAUNGHARANDSAHARI = "ROLE_AHMIS_GAUNGHARANDSAHARI_SAVEGAUNGHARANDSAHARI"
export const ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIFROMID = "ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIFROMID"
export const ROLE_AHMIS_GAUNGHARANDSAHARI_DELETEGAUNGHARANDSAHARI = "ROLE_AHMIS_GAUNGHARANDSAHARI_DELETEGAUNGHARANDSAHARI"
export const ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIBYPAGE = "ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIBYPAGE"
export const ROLE_AHMIS_ADMISSIONREGISTER_SAVEADMISSIONREGISTER = "ROLE_AHMIS_ADMISSIONREGISTER_SAVEADMISSIONREGISTER"
export const ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERFROMID = "ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERFROMID"
export const ROLE_AHMIS_ADMISSIONREGISTER_DELETEADMISSIONREGISTER = "ROLE_AHMIS_ADMISSIONREGISTER_DELETEADMISSIONREGISTER"
export const ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERBYPAGE = "ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERBYPAGE"
export const ROLE_AHMIS_DISCHARGEREGISTER_SAVEDISCHARGEREGISTER = "ROLE_AHMIS_DISCHARGEREGISTER_SAVEDISCHARGEREGISTER"
export const ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERFROMID = "ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERFROMID"
export const ROLE_AHMIS_DISCHARGEREGISTER_DELETEDISCHARGEREGISTER = "ROLE_AHMIS_DISCHARGEREGISTER_DELETEDISCHARGEREGISTER"
export const ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE = "ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE"
export const ROLE_AHMIS_EMERGENCYSERVICE_SAVEEMERGENCYSERVICE = "ROLE_AHMIS_EMERGENCYSERVICE_SAVEEMERGENCYSERVICE"
export const ROLE_AHMIS_EMERGENCYSERVICE_GETEMERGENCYSERVICEFROMID = "ROLE_AHMIS_EMERGENCYSERVICE_GETEMERGENCYSERVICEFROMID"
export const ROLE_AHMIS_EMERGENCYSERVICE_DELETEEMERGENCYSERVICE = "ROLE_AHMIS_EMERGENCYSERVICE_DELETEEMERGENCYSERVICE"
export const ROLE_AHMIS_EMERGENCYSERVICE_GETEMERGENCYSERVICEBYPAGE = "ROLE_AHMIS_EMERGENCYSERVICE_GETEMERGENCYSERVICEBYPAGE"
export const ROLE_AHMIS_STANPAIAAMASISHU_SAVESTANPAIAAMASISHU = "ROLE_AHMIS_STANPAIAAMASISHU_SAVESTANPAIAAMASISHU"
export const ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUFROMID = "ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUFROMID"
export const ROLE_AHMIS_STANPAIAAMASISHU_DELETESTANPAIAAMASISHU = "ROLE_AHMIS_STANPAIAAMASISHU_DELETESTANPAIAAMASISHU"
export const ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE = "ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE"
export const ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE = "ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE"
export const ROLE_AHMIS_PANCHAKARMASERVICE_SAVEPANCHAKARMASERVICE = "ROLE_AHMIS_PANCHAKARMASERVICE_SAVEPANCHAKARMASERVICE"
export const ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEFROMID = "ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEFROMID"
export const ROLE_AHMIS_PANCHAKARMASERVICE_DELETEPANCHAKARMASERVICE = "ROLE_AHMIS_PANCHAKARMASERVICE_DELETEPANCHAKARMASERVICE"
export const ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEBYDATERANGEANDPAGE = "ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEBYDATERANGEANDPAGE"
export const ROLE_AHMIS_BITYABIBARAN_SAVEBITYABIBARAN = "ROLE_AHMIS_BITYABIBARAN_SAVEBITYABIBARAN"
export const ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARANBYID = "ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARANBYID"
export const ROLE_AHMIS_BITYABIBARAN_DELETEBITYABIBARANBYID = "ROLE_AHMIS_BITYABIBARAN_DELETEBITYABIBARANBYID"
export const ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARAN = "ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARAN"
export const ROLE_AHMIS_EMPLOYEEREGISTRATION_SAVEEMPLOYEEREGISTRATION = "ROLE_AHMIS_EMPLOYEEREGISTRATION_SAVEEMPLOYEEREGISTRATION"
export const ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATIONBYID = "ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATIONBYID"
export const ROLE_AHMIS_EMPLOYEEREGISTRATION_DELETEEMPLOYEEREGISTRATIONBYID = "ROLE_AHMIS_EMPLOYEEREGISTRATION_DELETEEMPLOYEEREGISTRATIONBYID"
export const ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION = "ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION"
export const ROLE_AHMIS_SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEFROMID = "ROLE_AHMIS_SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEFROMID"
export const ROLE_AHMIS_SHALYACHIKITSASERVICE_DELETESHALYACHIKITSASERVICE = "ROLE_AHMIS_SHALYACHIKITSASERVICE_DELETESHALYACHIKITSASERVICE"
export const ROLE_AHMIS_SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEBYDATERANGEANDPAGE = "ROLE_AHMIS_SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEBYDATERANGEANDPAGE"
export const ROLE_AHMIS_SHALYACHIKITSA_GETPENDINGSHALYACHIKITSA = "ROLE_AHMIS_SHALYACHIKITSA_GETPENDINGSHALYACHIKITSA"
export const ROLE_AHMIS_ACUPUNCTURESEWA_SAVEACUPUNCTURESEWA = "ROLE_AHMIS_ACUPUNCTURESEWA_SAVEACUPUNCTURESEWA"
export const ROLE_AHMIS_ACUPUNCTURESEWA_GETACUPUNCTURESEWABYID = "ROLE_AHMIS_ACUPUNCTURESEWA_GETACUPUNCTURESEWABYID"
export const ROLE_AHMIS_ACUPUNCTURESEWA_DELETEACUPUNCTURESEWA = "ROLE_AHMIS_ACUPUNCTURESEWA_DELETEACUPUNCTURESEWA"
export const ROLE_AHMIS_ACUPUNCTURESEWA_GETACUPUNCTURESEWABYPAGE = "ROLE_AHMIS_ACUPUNCTURESEWA_GETACUPUNCTURESEWABYPAGE"
export const ROLE_AHMIS_ACUPUNCTURESEWA_GETAllACUPUNCTURESEWAID = "ROLE_AHMIS_ACUPUNCTURESEWA_GETAllACUPUNCTURESEWAID"
export const ROLE_AHMIS_ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWAID = "ROLE_AHMIS_ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWAID"
export const ROLE_AHMIS_ACUPUNCTURESEWASERVICE_SAVEACUPUNCTURESEWASERVICE = "ROLE_AHMIS_ACUPUNCTURESEWASERVICE_SAVEACUPUNCTURESEWASERVICE"
export const ROLE_AHMIS_ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEFROMID = "ROLE_AHMIS_ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEFROMID"
export const ROLE_AHMIS_ACUPUNCTURESEWASERVICE_DELETEACUPUNCTURESEWASERVICE = "ROLE_AHMIS_ACUPUNCTURESEWASERVICE_DELETEACUPUNCTURESEWASERVICE"
export const ROLE_AHMIS_ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEBYDATERANGEANDPAGE = "ROLE_AHMIS_ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEBYDATERANGEANDPAGE"
export const ROLE_AHMIS_ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWA = "ROLE_AHMIS_ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWA"
export const ROLE_AHMIS_PHYSIOTHERAPYSEWA_SAVEPHYSIOTHERAPYSEWA = "ROLE_AHMIS_PHYSIOTHERAPYSEWA_SAVEPHYSIOTHERAPYSEWA"
export const ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYID = "ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYID"
export const ROLE_AHMIS_PHYSIOTHERAPYSEWA_DELETEPHYSIOTHERAPYSEWA = "ROLE_AHMIS_PHYSIOTHERAPYSEWA_DELETEPHYSIOTHERAPYSEWA"
export const ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYPAGE = "ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYPAGE"
export const ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETAllPHYSIOTHERAPYSEWAID = "ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETAllPHYSIOTHERAPYSEWAID"
export const ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPENDINGPHYSIOTHERAPYSEWAID = "ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPENDINGPHYSIOTHERAPYSEWAID"
export const ROLE_AHMIS_YOGSEWA_SAVEYOGSEWA = "ROLE_AHMIS_YOGSEWA_SAVEYOGSEWA"
export const ROLE_AHMIS_YOGSEWA_GETYOGSEWABYID = "ROLE_AHMIS_YOGSEWA_GETYOGSEWABYID"
export const ROLE_AHMIS_YOGSEWA_DELETEYOGSEWA = "ROLE_AHMIS_YOGSEWA_DELETEYOGSEWA"
export const ROLE_AHMIS_YOGSEWA_GETYOGSEWABYPAGE = "ROLE_AHMIS_YOGSEWA_GETYOGSEWABYPAGE"
export const ROLE_AHMIS_YOGSEWA_GETAllYOGSEWAID = "ROLE_AHMIS_YOGSEWA_GETAllYOGSEWAID"
export const ROLE_AHMIS_YOGSEWA_GETPENDINGYOGSEWAID = "ROLE_AHMIS_YOGSEWA_GETPENDINGYOGSEWAID"
export const ROLE_AHMIS_SAVE_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER = "ROLE_AHMIS_SAVE_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER"
export const ROLE_AHMIS_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_GET_BY_ID = "ROLE_AHMIS_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_GET_BY_ID"
export const ROLE_AHMIS_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_DELETE_BY_ID = "ROLE_AHMIS_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_DELETE_BY_ID"
export const ROLE_AHMIS_SAVE_AND_UPDATE_HOMEOPATHY_REGISTER = "ROLE_AHMIS_SAVE_AND_UPDATE_HOMEOPATHY_REGISTER"
export const ROLE_AHMIS_HOMEOPATHY_REGISTER_GET_BY_ID = "ROLE_AHMIS_HOMEOPATHY_REGISTER_GET_BY_ID"
export const ROLE_AHMIS_HOMEOPATHY_REGISTER_DELETE_BY_ID = "ROLE_AHMIS_HOMEOPATHY_REGISTER_DELETE_BY_ID"
export const ROLE_AHMIS_SAVE_NAGARIK_AROGYA_SEWA_REGISTER = "ROLE_AHMIS_SAVE_NAGARIK_AROGYA_SEWA_REGISTER"
export const ROLE_AHMIS_GET_NAGARIK_AROGYA_SEWA_REGISTER_BY_ID = "ROLE_AHMIS_GET_NAGARIK_AROGYA_SEWA_REGISTER_BY_ID"
export const ROLE_AHMIS_DELETE_NAGARIK_AROGYA_SEWA_REGISTER_BY_ID = "ROLE_AHMIS_DELETE_NAGARIK_AROGYA_SEWA_REGISTER_BY_ID"
export const ROLE_AHMIS_GET_ALL_NAGARIK_AROGYA_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE = "ROLE_AHMIS_GET_ALL_NAGARIK_AROGYA_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE"
export const ROLE_AHMIS_SAVE_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER = "ROLE_AHMIS_SAVE_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER"
export const ROLE_AHMIS_GET_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID = "ROLE_AHMIS_GET_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID"
export const ROLE_AHMIS_DELETE_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID = "ROLE_AHMIS_DELETE_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID"
export const ROLE_AHMIS_GET_ALL_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_FROM_DATE_RANGE_AND_PAGE = "ROLE_AHMIS_GET_ALL_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_FROM_DATE_RANGE_AND_PAGE"
export const ROLE_AHMIS_SAVE_AND_UPDATE_JESTHANAGARIK_HEALTH_SEWA_REGISTER = "ROLE_AHMIS_SAVE_AND_UPDATE_JESTHANAGARIK_HEALTH_SEWA_REGISTER"
export const ROLE_AHMIS_JESTHA_NAGARIK_HEALTH_SEWA_REGISTER_GET_BY_ID = "ROLE_AHMIS_JESTHA_NAGARIK_HEALTH_SEWA_REGISTER_GET_BY_ID"
export const ROLE_AHMIS_JESTHA_NAGAGIK_HEALTH_SEWA_REGISTER_DELETE_BY_ID = "ROLE_AHMIS_JESTHA_NAGAGIK_HEALTH_SEWA_REGISTER_DELETE_BY_ID"
export const ROLE_AHMIS_GET_ALL_JESTHA_NAGARIK_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE = "ROLE_AHMIS_GET_ALL_JESTHA_NAGARIK_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE"
export const ROLE_AHMIS_HOMEOPATHY_REPORT = "ROLE_AHMIS_HOMEOPATHY_REPORT"
export const ROLE_AHMIS_PHYSIO_THERAPY_REPORT = "ROLE_AHMIS_PHYSIO_THERAPY_REPORT"
export const ROLE_AHMIS_ACUPUNCTURE_SEWA_REPORT = "ROLE_AHMIS_ACUPUNCTURE_SEWA_REPORT"
export const ROLE_AHMIS_STAN_PAI_AAMA_SISHU_REPORT = "ROLE_AHMIS_STAN_PAI_AAMA_SISHU_REPORT"

export const HTTP_STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
};

// Common Messages
export const SERVICE_NA = "Some of our services are currently not available. Please try again later.";
export const SOMETHING_WENT_WRONG = "Oops! Something went wrong. Please try again.";
export const REQUIRED_FIELD = "This is a required field.";
export const ENTER_VALID_EMAIL = "कृपया मान्य ईमेल ठेगाना प्रविष्ट गर्नुहोस्।";
export const NO_RECORDS_FOUND = "माफ गर्नुहोस्, कुनै रेकर्ड फेला परेन।";
export const PASSWORD_DO_NOT_MATCHES = "नयाँ पासवर्ड र कन्फर्म पासवर्ड मेल खाएन।";
export const SESSION_EXPIRED = "तपाईंको सेसन समाप्त भएको छ, कृपया लग-इन गर्नुहोस।";
export const IS_SESSION_EXPIRED = "isSessionExpired";
export const EDIT_SELECTED_RECORD = "चयनित रेकर्ड सम्पादन गर्नुहोस्।";
export const ZERO_OR_GREATER = "कृपया 0 अथवा 0 भन्दा ठूलो संख्या प्रदान गर्नुहोस्।";
export const GREATER_THAN_ZERO = "कृपया 0 भन्दा ठूलो संख्या प्रदान गर्नुहोस्।";
export const PHONE_NUMBER_VALIDATION_ERROR = "कृपया १० अंकको सम्पर्क नं प्रदान गर्नुहोस्।";
export const PHONE_NUMBER_SHOULD_HAVE_NUMERIC_VALUE_MESSAGE = "Phone No. should only contain numeric value in english";
export const VALID_AGE_MESSAGE = "यस सेवा को लागि मान्य उमेर भएन";
export const LESS_THAN_FIFTY = "कृपया 50 भन्दा सानो संख्या प्रदान गर्नुहोस्।";
export const BETWEEN_ONE_AND_THREE = "कृपया एकदेखि तीन सम्मको अंक हाल्नुहोस।"
export const SUCCESS = "success";
export const ERROR = "error";
export const AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG = "एक पटक हटाइसकेपछि, यो स्थायी रूपमै प्रणालीबाट हटाइनेछ र तपाईं यस कार्यलाई पूर्ववत गर्न सक्नुहुन्न।";
export const FILE_SIZE_EXCEED = "तपाईंले छनौट गर्नुभएको फाईलको साइज धेरै ठूलो छ, कृपया सानो फाईल (१० एम.बी भन्दा कम) संलग्न गर्नुहोस्।";
export const ALL_FILES_SIZE_EXCEED = "तपाईंले छनौट गर्नुभएको फाईलहरुको साइज धेरै ठूलो छ, कृपया सबै फाइल गरेर (३० एम.बी भन्दा कम) संलग्न गर्नुहोस्।";
export const REPORT_NOT_DRAWN = "कृपया तपाईंले रिपोर्ट हेर्न चाहनुभएको मिति देखी र मिति सम्म चयन गर्नुहोस्, र रिपोर्ट हेर्नुहोस् लेखिएको बटन क्लिक गर्नुहोस्।";
export const MINIMUM = "min";
export const FISCAL_YEAR_NOT_MATCHED = "रेकर्ड अपडेट गर्दा अर्को आर्थिक वर्षको दर्ता मिति हाल्न अनुमति छैन।"
export const DO_NOT_REPEAT_FISCAL_YEAR = "कृपया रेकर्ड अपडेट गर्दा आर्थिक वर्ष नदोहोर्याउनुहोस्।"
export const VALID_BIRTH_DATE = "कृपया सही जन्ममिति छान्नुहोस्।"
export const ROLE_ELIGIBLE_FOR_SUBMITTING_DHIS2_REPORT = [ROLE_USER, ROLE_USER_KHOP, ROLE_HOSPITAL_AND_HEALTH_FACILITY_USER, ROLE_USER_LAB_ALL_FEATURE]

export const ALL_ROLES = [
  { value: ROLE_ADMIN, label: "प्रणाली प्रमुख" },
  { value: ROLE_USER_MUNICIPALITY, label: "नगरपालिका प्रशासक" },
  { value: ROLE_USER_MUNICIPALITY_BILLING, label: "नगरपालिका बिल प्रशासक" },
  { value: ROLE_USER_MUNICIPALITY_KHOP, label: "नगरपालिका खोप प्रशासक" },
  { value: ROLE_USER, label: "स्वास्थ संस्था प्रशासक" },
  { value: ROLE_USER_KHOP, label: "स्वास्थ संस्था खोप प्रशासक" },
  { value: ROLE_USER_LAB_ALL_FEATURE, label: "प्रयोगशाला प्रशासक" },
  { value: ROLE_HOSPITAL_AND_HEALTH_FACILITY_USER, label: "स्वास्थ संस्था तथा अस्पताल प्रशासक" },
  { value: ROLE_USER_PALIKA_INFO, label: "सक्रिय/निस्क्रिय स्वास्थ्य संस्था सुची प्रशासक" },
];

export const MUNICIPALITY_ROLES = [
  { value: ROLE_USER_MUNICIPALITY, label: "नगरपालिका प्रशासक" },
  { value: ROLE_USER_MUNICIPALITY_BILLING, label: "नगरपालिका बिल प्रशासक" },
  { value: ROLE_USER_MUNICIPALITY_KHOP, label: "नगरपालिका खोप प्रशासक" },
];

export const HEALTH_ORGANIZATION_ROLES = [
  { value: ROLE_USER, label: "स्वास्थ संस्था प्रशासक" },
  { value: ROLE_USER_LAB_ALL_FEATURE, label: "स्वास्थ संस्था प्रयोगशाला प्रशासक" },
  { value: ROLE_USER_KHOP, label: "स्वास्थ संस्था खोप प्रशासक" },
]

export const HOSPITAL_ORGANIZATION_ROLE = [
  { value: ROLE_HOSPITAL_AND_HEALTH_FACILITY_USER, label: "अस्पताल प्रशासक" },
  { value: ROLE_USER_LAB_ALL_FEATURE, label: "प्रयोगशाला प्रशासक" },
]

export const VILLAGE_CLINIC_DISEASE_CLASSIFICATION_BELOW_2_MONTHS = [
  { value: "GAMBHIR_SANGKRAMAN", label: "गम्भीर संक्रमण" },
  { value: "STHAANIYA_SANGKRAMAN", label: "स्थानिय संक्रमण" },
  { value: "NO_SANGKRAMAN", label: "संक्रमण नभएको" },
  { value: "SITAANGA", label: "सितांग" },
  { value: "SEVERE_JAUNDICE", label: "कडा जण्डिस" },
  { value: "JAUNDICE", label: "जण्डिस" }
]

export const PNEUMONIA_STATUS_OPTIONS = [
  { value: "NO_PNEUMONIA_COUGH", label: "निमोनिया नभएको रुघाखोकी" },
  { value: "PNEUMONIA", label: "निमोनिया" },
  { value: "SEVERE_PNEUMONIA", label: "कडा रोग वा कडा निमोनिया" },
]

export const DEHYDRATION_OPTIONS = [
  { value: "NO_JAAL_BIYOJAN", label: "जलवियोजन नभएको" },
  { value: "SOME_JAAL_BIYOJAN", label: "केहि जलवियोजन" },
  { value: "SEVERE_JAAL_BIYOJAN", label: "कडा जलवियोजन" },
];

export const MALNUTRITION_STATUS_OPTION = [
  { value: "MALNUTRITION", label: "कुपोषण/रक्त अल्पता" },
  { value: "SEVERE_MALNUTRITION", label: "कडा कुपोषण/कडा रक्त अल्पता" },
];

export const SUGGESTION_GIVEN_TO_MOTHER = [
  { value: "FOOD", label: "खाना " },
  { value: "LIQUID_BASED_FOOD", label: "झोलकुरा" },
  { value: "CONTACT_IMMEDIATELY", label: "तुरुन्त ल्याउने" }
]

export const BABY_SUGGESTION_OPTIONS = [
  { value: "FULL_BREAST_FEEDING", label: "पूर्ण स्तनपान" },
  { value: "KEEP_WARM", label: "न्यानो पार्ने" },
  { value: "WASH_HAND", label: "हात धुने" },
  { value: "TAKE_CARE_OF_KID", label: "नवजात शिशुलाई स्याहार सल्लाह " },
  { value: "FIX_FOLLOW_UP_DATE", label: "कहिले तुरुन्त फर्कने" },
  { value: "CLEAN_WOUNDS", label: "घाउ सफा गर्ने" }
];

export const MOTHER_SUGGESTION_OPTIONS = [
  { value: "PREGNANCY_CHECK", label: "सुत्केरी जाँच" },
  { value: "VITAMIN_A", label: "भिटामिन ए" },
  { value: "IRON", label: "आइरन" },
  { value: "BIRTH_GAP", label: "जन्न्मान्तर" }
];

export const TREATMENT_OUTCOME_FOR_IMNCI = [
  { value: "IMPROVED", label: "Improved" },
  { value: "REFERRED", label: "Referred" },
  { value: "LAMA_ABSCONDED", label: "LAMA / Absconded" },
  { value: "DEATH", label: "Death" }
]

export const FOLLOWUP_RESULT_OPTIONS = [
  { value: "PROGRESS", label: "सुधार" },
  { value: "SAME", label: "उस्तै" },
  { value: "ADMITTED_AFTER_GETTING_WORSE", label: "झन बिरामी भइ प्रेषण गरेका" },
  { value: "DEATH", label: "मृत्यु" }
];

export const VILLAGE_CLINIC_SERVICE_TYPES = [
  { value: "1", label: "बालरोग व्यवस्थापन (१)" },
  { value: "2", label: "पोषण (२)" },
  { value: "3", label: "सुरिक्षत मातृत्व (३)" },
  { value: "4", label: "परिवार नियोजन (४)" },
  { value: "5", label: "प्राथमिक उपचार (५)" },
];

export const MUL_DARTA_SEWA_TYPES = [
  { value: "1", label: "सामान्य उपचार (१) " },
  { value: "2", label: "पञ्चकर्म  सेवा (२)" },
  { value: "3", label: "शल्य सेवा (३)" },
  { value: "4", label: "योग सेवा (४)" },
  { value: "5", label: "जेष्ठ नागरिक सेवा (५)" },
  { value: "6", label: "स्तनपायी आमा तथा मातृशिशु सेवा (६)" },
  { value: "7", label: "अकुपञ्चर सेवा (७)" },
  { value: "8", label: "फिजियोथेरापी सेवा (८)" },
  { value: "9", label: "होमियोप्याथी सेवा (९)" },
  { value: "10", label: "प्राकृतिक चिकित्सा सेवा(१०)" },
  { value: "11", label: "सोवारिग्पा (आम्ची)  चिकित्सा सेवा(११) " },
  { value: "12", label: "नसर्ने रोग तथा मानसिक स्वास्थ्य सेवा (१२)" },
  { value: "13", label: "अन्य सेवा (१३ )" }
];

export const SNEHAN_MAHAL = [
  { value: "ABHYANTAR", label: "आभ्यान्तर स्नेहन " },
  { value: "BHARHA", label: "बाह्य स्नेहन " },
]

export const SWEDEN_CASTLE = [
  { value: "NADI", label: "नाडी स्वेदन" },
  { value: "SARVANG", label: "सर्वांग स्वेदन" },
]

export const VASTI_MAHAL = [
  { value: "MATRA", label: "मात्रा " },
  { value: "AANUBACHAN", label: "अनुवासन" },
  { value: "MUSTAADI", label: "मुस्तादी " },
  { value: "GUDUCHYADI", label: "गुडुच्यादी " },
  { value: "PEYACHA", label: "पिच्छा " },
  { value: "VAITARANA", label: "वैतरण " },
  { value: "AANAY", label: "अन्य " },
]

export const GRANTED_NOT_GRANTED_OPTIONS = [
  { value: GRANTED, label: "पाएको" },
  { value: NOT_GRANTED, label: "नपाएको" }
];

export const NUMBER_OF_ADOLESCENT_GIRLS_RECEIVING_IRON_FOLIC_ACID_PILLS = [
  { value: "RECEIVED_IRON_FOLIC_ACID_PILLS_FOR_13_WEEKS", label: "१३ हप्तासम्म आइरन फोलिक एसिड चक्की पाएका किशोरीको संख्या (जनामा)" },
  { value: "RECEIVED_IRON_FOLIC_ACID_PILLS_FOR_26_WEEKS", label: "२६ हप्तासम्म आइरन फोलिक एसिड चक्की पाएका किशोरीको संख्या (जनामा)" }
];

export const REPORT_SOURCE = [
  { value: "SCHOOL", label: "बिद्यालय" },
  { value: "HEALTH_ORGANIZATION", label: "स्वास्थ्य संस्था" },
  { value: "WOMEN_COMMUNITY_HEALTH_VOLUNTEERS", label: "महिला सामुदायिक स्वास्थ्य स्वयं सेविका" },
]

export const OTHER_MUL_DARTAA_SEWA_TYPE = [
  { value: SENIOR_CITIZEN_SERVICE, label: "जेष्ठ नगारिक" },
  { value: HOSPITAL_ADMISSION_SERVICE, label: "Hospital Admission" },
  { value: SNCU_NICU_KMCU_SERVICE, label: "SNCU / NICU / KMCU" },
  { value: REHABILITATION_SERVICE, label: "Rehabilitation" },
  { value: KHOP_SERVICE, label: "बालबालिका खोप सेवा" },
  { value: TD_KHOP_SERVICE, label: "टी.डी. खोप सेवा" },
  { value: LAB_SERVICE, label: "प्रयोगशाला सेवा" },
  { value: REPRODUCTIVE_HEALTH, label: "प्रजनन स्वास्थ्य रुग्णता सेवा" },
  { value: FORTIFIED_FLOUR_DISTRIBUTION, label: "Fortified Flour Distribution" },
  { value: SANITARY_PAD_DETAIL_SERVICE, label: "सेनेटरी प्याड सेवा" },
  { value: UPT_TEST_SERVICE, label: "UPT Test" },
  { value: COVID_VACCINE_SERVICE, label: "Covid" },
  { value: OCMC_SERVICE, label: "OCMC" },
  { value: ASRH_SERVICE, label: "ASRH" },
]

export const DIAGNOSIS_OF_LEPROSY = [
  { value: YES, label: "हो" },
  { value: NO, label: "होइन" },
  { value: "PROBABLY", label: "शंकास्पद" },
]

export const CASTE_CODES = [
  { value: "1", label: "दलित (१)" },
  { value: "2", label: "जनजाती (२)" },
  { value: "3", label: "मधेशी (३)" },
  { value: "4", label: "मुस्लिम (४)" },
  { value: "5", label: "ब्राह्मण/क्षेत्री (५)" },
  { value: "6", label: "अन्य (६)" }
];
// export const THERAPY = [
//   { value: "ABHYANTAR_SNEHANA", label: "आभ्यान्तर स्नेहन" },
//   { value: "2", label: "बाह्य स्नेहन" },
//   { value: "NADI_SWEDANA", label: "नाडी स्वेदन" },
//   { value: "SARVANGA_SWEDANA", label: "सर्वांग स्वेदन" },
//   { value: "VAMANA", label: "वमन" },
//   { value: "VIRECHANA", label: "विरेचन" },
//   { value: "NIRUHA_BASTI", label: "निरुह बस्ति" },
//   { value: "ANUVASANA_BASTI", label: "अनुवासन बस्ति" },
//   { value: "NASYA", label: "नस्य" },
//   { value: "RAKTAMOKSHANA", label: "रक्तमोक्षण" },
//   { value: "STHANIK_BASTI", label: "स्थानिक बस्ति" },
//   { value: "SHIRODHARA", label: "शिरोधारा" },
//   { value: "POTLI_SWEDANA", label: "पोटली स्वेद" },
//   { value: "KRIYA_KALPA", label: "क्रियाकल्प" },
//   { value: "PICHU", label: "पिचु" },
//   { value: "PRAKSHALAN", label: "प्रक्षालन" },
//   { value: "OTHER", label: "अन्य" }
// ]

export const SEWA_THERAPY = [
  { value: "1", label: "दिपन पाचन" },
  { value: "2", label: "आभ्यान्तर स्नेहन" },
  { value: "3", label: "एकांग" },
  { value: "4", label: "सर्बांग" },
  { value: "5", label: "एकांग" },
  { value: "6", label: "सर्बांग" },
  { value: "7", label: "वमन" },
  { value: "8", label: "विरेचन" },
  { value: "9", label: "निरुह बस्ति" },
  { value: "10", label: "अनुवासन बस्ति" },
  { value: "11", label: "नस्य" },
  { value: "12", label: "रक्तमोक्षण" },
  { value: "13", label: "स्थानिक बस्ति" },
  { value: "14", label: "शिरोधारा" },
  { value: "15", label: "पोटली स्वेद" },
  { value: "16", label: "क्रियाकल्प" },
  { value: "17", label: "पिचु" },
  { value: "18", label: "प्रक्षालन" },
]

export const JESTHANAGARIK_SEWA = [
  { value: "1", label: "स्नेहन" },
  { value: "2", label: "स्वेदन" },
  { label: "3", label: "रसायन औषधी/तेल" },
  { label: "4", label: "अन्य" }
]

export const SEWA_CODE = [
  { value: "1", label: "क्षारसूत्र" },
  { value: "2", label: "अग्निकर्म" },
  { value: "3", label: "रक्तमोक्षण" },
  { value: "4", label: "मर्म उपचार" },
  { value: "5", label: "विशिष्टिकृत शल्य सेवा" },
  { value: "6", label: "अन्य शल्य उपचार" }
]

export const bahya_Snehana = [
  { label: "Bahya Yakai", value: "BAHYA_YAKAI" },
  { label: "Bahya Sarvanga", value: "BAHYA_SARVANGA" }
]

export const AYURVEDIC_THERAPY = [
  { value: "1", label: "पञ्चकर्म (१)" },
  { value: "2", label: "शल्य सेवा (२)" },
  { value: "3", label: "उपचारात्मक योग (३)" },
  { value: "4", label: " स्त्री प्रसूति सेवा (४)" },
  { value: "5", label: "कौमारभृत्य सेवा (५)" },
  { value: "6", label: "शालक्य सेवा (६)" },
  { value: "7", label: "अक्युपञ्चर (७)" },
  { value: "8", label: "फिजियोथेरापी (८)" },
  { value: "9", label: " होमियोप्याथी (९)" },
  { value: "10", label: "अन्य सेवा (खुलाउने) (१०)" },
];

export const ACUPUNCTURE_SEVA_PRAKAAR = [
  { value: "ACUPUNCTURE_SAMANAYA", label: "अक्यूपंक्चर (सामान्य)" },
  { value: "CUPPING", label: "कपिङ" },
  { value: "MOXIBUSTION", label: "मोक्सीबस्टन" },
  { value: "ACUPRESSURE", label: "अक्यूप्रेसर" },
  { value: "RAKTA_MOKSHAN", label: "रक्त मोक्षण (ब्लड लेटिंग)" },
  { value: "ACUPUNCTURE_ELECTRIC_STIMULATION", label: "अक्यूपंक्चर (इलेक्टिक स्टिमुलेसन)" },
  { value: "ANYA", label: "अन्य" }
];

export const MEDICINE_NAME = [
  { value: "AVIPATTIKAR_CHURNA_FIFTY_GRAM", label: "अविपत्तिकर चुर्ण ५० ग्राम" },
  { value: "HINGWASHTAK_CHURNA_FIFTY_GRAM", label: "हिङग्वाष्टक चूर्ण ५० ग्राम" },
  { value: "MAHAYOGRAJ_GUGGULU_TEN_GRAM", label: "महायोगराज गुग्गुलु १० ग्राम" },
  { value: "MAHANARAYAN_TEL_SIXTY_ML", label: "महानारायण तेल ६० एम. एल." },
  { value: "SITOPALADI_CHURNA_FIFTY_GRAM", label: "सितोपलादि चूर्ण ५० ग्राम" },
  { value: "TANKAN_BHASMA_TEN_GRAM", label: "टंकण भष्म १० ग्राम" },
  { value: "PUSHYANUG_CHURNA_FIFTY_GRAM", label: "पुष्यानुग चूर्ण ५० ग्राम" },
  { value: "BAALAMRIT_TEN_ML", label: "बालामृत १०० एम एल." },
  { value: "MRIGAMADASAVA_FIFTEEN_ML", label: "मृगमदासव १५ एम. एल." },
  { value: "SARPAGANDHAGHAN_VATI_TWENTY_FIVE_GRAM", label: "सर्पगन्धाघन वटि २५ ग्राम" },
  { value: "NIMBADI_CHURNA_FIFTY_GRAM", label: "निम्बादी चूर्ण ५० ग्राम" },
  { value: "GANDHAK_RASAYAN_TEN_GRAM", label: "गन्धक रसायन १० ग्राम" },
  { value: "KHADIRADI_VATI_TEN_GRAM", label: "खदिरादी वटी १० ग्राम" },
  { value: "SHADBINDU_TEL_TEN_ML", label: "षडबिन्दु तेल १० एम. एल." },
  { value: "YOGRAJ_GUGGULE_TWENTY_FIVE_GRAM", label: "योगराज गुग्गुलु २५ ग्राम" },
  { value: "HAHASUDARSHAN_CHURNA_FIFTY_GRAM", label: "महासुदर्शन चूर्ण ५० ग्राम" },
  { value: "MAHASHANKHA_VATI_TEN_GRAM", label: "महाशंख वटी १० ग्राम" },
  { value: "BILWADI_CHURNA_FIFTY_GRAM", label: "बिल्वादी चूर्ण ५० ग्राम" },
  { value: "JATYADI_TEL_SIXTY_ML", label: "जात्यादी तेल ६० एम. एल." },
  { value: "KAISHORE_GUGGULU_TWENTY_FIVE_GRAM", label: "कैशोर गुग्गुलु २५ ग्राम" },
  { value: "CHANDRAPRABHA_VATI_TWENTY_FIVE_GRAM", label: "चन्द्रप्रभावटी २५ ग्राम " },
  { value: "TRIPHALE_CHURNA_FIFTY_GRAM", label: "त्रिफला चूर्ण ५० ग्राम" },
  { value: "ASHWAGANDHA_CHURNA_FIFTY_GRAM", label: "अश्वगन्धा चूर्ण ५० ग्राम" },
  { value: "AMLAKI_POWDER_FIFTY_GRAM", label: "आमलकी चूर्ण ५० ग्राम" },
  { value: "DASHMUL_TEL_SIXTY_ML", label: "दशमुल तेल ६० एम. एल." },
  { value: "SHATAVARI_CHURNA_HUNDRED_GRAM", label: "शतावरी चूर्ण १०० ग्राम" },
  { value: "JWANO_YAVANI_CHURNA_HUNDRED_GRAM", label: "ज्वानो -यवानी चूर्ण_ १०० ग्राम" },
  { value: "OTHERS", label: "अन्य" }
];


export const GHAR_BHETA_SEWA = [
  { value: "BP_TESTING", label: "रक्तचाप विएस  लगायत  परिक्षण" },
  { value: "HEALTH_EDUCATION", label: "स्वास्थ्य शिक्षा" },
  { value: "CONSULTATION", label: "सल्लाह सुझाव प्रेषण" },
  { value: "OTHERS", label: "अन्य" }
];

export const YOG_SEWA = [
  { value: "1", label: "उपचारात्मक" },
  { value: "2", label: "प्रवर्धनात्मक " },
  { value: "3", label: "अभियानमूलक" },
];

export const YOG_SEWA_VIVARAN = [
  { value: "1", label: "आसन" },
  { value: "2", label: "प्राणायम " },
  { value: "3", label: "ध्यान" },
  { value: "4", label: "षट्कर्म" },
  { value: "5", label: "अन्य" },
];

export const SOURCE_OF_ADMISSION = [
  { value: "1", label: "Emergency" },
  { value: "2", label: "OPD" },
  { value: "1", label: "Refer from other HF" },
]

export const THERAPY_CODE = [
  { value: "1", label: "Acupuncture (Needling)" },
  { value: "2", label: "Cupping" },
  { value: "3", label: "Moxibustion" },
  { value: "4", label: "Acupressure" },
  { value: "5", label: "Blood letting" },
  { value: "6", label: "others" }
]

export const PHYSIO_THERAPY_CODE = [
  { value: "MANUAL_THERAPY", label: "म्यानुअल थेरापी" },
  { value: "EXERCISE_THERAPY", label: "एक्सरसाइज थेरापी" },
  { value: "TAPES", label: "टेप्स" },
  { value: "USG", label: "यूएसजी" },
  { value: "IFT", label: "आईएफटी" },
  { value: "MUSCLE_STIMULATOR", label: "मसल स्टिमुलेटर" },
  { value: "TRACTION", label: "ट्रैक्शन" },
];

export const POLICE_CASE = [
  { value: YES, label: "हो" },
  { value: NO, label: "होइन" },
]

export const Medicine_Name = [
  { value: "Avipatkar Churn 50 grams", label: "अविपतकर चुर्ण ५० ग्राम" },
  { value: "Mahayogaraj Guggul 10 grams", label: "महायोगराज गुग्गुल १० ग्राम" }
]

export const SEWA_TYPES = [
  { value: "1", label: "जेष्ठ नागरिक सेवा" },
  { value: "2", label: "स्तनपायी आमा तथा शिशु सेवा" },
  { value: "3", label: "नसर्ने रोग व्यवस्थापन सेवा" },
  { value: "4", label: "परामर्श सेवा" },
]

export const PALIKA_TYPES = [
  { value: "MAHANAGAR_PALIKA", label: "महानगरपालिका" },
  { value: "UPAMAHANAGAR_PALIKA", label: "उप-महानगरपालिका" },
  { value: "NAGAR_PALIKA", label: "नगरपालिका" },
  { value: "GAUN_PALIKA", label: "गाउँपालिका " }
];

export const HEALTH_ORGANIZATION_TYPES = [
  { value: "HOSPITAL", label: "अस्पताल" },
  { value: "PRIMARY_HEALTH_CARE_CENTER", label: "प्राथमिक स्वास्थ्य सेवा केन्द्र" },
  { value: "HEALTH_POST", label: "स्वास्थ्य चौकी" },
  { value: "URBAN_HEALTH_CENTER", label: "शहरी स्वास्थ्य केन्द्र" },
  { value: "BASIC_HEALTH_UNIT", label: "आधारभूत स्वास्थ्य सेवा केन्द्र" },
  { value: "INSTITUTIONAL_OR_DISTRICT_HEALTH_CLINIC", label: "संस्थागत/ जिल्ला स्वास्थ्य क्लिनिक" },
  { value: "COMMUNITY_HEALTH_UNIT", label: "सामुदायिक स्वास्थ्य इकाई" },
];

export const FISCAL_YEARS = [
  { value: "2077_78", label: "२०७७/७८" },
  { value: "2078_79", label: "२०७८/७९" },
  { value: "2079_80", label: "२०७९/८०" },
  { value: "2080_81", label: "२०८०/८१" }
];

export const CHILD_BIRTH_LOCATION_OPTIONS = [
  { value: "HOME", label: "घर" },
  { value: "INSTITUTION", label: "संस्था" },
]

export const CHILD_BIRTH_SITUATION_OPTIONS = [
  { value: "LESS_THAN_THIRTY_SEVEN_WEEKS", label: "समय नपुगेको (३७ हप्ता भन्दा कम)" },
  { value: "LESS_WEIGHT", label: "कम तौल (२५०० ग्राम भन्दा कम )" },
  { value: "NORMAL", label: "सामान्य" },
]

export const CHILD_BIRTH_WEIGHT_OPTIONS = [
  { value: "NORMAL_WEIGHT", label: "सामान्य" },
  { value: "LESS_WEIGHT", label: "कम तौल" },
  { value: "MUCH_LESS_WEIGHT", label: "धेरै कम तौल" },
]

export const CHILD_DEATH_REASON_OPTIONS = [
  { value: "DIFFICULTY_BREATHING", label: "निसास्सिएको" },
  { value: "SITAANGA", label: "शिताङ्ग" },
  { value: "INFECTION", label: "संक्रमण" },
]

export const PHYSIO_THERAPY_TYPE = [
  {
    label: "म्यानुअल थेरापी",
    options: [
      { value: "MANUAL_THERAPY", label: "म्यानुअल थेरापी" },
    ]
  },
  {
    label: "एक्सरसाइज थेरापी",
    options: [
      { value: "EXERCISE_THERAPY", label: "एक्सरसाइज थेरापी" },
    ]
  },
  {
    label: "इलेक्टो थेरापी",
    options: [
      { values: "TAPES", label: "टेप्स" },
      { values: "UST", label: "यूएसजी" },
      { values: "IFT", label: "आईएफटी" },
      { values: "TRACTION", label: "ट्याक्शन" },
      { value: "MUSCLE_STIMULATOR", label: "मसल स्टीमुलेटर" },
      { values: "OTHER", label: "अन्य" }
    ]
  },
  {
    label: "अन्य",
    options: [
      { value: "OTHER", label: "अन्य" },
    ]
  },
];

export const medicineOptions = [
  { value: "VITAMIN_A", label: "VITAMIN A" },
  { value: "JUKA_MEDICINE", label: "JUKA MEDICINE" },
  { value: "BAAL_BHITA_MEDICINE", label: "BAAL BHITA MEDICINE" }
];

export const ASRH_SERVICE_OPTIONS = [
  { value: "INFORMATION_AND_EDUCATION", label: "सूचना, शिक्षा (Information and Education)" },
  { value: "PSYCHO_COUNSELLING", label: "मनोपरामर्श सेवा (Phycho-counselling)" },
  { value: "TREATMENT_SERVICES", label: "उपचार सेवा (Treatment Services)" },
  { value: "PHYSICAL_AND_MENTAL_CHANGES_DURING_ADOLESCENCE", label: "किशोरावस्थामा हुने शारीरिक तथा मानसिक परिवर्तनहरू" },
  { value: "DANGEROUS_BEHAVIOUR", label: "जोखिमपूर्ण व्यवहारहरु(असुरक्षित यौन सम्पर्क, लागुपदार्थको दुर्व्यसन आदि)" },
  { value: "NUTRITION", label: "पोषण" },
  { value: "ABORTION", label: "गर्भपतन" },
  { value: "SEXUAL_HEALTH_PROBLEMS", label: "यौन स्वास्थ्यका समस्या" },
  { value: "GENDER_AND_SEXUAL_VIOLENCE", label: "लैङ्गिक हिंसा तथा यौनजन्य हिंसा" },
  { value: "PROBLEMS_RELATED_TO_MENSTRUATION", label: "महिनावारीसँग सम्बन्धित समस्याहरु" },
  { value: "DANGER_SIGNS_AND_SYMPTOMS_OF_PREGNANCY", label: "गर्भवति अवस्थाका खतराका लक्षण तथा चिन्हहरु" },
  { value: "OTHER_HEALTH_PROBLEMS", label: "अन्य स्वास्थ्य समस्याहरु" },
  { value: "EMERGENCY_CONTRACEPTION", label: "आकस्मिक गर्भ निरोधको साधन" },
  { value: "CONDOM", label: "कण्डम" },
  { value: "DEPO", label: "डिपो" },
  { value: "PILLS", label: "पिल्स" },
  { value: "IMPLANT", label: "ईम्प्लान्ट" },
  { value: "IUCD", label: "आइ यू. सी. डी. (कपर टी)" },
  { value: "SEXUALLY_TRANSMITTED_DISEASES", label: "प्रजनन अङ्गहरुको सङ्क्रमण, यौन रोग, एच.आई.भी. तथा एड्स" },
  { value: "ANTENATAL_CARE_FOR_TEENAGE_PREGNANCY", label: "किशोरावस्थाका गर्भवतिहरुको लागि पूर्व प्रसूति सेवा" },
  { value: "TD_VACCINATED_FOR_TEENAGE_PREGNANCY", label: "किशोरावस्थाका गर्भवतिहरुको लागि टी.डी. खोप दिइएको" },
  { value: "ABORTION_SERVICE_FOR_TEENAGE", label: "गर्भपतन सेवा दिइएको" },
  { value: "SAFE_DELIVERY_SERVICES", label: "सुरक्षित सुत्केरी सेवा" },
  { value: "OTHERS", label: "अन्य सेवाहरु दिइएको" }
]

export const countOptions = [
  { value: "1", label: "One" },
  { value: "2", label: "Two" },
  { value: "3", label: "Three" },
  { value: "4", label: "Four" },
  { value: "5", label: "Five" },
  { value: "6", label: "Six" },
  { value: "7", label: "Seven" },
  { value: "8", label: "Eight" },
  { value: "9", label: "Nine" },
  { value: "10", label: "Ten" }
];

export const monthOptions = [
  { value: "28D", label: "२८ दिन भित्र" },
  { value: "1M", label: "१ महिना" },
  { value: "2M", label: "२ महिना" },
  { value: "3M", label: "३ महिना" },
  { value: "4M", label: "४ महिना" },
  { value: "5M", label: "५ महिना" },
  { value: "6M", label: "६ महिना" },
  { value: "7M", label: "७ महिना" },
  { value: "8M", label: "८ महिना" },
  { value: "9M", label: "९ महिना" },
  { value: "10M", label: "१० महिना" },
  { value: "11M", label: "११ महिना" },
  { value: "12M", label: "१२ महिना" },
  { value: "13M", label: "१३ महिना" },
  { value: "14M", label: "१४ महिना" },
  { value: "15M", label: "१५ महिना" },
  { value: "16M", label: "१६ महिना" },
  { value: "17M", label: "१७ महिना" },
  { value: "18M", label: "१८ महिना" },
  { value: "19M", label: "१९ महिना" },
  { value: "20M", label: "२० महिना" },
  { value: "21M", label: "२१ महिना" },
  { value: "22M", label: "२२ महिना" },
  { value: "23M", label: "२३ महिना" }
];

export const motherMilkWithOtherFoodPeriodOptions = [
  { value: "BEFORE_6M", label: "६ महिना भन्दा अघि" },
  { value: "AT_6M", label: "६ महिनामा" },
  { value: "AFTER_6M", label: "६ महिना भन्दा पछि" }
];

export const childAgeInMonthOptions = [
  { value: "6M_TO_8M", label: "६ देखि ८" },
  { value: "9M_TO_11M", label: "९ देखि ११" },
  { value: "12M_TO_23M", label: "१२ देखि २३" }
];

export const SITUATION_OPTIONS = [
  { value: "GENERAL", label: "सामान्य" },
  { value: "DANGER", label: "जोखिम" },
  { value: "CRITICAL", label: "अति जोखिम" }
];

export const wiwaranOptions = [
  { value: "patient", label: "विरामिको" },
  { value: "helper", label: "उपचार सुपरभाईजर सहयोगी" }
];

export const typeOfTBOptions = [
  { value: "pulmonary", label: "Pulmonary" },
  { value: "extraPulmonary", label: "Extra Pulmonary" }
];

export const monthRangeOptions = [
  { value: "6-11M", label: "६-११" },
  { value: "12-17M", label: "१२-१७" },
  { value: "18-24M", label: "१८-२३" }
];

export const DASHBOARD = [
  { value: "PROGRAM", label: "Program" },
  { value: "HR", label: "HR" },
  { value: "LOGISTIC", label: "Logistic" },
  { value: "FINANCE", label: "Finance" }
];

export const GENDER_OPTIONS = [
  { value: MALE, label: "पुरुष" },
  { value: FEMALE, label: "महिला" },
  { value: OTHER, label: "अन्य" }
];

export const CONDOM_DISTRIBUTION_TYPES = [
  { value: "SEWAGRAHI", label: "सेवाग्राही" },
  { value: "CONDOM_BOX", label: "कन्डम बक्स" }
];

export const MEDICINE_ROUTE_OPTIONS = [
  { value: "PO", label: "PO" },
  { value: "IV", label: "IV" },
  { value: "IM", label: "IM" },
  { value: "LOCAL", label: "Local" },
  { value: "SUBLINGUAL", label: "Sublingual" },
  { value: "IA", label: "IA" }
]

export const REGISTERS = [
  { value: "mulDartaa", label: "मूल दर्ता रजिस्टर" },
  { value: "outPatient", label: "बहिरङ्ग सेवा रजिष्टर" },
  { value: "khopSewa", label: "खोप अभिलेख (बाल-बालिका)" },
  { value: "tdKhopSewa", label: "टी.डी. खोप सेवा" },
  { value: "balbalikaPosan", label: "दुई–वर्षमुनिका बाल–बालिकाहरूको पोषण रजिस्टर" },
  { value: "matriTathaNawasisuSwasthyaSewa", label: "मातृ तथा नवशिशु स्वास्थ्य सेवा रजिष्टर" },
  { value: "safeAbortion", label: "सुरक्षित गर्भपतन सेवा रजिष्टर" },
  { value: "pillsDepo", label: "पिल्स, डिपो सेवा रजिष्टर" },
  { value: "iucdImplant", label: "आइ.यु.सि.डी तथा इम्प्लाण्ट सेवा रजिस्टर" },
  { value: "labBillsInfo", label: "प्रयोगशाला सेवा" }
]

export const SMS_RECORDS_REGISTERS = [
  { value: "khopSewa", label: "खोप अभिलेख (बाल-बालिका)" },
  { value: "iucdImplant", label: "आइ.यु.सि.डी तथा इम्प्लाण्ट सेवा रजिस्टर" },
  { value: "balbalikaPosan", label: "दुई–वर्षमुनिका बाल–बालिकाहरूको पोषण रजिस्टर" },
  { value: "matriTathaNawasisuSwasthyaSewa", label: "मातृ तथा नवशिशु स्वास्थ्य सेवा रजिष्टर" }
]

export const VILLAGE_CLINIC_REFERRED_BY_OPTIONS_FOR_CHILDREN = [
  { value: "SELF", label: "आफै" },
  { value: "FEMALE_COMMUNITY_HEALTH_VOLUNTEER", label: "म.स्वा.स्व.से" }
];

export const PEEP_LE_BHARIYEKA_FOKA_COUNT_OPTIONS = [
  { value: "LESS_THAN_10_SANA_FOKA", label: "< १० साना फोका" },
  { value: "GREATER_THAN_OR_EQUAL_TO_10_SANA_FOKA_OR_1_BIG_FOKA", label: ">=१० साना फोका/१ ठुलो फोका" }
];

export const MONITORING_ROLES_OPTIONS = [
  { value: ROLE_USER_PROVINCE, label: "प्रदेश प्रयोगकर्ता" },
  { value: ROLE_USER_DISTRICT, label: "जिल्ला प्रयोगकर्ता" },
  { value: ROLE_USER_CENTRAL, label: "केन्द्र प्रयोगकर्ता" }
]

export const SKIN_TWIST_OPTIONS = [
  { value: "SLOWLY", label: "बिस्तारै" },
  { value: "VERY_SLOWLY", label: "धेरै बिस्तारै" }
];

export const WEIGHT_STATUS_OPTIONS = [
  { value: "NORMAL_WEIGHT", label: "सामान्य तौल" },
  { value: "LESS_WEIGHT", label: "कम तौल" },
  { value: "VERY_LESS_WEIGHT", label: "धेरै कम तौल" }
];

export const WHITE_PATCH_STATUS_OPTIONS = [
  { value: "FEW", label: "केहि भएको" },
  { value: "LOT", label: "धेरै भएको" }
];

export const ARMS_COLOR_OPTIONS = [
  { value: "RED", label: "रातो" },
  { value: "YELLOW", label: "पहेलो" },
  { value: "GREEN", label: "हरियो" }
];

export const REFERRED_BY_OPTIONS = [
  { value: "FCHV", label: "FCHV" },
  { value: "PHC/ORC", label: "PHC/ORC" },
  { value: "HF", label: "HF" }
];

export const YES_NO_OPTIONS = [
  { value: YES, label: "छ" },
  { value: NO, label: "छैन" },
];

export const YES_NO_NOT_NEEDED_OPTIONS = [
  { value: YES, label: "छ" },
  { value: NO, label: "छैन" },
  { value: "NOT_REQUIRED", label: "आवश्यक छैन" },
];

export const DONE_NOT_DONE_OPTIONS = [
  { value: YES, label: "गराएको" },
  { value: NO, label: "नगराएको" }
]

export const PATIENT_TYPES = [
  { value: "NEW_PATIENT", label: "नयाँ सेवाग्राही" },
  { value: "PREVIOUS_PATIENT", label: "पुरानो सेवाग्राही" }
];

export const PRASUTI_KO_STHAN = [
  { value: "HEALTH_INSTITUTION", label: "स्वास्थ्य संस्था" },
  { value: "HOME", label: "घर" },
  { value: OTHER, label: "अन्य" }
];

export const PRASUTI_KO_TYPES = [
  { value: "NORMAL", label: "सामान्य" },
  { value: "CS", label: "शल्यक्रिया" },
  { value: OTHER, label: "अन्य" }
];

export const SHOW_HIDE_OPTIONS = [
  { value: "NO", label: "Show" },
  { value: "YES", label: "Hide" }
]

export const ACTIVE_INACTIVE_OPTIONS = [
  { value: YES, label: "सक्रिय" },
  { value: NO, label: "निस्क्रिय" },
];

export const IMPAIRMENT_CODE = [
  { value: "PHYSICAL_DISABILITY", label: "शारीरिक अपाङ्गता(०१)" },
  { value: "VISUALLY_IMPAIRED", label: "दृष्टि सम्बन्धी अपाङ्गता(०२)" },
  { value: "HEARING_IMPAIRMENT", label: "सुनाइ सम्बन्धी अपाङ्गता(०३)" },
  { value: "VISION_IMPAIRMENT", label: "श्रवण दृष्टिविहीन अपाङ्गता(०४)" },
  { value: "VOICE_AND_SPEECH_IMPAIRMENT", label: "स्वर र बोलाइ सम्बन्धी अपाङ्गता(०५)" },
  { value: "MENTAL_OR_PSYCHOSOCIAL_DISABILITY", label: "मानसिक वा मनोसामाजिक अपाङ्गता(०६)" },
  { value: "INTELLECTUAL_DISABILITY", label: "बौद्धिक अपाङ्गता(०७)" },
  { value: "HEREDITARY_HEMORRHAGE", label: "अनुवंशीय रक्तश्राव(०८)" },
  { value: "AUTISMRELATED_DISABILITY", label: "अटिज्म सम्बन्धी अपाङ्गता(०९)" },
  { value: "MULTIPLE_DISABILITIES", label: "बहुअपाङ्गता(१०)" },
];

export const NEWBORN_COMPLICATIONS = [
  { value: "ASPHYXIA", label: "Asphyxia" },
  { value: "RESPIRATORY_DISTRESS", label: "Respiratory Distress" },
  { value: "PREMATURITY", label: "Prematurity" },
  { value: "LOW_BIRTH_WEIGHT", label: "Low Birth Weight" },
  { value: "MECONIUM_ASPIRATION_SYNDROME", label: "Meconium Aspiration Syndrome" },
  { value: "IUGR", label: "IUGR" },
  { value: "HYPOTHERMIA", label: "Hypothermia" },
  { value: "RH_INCOMPATIBILITY", label: "Rh Incompatibility" },
  { value: "SHOULDER_DYSTOCIA", label: "Shoulder Dystocia" },
  { value: "SEPSIS", label: "Sepsis" },
  { value: "OTHERS", label: "Others" },
  { value: "NO_COMPLICATIONS", label: "No Complications" },
];

export const MOTHER_COMPLICATIONS_AT_TIME_OF_DELIVERY = [
  { value: "HYPERTENSION_ECLAMPSIA_PRE-ECLAMPSIA", label: "Hypertension/Eclampsia/Pre-Eclampsia" },
  { value: "GESTATIONAL_DIABETES_MELLITUS", label: "Gestational Diabetes Mellitus" },
  { value: "ANTEPARTUM_HEMORRHAGE", label: "Antepartum Hemorrhage" },
  { value: "POST PARTUM_HEMORRHAGE", label: "Post Partum Hemorrhage" },
  { value: "CHORIAMINOITIS", label: "Choriaminoitis" },
  { value: "SEPSIS", label: "Sepsis" },
  { value: "SEVERE_ANAEMIA", label: "Severe Anaemia" },
  { value: "MATERNAL_HEART_DISEASE", label: "Maternal Heart Disease" },
  { value: "HYPO_HYPERTHYROIDISM", label: "Hypo/Hyperthyroidism" },
  { value: "OTHERS", label: "Others" },
  { value: "NO_COMPLICATIONS", label: "No Complications" },
]

export const MAJOR_CONGENTIAL_ANOMALY = [
  { value: "NEURAL_TUBE_DEFECT", label: "Neural Tube Defect" },
  { value: "CLEFT_LIP_PALATE", label: "Oro-facial clefts: Cleft lip/palate" },
  { value: "TALIPES_EQUINOVIRUS_CLUBFOOT", label: "Talipes Equinovirus Clubfoot" },
  { value: "LIMB_REDUCTION_DEFECTS", label: "Limb Reduction Defects" },
  { value: "HYPOSPADIAS", label: "Hypospadias" },
  { value: "EXOMPHALOS_OMPHALOCELE", label: "Exomphalos/Omphalocele" },
  { value: "GASTROSCHISIS", label: "Gastroschisis" },
  { value: "IMPERFORATE_ANUS", label: "Imperforate anus" },
  { value: "MINOR_DEFECTS", label: "minor defects" },
  { value: "NO_ANOMOLY_DETECTED", label: "No Anomoly detected" },
]

export const CAUSE_OF_ADMISSION = [
  { value: "Low Birth Weight", label: "Low Birth Weight" },
  { value: "IUGR_PREMATURITY_PRETERM", label: "IUGR Prematurity/Pre-term" },
  { value: "HYPOGLYCEMIA", label: "Hypoglycemia" },
  { value: "HYPOTHERMIA", label: "Hypothermia" },
  { value: "HYPERBILIRUBINEMIA", label: "Hyperbilirubinemia" },
  { value: "NEONATAL_SEPSIS", label: "Neonatal Sepsis" },
  { value: "PREMATURE_RUPTURE_OF_MEMBRANE", label: "Premature Rupture of Membrane" },
  { value: "RESPIRATORY_DISTRESS", label: "Respiratory Distress" },
  { value: "PERINATAL_ASPHYXIA", label: "Perinatal Asphyxia" },
  { value: "CONGENTIAL_HEART_DISEASE", label: "Congential Heart Disease" },
  { value: "CONGENTIAL_ANOMALY", label: "Congential Anomaly" },
  { value: "MECONIUM_ASPIRATION_SYNDROME", label: "Meconium Aspiration Syndrome" },
  { value: "NECROTISING_ENTEROLITIS", label: "Necrotising Enterolitis" },
  { value: "PUSTULISIS", label: "Pustulisis" },
  { value: "VITAMIN_K_DEFICIENCY_BLEEDING", label: "Vitamin K Deficiency Bleeding" },
  { value: "ASPIRATION", label: "Aspiration" },
  { value: "SEIZURE", label: "Seizure" },
  { value: "OTHERS", label: "Others" },
]

export const DISABLE_TYPES = [
  { value: "NONE", label: "कुनै पनि अपाङ्गता नभएको" },
  { value: "PHYSICAL_DISABILITY", label: "१. शारीरिक अपाङ्गता" },
  { value: "VISIBLE_DISABILITY", label: "२. दृष्टिसम्बन्धी अपाङ्गता" },
  { value: "HEARING_DISABILITY", label: "३. सुनाइसम्बन्धी अपाङ्गता" },
  { value: "VOICE_AND_SPEECH_DISABILITY", label: "४. स्वर र बोलाइसम्बन्धी अपाङ्गता" },
  { value: "MENTAL_DISABIILITY", label: "५. मानसिक बौद्धिक अपाङ्गता" },
]

export const CONFLICT_TYPES = [
  { value: "RAPE", label: "(१) वलात्कार" },
  { value: "SEXUAL_ABUSE", label: "(२) यौनजन्य दुव्यवहार" },
  { value: "PHYSICAL_ABUSE", label: "(३) शारीरिक दुव्र्यवहार" },
  { value: "CHILD_AND_FORCE_MARRIAGE", label: "(४) बालविवाह र जबरजस्ती विवाह" },
  { value: "DEPRIVED_OF_RESOURCES_AND_RIGHTS", label: "(५) स्रोतसाधन र अधिकारबाट बञ्चित" },
  { value: "MENTAL_ABUSE", label: "(६) मानसिक दुव्र्यवहार" },
  { value: "DISCRIMINATION_AGAINST_THE_RIGHTS_TRADITIONAL_ORTHODOXY", label: "(७) परम्परागत रुढीवादी परम्पराका आधकारमा भेदभाव" },
]

export const INCIDENT_BACKGROUND = [
  { value: "POLYGAMY", label: "१. बहुविवाह" },
  { value: "DOWRY", label: "२. दाइजो" },
  { value: "ACCUSATIONS_OF_WITCHCRAFT", label: "३. बोक्सीको आरोप" },
  { value: "DOMESTIC_VOILENCE", label: "४. घरेलुहिंसा" },
  { value: "CHHAUPADI", label: "५. छाउपडी" },
  { value: "HUMAN_TRAFFICKING", label: "६. मानव बेचविखन" },
  { value: "SOCIAL_DISCRIMINATION", label: "७. सामाजिक भेदभाव." },
  { value: "CHILD_LABOUR", label: "८. बालश्रम" },
]

export const DISPATCH_TO_VICTIMS_OR_AFFECTED = [
  { value: "SELF", label: "१. आफै" },
  { value: "RELATIVES", label: "२. नातेदार" },
  { value: "RESIDENCE_HALL", label: "३. आवासगृह" },
  { value: "POLICE", label: "४. प्रहरी" },
  { value: "NGO", label: "५. गैरसरकारी संस्था" },
  { value: "HEALTH_POST", label: "६. स्वास्थ्य संस्था" },
  { value: "LOCAL_FOLD", label: "७. स्थानीय तह" },
]

export const SEND_TO_VICTIMS_OR_AFFECTED = [
  { value: "RESIDENCE_HALL", label: "१. आवासगृह" },
  { value: "REHABILITATION_CENTER", label: "२. पुनस्थापना केन्द्र" },
  { value: "POLICE", label: "३. प्रहरी" },
  { value: "LAWYER", label: "४. वकिल" },
  { value: "OTHER_WELL_EQUIPPED_HOSPITAL", label: "५. अन्य सुविधा सम्पन्न अस्पताल" },
  { value: "OTHER", label: "६. अन्य ( खुलाउने)" },
]

export const PATIENT_PRESENT_CONDITION_IN_HOSPITAL = [
  { value: "BEING_WELL", label: "०१. सुधार भएको" },
  { value: "BEING_TREATED", label: "०२. उपचाररत रहेका" },
  { value: "DEAD", label: "०३. मृत्यू भएका" },
]

export const PATIENT_BEING_TREATED = [
  { value: "REPRODUCTIVE_PROBLEM", label: "०१. प्रजनन स्वास्थ्य समस्या" },
  { value: "MENTAL_PROBLEM", label: "०२. मानसिक स्वास्थ्य समस्या" },
  { value: "OTHER", label: "अन्य" },
]

export const INVESTIGATION_AND_MANAGEMENT = [
  { value: "ALTERNATIVE_FEEDING", label: "Alternative feeding" },
  { value: "KMC", label: "KMC" },
  { value: "SAFE_ADMINISTRATION_OF_OXYGEN", label: "Safe administration of oxygen" },
  { value: "INJECTABLE_ANTIBIOTICS_FOR_NEONATAL_SEPSIS", label: "Injectable Antibiotics for Neonatal Sepsis" },
  { value: "MANAGEMENT_OF_SHOCK", label: "Management of shock" },
  { value: "INTRAVENOUS_FLUID", label: "Intravenous fluid" },
  { value: "MANAGEMENT_OF_HYPOGLYCEMIA", label: "Management of Hypoglycemia" },
  { value: "EFFECTIVE_PHOTOTHERPAY", label: "Effective Phototherpay" },
  { value: "SEIZURE_MANAGEMENT", label: "Seizure Management" },
  { value: "CONTINUOUS_POSITIVE_AIRWAY_PRESSURE", label: "Continuous positive airway pressure" },
  { value: "MECHANICAL_ASSISTED_VENTILATION", label: "Mechanical/assisted Ventilation" },
  { value: "EXCHANGE", label: "Exchange / Partial Transfusion" },
  { value: "RETINOPATHY_OF_PREMATURITY", label: "Retinopathy of prematurity" },
  { value: "HYPOTHERMIA_MANAGEMENT", label: "Hypothermia Management" },
  { value: "BLOOD_TRANSFUSION", label: "Blood Transfusion" },
  { value: "MANAGEMENT_OF_PNUEMOTHORAX", label: "Management of Pnuemothorax/Chest Tube/Needle Puncture" },
  { value: "OTHERS", label: "Others" },
]

export const OUTCOME_OF_BABY_AT_TIME_OF_DISCHARGE = [
  { value: "IMPROVED", label: "Improved" },
  { value: "REFERRED", label: "Referred" },
  { value: "DIED", label: "Died" },
  { value: "LAMA_ABSCONDED", label: "LAMA/Absconded" },
]

export const NEWBORN_CARE_PACKAGE_PROVIDER = [
  { value: "PACKAGE_A", label: "Package A" },
  { value: "PACKAGE_B", label: "Package B" },
  { value: "PACKAGE_A+B", label: "Package A+B" },
  { value: "PACKAGE_A+B+C", label: "Package A+B+C" },
]

export const REFERRED_FROM_OPTIONS = [
  { value: "WITHIN_HOSPITAL", label: "Within Hospital" },
  { value: "OPD", label: "OPD" },
  { value: "DELIVERY_WARD", label: "Delivery Ward" },
  { value: "EMERGENCY", label: "Emergency" },
  { value: "OTHER_HOSPITAL", label: "Other Hospital" },
]

export const PLACE_OF_DELIVERY = [
  { value: "HEALTH_OFFICE", label: "Institutional(संस्था)" },
  { value: "HOME", label: "Home(घर)" },
  { value: "OTHER", label: "Others(अन्य) (On the way or..)" },
]

export const DEAD_ALIVE_OPTIONS = [
  { value: "DEAD", label: "मृत" },
  { value: "ALIVE", label: "जीवित" },
]

export const MODE_OF_DELIVERY = [
  { value: "SVD", label: "SVD (Spontaneous Vaginal Delivery)" },
  { value: "INSTRUMENTAL", label: "Instrumental" },
  { value: "C_SECTION", label: "C-section" },
]

export const DELIVERY_CONDUCT_BY = [
  { value: "SBA", label: "SBA: SBA तालीम प्राप्तः प्रसुतीकमीले प्रसुती गराइएको" },
  { value: "SHP", label: "SHP: Skilled Health Professional- Doctors, Nurses, Gynae ले प्रसुती गराइएको" },
  { value: "OTHER", label: "Others: अन्य स्वास्थ्यकमीको सहायता" },
]

export const DISABILITY_ID_CARD_TYPES = [
  { value: "TYPE_A", label: "क वर्ग" },
  { value: "TYPE_B", label: "ख वर्ग" },
  { value: "TYPE_C", label: "ग वर्ग" },
  { value: "TYPE_D", label: "घ वर्ग" },
];

export const COVID_VACCINE_OPTIONS = [
  { value: "ASTRAZENECA", label: "ASTRAZENECA" },
  { value: "COVISHIELD", label: "COVISHIELD" },
  { value: "MODERNA", label: "MODERNA" },
  { value: "VEROCELL", label: "VEROCELL" },
  { value: "PFIZER", label: "PFIZER" },
  { value: "JANSSEN", label: "JANSSEN" },
];

export const COVID_VACCINE_LIST = [
  { value: "FIRST_DOSE_ASTRAZENECA", label: "ASTRAZENECA(1st Dose)" },
  { value: "SECOND_DOSE_ASTRAZENECA", label: "ASTRAZENECA(2nd Dose)" },
  { value: "ADDITIONAL_DOSE_ASTRAZENECA", label: "ASTRAZENECA(Additional Dose)" },
  { value: "FIRST_DOSE_COVISHIELD", label: "COVISHIELD(1st Dose)" },
  { value: "SECOND_DOSE_COVISHIELD", label: "COVISHIELD(2nd Dose)" },
  { value: "ADDITIONAL_DOSE_COVISHIELD", label: "COVISHIELD(Additional Dose)" },
  { value: "FIRST_DOSE_MODERNA", label: "MODERNA(1st Dose)" },
  { value: "SECOND_DOSE_MODERNA", label: "MODERNA(2nd Dose)" },
  { value: "ADDITIONAL_DOSE_MODERNA", label: "MODERNA(Additional Dose)" },
  { value: "FIRST_DOSE_VEROCELL", label: "VEROCELL(1st Dose)" },
  { value: "SECOND_DOSE_VEROCELL", label: "VEROCELL(2nd Dose)" },
  { value: "ADDITIONAL_DOSE_VEROCELL", label: "VEROCELL(Additonal Dose)" },
  { value: "FIRST_DOSE_PFIZER", label: "PFIZER(1st Dose)" },
  { value: "SECOND_DOSE_PFIZER", label: "PFIZER(2nd Dose)" },
  { value: "ADDITIONAL_DOSE_PFIZER", label: "PFIZER(Additional Dose)" },
  { value: "FIRST_DOSE_JANSSEN", label: "JANSSEN(1st Dose)" },
  { value: "ADDITIONAL_DOSE_JANSSEN", label: "JANSSEN(Additional Dose)" }
];

export const CAUSE_OF_DEATH = [
  {
    value: "HIV_RELETED_DEATH", label: "HIV Releted Death", subType: [
      { value: "ADVANCED_HIV_DISEASE", label: "Advanced HIV disease: Advanced HIV disease, Late ART initiation, Low CD4 Count (<200), WHO Stage III, WHO Stage IV" },
      { value: "TUBERCULOSIS", label: "Tuberculosis: Pulmonary Tuberculosis, extra -pulmonary Tuberculosis (Abdominal Tuberculosis, Gland Tuberculosis etc.)" },
      { value: "PNEUMOCYSTIS_PNEUMONIA", label: "Pneumocystis Pneumonia" },
      { value: "ANEMIA_THROMBOCYTOPENIPA_NCYTOPENIA", label: "Anemia, thrombocytopenia, Pancytopenia" },
      { value: "MENINGITIS", label: "Meningitis: Meningitis, Cryptococcal meningitis, Chronic meningitis" },
      { value: "CRYPTOSPORIDIOSIS", label: "Cryptosporidiosis or Chronic Diarrhea" },
      { value: "UNSPECIFIED_OPPORTUNISTIC", label: "Unspecified opportunistic, infection" },
      { value: "CHEST_INFECTION", label: "Chest infection - pneumonia" },
      { value: "SEPTICEMIA/SEPTIC_SHOCK", label: "Septicemia/Septic shock: Septicemia, Septic shock" },
      { value: "SEVERE_MALNUTRITION", label: "Severe malnutrition" },
      { value: "TREATMENT_FAILURE", label: "Treatment Failure- Virological failure, Immunological Failure and Clinical failure" },
      { value: "TOXOPLASMOSIS", label: "Toxoplasmosis" },
      { value: "SPLENOMEGALY", label: "Splenomegaly" },
      { value: "CANDIDIASIS-OESOPHAGEAL", label: "Candidiasis-oesophageal" },
      { value: OTHER, label: "Others (if not included above)" },
    ]
  },
  {
    value: "NON_HIV_RELETED_DEATH", label: "Non-HIV Releted Death", subType: [
      { value: "CARDIAC_DISEASE", label: "Cardiac disease: Cardiac failure, Cardiopulmonary arrest, Chronic heart disease, Heart disease, Hypertension" },
      { value: "MENTAL_ILLNESS", label: "Mental illness: Alcoholism, Drug overdose, Depression, Suicide, and other mental illness" },
      { value: "RENAL_DISEASE", label: "Renal disease: Nephropathy, Renal failure" },
      { value: "HEPATIC_DISEASE", label: "Hepatic disease: Acute liver disease, Cirrhosis, Hepatic coma, Hepatitis, Hepatitis B, Hepatitis C, other Liver disease (Jaundince etc.)" },
      { value: "CANCER", label: "Cancer: Blood cancer, Brain tumor, Cervical cancer, breast cancer, Leukaemia, Liver cancer, Lung cancer, Penis cancer, Uterus cancer, lymphoma" },
      { value: "ACCIDENT_INJURY", label: "Accident/Injury: Accident, Road traffic accident, Head injury and any other grievous injuries (including murder etc).. Snakebite" },
      { value: "PULMONARY_DISEASE", label: "Pulmonary disease: Asthma, Chronic Obstructive Pulmonary Disease, Pneumothorax, Pulmonary Edema, Pulmonary Embolism, Respiratory Failure" },
      { value: "BRAIN_DISEASE", label: "Brain and Nervous System disease: Brain Hemorrhage, Central Nervous System Disorder, Hydrocephalus, Neuropathy, Stroke" },
      { value: "GASTRO_INTESTINAL_DISEASE", label: "Gastro-Intestinal disease: Abdominal perforation, Acute abdominal pain, Ascites, Gastro intestinal bleeding, Typhoid" },
      { value: "PARALYSIS", label: "Paralysis:" },
      { value: "DIABETES", label: "Diabetes: Diabetes mellitus," },
      { value: "KALAZAR", label: "Kalazar" },
      { value: "HEMORRHAGE", label: "Hemorrhage" },
      { value: "MULTIPLE_ORGAN_FALIURE", label: "Multiple organ failure" },
      { value: OTHER, label: "Other" },
    ]
  },
  {
    value: "UNSPECIFIED_HIV_DEATH", label: "Unspecified HIV death"
  }
]
// TOD0:rename HMIS to AHMIS
export const PERMISSION_LIST = [
  { label: "MULDARTAAREGISTER_GETMULDARTAABYPAGE", value: ROLE_HMIS_MULDARTAAREGISTER_GETMULDARTAABYPAGE },
  { label: "MUNICIPALITY_GETALLMUNICIPALITYINFOS", value: ROLE_HMIS_MUNICIPALITY_GETALLMUNICIPALITYINFOS },
  { label: "APPLICATIONSETTING_SAVESETTING", value: ROLE_AHMIS_APPLICATIONSETTING_SAVESETTING },
  { label: "HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO", value: ROLE_HMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO },
  { label: "USER_GETUSERINFO", value: ROLE_HMIS_USER_GETUSERINFO },
  { label: "AUTHENTICATION_LOGOUT", value: ROLE_AHMIS_AUTHENTICATION_LOGOUT },
  { label: "PUBLIC FORGETPASSWORD", value: ROLE_AHMIS_PUBLIC_FORGETPASSWORD },
  { label: "AUTHENTICATION CHANGEPASSWORD", value: ROLE_AHMIS_AUTHENTICATION_CHANGEPASSWORD },
  { label: "AUTHENTICATION UPDATEUSERPROFILE", value: ROLE_AHMIS_USER_UPDATEUSERPROFILE },
  { label: "SAVEMUNICIPALITY", value: ROLE_AHMIS_MUNICIPALITY_SAVEMUNICIPALITY },
  { label: "GETMUNICIPLITYINFO", value: ROLE_AHMIS_MUNICIPALITY_GETALLMUNICIPALITYINFOS },
  { label: "GETMUNICIPALITYFROMID", value: ROLE_AHMIS_MUNICIPALITY_GETMUNICIPALITYINFOFROMID },
  { label: "SAVEHEALTHCLIENT", value: ROLE_AHMIS_HEALTHCLIENT_SAVEHEALTHCLIENT },
  { label: "GETALLHEALTHCLIENTSINFO", value: ROLE_AHMIS_HEALTHCLIENT_GETALLHEALTHCLIENTSINFO },
  { label: "GETHEALTHCLIENTSINFOBYMUNICIPALITY", value: ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTSINFOBYMUNICIPALITY },
  { label: "GETHEALTHCLIENTINFOBYID", value: ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTINFOBYID },
  { label: "GETLOGGEDINUSEROFCINFO", value: ROLE_AHMIS_HEALTHCLIENT_GETLOGGEDINUSEROFCINFO },
  { label: "GETHEALTHCLIENTANDPALIKAINFO", value: ROLE_AHMIS_HEALTHCLIENT_GETHEALTHCLIENTANDPALIKAINFO },
  { label: "USERSAVEUSER", value: ROLE_AHMIS_USER_SAVEUSER },
  { label: "USERUPDATEUSERPROFILE", value: ROLE_AHMIS_USER_UPDATEUSERPROFILE },
  { label: "USERGETLOGGEDINUSERINFO", value: ROLE_AHMIS_USER_GETLOGGEDINUSERINFO },
  { label: "USERGETUSERINFO", value: ROLE_AHMIS_USER_GETUSERINFO },
  { label: "MULDARTAAREGISTER SAVE", value: ROLE_AHMIS_MULDARTA_SAVEMULDARTA },
  { label: "MULDARTA_GETALLMULDARTAREGISTRATIONS", value: ROLE_AHMIS_MULDARTA_GETALLMULDARTAREGISTRATIONS },
  { label: "MULDARTA_GETMULDARTAREGISTRATIONFROMID", value: ROLE_AHMIS_MULDARTA_GETMULDARTAREGISTRATIONFROMID },
  { label: "OUTPATIENT_SAVEOUTPATIENT", value: ROLE_AHMIS_OUTPATIENT_SAVEOUTPATIENT },
  { label: "OUTPATIENT_GETALLOUTPATIENT", value: ROLE_AHMIS_OUTPATIENT_GETALLOUTPATIENT },
  { label: "OUTPATIENT_GETOUTPATIENTREGISRATIONFROMID", value: ROLE_AHMIS_OUTPATIENT_GETOUTPATIENTREGISRATIONFROMID },
  { label: "MEDICINE_GETALLMEDICINEDISTRIBUTOR", value: ROLE_AHMIS_MEDICINE_GETALLMEDICINEDISTRIBUTOR },
  { label: "MEDICINE_DISTRIBUTOR_SAVE", value: ROLE_AHMIS_MEDICINE_DISTRIBUTOR_SAVE },
  { label: "MEDICINE_GETMEDICINEDISTRIBUTORFROMID", value: ROLE_AHMIS_MEDICINE_GETMEDICINEDISTRIBUTORFROMID },
  { label: "ADDMEDICINE_SAVEADDMEDICINE", value: ROLE_AHMIS_ADDMEDICINE_SAVEADDMEDICINE },
  { label: "ADDMEDICINE_GETALLADDMEDICINE", value: ROLE_AHMIS_ADDMEDICINE_GETALLADDMEDICINE },
  { label: "ADDMEDICINE_GETALLMEDICINEBYID", value: ROLE_AHMIS_ADDMEDICINE_GETALLMEDICINEBYID },
  { label: "PANCHAKARMA_SAVEPANCHAKARMA", value: ROLE_AHMIS_PANCHAKARMA_SAVEPANCHAKARMA },
  { label: "PANCHAKARMA_GETALLPANCHAKARMA", value: ROLE_AHMIS_PANCHAKARMA_GETALLPANCHAKARMA },
  { label: "PANCHAKARMA_GETPANCHAKARMABYID", value: ROLE_AHMIS_PANCHAKARMA_GETPANCHAKARMABYID },
  { label: "KSHARSUTRA_SAVEKSHARSUTRA", value: ROLE_AHMIS_KSHARSUTRA_SAVEKSHARSUTRA },
  { label: "KSHARSUTRA_GETALLKSHARSUTRA", value: ROLE_AHMIS_KSHARSUTRA_GETALLKSHARSUTRA },
  { label: "KSHARSUTRA_GETKSHARSUTRAFROMID", value: ROLE_AHMIS_KSHARSUTRA_GETKSHARSUTRAFROMID },
  { label: "KSHARSUTRA_DELETEKSHARSUTRA", value: ROLE_AHMIS_KSHARSUTRA_DELETEKSHARSUTRA },
  { label: "THERAPEUTIC_GETTHERAPEUTICFROMID", value: ROLE_AHMIS_THERAPEUTIC_GETTHERAPEUTICFROMID },
  { label: "THERAPEUTIC_GETALLTHERAPUTICREGISTRATION", value: ROLE_AHMIS_THERAPEUTIC_GETALLTHERAPUTICREGISTRATION },
  { label: "THERAPEUTIC_DELETETHERAPEUTICREGISTRATION", value: ROLE_AHMIS_THERAPEUTIC_DELETETHERAPEUTICREGISTRATION },
  { label: "THERAPEUTIC_SAVETHERAPEUTIC", value: ROLE_AHMIS_THERAPEUTIC_SAVETHERAPEUTIC },
  { label: "THERAPEUTICREGISTER_GETTHERAPEUTICREGISTERABYPAGE", value: ROLE_AHMIS_THERAPEUTICREGISTER_GETTHERAPEUTICREGISTERABYPAGE },
  { label: "ESTHANAGARIK_SAVEJESTHANAGARIK", value: ROLE_AHMIS_ESTHANAGARIK_SAVEJESTHANAGARIK },
  { label: "ESTHANAGARIK_GETJESTHANAGARIKBYID", value: ROLE_AHMIS_ESTHANAGARIK_GETJESTHANAGARIKBYID },
  { label: "ESTHANAGARIK_DELETEESTHANAGARIK", value: ROLE_AHMIS_ESTHANAGARIK_DELETEESTHANAGARIK },
  { label: "JESTHANAGARIK_GETJESTHANAGARIKBYPAGE", value: ROLE_AHMIS_JESTHANAGARIK_GETJESTHANAGARIKBYPAGE },
  { label: "GAUNGHARANDSAHARI_SAVEGAUNGHARANDSAHARI", value: ROLE_AHMIS_GAUNGHARANDSAHARI_SAVEGAUNGHARANDSAHARI },
  { label: "GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIFROMID", value: ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIFROMID },
  { label: "GAUNGHARANDSAHARI_DELETEGAUNGHARANDSAHARI", value: ROLE_AHMIS_GAUNGHARANDSAHARI_DELETEGAUNGHARANDSAHARI },
  { label: "GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIBYPAGE", value: ROLE_AHMIS_GAUNGHARANDSAHARI_GETGAUNGHARANDSAHARIBYPAGE },
  { label: "ADMISSIONREGISTER_SAVEADMISSIONREGISTER", value: ROLE_AHMIS_ADMISSIONREGISTER_SAVEADMISSIONREGISTER },
  { label: "ADMISSIONREGISTER_GETADMISSIONREGISTERFROMID", value: ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERFROMID },
  { label: "ADMISSIONREGISTER_DELETEADMISSIONREGISTER", value: ROLE_AHMIS_ADMISSIONREGISTER_DELETEADMISSIONREGISTER },
  { label: "ADMISSIONREGISTER_GETADMISSIONREGISTERBYPAGE", value: ROLE_AHMIS_ADMISSIONREGISTER_GETADMISSIONREGISTERBYPAGE },
  { label: "DISCHARGEREGISTER_SAVEDISCHARGEREGISTER", value: ROLE_AHMIS_DISCHARGEREGISTER_SAVEDISCHARGEREGISTER },
  { label: "DISCHARGEREGISTER_GETDISCHARGEREGISTERFROMID", value: ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERFROMID },
  { label: "DISCHARGEREGISTER_DELETEDISCHARGEREGISTER", value: ROLE_AHMIS_DISCHARGEREGISTER_DELETEDISCHARGEREGISTER },
  { label: "DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE", value: ROLE_AHMIS_DISCHARGEREGISTER_GETDISCHARGEREGISTERBYPAGE },
  { label: "EMERGENCYSERVICE_SAVEEMERGENCYSERVICE", value: ROLE_AHMIS_EMERGENCYSERVICE_SAVEEMERGENCYSERVICE },
  { label: "EMERGENCYSERVICE_GETEMERGENCYSERVICEFROMID", value: ROLE_AHMIS_EMERGENCYSERVICE_GETEMERGENCYSERVICEFROMID },
  { label: "EMERGENCYSERVICE_DELETEEMERGENCYSERVICE", value: ROLE_AHMIS_EMERGENCYSERVICE_DELETEEMERGENCYSERVICE },
  { label: "EMERGENCYSERVICE_GETEMERGENCYSERVICEBYPAGE", value: ROLE_AHMIS_EMERGENCYSERVICE_GETEMERGENCYSERVICEBYPAGE },
  { label: "STANPAIAAMASISHU_SAVESTANPAIAAMASISHU", value: ROLE_AHMIS_STANPAIAAMASISHU_SAVESTANPAIAAMASISHU },
  { label: "STANPAIAAMASISHU_GETSTANPAIAAMASISHUFROMID", value: ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUFROMID },
  { label: "STANPAIAAMASISHU_DELETESTANPAIAAMASISHU", value: ROLE_AHMIS_STANPAIAAMASISHU_DELETESTANPAIAAMASISHU },
  { label: "STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE", value: ROLE_AHMIS_STANPAIAAMASISHU_GETSTANPAIAAMASISHUBYPAGE },
  { label: "PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE", value: ROLE_AHMIS_PANCHAKARMAREPORT_GETREPORTBYPALIKAIDANDDATERANGE },
  { label: "PANCHAKARMASERVICE_SAVEPANCHAKARMASERVICE", value: ROLE_AHMIS_PANCHAKARMASERVICE_SAVEPANCHAKARMASERVICE },
  { label: "PANCHAKARMASERVICE_GETPANCHAKARMASERVICEFROMID", value: ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEFROMID },
  { label: "PANCHAKARMASERVICE_DELETEPANCHAKARMASERVICE", value: ROLE_AHMIS_PANCHAKARMASERVICE_DELETEPANCHAKARMASERVICE },
  { label: "PANCHAKARMASERVICE_GETPANCHAKARMASERVICEBYDATERANGEANDPAGE", value: ROLE_AHMIS_PANCHAKARMASERVICE_GETPANCHAKARMASERVICEBYDATERANGEANDPAGE },
  { label: "BITYABIBARAN_SAVEBITYABIBARAN", value: ROLE_AHMIS_BITYABIBARAN_SAVEBITYABIBARAN },
  { label: "BITYABIBARAN_GETALLBITYABIBARANBYID", value: ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARANBYID },
  { label: "BITYABIBARAN_DELETEBITYABIBARANBYID", value: ROLE_AHMIS_BITYABIBARAN_DELETEBITYABIBARANBYID },
  { label: "BITYABIBARAN_GETALLBITYABIBARAN", value: ROLE_AHMIS_BITYABIBARAN_GETALLBITYABIBARAN },
  { label: "EMPLOYEEREGISTRATION_SAVEEMPLOYEEREGISTRATION", value: ROLE_AHMIS_EMPLOYEEREGISTRATION_SAVEEMPLOYEEREGISTRATION },
  { label: "EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATIONBYID", value: ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATIONBYID },
  { label: "EMPLOYEEREGISTRATION_DELETEEMPLOYEEREGISTRATIONBYID", value: ROLE_AHMIS_EMPLOYEEREGISTRATION_DELETEEMPLOYEEREGISTRATIONBYID },
  { label: "EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION", value: ROLE_AHMIS_EMPLOYEEREGISTRATION_GETALLEMPLOYEEREGISTRATION },
  { label: "SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEFROMID", value: ROLE_AHMIS_SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEFROMID },
  { label: "SHALYACHIKITSASERVICE_DELETESHALYACHIKITSASERVICE", value: ROLE_AHMIS_SHALYACHIKITSASERVICE_DELETESHALYACHIKITSASERVICE },
  { label: "SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEBYDATERANGEANDPAGE", value: ROLE_AHMIS_SHALYACHIKITSASERVICE_GETSHALYACHIKITSASERVICEBYDATERANGEANDPAGE },
  { label: "SHALYACHIKITSA_GETPENDINGSHALYACHIKITSA", value: ROLE_AHMIS_SHALYACHIKITSA_GETPENDINGSHALYACHIKITSA },
  { label: "ACUPUNCTURESEWA_SAVEACUPUNCTURESEWA", value: ROLE_AHMIS_ACUPUNCTURESEWA_SAVEACUPUNCTURESEWA },
  { label: "ACUPUNCTURESEWA_GETACUPUNCTURESEWABYID", value: ROLE_AHMIS_ACUPUNCTURESEWA_GETACUPUNCTURESEWABYID },
  { label: "ACUPUNCTURESEWA_DELETEACUPUNCTURESEWA", value: ROLE_AHMIS_ACUPUNCTURESEWA_DELETEACUPUNCTURESEWA },
  { label: "ACUPUNCTURESEWA_GETACUPUNCTURESEWABYPAGE", value: ROLE_AHMIS_ACUPUNCTURESEWA_GETACUPUNCTURESEWABYPAGE },
  { label: "ACUPUNCTURESEWA_GETAllACUPUNCTURESEWAID", value: ROLE_AHMIS_ACUPUNCTURESEWA_GETAllACUPUNCTURESEWAID },
  { label: "ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWAID", value: ROLE_AHMIS_ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWAID },
  { label: "ACUPUNCTURESEWASERVICE_SAVEACUPUNCTURESEWASERVICE", value: ROLE_AHMIS_ACUPUNCTURESEWASERVICE_SAVEACUPUNCTURESEWASERVICE },
  { label: "ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEFROMID", value: ROLE_AHMIS_ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEFROMID },
  { label: "ACUPUNCTURESEWASERVICE_DELETEACUPUNCTURESEWASERVICE", value: ROLE_AHMIS_ACUPUNCTURESEWASERVICE_DELETEACUPUNCTURESEWASERVICE },
  { label: "ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEBYDATERANGEANDPAGE", value: ROLE_AHMIS_ACUPUNCTURESEWASERVICE_GETACUPUNCTURESEWASERVICEBYDATERANGEANDPAGE },
  { label: "ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWA", value: ROLE_AHMIS_ACUPUNCTURESEWA_GETPENDINGACUPUNCTURESEWA },
  { label: "PHYSIOTHERAPYSEWA_SAVEPHYSIOTHERAPYSEWA", value: ROLE_AHMIS_PHYSIOTHERAPYSEWA_SAVEPHYSIOTHERAPYSEWA },
  { label: "PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYID", value: ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYID },
  { label: "PHYSIOTHERAPYSEWA_DELETEPHYSIOTHERAPYSEWA", value: ROLE_AHMIS_PHYSIOTHERAPYSEWA_DELETEPHYSIOTHERAPYSEWA },
  { label: "PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYPAGE", value: ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPHYSIOTHERAPYSEWABYPAGE },
  { label: "PHYSIOTHERAPYSEWA_GETAllPHYSIOTHERAPYSEWAID", value: ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETAllPHYSIOTHERAPYSEWAID },
  { label: "PHYSIOTHERAPYSEWA_GETPENDINGPHYSIOTHERAPYSEWAID", value: ROLE_AHMIS_PHYSIOTHERAPYSEWA_GETPENDINGPHYSIOTHERAPYSEWAID },
  { label: "YOGSEWA_SAVEYOGSEWA", value: ROLE_AHMIS_YOGSEWA_SAVEYOGSEWA },
  { label: "YOGSEWA_GETYOGSEWABYID", value: ROLE_AHMIS_YOGSEWA_GETYOGSEWABYID },
  { label: "YOGSEWA_DELETEYOGSEWA", value: ROLE_AHMIS_YOGSEWA_DELETEYOGSEWA },
  { label: "YOGSEWA_GETYOGSEWABYPAGE", value: ROLE_AHMIS_YOGSEWA_GETYOGSEWABYPAGE },
  { label: "YOGSEWA_GETAllYOGSEWAID", value: ROLE_AHMIS_YOGSEWA_GETAllYOGSEWAID },
  { label: "YOGSEWA_GETPENDINGYOGSEWAID", value: ROLE_AHMIS_YOGSEWA_GETPENDINGYOGSEWAID },
  { label: "PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER", value: ROLE_AHMIS_SAVE_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER },
  { label: "PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_GET_BY_ID", value: ROLE_AHMIS_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_GET_BY_ID },
  { label: "PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_DELETE_BY_ID", value: ROLE_AHMIS_PRABARDHANATMAK_ABHIYAN_YOG_SEWA_REGISTER_DELETE_BY_ID },
  { label: "HOMEOPATHY_REGISTER", value: "ROLE_AHMIS_SAVE_AND_UPDATE_HOMEOPATHY_REGISTER" },
  { label: "HOMEOPATHY_REGISTER_GET_BY_ID", value: ROLE_AHMIS_HOMEOPATHY_REGISTER_GET_BY_ID },
  { label: "HOMEOPATHY_REGISTER_DELETE_BY_ID", value: ROLE_AHMIS_HOMEOPATHY_REGISTER_DELETE_BY_ID },
  { label: "NAGARIK_AROGYA_SEWA_REGISTER", value: ROLE_AHMIS_SAVE_NAGARIK_AROGYA_SEWA_REGISTER },
  { label: "NAGARIK_AROGYA_SEWA_REGISTER_BY_ID", value: ROLE_AHMIS_GET_NAGARIK_AROGYA_SEWA_REGISTER_BY_ID },
  { label: "NAGARIK_AROGYA_SEWA_REGISTER_BY_ID", value: ROLE_AHMIS_DELETE_NAGARIK_AROGYA_SEWA_REGISTER_BY_ID },
  { label: "NAGARIK_AROGYA_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE", value: ROLE_AHMIS_GET_ALL_NAGARIK_AROGYA_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE },
  { label: "NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER", value: ROLE_AHMIS_SAVE_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER },
  { label: "NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID", value: ROLE_AHMIS_GET_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID },
  { label: "NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID", value: ROLE_AHMIS_DELETE_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_BY_ID },
  { label: "NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_FROM_DATE_RANGE_AND_PAGE", value: ROLE_AHMIS_GET_ALL_NON_COMMUNICABLE_AND_MENTAL_HEALTH_REGISTER_FROM_DATE_RANGE_AND_PAGE },
  { label: "SAVE_AND_UPDATE_JESTHANAGARIK_HEALTH_SEWA_REGISTER", value: ROLE_AHMIS_SAVE_AND_UPDATE_JESTHANAGARIK_HEALTH_SEWA_REGISTER },
  { label: "JESTHA_NAGARIK_HEALTH_SEWA_REGISTER_GET_BY_ID", value: ROLE_AHMIS_JESTHA_NAGARIK_HEALTH_SEWA_REGISTER_GET_BY_ID },
  { label: "JESTHA_NAGAGIK_HEALTH_SEWA_REGISTER_DELETE_BY_ID", value: ROLE_AHMIS_JESTHA_NAGAGIK_HEALTH_SEWA_REGISTER_DELETE_BY_ID },
  { label: "JESTHA_NAGARIK_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE", value: ROLE_AHMIS_GET_ALL_JESTHA_NAGARIK_SEWA_REGISTER_FROM_DATE_RANGE_AND_PAGE },
  { label: "PHYSIO_THERAPY_REPORT", value: ROLE_AHMIS_PHYSIO_THERAPY_REPORT },
  { label: "ACUPUNCTURE_SEWA_REPORT", value: ROLE_AHMIS_ACUPUNCTURE_SEWA_REPORT },
  { label: "STAN_PAI_AAMA_SISHU_REPORT", value: ROLE_AHMIS_STAN_PAI_AAMA_SISHU_REPORT }
]

export const JALCCOD_OPTIONS = [
  { label: "J(Jaundice)", value: "JAUNDICE" },
  { label: "A(Anemia)", value: "ANEMIA" },
  { label: "L(Lymphadenopathy)", value: "LYMPHADENOPATHY" },
  { label: "C(Cyanosis)", value: "CYANOSIS" },
  { label: "C(Clubbing)", value: "CLUBBING" },
  { label: "O(Oedema)", value: "OEDEMA" },
  { label: "D(Dehydration)", value: "DEHYDRATION" }
]

export const CONST_COLUMNS = [
  {
    id: "Mul_Darta_Number",
    name: "मूल दर्ता नं.",
    selector: (row) => Boolean(row?.mulDartaaNumber) ? AppUtils.replaceWithNepaliDigit(row.mulDartaaNumber) : Boolean(row.dartaaNumber) ? AppUtils.replaceWithNepaliDigit(row?.dartaaNumber) : (Boolean(row.villageClinicDartaaNumber) || Boolean(row.villageClinicSewaDartaaNumber)) ? "ORC" : row.pillsWitaranId ? 'FHCV-1' : "-",
    sortable: true,
  },
  {
    id: "Sewa_Darta_Number",
    name: "सेवा दर्ता नं.",
    selector: (row) => Boolean(row?.sewaDartaaNumber) ? AppUtils.replaceWithNepaliDigit(row.sewaDartaaNumber) : Boolean(row.villageClinicSewaDartaaNumber) && AppUtils.replaceWithNepaliDigit(row.villageClinicSewaDartaaNumber),
    sortable: true,
  },
  {
    id: "Darta_Miti",
    name: "दर्ता मिति",
    selector: (row) => row?.dartaaMiti && AppUtils.replaceWithNepaliDigit(DateUtils.getDateFromMilliseconds(row.dartaaMiti)),
    sortable: true,
  },
  {
    id: "SewaGrahi_Name",
    name: "सेवाग्राहीको नाम",
    selector: (row) => row?.patientFirstName + " " + row?.patientLastName,
    sortable: true,
    wrap: true
  },
  {
    id: "Age",
    name: "उमेर",
    selector: (row) => row.age && AppUtils.replaceWithNepaliDigit(row?.age) + " " + (row.ageUnit ? AppMisc.getAgeUnitLabel(row.ageUnit) : "वर्ष")
  },
  {
    id: "Address",
    name: "ठेगाना",
    selector: (row) => row.foreigner ? (row.foreignAddress) : ((row.district ? AppMisc.getDistrictName(row.district) : "-") + ", " + (row.palikaName ? AppMisc.getMunicipalityName(row.palikaName) : "-") + (row.wardNumber ? (", " + row.wardNumber) : "")),
    wrap: true,
  },
  {
    id: "Created_By",
    name: "Created By",
    selector: (row) => row.createdBy || "-",
    wrap: true,
  },
  {
    id: "Modified_By",
    name: "Modified By",
    selector: (row) => row.lastModifiedBy || "-",
    wrap: true,
  }
]

export const PROVINCE_DISTRICT_PALIKA_LIST = [
  {
    value: "PROVINCE_1", label: "कोशी प्रदेश", districts: [
      {
        value: "taplejung", label: "ताप्लेजुंग", palikas: [
          { value: "TAPLEJUNG_FUNGLING_MUN", label: "फुङलिङ नगरपालिका" },
          { value: "TAPLEJUNG_AATHRAI_TRIBENI_MUN", label: "आठराई त्रिवेणी गाउँपालिका" },
          { value: "TAPLEJUNG_SIDINGWA_MUN", label: "सिदिङ्वा गाउँपालिका" },
          { value: "TAPLEJUNG_FAKTANGLUNG_MUN", label: "फक्ताङलुङ गाउँपालिका" },
          { value: "TAPLEJUNG__MUKWAKHOLA_MUN", label: "मिक्वाखोला गाउँपालिका" },
          { value: "TAPLEJUNG_MERINGDEN_MUN", label: "मेरिङदेन गाउँपालिका" },
          { value: "TAPLEJUNG_MAUWAKHOLA_MUN", label: "मैवाखोला गाउँपालिका" },
          { value: "TAPLEJUNG_PATHIBHARA_YANGWARAK_MUN", label: "पाथीभरा याङवरक गाउँपालिका" },
          { value: "TAPLEJUNG_SIRIJANGHA_MUN", label: "सिरीजङ्घा गाउँपालिका" }
        ]
      },
      {
        value: "pachthar", label: "पाँचथर", palikas: [
          { value: "PACHTHAR_FIDIM_MUN", label: "फिदिम नगरपालिका" },
          { value: "PACHTHAR_FALELUNG_MUN", label: "फालेलुङ गाउँपालिका" },
          { value: "PACHTHAR_FALGUNANDA_MUN", label: "फाल्गुनन्द गाउँपालिका" },
          { value: "PACHTHAR_HILIHANG_MUN", label: "हिलिहाङ गाउँपालिका" },
          { value: "PACHTHAR_KAMMAYAK_MUN", label: "कुम्मायक गाउँपालिका" },
          { value: "PACHTHAR_MIKLAJUNG_MUN", label: "मिक्लाजुङ गाउँपालिका" },
          { value: "PACHTHAR_TUMBEWA_MUN", label: "तुम्बेवा गाउँपालिका" },
          { value: "PACHTHAR_YANGWARAK_MUN", label: "याङवरक गाउँपालिका" }
        ]
      },
      {
        value: "illam", label: "इलाम", palikas: [
          { value: "ILLAM_ILLAM_MUN", label: "ईलाम नगरपालिका" },
          { value: "ILLAM_DEUMAI_MUN", label: "देउमाई नगरपालिका" },
          { value: "ILLAM_MAI_MUN", label: "माई नगरपालिका" },
          { value: "ILLAM_SURYODAYA_MUN", label: "सूर्योदय नगरपालिका" },
          { value: "ILLAM_FAKFOKTHUM_MUN", label: "फाकफोकथुम गाउँपालिका" },
          { value: "ILLAM_CHULACHALI_MUN", label: "चुलाचुली गाउँपालिका" },
          { value: "ILLAM_MAIJOGMAI_MUN", label: "माईजोगमाई गाउँपालिका" },
          { value: "ILLAM_MANGSEBUNG_MUN", label: "माङसेबुङ गाउँपालिका" },
          { value: "ILLAM_RONG_MUN", label: "रोङ गाउँपालिका" },
          { value: "ILLAM_SANDAKPUR_MUN", label: "सन्दकपुर गाउँपालिका" }
        ]
      },
      {
        value: "jhapa", label: "झापा", palikas: [
          { value: "JHAPA_MECHINAGAR_MUN", label: "मेचीनगर नगरपालिका" },
          { value: "JHAPA_DAMAK_MUN", label: "दमक नगरपालिका" },
          { value: "JHAPA_KANKAI_MUN", label: "कन्काई नगरपालिका" },
          { value: "JHAPA_BHADRAPUR_MUN", label: "भद्रपुर नगरपालिका" },
          { value: "JHAPA_ARJUNDHARA_MUN", label: "अर्जुनधारा नगरपालिका" },
          { value: "JHAPA_SHIWASHATAAKSHI_MUN", label: "शिवशताक्षी नगरपालिका" },
          { value: "JHAPA_GAURADAHA_MUN", label: "गौरादह नगरपालिका" },
          { value: "JHAPA_BIRTAMOD_MUN", label: "विर्तामोड नगरपालिका" },
          { value: "JHAPA_KAMAL_MUN", label: "कमल गाउँपालिका" },
          { value: "JHAPA_GAURIGANJA_MUN", label: "गौरीगंज गाउँपालिका" },
          { value: "JHAPA_BAHRADASHI_MUN", label: "बाह्रदशी गाउँपालिका" },
          { value: "JHAPA_JHAPA_MUN", label: "झापा गाउँपालिका" },
          { value: "JHAPA_BUDDHASHANTI_MUN", label: "बुद्धशान्ति गाउँपालिका" },
          { value: "JHAPA_HALDIWARI_MUN", label: "हल्दिवारी गाउँपालिका" },
          { value: "JHAPA_KACHANKAWAL_MUN", label: "कचनकवल गाउँपालिका" }
        ]
      },
      {
        value: "morang", label: "मोरङ", palikas: [
          { value: "MORANG_WIRATANAGAR_MUN", label: "विराटनगर महानगरपालिका" },
          { value: "MORANG_BELWARI_MUN", label: "बेलवारी नगरपालिका" },
          { value: "MORANG_LETANG_MUN", label: "लेटाङ नगरपालिका" },
          { value: "MORANG_PATHARI_SANISHRARE_MUN", label: "पथरी शनिश्चरे नगरपालिका" },
          { value: "MORANG_RANGELI_MUN", label: "रंगेली नगरपालिका" },
          { value: "MORANG_RATUWAMAI_MUN", label: "रतुवामाई नगरपालिका" },
          { value: "MORANG_SUNBARSI_MUN", label: "सुनवर्षि नगरपालिका" },
          { value: "MORANG_URLAWARI_MUN", label: "उर्लावारी नगरपालिका" },
          { value: "MORANG_SUNDARAHARAICHA_MUN", label: "सुन्दरहरैचा नगरपालिका" },
          { value: "MORANG_BUDIGANGA_MUN", label: "बुढीगंगा गाउँपालिका" },
          { value: "MORANG_DHANPAALATHAAN_MUN", label: "धनपालथान गाउँपालिका" },
          { value: "MORANG_GRAMTHAN_MUN", label: "ग्रामथान गाउँपालिका" },
          { value: "MORANG_JAHADA_MUN", label: "जहदा गाउँपालिका" },
          { value: "MORANG_KANEPOKHARI_MUN", label: "कानेपोखरी गाउँपालिका" },
          { value: "MORANG_KATAHARI_MUN", label: "कटहरी गाउँपालिका" },
          { value: "MORANG_KERAWARI_MUN", label: "केरावारी गाउँपालिका" },
          { value: "MORANG_MIKLAJUNG_MUN", label: "मिक्लाजुङ गाउँपालिका" }
        ]
      },
      {
        value: "sunsari", label: "सुनसरी", palikas: [
          { value: "SUNSARI_ITAHARI_MUN", label: "ईटहरी उपमहानगरपालिका" },
          { value: "SUNSARI_DHARAN_MUN", label: "धरान उपमहानगरपालिका" },
          { value: "SUNSARI_INRUWA_MUN", label: "ईनरुवा नगरपालिका" },
          { value: "SUNSARI_DUHAWI_MUN", label: "दुहवी नगरपालिका" },
          { value: "SUNSARI_RAMDHUNI_MUN", label: "रामधुनी नगरपालिका" },
          { value: "SUNSARI_BARAHAKSHETRA", label: "बराहक्षेत्र नगरपालिका" },
          { value: "SUNSARI_DEWANGANJ_MUN", label: "देवानगञ्ज गाउँपालिका" },
          { value: "SUNSARI_KOSHI_MUN", label: "कोशी गाउँपालिका" },
          { value: "SUNSARI_GADHI_MUN", label: "गढी गाउँपालिका" },
          { value: "SUNSARI_BARJU_MUN", label: "बर्जु गाउँपालिका" },
          { value: "SUNSARI_BHOKRAHA_NARSIHA_MUN", label: "भोक्राहा नरसिंह गाउँपालिका" },
          { value: "SUNSARI_HARINAGAR_MUN", label: "हरिनगर गाउँपालिका" }
        ]
      },
      {
        value: "dhankuta", label: "धनकुटा", palikas: [
          { value: "DHANKUTA_PAKHRIBAS_MUN", label: "पाख्रिबास नगरपालिका" },
          { value: "DHANKUTA_DHANKUTA_MUN", label: "धनकुटा नगरपालिका" },
          { value: "DHANKUTA_MAHALAXMI_MUN", label: "महालक्ष्मी नगरपालिका" },
          { value: "DHANKUTA_SAGURIGADHI_MUN", label: "साँगुरीगढी गाउँपालिका" },
          { value: "DHANKUTA_SAHIDBHUMI_MUN", label: "सहिदभूमि गाउँपालिका" },
          { value: "DHANKUTA_CHHATHAR_JORPATI_MUN", label: "छथर जोरपाटी गाउँपालिका" },
          { value: "DHANKUTA_CHAUBISE_MUN", label: "चौविसे गाउँपालिका" }
        ]
      },
      {
        value: "tehrathum", label: "तेर्हथुम", palikas: [
          { value: "TEHRATHUM_MYANGLUNG_MUN", label: "म्याङलुङ नगरपालिका" },
          { value: "TEHRATHUM_LALUGURAS_MUN", label: "लालीगुराँस नगरपालिका" },
          { value: "TEHRATHUM_AATHRAI_MUN", label: "आठराई गाउँपालिका" },
          { value: "TEHRATHUM_CHHATHAR_MUN", label: "छथर गाउँपालिका" },
          { value: "TEHRATHUM_FEDAP_MUN", label: "फेदाप गाउँपालिका" },
          { value: "TEHRATHUM_MENCHHAYAYEM_MUN", label: "मेन्छयायेम गाउँपालिका" }
        ]
      },
      {
        value: "sankhuwasabha", label: "संखुवासभा", palikas: [
          { value: "SANKHUWASABHA_CHAINPUR_MUN", label: "चैनपुर नगरपालिका" },
          { value: "SANKHUWASABHA_DHARMADEWI_MUN", label: "धर्मदेवी नगरपालिका" },
          { value: "SANKHUWASABHA_KHADWARI_MUN", label: "खाँदवारी नगरपालिका" },
          { value: "SANKHUWASABHA_MADI_MUN", label: "मादी नगरपालिका" },
          { value: "SANKHUWASABHA_PACHKHAPAN_MUN", label: "पाँचखपन नगरपालिका" },
          { value: "SANKHUWASABHA_BHOTKHOLA_MUN", label: "भोटखोला गाउँपालिका" },
          { value: "SANKHUWASABHA_CHICHILA_MUN", label: "चिचिला गाउँपालिका" },
          { value: "SANKHUWASABHA_MAKALU_MUN", label: "मकालु गाउँपालिका" },
          { value: "SANKHUWASABHA_SABHAPOKHARI_MUN", label: "सभापोखरी गाउँपालिका" },
          { value: "SANKHUWASABHA_SILICHONG_MUN", label: "सिलीचोङ गाउँपालिका" }
        ]
      },
      {
        value: "bhojpur", label: "भोजपुर", palikas: [
          { value: "BHOJPUR_BHOJPUR_MUN", label: "भोजपुर नगरपालिका" },
          { value: "BHOJPUR_SHADANANDA_MUN", label: "षडानन्द नगरपालिका" },
          { value: "BHOJPUR_TEMKEMAIYUNG_MUN", label: "टेम्केमैयुङ गाउँपालिका" },
          { value: "BHOJPUR_RAMPRASAD_RAI_MUN", label: "रामप्रसाद राई गाउँपालिका" },
          { value: "BHOJPUR_ARUN_MUN", label: "अरुण गाउँपालिका" },
          { value: "BHOJPUR_PAUWAADUNGMA_MUN", label: "पौवादुङमा गाउँपालिका" },
          { value: "BHOJPUR_SALPAASILICHHO_MUN", label: "साल्पासिलिछो गाउँपालिका" },
          { value: "BHOJPUR_AAMCHOK", label: "आमचोक गाउँपालिका" },
          { value: "BHOJPUR_HATUWAGADI", label: "हतुवागढी गाउँपालिका" }
        ]
      },
      {
        value: "solukhumbu", label: "सोलुखुम्बु", palikas: [
          { value: "SOLUKHUMBU_SOLUDUDHKUNDA_MUN", label: "सोलुदुधकुण्ड नगरपालिका" },
          { value: "SOLUKHUMBU_MAPYA_DUDHKOSI_MUN", label: "माप्य दुधकोशी गाउँपालिका" },
          { value: "SOLUKHUMBU_KHUMWU_PASANGLHAMU_MUN", label: "खुम्वु पासाङल्हमु गाउँपालिका" },
          { value: "SOLUKHUMBU_THULUNG_DUDHKOSI_MUN", label: "थुलुङ दुधकोशी गाउँपालिका" },
          { value: "SOLUKHUMBU_NECHASALYAN_MUN", label: "नेचासल्यान गाउँपालिका" },
          { value: "SOLUKHUMBU_MAHAKULUNG_MUN", label: "माहाकुलुङ गाउँपालिका" },
          { value: "SOLUKHUMBU_LIKHU_PIKE_MUN", label: "लिखु पिके गाउँपालिका" },
          { value: "SOLUKHUMBU_SOTANG_MUN", label: "सोताङ गाउँपालिका" }
        ]
      },
      {
        value: "okhaldhunga", label: "ओखलढुङ्गा", palikas: [
          { value: "OKHALDHUNGA_SIDDHICHARAN_MUN", label: "सिद्दिचरण नगरपालिका" },
          { value: "OKHALDHUNGA_KHIJIDEMBA_MUN", label: "खिजिदेम्बा गाउँपालिका" },
          { value: "OKHALDHUNGA_CHAMPADEWI_MUN", label: "चम्पादेवी गाउँपालिका" },
          { value: "OKHALDHUNGA_CHISHANGKHUGADI_MUN", label: "चिशंखुगढी गाउँपालिका" },
          { value: "OKHALDHUNGA_MANEBHANGYANG_MUN", label: "मानेभञ्याङ गाउँपालिका" },
          { value: "OKHALDHUNGA_MOLUNG_MUN", label: "मोलुङ गाउँपालिका" },
          { value: "OKHALDHUNGA_LIKHU_MUN", label: "लिखु गाउँपालिका" },
          { value: "OKHALDHUNGA_SUNKOSHI_MUN", label: "सुनकोशी गाउँपालिका" },
        ]
      },
      {
        value: "khotang", label: "खोटाङ", palikas: [
          { value: "KHOTANG_HALESI_TUWACHUNG_MUN", label: "हलेसी तुवाचुङ नगरपालिका" },
          { value: "KHOTANG_DIKTEL_RUPAKOT_MAJHUWAGADI_MUN", label: "दिक्तेल रुपाकोट मझुवागढी नगरपालिका" },
          { value: "KHOTANG_YESELUKHARK_MUN", label: "ऐसेलुखर्क गाउँपालिका" },
          { value: "KHOTANG_RAWA_BESI_MUN", label: "रावा बेसी गाउँपालिका" },
          { value: "KHOTANG_JANTHEDHUNGA_MUN", label: "जन्तेढुंगा गाउँपालिका" },
          { value: "KHOTANG_KHOTEHANG_MUN", label: "खोटेहाङ गाउँपालिका" },
          { value: "KHOTANG_KEPILASGADI_MUN", label: "केपिलासगढी गाउँपालिका" },
          { value: "KHOTANG_DIPRUNG_CHUICHUMMA_MUN", label: "दिप्रुङ चुइचुम्मा गाउँपालिका" },
          { value: "KHOTANG_SAKELA_MUN", label: "साकेला गाउँपालिका" },
          { value: "KHOTANG_WARAHAPOKHARI_MUN", label: "वराहपोखरी गाउँपालिका	" }
        ]
      },
      {
        value: "udayapur", label: "उदयपुर", palikas: [
          { value: "UDAYAPUR_KATARI_MUN", label: "कटारी नगरपालिका" },
          { value: "UDAYAPUR_CHAUDANDIGADI_MUN", label: "चौदण्डीगढी नगरपालिका" },
          { value: "UDAYAPUR_TRIYUGA_MUN", label: "त्रियुगा नगरपालिका" },
          { value: "UDAYAPUR_WELAKA_MUN", label: "वेलका नगरपालिका" },
          { value: "UDAYAPUR_UDAYAPURGADI_MUN", label: "उदयपुरगढी गाउँपालिका" },
          { value: "UDAYAPUR_TAPLI_MUN", label: "ताप्ली गाउँपालिका" },
          { value: "UDAYAPUR_RAUTAMAI_MUN", label: "रौतामाई गाउँपालिका" },
          { value: "UDAYAPUR_LIMCHUNGBUNG_MUN", label: "लिम्चुङ्बुङ गाउँपालिका" }
        ]
      }
    ]
  }, {
    value: "PROVIENCE_2", label: "मधेश प्रदेश", districts: [
      //8 districts
      {
        value: "saptari", label: "सप्तरी", palikas: [
          { value: "SAPTARI_RAJWIRAJ_MUN", label: "राजविराज नगरपालिका" },
          { value: "SAPTARI_KANCHANRUP_MUN", label: "कञ्चनरुप नगरपालिका" },
          { value: "SAPTARI_DAKNESHOWRI_MUN", label: "डाक्नेश्वरी नगरपालिका" },
          { value: "SAPTARI_BODEBARASAIN_MUN", label: "बोदेबरसाईन नगरपालिका" },
          { value: "SAPTARI_KHADAK_MUN", label: "खडक नगरपालिका" },
          { value: "SAPTARI_SHAMBHUNATH_MUN", label: "शम्भुनाथ नगरपालिका" },
          { value: "SAPTARI_SURUNGA_MUN", label: "सुरुङ्‍गा नगरपालिका" },
          { value: "SAPTARI_HANUMANNAGAR_KANGKALINI_MUN", label: "हनुमाननगर कङ्‌कालिनी नगरपालिका" },
          { value: "SAPTARI_SAPTAKOSI_MUN", label: "सप्तकोशी नगरपालिका" },
          { value: "SAPTARI_AGNISAIR_KRISHNASARAWAN_MUN", label: "अग्निसाइर कृष्णासरवन गाउँपालिका" },
          { value: "SAPTARI_CHHINNAMASTA_MUN", label: "छिन्नमस्ता गाउँपालिका	" },
          { value: "SAPTARI_MAHADEWA_MUN", label: "महादेवा गाउँपालिका	" },
          { value: "SAPTARI_TIRAHUT_MUN", label: "तिरहुत गाउँपालिका" },
          { value: "SAPTARI_TILATHI_KOILADI_MUN", label: "तिलाठी कोईलाडी गाउँपालिका" },
          { value: "SAPTARI_RUPANI_MUN", label: "रुपनी गाउँपालिका" },
          { value: "SAPTARI_RAJGAD_MUN", label: "राजगढ गाउँपालिका" },
          { value: "SAPTARI_BISHNUPUR_MUN", label: "बिष्णुपुर गाउँपालिका" },
          { value: "SAPTARI_BALAN_BIHUL_MUN", label: "बलान-बिहुल गाउँपालिका" }
        ]
      },
      {
        value: "siraha", label: "सिरहा", palikas: [
          { value: "SIRAHA_LAHAN_MUN", label: "लहान नगरपालिका" },
          { value: "SIRAHA_DHANGADIMAI_MUN", label: "धनगढीमाई नगरपालिका" },
          { value: "SIRAHA_SIRAHA_MUN", label: "सिरहा नगरपालिका" },
          { value: "SIRAHA_GOLBAJAR_MUN", label: "गोलबजार नगरपालिका" },
          { value: "SIRAHA_MIRCHAIYA_MUN", label: "मिर्चैयाँ नगरपालिका" },
          { value: "SIRAHA_KALYANPUR_MUN", label: "कल्याणपुर नगरपालिका" },
          { value: "SIRAHA_KARJANHA_MUN", label: "कर्जन्हा नगरपालिका" },
          { value: "SIRAHA_SUKHIPUR_MUN", label: "सुखीपुर नगरपालिका" },
          { value: "SIRAHA_BHAGAWANPUR_MUN", label: "भगवानपुर गाउँपालिका" },
          { value: "SIRAHA_AAURAHI_MUN", label: "औरही गाउँपालिका" },
          { value: "SIRAHA_WISHNUPUR_MUN", label: "विष्णुपुर गाउँपालिका" },
          { value: "SIRAHA_BARIYARPATTI_MUN", label: "बरियारपट्टी गाउँपालिका" },
          { value: "SIRAHA_LAXMIPUR_PATARI_MUN", label: "लक्ष्मीपुर पतारी गाउँपालिका" },
          { value: "SIRAHA_NARAHA_MUN", label: "नरहा गाउँपालिका" },
          { value: "SIRAHA_SANKHUWANANKARAKATTI_MUN", label: "सखुवानान्कारकट्टी गाउँपालिका" },
          { value: "SIRAHA_ARNAMA_MUN", label: "अर्नमा गाउँपालिका" },
          { value: "SIRAHA_NAWARAJPUR_MUN", label: "नवराजपुर गाउँपालिका" }
        ]
      },
      {
        value: "dhanusa", label: "धनुषा", palikas: [
          { value: "DHANUSA_JANAKPURDHAM_MUN", label: "जनकपुरधाम उपमहानगरपालिका" },
          { value: "DHANUSA_KSHIRESHWORNATH_MUN", label: "क्षिरेश्वरनाथ नगरपालिका" },
          { value: "DHANUSA_GANESHMAN_CHARNATH_MUN", label: "गणेशमान चारनाथ नगरपालिका" },
          { value: "DHANUSA_DHANUSHADHAM_MUN", label: "धनुषाधाम नगरपालिका" },
          { value: "DHANUSA_NAGRAIN_MUN", label: "नगराइन नगरपालिका" },
          { value: "DHANUSA_WIDEHA_MUN", label: "विदेह नगरपालिका" },
          { value: "DHANUSA_MITHILA_MUN", label: "मिथिला नगरपालिका" },
          { value: "DHANUSA_SHAHIDNAGAR_MUN", label: "शहीदनगर नगरपालिका" },
          { value: "DHANUSA_SABAILA_MUN", label: "सबैला नगरपालिका" },
          { value: "DHANUSA_KAMALA_MUN", label: "कमला नगरपालिका" },
          { value: "DHANUSA_MITHILA_BIHARI_MUN", label: "मिथिला बिहारी नगरपालिका" },
          { value: "DHANUSA_HANSAPUR_MUN", label: "हंसपुर नगरपालिका" },
          { value: "DHANUSA_JANAKNANDINI_MUN", label: "जनकनन्दिनी गाउँपालिका" },
          { value: "DHANUSA_BATESHOWER_MUN", label: "बटेश्वर गाउँपालिका" },
          { value: "DHANUSA_MUKHIYAPATTI_MUSAHARMIYA_MUN", label: "मुखियापट्टी मुसहरमिया गाउँपालिका" },
          { value: "DHANUSA_LAXMINIYA_MUN", label: "लक्ष्मीनिया गाउँपालिका" },
          { value: "DHANUSA_AAURAHI_MUN", label: "औरही गाउँपालिका" },
          { value: "DHANUSA_DHANAUJI_MUN", label: "धनौजी गाउँपालिका" }
        ]
      },
      {
        value: "mahottari", label: "महोत्तरी", palikas: [
          { value: "MAHOTTARI_JALESHOWER_MUN", label: "जलेश्वर नगरपालिका" },
          { value: "MAHOTTARI_BARDIBAS_MUN", label: "बर्दिबास नगरपालिका" },
          { value: "MAHOTTARI_GAUSALA_MUN", label: "गौशाला नगरपालिका" },
          { value: "MAHOTTARI_LOHALPATTI_MUN", label: "लोहरपट्टी नगरपालिका" },
          { value: "MAHOTTARI_RAMGOPALPUR_MUN", label: "रामगोपालपुर नगरपालिका" },
          { value: "MAHOTTARI_MANARA_SHISAWA_MUN", label: "मनरा शिसवा नगरपालिका" },
          { value: "MAHOTTARI_MATIHANI_MUN", label: "मटिहानी नगरपालिका" },
          { value: "MAHOTTARI_BHANGAHA_MUN", label: "भँगाहा नगरपालिका" },
          { value: "MAHOTTARI_BALAWA_MUN", label: "बलवा नगरपालिका" },
          { value: "MAHOTTARI_AAURAHI_MUN", label: "औरही नगरपालिका" },
          { value: "MAHOTTARI_EKDARA_MUN", label: "एकडारा गाउँपालिका" },
          { value: "MAHOTTARI_SONAMA_MUN", label: "सोनमा गाउँपालिका" },
          { value: "MAHOTTARI_SAMSI_MUN", label: "साम्सी गाउँपालिका" },
          { value: "MAHOTTARI_MAHOTTARI_MUN", label: "महोत्तरी गाउँपालिका" },
          { value: "MAHOTTARI_PIPARA_MUN", label: "पिपरा गाउँपालिका" }
        ]
      },
      {
        value: "sarlahi", label: "सर्लाही", palikas: [
          { value: "SARLAHI_ISHWORPUR_MUN", label: "ईश्वरपुर नगरपालिका" },
          { value: "SARLAHI_MANGALGAWA_MUN", label: "मलंगवा नगरपालिका" },
          { value: "SARLAHI_LALBANDI_MUN", label: "लालबन्दी नगरपालिका" },
          { value: "SARLAHI_HARIPUR_MUN", label: "हरिपुर नगरपालिका" },
          { value: "SARLAHI_HARIPURWA_MUN", label: "हरिपुर्वा नगरपालिका" },
          { value: "SARLAHI_HARIWAN_MUN", label: "हरिवन नगरपालिका" },
          { value: "SARLAHI_BARAHATHAWA_MUN", label: "बरहथवा नगरपालिका" },
          { value: "SARLAHI_BALARA_MUN", label: "बलरा नगरपालिका" },
          { value: "SARLAHI_GODAITA_MUN", label: "गोडैटा नगरपालिका" },
          { value: "SARLAHI_BAGAMATI_MUN", label: "बागमती नगरपालिका" },
          { value: "SARLAHI_KAWILASI_MUN", label: "कविलासी नगरपालिका" },
          { value: "SARLAHI_CHAKRAGHATTA_MUN", label: "चक्रघट्टा गाउँपालिका" },
          { value: "SARLAHI_CHANDRANAGAR_MUN", label: "चन्द्रनगर गाउँपालिका" },
          { value: "SARLAHI_DHANKAUL_MUN", label: "धनकौल गाउँपालिका" },
          { value: "SARLAHI_BRHAPURI_MUN", label: "ब्रह्मपुरी गाउँपालिका" },
          { value: "SARLAHI_RAMNAGAR_MUN", label: "रामनगर गाउँपालिका" },
          { value: "SARLAHI_BISHNU_MUN", label: "विष्णु गाउँपालिका" },
          { value: "SARLAHI_KAUDENA_MUN", label: "कौडेना गाउँपालिका" },
          { value: "SARLAHI_PARSA_MUN", label: "पर्सा गाउँपालिका" },
          { value: "SARLAHI_BASABARIYA_MUN", label: "बसबरीया गाउँपालिका" }
        ]
      },
      {
        value: "rautahat", label: "रौतहट", palikas: [
          { value: "RAUTAHAT_CHANDRAPUR_MUN", label: "चन्द्रपुर नगरपालिका" },
          { value: "RAUTAHAT_GARUDA_MUN", label: "गरुडा नगरपालिका" },
          { value: "RAUTAHAT_GAUR_MUN", label: "गौर नगरपालिका" },
          { value: "RAUTAHAT_BAUDHIMAI_MUN", label: "बौधीमाई नगरपालिका" },
          { value: "RAUTAHAT_BRINDAWAN_MUN", label: "बृन्दावन नगरपालिका" },
          { value: "RAUTAHAT_DEWAHI_GONAHI_MUN", label: "देवाही गोनाही नगरपालिका" },
          { value: "RAUTAHAT_GADIMAI_MUN", label: "गढीमाई नगरपालिका" },
          { value: "RAUTAHAT_GUJARA_MUN", label: "गुजरा नगरपालिका" },
          { value: "RAUTAHAT_KATAHARIYA", label: "कटहरिया नगरपालिका" },
          { value: "RAUTAHAT_MADHAWA_NARAYAN_MUN", label: "माधव नारायण नगरपालिका" },
          { value: "RAUTAHAT_MAULAPUR_MUN", label: "मौलापुर नगरपालिका" },
          { value: "RAUTAHAT_FATUWABIJAYAPUR_MUN", label: "फतुवाबिजयपुर नगरपालिका" },
          { value: "RAUTAHAT_ISHANATH_MUN", label: "ईशनाथ नगरपालिका" },
          { value: "RAUTAHAT_PAROHA_MUN", label: "परोहा नगरपालिका" },
          { value: "RAUTAHAT_RAJPUR_MUN", label: "राजपुर नगरपालिका" },
          { value: "RAUTAHAT_RAJDEWI_MUN", label: "राजदेवी नगरपालिका" },
          { value: "RAUTAHAT_DURGA_BHAGAWATI_MUN", label: "दुर्गा भगवती गाउँपालिका" },
          { value: "RAUTAHAT_YAMUNAMAI_MUN", label: "यमुनामाई गाउँपालिका" }

        ]
      },
      {
        value: "bara", label: "बारा", palikas: [
          { value: "BARA_KALAIYA_MUN", label: "कलैया उपमहानगरपालिका" },
          { value: "BARA_JITPUR_SIMARA_MUN", label: "जीतपुर सिमरा उपमहानगरपालिका" },
          { value: "BARA_KOLHAWI_MUN", label: "कोल्हवी नगरपालिका" },
          { value: "BARA_NIJGOD_MUN", label: "निजगढ नगरपालिका" },
          { value: "BARA_MAHAGADIMAI_MUN", label: "महागढीमाई नगरपालिका" },
          { value: "BARA_SIMRAUNGOD_MUN", label: "सिम्रौनगढ नगरपालिका" },
          { value: "BARA_PACHARAUTA_MUN", label: "पचरौता नगरपालिका" },
          { value: "BARA_AADARSHA_KOTAWAL_MUN", label: "आदर्श कोटवाल गाउँपालिका" },
          { value: "BARA_KARAIYAMAI_MUN", label: "करैयामाई गाउँपालिका" },
          { value: "BARA_DEWATAL_MUN", label: "देवताल गाउँपालिका" },
          { value: "BARA_PARAWANIPUR_MUN", label: "परवानीपुर गाउँपालिका" },
          { value: "BARA_PRASAUNI_MUN", label: "प्रसौनी गाउँपालिका" },
          { value: "BARA_FETA_MUN", label: "फेटा गाउँपालिका" },
          { value: "BARA_BARAGADI_MUN", label: "बारागढी गाउँपालिका" },
          { value: "BARA_SUWARNA_MUN", label: "सुवर्ण गाउँपालिका" },
          { value: "BARA_WISHRAMPUR_MUN", label: "विश्रामपुर गाउँपालिका" }
        ]
      },
      {
        value: "parsa", label: "पर्सा", palikas: [
          { value: "PARSA_BIRJUNG_MUN", label: "बिरगंज महानगरपालिका" },
          { value: "PARSA_POKHARIYA_MUN", label: "पोखरिया नगरपालिका" },
          { value: "PARSA_BAHUDARMAI_MUN", label: "बहुदरमाई नगरपालिका" },
          { value: "PARSA_PARSAGADI_MUN", label: "पर्सागढी नगरपालिका" },
          { value: "PARSA_THORI_MUN", label: "ठोरी गाउँपालिका" },
          { value: "PARSA_JAGARNATHPUR_MUN", label: "जगरनाथपुर गाउँपालिका" },
          { value: "PARSA_DHOBINI_MUN", label: "धोबीनी गाउँपालिका" },
          { value: "PARSA_CHHIPAHARMAI_MUN", label: "छिपहरमाई गाउँपालिका" },
          { value: "PARSA_PAKAHA_MAINAPUR_MUN", label: "पकाहा मैनपुर गाउँपालिका" },
          { value: "PARSA_BINDABASINI_MUN", label: "बिन्दबासिनी गाउँपालिका" },
          { value: "PARSA_SAKHUWA_PRASAUNI_MUN", label: "सखुवा प्रसौनी गाउँपालिका" },
          { value: "PARSA_PATERWA_SUGAULI_MUN", label: "पटेर्वा सुगौली गाउँपालिका" },
          { value: "PARSA_KALIKAMAI_MUN", label: "कालिकामाई गाउँपालिका" },
          { value: "PARSA_JIRA_BHAWANI_MUN", label: "जिरा भवानी गाउँपालिका" }
        ]
      }
    ]
  }, {
    value: "PROVINCE_3", label: "बागमती प्रदेश", districts: [
      {
        value: "sindhuli", label: "सिन्धुली", palikas: [
          { value: "SINDHULI_KAMALAMAI_MUN", label: "कमलामाई नगरपालिका" },
          { value: "SINDHULI_DUDHAULI_MUN", label: "दुधौली नगरपालिका" },
          { value: "SINDHULI_GOLANJAR_MUN", label: "गोलन्जर गाउँपालिका" },
          { value: "SINDHULI_GHYANGLEKH_MUN", label: "घ्याङलेख गाउँपालिका" },
          { value: "SINDHULI_TINPATAN_MUN", label: "तीनपाटन गाउँपालिका" },
          { value: "SINDHULI_FIKKAL_MUN", label: "फिक्कल गाउँपालिका" },
          { value: "SINDHULI_MARIN_MUN", label: "मरिण गाउँपालिका" },
          { value: "SINDHULI_SUNKOSHI_MUN", label: "सुनकोशी गाउँपालिका" },
          { value: "SINDHULI_HARIHARPURGADI_MUN", label: "हरिहरपुरगढी गाउँपालिका" }
        ]
      },
      {
        value: "ramechhap", label: "रामेछाप", palikas: [
          { value: "RAMECHHAP_MANTHALI_MUN", label: "मन्थली नगरपालिका" },
          { value: "RAMECHHAP_RAMECHHAP_MUN", label: "रामेछाप नगरपालिका" },
          { value: "RAMECHHAP_UMAKUNDA_MUN", label: "उमाकुण्ड गाउँपालिका" },
          { value: "RAMECHHAP_KHADADEWI_MUN", label: "खाँडादेवी गाउँपालिका" },
          { value: "RAMECHHAP_GOKULDHUNGA_MUN", label: "गोकुलगङ्गा गाउँपालिका" },
          { value: "RAMECHHAP_DORAMBA_MUN", label: "दोरम्बा गाउँपालिका" },
          { value: "RAMECHHAP_LIKHU_TAMAKOSHI_MUN", label: "लिखु तामाकोशी गाउँपालिका" },
          { value: "RAMECHHAP_SUNAPATI_MUN", label: "सुनापती गाउँपालिका" }
        ]
      },
      {
        value: "dolakha", label: "दोलखा", palikas: [
          { value: "DOLAKHA_JIRI_MUN", label: "जिरी नगरपालिका" },
          { value: "DOLAKHA_BHIMESHOWER_MUN", label: "भिमेश्वर नगरपालिका" },
          { value: "DOLAKHA_KALINCHOK_MUN", label: "कालिन्चोक गाउँपालिका" },
          { value: "DOLAKHA_GAURISHANKAR_MUN", label: "गौरीशङ्कर गाउँपालिका" },
          { value: "DOLAKHA_TAMAKOSHI_MUN", label: "तामाकोशी गाउँपालिका" },
          { value: "DOLAKHA_MELUNG_MUN", label: "मेलुङ्ग गाउँपालिका" },
          { value: "DOLAKHA_WIGU_MUN", label: "विगु गाउँपालिका" },
          { value: "DOLAKHA_WAITESHOWER_MUN", label: "वैतेश्वर गाउँपालिका" },
          { value: "DOLAKHA_SHAILING_MUN", label: "शैलुङ्ग गाउँपालिका" }
        ]
      },
      {
        value: "sindhupalchok", label: "सिन्धुपाल्चोक", palikas: [
          { value: "SINDHUPALCHOK_CHAUTARA_SANGACHOKGADI_MUN", label: "चौतारा साँगाचोकगढी नगरपालिका" },
          { value: "SINDHUPALCHOK_BAHRAWISE_MUN", label: "बाह्रविसे नगरपालिका" },
          { value: "SINDHUPALCHOK_MELAMCHI_MUN", label: "मेलम्ची नगरपालिका" },
          { value: "SINDHUPALCHOK_INDRAWATI_MUN", label: "ईन्द्रावती गाउँपालिका" },
          { value: "SINDHUPALCHOK_JUGAL_MUN", label: "जुगल गाउँपालिका" },
          { value: "SINDHUPALCHOK_PACHPOKHARI_THANGPAL_MUN", label: "पाँचपोखरी थाङपाल गाउँपालिका" },
          { value: "SINDHUPALCHOK_BALEFI_MUN", label: "बलेफी गाउँपालिका" },
          { value: "SINDHUPALCHOK_BHOTEKOSHI_MUN", label: "भोटेकोशी गाउँपालिका" },
          { value: "SINDHUPALCHOK_LISANGKHU_PAKHAR_MUN", label: "लिसङ्खु पाखर गाउँपालिका" },
          { value: "SINDHUPALCHOK_SUNKOSHI_MUN", label: "सुनकोशी गाउँपालिका" },
          { value: "SINDHUPALCHOK_HELAMBU_MUN", label: "हेलम्बु गाउँपालिका" },
          { value: "SINDHUPALCHOK_TRIPURASUNDARI_MUN", label: "त्रिपुरासुन्दरी गाउँपालिका" }
        ]
      },
      {
        value: "kabhre", label: "काभ्रे", palikas: [
          { value: "KABHRE_DHULIKHEL_MUN", label: "धुलिखेल नगरपालिका" },
          { value: "KABHRE_BANEPA_MUN", label: "बनेपा नगरपालिका" },
          { value: "KABHRE_PANAUTI_MUN", label: "पनौती नगरपालिका" },
          { value: "KABHRE_PACHKHAL_MUN", label: "पाँचखाल नगरपालिका" },
          { value: "KABHRE_NAMOBUDDHA_MUN", label: "नमोबुद्ध नगरपालिका" },
          { value: "KABHRE_MANDANDEUPUR_MUN", label: "मण्डनदेउपुर नगरपालिका" },
          { value: "KABHRE_KHANIKHOLA_MUN", label: "खानीखोला गाउँपालिका" },
          { value: "KABHRE_CHURIDEURALI_MUN", label: "चौंरीदेउराली गाउँपालिका" },
          { value: "KABHRE_TEMAL_MUN", label: "तेमाल गाउँपालिका" },
          { value: "KABHRE_BETHANCHOK_MUN", label: "बेथानचोक गाउँपालिका" },
          { value: "KABHRE_BHUMLU_MUN", label: "भुम्लु गाउँपालिका" },
          { value: "KABHRE_MAHABHARAT_MUN", label: "महाभारत गाँउपालिका" },
          { value: "KABHRE_ROSHI_MUN", label: "रोशी गाउँपालिका" }
        ]
      },
      {
        value: "lalitpur", label: "ललितपुर", palikas: [
          { value: "LALITPUR_LALITPUR_MUN", label: "ललितपुर महानगरपालिका" },
          { value: "LALITPUR_GODAWARI_MUN", label: "गोदावरी नगरपालिका" },
          { value: "LALITPUR_MAHALAXMI_MUN", label: "महालक्ष्मी नगरपालिका" },
          { value: "LALITPUR_KONJYOSOM_MUN", label: "कोन्ज्योसोम गाउँपालिका" },
          { value: "LALITPUR_BAGAMATI_MUN", label: "बागमती गाउँपालिका" },
          { value: "LALITPUR_MAHANGKAL_MUN", label: "महाङ्काल गाउँपालिका" }
        ]
      },
      {
        value: "bhaktapur", label: "भक्तपुर", palikas: [
          { value: "BHAKTAPUR_CHAGUNARAYAN_MUN", label: "चाँगुनारायण नगरपालिका" },
          { value: "BHAKTAPUR_BHAKTAPUR_MUN", label: "भक्तपुर नगरपालिका" },
          { value: "BHAKTAPUR_MADHYAPUR_THIMI_MUN", label: "मध्यपुर थिमी नगरपालिका" },
          { value: "BHAKTAPUR_SURYABINAYAK_MUN", label: "सूर्यविनायक नगरपालिका" }
        ]
      },
      {
        value: "kathmandu", label: "काठमाडौं", palikas: [
          { value: "KATHMANDU_KATHMANDU_MUN", label: "काठमाण्डौं महानगरपालिका" },
          { value: "KATHMANDU_KAGESHOWRI_MANOHARA_MUN", label: "कागेश्वरी मनोहरा नगरपालिका" },
          { value: "KATHMANDU_KRITIPUR_MUN", label: "कीर्तिपुर नगरपालिका" },
          { value: "KATHMANDU_GOKARNESHOWER_MUN", label: "गोकर्णेश्वर नगरपालिका" },
          { value: "KATHMANDU_CHANDRAGIRI_MUN", label: "चन्द्रागिरी नगरपालिका" },
          { value: "KATHMANDU_TOKHA_MUN", label: "टोखा नगरपालिका" },
          { value: "KATHMANDU_TAEKESHOWER_MUN", label: "तारकेश्वर नगरपालिका" },
          { value: "KATHMANDU_DAKSHINKALI_MUN", label: "दक्षिणकाली नगरपालिका" },
          { value: "KATHMANDU_NAGARJUNA_MUN", label: "नागार्जुन नगरपालिका" },
          { value: "KATHMANDU_BUDANILAKANTHA_MUN", label: "बुढानिलकण्ठ नगरपालिका" },
          { value: "KATHMANDU_SHANKHARAPUR_MUN", label: "	शङ्खरापुर नगरपालिका" },
        ]
      },
      {
        value: "nuwakot", label: "नुवाकोट", palikas: [
          { value: "NUWAKOT_BIDUR_MUN", label: "विदुर नगरपालिका" },
          { value: "NUWAKOT_BELKOTGADI_MUN", label: "बेलकोटगढी नगरपालिका" },
          { value: "NUWAKOT_KAKANI_MUN", label: "ककनी गाउँपालिका" },
          { value: "NUWAKOT_KISPANG_MUN", label: "किस्पाङ गाउँपालिका" },
          { value: "NUWAKOT_TADI_MUN", label: "तादी गाउँपालिका" },
          { value: "NUWAKOT_TARKESHOWER_MUN", label: "तारकेश्वर गाउँपालिका" },
          { value: "NUWAKOT_DUPCHESHOWR_MUN", label: "दुप्चेश्वर गाउँपालिका" },
          { value: "NUWAKOT_PANCHAKANYA_MUN", label: "पञ्चकन्या गाउँपालिका" },
          { value: "NUWAKOT_LIKHU_MUN", label: "लिखु गाउँपालिका" },
          { value: "NUWAKOT_MYAGANG_MUN", label: "म्यगङ गाउँपालिका" },
          { value: "NUWAKOT_SHIWAPURI_MUN", label: "शिवपुरी गाउँपालिका" },
          { value: "NUWAKOT_SURYAGADI_MUN", label: "सुर्यगढी गाउँपालिका" }
        ]
      },
      {
        value: "rasuwa", label: "रसुवा", palikas: [
          { value: "RASUWA_UTTARGAYA_MUN", label: "उत्तरगया गाउँपालिका" },
          { value: "RASUWA_KALIKA_MUN", label: "कालिका गाउँपालिका" },
          { value: "RASUWA_GOSAIKUNDA_MUN", label: "गोसाईकुण्ड गाउँपालिका" },
          { value: "RASUWA_NAUKUNDA_MUN", label: "नौकुण्ड गाउँपालिका" },
          { value: "RASUWA_AAMACHHODINGMO_MUN", label: "आमाछोदिङमो गाउँपालिका" }
        ]
      },
      {
        value: "dhading", label: "धादिङ", palikas: [
          { value: "DHADING_DHUNBESHI_MUN", label: "धुनीबेंशी नगरपालिका" },
          { value: "DHADING_NILAKANTHA_MUN", label: "निलकण्ठ नगरपालिका" },
          { value: "DHADING_KHANIYABAS", label: "खनियाबास गाउँपालिका" },
          { value: "DHADING_GAJURI_MUN", label: "गजुरी गाउँपालिका" },
          { value: "DHADING_GALCHHI_MUN", label: "गल्छी गाउँपालिका" },
          { value: "DHADING_GANGAJAMUNA_MUN", label: "गङ्गाजमुना गाउँपालिका" },
          { value: "DHADING_JWALAMUKHI_MUN", label: "ज्वालामूखी गाउँपालिका" },
          { value: "DHADING_THAKRE_MUN", label: "थाक्रे गाउँपालिका" },
          { value: "DHADING_NETRAWATI_DABAJONG_MUN", label: "नेत्रावती डबजोङ गाउँपालिका" },
          { value: "DHADING_BENIGHAT_RORANG_MUN", label: "बेनीघाट रोराङ्ग गाउँपालिका" },
          { value: "DHADING_RUWI_VYALI_MUN", label: "रुवी भ्याली गाउँपालिका" },
          { value: "DHADING_SIDDHALEKH_MUN", label: "सिद्धलेक गाउँपालिका	" },
          { value: "DHADING_TRIPURASUNDARI_MUN", label: "त्रिपुरासुन्दरी गाउँपालिका	" }
        ]
      },
      {
        value: "makawanpur", label: "मकवानपुर", palikas: [
          { value: "MAKAWANPUR_HETAUDA_MUN", label: "हेटौडा उपमहानगरपालिका" },
          { value: "MAKAWANPUR_THAHA_MUN", label: "थाहा नगरपालिका" },
          { value: "MAKAWANPUR_INDRASAROBAR_MUN", label: "इन्द्रसरोबर गाउँपालिका" },
          { value: "MAKAWANPUR_KAILASH_MUN", label: "कैलाश गाउँपालिका" },
          { value: "MAKAWANPUR_BAKAIYA_MUN", label: "बकैया गाउँपालिका" },
          { value: "MAKAWANPUR_BAGMATI_MUN", label: "बाग्मति गाउँपालिका" },
          { value: "MAKAWANPUR_BHIMFEDI_MUN", label: "भिमफेदी गाउँपालिका" },
          { value: "MAKAWANPUR_MAKAWANPURGADI_MUN", label: "मकवानपुरगढी गाउँपालिका" },
          { value: "MAKAWANPUR_MANAHARI_MUN", label: "मनहरी गाउँपालिका" },
          { value: "MAKAWANPUR_RAKSIRANG_MUN", label: "राक्सिराङ्ग गाउँपालिका" }
        ]
      },
      {
        value: "chitwan", label: "चितवन", palikas: [
          { value: "CHITWAN_BHARATPUR_MUN", label: "भरतपुर महानगरपालिका" },
          { value: "CHITWAN_KALIKA_MUN", label: "कालिका नगरपालिका" },
          { value: "CHITWAN_KHAIRAHANI_MUN", label: "खैरहनी नगरपालिका" },
          { value: "CHITWAN_MADI_MUN", label: "माडी नगरपालिका" },
          { value: "CHITWAN_RATNANAGAR_MUN", label: "रत्ननगर नगरपालिका" },
          { value: "CHITWAN_RAPTI_MUN", label: "राप्ती नगरपालिका" },
          { value: "CHITWAN_ICHCHHAKAMANA_MUN", label: "इच्छाकामना गाउँपालिका" }
        ]
      }
    ]
  },
  {
    value: "GANDAKI_PROVINCE", label: "गण्डकी प्रदेश", districts: [
      {
        value: "gorakha", label: "गोरखा", palikas: [
          { value: "GORAKHA_GORAKHA_MUN", label: "गोरखा नगरपालिका" },
          { value: "GORAKHA_PALUNGTAR_MUN", label: "पालुङटार नगरपालिका" },
          { value: "GORAKHA_BARPAK_SULIKOT_MUN", label: "बारपाक सुलिकोट गाउँपालिका" },
          { value: "GORAKHA_SIRANCHOK_MUN", label: "सिरानचोक गाउँपालिका" },
          { value: "GORAKHA_AAJIRKOT_MUN", label: "अजिरकोट गाउँपालिका" },
          { value: "GORAKHA_AARUGHAT_MUN", label: "आरूघाट गाउँपालिका" },
          { value: "GORAKHA_GANDAKI_MUN", label: "गण्डकी गाउँपालिका" },
          { value: "GORAKHA_CHUMANUWRI_MUN", label: "चुमनुव्री गाउँपालिका" },
          { value: "GORAKHA_DHARCHE_MUN", label: "धार्चे गाउँपालिका" },
          { value: "GORAKHA_BHIMSENTHAPA_MUN", label: "भिमसेनथापा गाउँपालिका" },
          { value: "GORAKHA_SHAHID_LAKHAN_MUN", label: "शहिद लखन गाउँपालिका" }
        ]
      },
      {
        value: "lamjung", label: "लमजुङ", palikas: [
          { value: "LAMJUNG_BESISHAHAR_MUN", label: "बेसीशहर नगरपालिका" },
          { value: "LAMJUNG_MADDHYANEPAL_MUN", label: "मध्यनेपाल नगरपालिका" },
          { value: "LAMJUNG_RAINAS_MUN", label: "रार्इनास नगरपालिका" },
          { value: "LAMJUNG_SUNDARBAJAR_MUN", label: "सुन्दरबजार नगरपालिका" },
          { value: "LAMJUNG_KWHOLASOTHAR_MUN", label: "क्व्होलासोथार गाउँपालिका" },
          { value: "LAMJUNG_DUDHAPOKHARI_MUN", label: "दूधपोखरी गाउँपालिका" },
          { value: "LAMJUNG_DORDI_MUN", label: "दोर्दी गाउँपालिका" },
          { value: "LAMJUNG_MARSYANGDI_MUN", label: "मर्स्याङदी गाउँपालिका" }
        ]
      },
      {
        value: "tanahu", label: "तनहुँ", palikas: [
          { value: "TANAHU_BHANU_MUN", label: "भानु नगरपालिका" },
          { value: "TANAHU_BHIMAD_MUN", label: "भिमाद नगरपालिका" },
          { value: "TANAHU_BYAS_MUN", label: "व्यास नगरपालिका" },
          { value: "TANAHU_SHUKLAGANDAKI_MUN", label: "शुक्लागण्डकी नगरपालिका" },
          { value: "TANAHU_AABUKHAIRAHANI_MUN", label: "आँबुखैरेनी गाउँपालिका" },
          { value: "TANAHU_RISING_MUN", label: "ऋषिङ्ग गाउँपालिका" },
          { value: "TANAHU_GHIRING_MUN", label: "घिरिङ गाउँपालिका" },
          { value: "TANAHU_DEWAGHAT_MUN", label: "देवघाट गाउँपालिका" },
          { value: "TANAHU_MYAGDE_MUN", label: "म्याग्दे गाउँपालिका" },
          { value: "TANAHU_WANDIPUR_MUN", label: "वन्दिपुर गाउँपालिका" }
        ]
      },
      {
        value: "syangja", label: "स्याङ्जा", palikas: [
          { value: "SYANGJA_GALYANG_MUN", label: "गल्याङ नगरपालिका" },
          { value: "SYANGJA_CHAPAKOT_MUN", label: "चापाकोट नगरपालिका" },
          { value: "SYANGJA_PUTALIBAJAR_MUN", label: "पुतलीबजार नगरपालिका" },
          { value: "SYANGJA_BHIRKOT_MUN", label: "भीरकोट नगरपालिका" },
          { value: "SYANGJA_WALING_MUN", label: "वालिङ नगरपालिका" },
          { value: "SYANGJA_ARJUNCHAUPARI_MUN", label: "अर्जुनचौपारी गाउँपालिका" },
          { value: "SYANGJA_AADHIKHOLA_MUN", label: "आँधिखोला गाउँपालिका" },
          { value: "SYANGJA_KALIGANDAKI_MUN", label: "कालीगण्डकी गाउँपालिका" },
          { value: "SYANGJA_FEDIKHOLA_MUN", label: "फेदीखोला गाउँपालिका" },
          { value: "SYANGJA_BIRUWA_MUN", label: "बिरुवा गाउँपालिका" },
          { value: "SYANGJA_HARINAS_MUN", label: "हरिनास गाउँपालिका" }
        ]
      },
      {
        value: "kaski", label: "कास्की", palikas: [
          { value: "KASKI_POKHARA_MUN", label: "पोखरा महानगरपालिका" },
          { value: "KASKI_ANNAPURNA_MUN", label: "अन्नपूर्ण गाउँपालिका" },
          { value: "KASKI_MACHHAPUCHCHHRE_MUN", label: "माछापुच्छ्रे गाउँपालिका" },
          { value: "KASKI_MADI_MUN", label: "मादी गाउँपालिका" },
          { value: "KASKI_RUPA_MUN", label: "रूपा गाउँपालिका" }
        ]
      },
      {
        value: "manang", label: "मनाङ", palikas: [
          { value: "MANANG_CHAME_MUN", label: "चामे गाउँपालिका" },
          { value: "MANANG_NARPA_BHUMI_MUN", label: "नार्पा भूमि गाउँपालिका" },
          { value: "MANANG_NASO_MUN", label: "नासोँ गाउँपालिका" },
          { value: "MANANG_MANANG_NGISYANG_MUN", label: "मनाङ ङिस्याङ गाउँपालिका" }
        ]
      },
      {
        value: "mustang", label: "मुस्ताङ", palikas: [
          { value: "MUSTANG_GHARPAJHONG_MUN", label: "घरपझोङ गाउँपालिका" },
          { value: "MUSTANG_THASANG_MUN", label: "थासाङ गाउँपालिका" },
          { value: "MUSTANG_LOGHEKAR_DAMODARKUNDA_MUN", label: "लो-घेकर दामोदरकुण्ड गाउँपालिका" },
          { value: "MUSTANG_LOMANTHANG_MUN", label: "लोमन्थाङ गाउँपालिका" },
          { value: "MUSTANG_WARAGUNG_MUN", label: "वारागुङ मुक्तिक्षेत्र गाउँपालिका" }
        ]
      },
      {
        value: "myagdi", label: "म्याग्दी", palikas: [
          { value: "MYAGDI_BENI_MUN", label: "बेनी नगरपालिका" },
          { value: "MYAGDI_ANNAPURNA_MUN", label: "अन्नपुर्ण गाउँपालिका" },
          { value: "MYAGDI_DHAWALAGIRI_MUN", label: "धवलागिरी गाउँपालिका" },
          { value: "MYAGDI_MANGALA_MUN", label: "मंगला गाउँपालिका" },
          { value: "MYAGDI_MALIKA_MUN", label: "मालिका गाउँपालिका" },
          { value: "MYAGDI_RAGHUGANGA_MUN", label: "रघुगंगा गाउँपालिका" }
        ]
      },
      {
        value: "parbat", label: "पर्वत", palikas: [
          { value: "PARBAT_KUSHMA_MUN", label: "कुश्मा नगरपालिका" },
          { value: "PARBAT_FALEWAS_MUN", label: "फलेवास नगरपालिका" },
          { value: "PARBAT_JALJALA_MUN", label: "जलजला गाउँपालिका" },
          { value: "PARBAT_PAIYU_MUN", label: "पैयूं गाउँपालिका" },
          { value: "PARBAT_MAHASHILA_MUN", label: "महाशिला गाउँपालिका" },
          { value: "PARBAT_MODI_MUN", label: "मोदी गाउँपालिका" },
          { value: "PARBAT_WIHADI_MUN", label: "विहादी गाउँपालिका" }
        ]
      },
      {
        value: "baglung", label: "बागलुङ", palikas: [
          { value: "BAGLUNG_BAGLUNG_MUN", label: "बागलुङ नगरपालिका" },
          { value: "BAGLUNG_GALKOT_MUN", label: "गल्कोट नगरपालिका" },
          { value: "BAGLUNG_JAIMUNI_MUN", label: "जैमिनी नगरपालिका" },
          { value: "BAGLUNG_DHORPATAN_MUN", label: "ढोरपाटन नगरपालिका" },
          { value: "BAGLUNG_WARENG_MUN", label: "वरेङ गाउँपालिका" },
          { value: "BAGLUNG_KATHEKHOLA_MUN", label: "काठेखोला गाउँपालिका" },
          { value: "BAGLUNG_TAMANKHOLA_MUN", label: "तमानखोला गाउँपालिका" },
          { value: "BAGLUNG_TARAKHOLA_MUN", label: "ताराखोला गाउँपालिका" },
          { value: "BAGLUNG_NISIKHOLA_MUN", label: "निसीखोला गाउँपालिका" },
          { value: "BAGLUNG_WADIGAD_MUN", label: "वडिगाड गाउँपालिका" }

        ]
      },
      {
        value: "nawalparasiSustaPurwa", label: "नवलपरासी (बर्दघाट सुस्ता पूर्व)", palikas: [
          { value: "NAWALPARASI_SUSTA_PURWA_KAWASOTI_MUN", label: "कावासोती नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWA_GAIDAKOT_MUN", label: "गैडाकोट नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWA_DEWACHULI_MUN", label: "देवचुली नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWA_MADHYAWINDU_MUN", label: "मध्यविन्दु नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWA_BAUDIKALI_MUN", label: "बौदीकाली गाउँपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWABULINGTAR_MUN", label: "बुलिङटार गाउँपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWA_WINAYI_MUN", label: "विनयी त्रिवेणी गाउँपालिका" },
          { value: "NAWALPARASI_SUSTA_PURWA_HUPSEKOT_MUN", label: "हुप्सेकोट गाउँपालिका" }
        ]
      }
    ]
  },
  {
    value: "PROVINCE_5", label: "लुम्बिनी प्रदेश", districts: [
      {
        value: "gulmi", label: "गुल्मी", palikas: [
          { value: "GULMI_MUSIKOT_MUN", label: "मुसिकोट नगरपालिका" },
          { value: "GULMI_RESUNGA_MUN", label: "रेसुङ्गा नगरपालिका" },
          { value: "GULMI_ISMA_MUN", label: "	ईस्मा गाउँपालिका" },
          { value: "GULMI_KALIGANDAKI_MUN", label: "कालीगण्डकी गाउँपालिका	" },
          { value: "GULMI_GULMI_DARBAR_MUN", label: "गुल्मी दरबार गाउँपालिका" },
          { value: "GULMI_SATYAWATI_MUN", label: "सत्यवती गाउँपालिका" },
          { value: "GULMI_CHANDRAKOT_MUN", label: "चन्द्रकोट गाउँपालिका" },
          { value: "GULMI_RURUCHHETRA_MUN", label: "रुरुक्षेत्र गाउँपालिका" },
          { value: "GULMI_CHHATRAKOT_MUN", label: "छत्रकोट गाउँपालिका" },
          { value: "GULMI_DHURKOT_MUN", label: "धुर्कोट गाउँपालिका" },
          { value: "GULMI_MADANE_MUN", label: "मदाने गाउँपालिका" },
          { value: "GULMI_MALIKA_MUN", label: "मालिका गाउँपालिका" }
        ]
      },
      {
        value: "palpa", label: "पाल्पा", palikas: [
          { value: "PALPA_RAMPUR_MUN", label: "रामपुर नगरपालिका" },
          { value: "PALPA_TANSEN_MUN", label: "तानसेन नगरपालिका" },
          { value: "PALPA_NISDI_MUN", label: "निस्दी गाउँपालिका" },
          { value: "PALPA_PURWAKHOLA_MUN", label: "पूर्वखोला गाउँपालिका" },
          { value: "PALPA_RAMBHA_MUN", label: "रम्भा गाउँपालिका" },
          { value: "PALPA_MATHAGADI_MUN", label: "माथागढी गाउँपालिका" },
          { value: "PALPA_TINAU_MUN", label: "तिनाउ गाउँपालिका" },
          { value: "PALPA_BAGANASKALI_MUN", label: "बगनासकाली गाउँपालिका" },
          { value: "PALPA_RIBDIKOT_MUN", label: "रिब्दिकोट गाउँपालिका" },
          { value: "PALPA_RAINADEWI_CHHAHARA_MUN", label: "रैनादेवी छहरा गाउँपालिका" }
        ]
      },
      {
        value: "rupandehi", label: "रुपन्देही", palikas: [
          { value: "RUPANDEHI_BUTWAL_MUN", label: "बुटवल उपमहानगरपालिका" },
          { value: "RUPANDEHI_DEWADAHA_MUN", label: "देवदह नगरपालिका" },
          { value: "RUPANDEHI_LUMBINI_SANSKRITIK_MUN", label: "लुम्बिनी सांस्कृतिक नगरपालिका" },
          { value: "RUPANDEHI_SAINAMAINA_MUN", label: "सैनामैना नगरपालिका" },
          { value: "RUPANDEHI_SIDDARTHANAGAR_MUN", label: "सिद्धार्थनगर नगरपालिका" },
          { value: "RUPANDEHI_TILOTTAMA_MUN", label: "तिलोत्तमा नगरपालिका" },
          { value: "RUPANDEHI_GAIDAHAWA_MUN", label: "गैडहवा गाउँपालिका" },
          { value: "RUPANDEHI_KANCHAN_MUN", label: "कन्चन गाउँपालिका" },
          { value: "RUPANDEHI_KOTAHIMAI_MUN", label: "कोटहीमाई गाउँपालिका" },
          { value: "RUPANDEHI_MARCHAWARI_MUN", label: "मर्चवारी गाउँपालिका" },
          { value: "RUPANDEHI_MAYADEWI_MUN", label: "मायादेवी गाउँपालिका" },
          { value: "RUPANDEHI_OMASATIYA_MUN", label: "ओमसतिया गाउँपालिका" },
          { value: "RUPANDEHI_ROHINI_MUN", label: "रोहिणी गाउँपालिका" },
          { value: "RUPANDEHI_SAMMARIMAI_MUN", label: "सम्मरीमाई गाउँपालिका" },
          { value: "RUPANDEHI_SIYARI_MUN", label: "सियारी गाउँपालिका" },
          { value: "RUPANDEHI_SHUDDHODHAN_MUN", label: "शुद्धोधन गाउँपालिका" }
        ]
      },
      {
        value: "kapilwasthu", label: "कपिलवस्तु", palikas: [
          { value: "KAPILWASTHU_KAPILWASTHU_MUN", label: "कपिलवस्तु नगरपालिका" },
          { value: "KAPILWASTHU_BUDDHABHUMI_MUN", label: "बुद्धभुमी नगरपालिका" },
          { value: "KAPILWASTHU_SHIWARAJ_MUN", label: "शिवराज नगरपालिका" },
          { value: "KAPILWASTHU_MAHARAJGANG_MUN", label: "महाराजगंज नगरपालिका" },
          { value: "KAPILWASTHU_KRISHNANAGAR_MUN", label: "कृष्णनगर नगरपालिका" },
          { value: "KAPILWASTHU_BANAGANGA_MUN", label: "बाणगंगा नगरपालिका" },
          { value: "KAPILWASTHU_MAYADEWI_MUN", label: "मायादेवी गाउँपालिका" },
          { value: "KAPILWASTHU_YASODHARA_MUN", label: "यसोधरा गाउँपालिका" },
          { value: "KAPILWASTHU_SUDDHODHAN", label: "सुद्धोधन गाउँपालिका" },
          { value: "KAPILWASTHU_BIJAYANAGAR_MUN", label: "विजयनगर गाउँपालिका" }
        ]
      },
      {
        value: "arghakhachi", label: "अर्घाखाँची", palikas: [
          { value: "ARGHAKHACHI_SANDHIKHARKA_MUN", label: "सन्धिखर्क नगरपालिका" },
          { value: "ARGHAKHACHI_SHITAGANGA_MUN", label: "शितगंगा नगरपालिका" },
          { value: "ARGHAKHACHI_BHUMIKASTHAN_MUN", label: "भूमिकास्थान नगरपालिका" },
          { value: "ARGHAKHACHI_CHHATRADEWA_MUN", label: "छत्रदेव गाउँपालिका" },
          { value: "ARGHAKHACHI_PANINI_MUN", label: "पाणिनी गाउँपालिका" },
          { value: "ARGHAKHACHI_MALARANI_MUN", label: "मालारानी गाउँपालिका" }
        ]
      },
      {
        value: "pyuthan", label: "प्युठान", palikas: [
          { value: "PYUTHAN_PYUTHAN_MUN", label: "प्यूठान नगरपालिका" },
          { value: "PYUTHAN_SWARGADWARI_MUN", label: "स्वर्गद्वारी नगरपालिका" },
          { value: "PYUTHAN_GAUMUKHI_MUN", label: "गौमुखी गाउँपालिका" },
          { value: "PYUTHAN_MANDAWI_MUN", label: "माण्डवी गाउँपालिका" },
          { value: "PYUTHAN_SARUMARANI_MUN", label: "सरुमारानी गाउँपालिका" },
          { value: "PYUTHAN_MALLARANI_MUN", label: "मल्लरानी गाउँपालिका" },
          { value: "PYUTHAN_NAUWAHINI_MUN", label: "नौवहिनी गाउँपालिका" },
          { value: "PYUTHAN_JHIMRUK_MUN", label: "झिमरुक गाउँपालिका" },
          { value: "PYUTHAN_YERAWATI_MUN", label: "ऐरावती गाउँपालिका" }
        ]
      },
      {
        value: "rolpa", label: "रोल्पा", palikas: [
          { value: "ROLPA_ROLPA_MUN", label: "रोल्पा नगरपालिका" },
          { value: "ROLPA_TRIWENI_MUN", label: "त्रिवेणी गाउँपालिका" },
          { value: "ROLPA_PARIWARTAN_MUN", label: "परिवर्तन गाउँपालिका" },
          { value: "ROLPA_MADI_MUN", label: "माडी गाउँपालिका" },
          { value: "ROLPA_RUNTIGADI_MUN", label: "रुन्टीगढी गाउँपालिका" },
          { value: "ROLPA_LUNGRI_MUN", label: "लुङग्री गाउँपालिका" },
          { value: "ROLPA_GANGADEWA_MUN", label: "गंगादेव गाउँपालिका" },
          { value: "ROLPA_SUNCHHAHARI_MUN", label: "सुनछहरी गाउँपालिका" },
          { value: "ROLPA_SUNIL_SMRITI_MUN", label: "सुनिल स्मृति गाउँपालिका" },
          { value: "ROLPA_THAWANG_MUN", label: "थवाङ गाउँपालिका" }
        ]
      },
      {
        value: "dang", label: "दाङ", palikas: [
          { value: "DANG_TULSIPUR_MUN", label: "तुल्सीपुर उपमहानगरपालिका" },
          { value: "DANG_GHORAHI_MUN", label: "घोराही उपमहानगरपालिका" },
          { value: "DANG_LAMAHI_MUN", label: "लमही नगरपालिका" },
          { value: "DANG_BANGALACHULI_MUN", label: "बंगलाचुली गाउँपालिका" },
          { value: "DANG_DANGISHARAN_MUN", label: "दंगीशरण गाउँपालिका" },
          { value: "DANG_GADAWA_MUN", label: "गढवा गाउँपालिका" },
          { value: "DANG_RAJPUR_MUN", label: "राजपुर गाउँपालिका" },
          { value: "DANG_RAPTI_MUN", label: "राप्ती गाउँपालिका" },
          { value: "DANG_SHANTINAGAR_MUN", label: "शान्तिनगर गाउँपालिका" },
          { value: "DANG_BABAI_MUN", label: "बबई गाउँपालिका" }
        ]
      },
      {
        value: "bake", label: "बाँके", palikas: [
          { value: "BAKE_NEPALGANJ_MUN", label: "नेपालगंज उपमहानगरपालिका" },
          { value: "BAKE_KOHALAPUR_MUN", label: "कोहलपुर नगरपालिका" },
          { value: "BAKE_NARAINAPUR_MUN", label: "नरैनापुर गाउँपालिका" },
          { value: "BAKE_RAPTI_SONARI_MUN", label: "राप्ती सोनारी गाउँपालिका" },
          { value: "BAKE_BAIJANATH_MUN", label: "बैजनाथ गाउँपालिका" },
          { value: "BAKE_KHAJURA_MUN", label: "खजुरा गाउँपालिका" },
          { value: "BAKE_DADUWA_MUN", label: "डुडुवा गाउँपालिका" },
          { value: "BAKE_JANAKI_MUN", label: "जानकी गाउँपालिका" }
        ]
      },
      {
        value: "bardiya", label: "बर्दिया", palikas: [
          { value: "BARDIYA_GULARIYA_MUN", label: "गुलरिया नगरपालिका" },
          { value: "BARDIYA_MADHUWAN_MUN", label: "मधुवन नगरपालिका" },
          { value: "BARDIYA_RAJAPUR_MUN", label: "राजापुर नगरपालिका" },
          { value: "BARDIYA_THAKURBABA_MUN", label: "ठाकुरबाबा नगरपालिका" },
          { value: "BARDIYA_BASAGADI_MUN", label: "बाँसगढी नगरपालिका" },
          { value: "BARDIYA_BARBARDIYA_MUN", label: "बारबर्दिया नगरपालिका" },
          { value: "BARDIYA_BADAIYATAL_MUN", label: "बढैयाताल गाउँपालिका" },
          { value: "BARDIYA_GERUWA_MUN", label: "गेरुवा गाउँपालिका" }
        ]
      },
      {
        value: "nawalparasiSustaPaschchim", label: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)", palikas: [
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_BARDAGHAT_MUN", label: "बर्दघाट नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_RAMGRAM_MUN", label: "रामग्राम नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_SUNAWAL_MUN", label: "सुनवल नगरपालिका" },
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_SUSTA_MUN", label: "सुस्ता गाउँपालिका" },
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_PALHINANDAN_MUN", label: "पाल्हीनन्दन गाउँपालिका" },
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_PRATAPPUR_MUN", label: "प्रतापपुर गाउँपालिका" },
          { value: "NAWALPARASI_SUSTA_PASCHCHIM_SARAWAL_MUN", label: "सरावल गाउँपालिका" }
        ]
      },
      {
        value: "rukumPurwa", label: "रुकुम (पूर्वी भाग)", palikas: [
          { value: "RUKUM_PURWA_PUTHA_UTTARGANGA_MUN", label: "पुथा उत्तरगंगा गाउँपालिका" },
          { value: "RUKUM_PURWA_BHUME_MUN", label: "भूमे गाउँपालिका" },
          { value: "RUKUM_PURWA_SISNE_MUN", label: "सिस्ने गाउँपालिका" }
        ]
      }
    ]
  },
  {
    value: "KARNALI_PROVINCE", label: "कर्णाली प्रदेश", districts: [
      {
        value: "salyan", label: "सल्यान", palikas: [
          { value: "SALYAN_SHARADA_MUN", label: "शारदा नगरपालिका" },
          { value: "SALYAN_BAGCHAUR_MUN", label: "बागचौर नगरपालिका" },
          { value: "SALYAN_BANGAD_MUN", label: "बनगाड कुपिण्डे नगरपालिका" },
          { value: "SALYAN_KALIMATI_MUN", label: "कालिमाटी गाउँपालिका" },
          { value: "SALYAN_TRIBENI_MUN", label: "त्रिवेणी गाउँपालिका" },
          { value: "SALYAN_KAPURKOT_MUN", label: "कपुरकोट गाउँपालिका" },
          { value: "SALYAN_CHHATRESHOWRI_MUN", label: "छत्रेश्वरी गाउँपालिका" },
          { value: "SALYAN_SIDDHA_KUMAKH_MUN", label: "सिद्ध कुमाख गाउँपालिका" },
          { value: "SALYAN_DARMA_MUN", label: "दर्मा गाउँपालिका" },
          { value: "SALYAN_KUMAKH_MUN", label: "कुमाख गाउँपालिका" },
        ]
      },
      {
        value: "surkhet", label: "सुर्खेत", palikas: [
          { value: "SURKHET_BIRENDRANAGAR_MUN", label: "बीरेन्द्रनगर नगरपालिका" },
          { value: "SURKHET_BHERIGANGA_MUN", label: "भेरीगंगा नगरपालिका" },
          { value: "SURKHET_GURBHAKOT_MUN", label: "गुर्भाकोट नगरपालिका" },
          { value: "SURKHET_PANCHAPURI_MUN", label: "पञ्चपुरी नगरपालिका" },
          { value: "SURKHET_LEKWESHI_MUN", label: "लेकवेशी नगरपालिका" },
          { value: "SURKHET_CHAUKUNE_MUN", label: "चौकुने गाउँपालिका" },
          { value: "SURKHET_BARAHATAL_MUN", label: "बराहताल गाउँपालिका" },
          { value: "SURKHET_CHINGAD_MUN", label: "चिङ्गाड गाउँपालिका" },
          { value: "SURKHET_SIMTA_MUN", label: "सिम्ता गाउँपालिका" }
        ]
      },
      {
        value: "dailekh", label: "दैलेख", palikas: [
          { value: "DAILEKH_NARAYAN_MUN", label: "नारायण नगरपालिका" },
          { value: "DAILEKH_DULLU_MUN", label: "दुल्लु नगरपालिका" },
          { value: "DAILEKH_CHAMUNDA_WINDRASAINI_MUN", label: "चामुण्डा विन्द्रासैनी नगरपालिका" },
          { value: "DAILEKH_AATHBIS_MUN", label: "आठबीस नगरपालिका" },
          { value: "DAILEKH_BHAGAWATIMAI_MUN", label: "भगवतीमाई गाउँपालिका" },
          { value: "DAILEKH_GURAS_MUN", label: "गुराँस गाउँपालिका" },
          { value: "DAILEKH_DUGESHOWER_MUN", label: "डुंगेश्वर गाउँपालिका" },
          { value: "DAILEKH_NAUMULE_MUN", label: "नौमुले गाउँपालिका" },
          { value: "DAILEKH_MAHAWU_MUN", label: "महावु गाउँपालिका" },
          { value: "DAILEKH_BHAIRAWI_MUN", label: "भैरवी गाउँपालिका" },
          { value: "DAILEKH_THATIKADH_MUN", label: "ठाँटीकाँध गाउँपालिका" }
        ]
      },
      {
        value: "jaajarkot", label: "जाजरकोट", palikas: [
          { value: "JAJARKOT_BHERI_MUN", label: "भेरी नगरपालिका" },
          { value: "JAJARKOT_CHHEDAGAD_MUN", label: "छेडागाड नगरपालिका" },
          { value: "JAJARKOT_NALAGAD_MUN", label: "नलगाड नगरपालिका" },
          { value: "JAJARKOT_BAREKOT_MUN", label: "बारेकोट गाउँपालिका" },
          { value: "JAJARKOT_KUSE_MUN", label: "कुसे गाउँपालिका" },
          { value: "JAJARKOT_JUNICHADE_MUN", label: "जुनीचाँदे गाउँपालिका" },
          { value: "JAJARKOT_SHIWALAYA_MUN", label: "शिवालय गाउँपालिका" }
        ]
      },
      {
        value: "dolpa", label: "डोल्पा", palikas: [
          { value: "DOLPA_THULO_BHERI_MUN", label: "ठूली भेरी नगरपालिका" },
          { value: "DOLPA_TRIPURASUNDARI_MUN", label: "त्रिपुरासुन्दरी नगरपालिका" },
          { value: "DOLPA_DOLPO_BUDDHA_MUN", label: "डोल्पो बुद्ध गाउँपालिका" },
          { value: "DOLPA_SE_FOKSUNDO_MUN", label: "शे फोक्सुन्डो गाउँपालिका" },
          { value: "DOLPA_JAGADULLA_MUN", label: "जगदुल्ला गाउँपालिका" },
          { value: "DOLPA_MUDKECHULA_MUN", label: "मुड्केचुला गाउँपालिका" },
          { value: "DOLPA_KAIKE_MUN", label: "काईके गाउँपालिका" },
          { value: "DOLPA_CHHARKA_TANGSONG_MUN", label: "छार्का ताङसोङ गाउँपालिका" }
        ]
      },
      {
        value: "jumla", label: "जुम्ला", palikas: [
          { value: "JUMLA_CHANDANNATH_MUN", label: "चन्दननाथ नगरपालिका" },
          { value: "JUMLA_KANAKASUNDARI_MUN", label: "कनकासुन्दरी गाउँपालिका" },
          { value: "JUMLA_SINJA_MUN", label: "सिंजा गाउँपालिका" },
          { value: "JUMLA_HIMA_MUN", label: "हिमा गाउँपालिका" },
          { value: "JUMLA_TILA_MUN", label: "तिला गाउँपालिका" },
          { value: "JUMLA_GUTHICHAUR_MUN", label: "गुठिचौर गाउँपालिका" },
          { value: "JUMLA_TATOPANI_MUN", label: "तातोपानी गाउँपालिका" },
          { value: "JUMLA_PATARASI_MUN", label: "पातारासी गाउँपालिका" }
        ]
      },
      {
        value: "kalikot", label: "कालिकोट", palikas: [
          { value: "KALIKOT_KHADACHAKRA_MUN", label: "खाँडाचक्र नगरपालिका" },
          { value: "KALIKOT_RASKOT_MUN", label: "रास्कोट नगरपालिका" },
          { value: "KALIKOT_TILAGUFA_MUN", label: "तिलागुफा नगरपालिका" },
          { value: "KALIKOT_PACHALJHARANA_MUN", label: "पचालझरना गाउँपालिका" },
          { value: "KALIKOT_SANNI_TRIWENI_MUN", label: "सान्नी त्रिवेणी गाउँपालिका" },
          { value: "KALIKOT_NARAHARINATH_MUN", label: "नरहरिनाथ गाउँपालिका" },
          { value: "KALIKOT_SHUBHA_KALIKA_MUN", label: "शुभ कालीका गाउँपालिका" },
          { value: "KALIKOT_MAHAWAI_MUN", label: "महावै गाउँपालिका" },
          { value: "KALIKOT_PALATA_MUN", label: "पलाता गाउँपालिका" }
        ]
      },
      {
        value: "mugu", label: "मुगु", palikas: [
          { value: "MUGU_CHHAYANATH_RARA_MUN", label: "छायाँनाथ रारा नगरपालिका" },
          { value: "MUGU_MUGUM_KARMARONG_MUN", label: "मुगुम कार्मारोंग गाउँपालिका" },
          { value: "MUGU_SORU_MUN", label: "सोरु गाउँपालिका" },
          { value: "MUGU_KHATYAD_MUN", label: "खत्याड गाउँपालिका" }
        ]
      },
      {
        value: "humla", label: "हुम्ला", palikas: [
          { value: "HUMLA_SIMKOT_MUN", label: "सिमकोट गाउँपालिका" },
          { value: "HUMLA_NAMKHA_MUN", label: "नाम्खा गाउँपालिका" },
          { value: "HUMLA_KHARPUNATH_MUN", label: "खार्पुनाथ गाउँपालिका" },
          { value: "HUMLA_SARKEGAD_MUN", label: "सर्केगाड गाउँपालिका" },
          { value: "HUMLA_CHANKHELI_MUN", label: "चंखेली गाउँपालिका" },
          { value: "HUMLA_ADANCHULI_MUN", label: "अदानचुली गाउँपालिका" },
          { value: "HUMLA_TAJAKOT_MUN", label: "ताँजाकोट गाउँपालिका" }

        ]
      },
      {
        value: "rukumPaschim", label: "रुकुम पश्चिम", palikas: [
          { value: "RUKUM_PASCHIM_MUSIKOT_MUN", label: "मुसिकोट नगरपालिका" },
          { value: "RUKUM_PASCHIM_CHAURAJHARI_MUN", label: "चौरजहारी नगरपालिका" },
          { value: "RUKUM_PASCHIM_AATHBISKOT_MUN", label: "आठबिसकोट नगरपालिका" },
          { value: "RUKUM_PASCHIM_BAFIKOT_MUN", label: "बाँफिकोट गाउँपालिका" },
          { value: "RUKUM_PASCHIM_TRIWENI_MUN", label: "त्रिवेणी गाउँपालिका" },
          { value: "RUKUM_PASCHIM_SANI_BHERI_MUN", label: "सानी भेरी गाउँपालिका" }
        ]
      }
    ]
  },
  {
    value: "SUDURPASHCHIM_PROVINCE", label: "सुदूरपश्चिम प्रदेश", districts: [
      {
        value: "bajura", label: "बाजुरा", palikas: [
          { value: "BAJURA_BADIMALIKA_MUN", label: "बडीमालिका नगरपालिका" },
          { value: "BAJURA_TRIWENI_MUN", label: "त्रिवेणी नगरपालिका" },
          { value: "BAJURA_BUDIGANGA_MUN", label: "बुढीगंगा नगरपालिका" },
          { value: "BAJURA_BUDINANDA_MUN", label: "बुढीनन्दा नगरपालिका" },
          { value: "BAJURA_GAUMUL_MUN", label: "गौमुल गाउँपालिका" },
          { value: "BAJURA_JAGANNATH_MUN", label: "जगन्‍नाथ गाउँपालिका" },
          { value: "BAJURA_SWAMIKARTIK_KHAPAR_MUN", label: "स्वामीकार्तिक खापर गाउँपालिका" },
          { value: "BAJURA_KHAPTAD_CHHEDEDAHA_MUN", label: "खप्तड छेडेदह गाउँपालिका" },
          { value: "BAJURA_HIMALI_MUN", label: "हिमाली गाउँपालिका" }
        ]
      },
      {
        value: "bajhang", label: "बझाङ", palikas: [
          { value: "BAJHANG_JAYAPRITHIWI_MUN", label: "जयपृथ्वी नगरपालिका" },
          { value: "BAJHANG_BUNGAL_MUN", label: "बुंगल नगरपालिका" },
          { value: "BAJHANG_TALAKOT_MUN", label: "तलकोट गाउँपालिका" },
          { value: "BAJHANG_MASTA_MUN", label: "मष्टा गाउँपालिका" },
          { value: "BAJHANG_KHAPTADCHHANNA_MUN", label: "खप्तडछान्ना गाउँपालिका" },
          { value: "BAJHANG_THALARA_MUN", label: "थलारा गाउँपालिका" },
          { value: "BAJHANG_WITTHADCHIR_MUN", label: "वित्थडचिर गाउँपालिका" },
          { value: "BAJHANG_SURMA_MUN", label: "सूर्मा गाउँपालिका" },
          { value: "BAJHANG_CHHABISPATHIBHERA_MUN", label: "छबिसपाथिभेरा गाउँपालिका" },
          { value: "BAJHANG_DURGATHALI_MUN", label: "दुर्गाथली गाउँपालिका" },
          { value: "BAJHANG_KEDHARSUE_MUN", label: "केदारस्युँ गाउँपालिका" },
          { value: "BAJHANG_SAIPAL_MUN", label: "साइपाल गाउँपालिका" }
        ]
      },
      {
        value: "achham", label: "अछाम", palikas: [
          { value: "ACHHAM_MANGALSEN_MUN", label: "मंगलसेन नगरपालिका" },
          { value: "ACHHAM_KAMALBAJAR_MUN", label: "कमलबजार नगरपालिका" },
          { value: "ACHHAM_SAFEBAGAR_MUN", label: "साँफेबगर नगरपालिका" },
          { value: "ACHHAM_PANCHADEWAL_WINAYEK_MUN", label: "पन्चदेवल विनायक नगरपालिका" },
          { value: "ACHHAM_CHAURPATI_MUN", label: "चौरपाटी गाउँपालिका" },
          { value: "ACHHAM_MELLEKH_MUN", label: "मेल्लेख गाउँपालिका" },
          { value: "ACHHAM_BANNIGADI_JAYAGAD_MUN", label: "बान्निगढी जयगढ गाउँपालिका" },
          { value: "ACHHAM_RAMAROSHAN_MUN", label: "रामारोशन गाउँपालिका" },
          { value: "ACHHAM_DHAKARI_MUN", label: "ढकारी गाउँपालिका" },
          { value: "ACHHAM_TURMAKHAD_MUN", label: "तुर्माखाँद गाउँपालिका" }
        ]
      },
      {
        value: "doti", label: "डोटी", palikas: [
          { value: "DOTI_DIPAYEL_SILAGADI_MUN", label: "दिपायल सिलगढी नगरपालिका" },
          { value: "DOTI_SHIKHAR_MUN", label: "शिखर नगरपालिका" },
          { value: "DOTI_PURBACHAUKI_MUN", label: "पूर्वीसंस्था गाउँपालिका" },
          { value: "DOTI_BADIKEDAR_MUN", label: "बडीकेदार गाउँपालिका" },
          { value: "DOTI_JORAYAL_MUN", label: "जोरायल गाउँपालिका" },
          { value: "DOTI_SAYAL_MUN", label: "सायल गाउँपालिका" },
          { value: "DOTI_AADARSHA_MUN", label: "आदर्श गाउँपालिका" },
          { value: "DOTI_KIC_MUN", label: "के.आई.सिं. गाउँपालिका" },
          { value: "DOTI_BOGTAN_FUDSIL_MUN", label: "बोगटान फुड्सिल गाउँपालिका" }
        ]
      },
      {
        value: "kailali", label: "कैलाली", palikas: [
          { value: "KAILALI_DHANGADI_MUN", label: "धनगढी उपमहानगरपालिका" },
          { value: "KAILALI_TIKAPUR_MUN", label: "टिकापुर नगरपालिका" },
          { value: "KAILALI_GHOGHADI_MUN", label: "घोडाघोडी नगरपालिका" },
          { value: "KAILALI_LAMKICHUHA_MUN", label: "लम्कीचुहा नगरपालिका" },
          { value: "KAILALI_BHAJANI_MUN", label: "भजनी नगरपालिका" },
          { value: "KAILALI_GODAWARI_MUN", label: "गोदावरी नगरपालिका" },
          { value: "KAILALI_GAURIGANGA_MUN", label: "गौरीगंगा नगरपालिका" },
          { value: "KAILALI_JANAKI_MUN", label: "जानकी गाउँपालिका" },
          { value: "KAILALI_BARDAGORIYA_MUN", label: "बर्दगोरिया गाउँपालिका" },
          { value: "KAILALI_MOHANYAL_MUN", label: "मोहन्याल गाउँपालिका" },
          { value: "KAILALI_KAILARI_MUN", label: "कैलारी गाउँपालिका" },
          { value: "KAILALI_JOSHIPUR_MUN", label: "जोशीपुर गाउँपालिका" },
          { value: "KAILALI_CHURE_MUN", label: "चुरे गाउँपालिका" }
        ]
      },
      {
        value: "kanchanpur", label: "कञ्चनपुर", palikas: [
          { value: "KANCHANPUR_BHEEMDATTA_MUN", label: "भीमदत्त नगरपालिका" },
          { value: "KANCHANPUR_PUNARBAAS_MUN", label: "पुर्नवास नगरपालिका" },
          { value: "KANCHANPUR_WEDAKOT_MUN", label: "वेदकोट नगरपालिका" },
          { value: "KANCHANPUR_MAHAKALI_MUN", label: "दोधारा चादँनी नगरपालिका" },
          { value: "KANCHANPUR_SUKLAFATA_MUN", label: "शुक्लाफाँटा नगरपालिका" },
          { value: "KANCHANPUR_BELAURI_MUN", label: "बेलौरी नगरपालिका" },
          { value: "KANCHANPUR_KRISHNAPUR_MUN", label: "कृष्णपुर नगरपालिका" },
          { value: "KANCHANPUR_BELDADI_MUN", label: "बेलडाडी गाउँपालिका" },
          { value: "KANCHANPUR_LALJHADI_MUN", label: "लालझाडी गाउँपालिका" }
        ]
      },
      {
        value: "dadeldhura", label: "डडेल्धुरा", palikas: [
          { value: "DADELDHURA_AMARGADI_MUN", label: "अमरगढी नगरपालिका" },
          { value: "DADELDHURA_PARASHURAM_MUN", label: "परशुराम नगरपालिका" },
          { value: "DADELDHURA_AALITAL_MUN", label: "आलिताल गाउँपालिका" },
          { value: "DADELDHURA_BHAGESHOWER_MUN", label: "भागेश्वर गाउँपालिका" },
          { value: "DADELDHURA_NAWADURGA_MUN", label: "नवदुर्गा गाउँपालिका" },
          { value: "DADELDHURA_AJAYMERU_MUN", label: "अजयमेरु गाउँपालिका" },
          { value: "DADELDHURA_GANNYAPADHURA_MUN", label: "गन्यापधुरा गाउँपालिका" }
        ]
      },
      {
        value: "baitadi", label: "बैतडी", palikas: [
          { value: "BAITADI_DASHARATHACHANDA_MUN", label: "दशरथचन्द नगरपालिका" },
          { value: "BAITADI_PATAN_MUN", label: "पाटन नगरपालिका" },
          { value: "BAITADI_MELAULI_MUN", label: "मेलौली नगरपालिका" },
          { value: "BAITADI_PUCHAURDI_MUN", label: "पुर्चौडी नगरपालिका" },
          { value: "BAITADI_SURNAYA_MUN", label: "सुर्नया गाउँपालिका" },
          { value: "BAITADI_SIGASA_MUN", label: "सिगास गाउँपालिका" },
          { value: "BAITADI_SHIWANATH_MUN", label: "शिवनाथ गाउँपालिका" },
          { value: "BAITADI_PANCHESHOWER_MUN", label: "पञ्चेश्वर गाउँपालिका" },
          { value: "BAITADI_DOGADAKEDAR_MUN", label: "दोगडाकेदार गाउँपालिका" },
          { value: "BAITADI_DILASAINI_MUN", label: "डीलासैनी गाउँपालिका" },
        ]
      },
      {
        value: "darchula", label: "दार्चुला", palikas: [
          { value: "DARCHULA_MAHAKALI_MUN", label: "महाकाली नगरपालिका" },
          { value: "DARCHULA_SHAILYASHIKHAR_MUN", label: "शैल्यशिखर नगरपालिका" },
          { value: "DARCHULA_MALIKARJUN_MUN", label: "मालिकार्जुन गाउँपालिका" },
          { value: "DARCHULA_APIHIMAL_MUN", label: "अपिहिमाल गाउँपालिका" },
          { value: "DARCHULA_DUHU_MUN", label: "दुहुँ गाउँपालिका" },
          { value: "DARCHULA_NAUGAD_MUN", label: "नौगाड गाउँपालिका" },
          { value: "DARCHULA_MARMA_MUN", label: "मार्मा गाउँपालिका" },
          { value: "DARCHULA_LEKAM_MUN", label: "लेकम गाउँपालिका" },
          { value: "DARCHULA_BYAS_MUN", label: "ब्याँस गाउँपालिका" }
        ]
      }
    ]
  }
];