import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AHMIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { Box, Grid, TextField } from "@mui/material";
import CustomModal from "../../../../components/modal/CustomModal";
import NepaliCalender from "../../../../components/nepali-calender";
import CustomReactSelect from "../../../../components/custom-react-select/CustomReactSelect";
import DeleteRegisterRecordConformation from "../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import RegisterHeader from "../../../../components/register-header/RegisterHeader";
import { ERROR, ID, REQUIRED_FIELD, ROW_PER_PAGE, SEWA_CODE, SOMETHING_WENT_WRONG, SUCCESS } from "../../../../utils/constants"
import ShalyaChikitsaBabaranRegister from "../../../users/components/register/shalya-chikitsa-register/helper/ShalyaChikitsaBabaranRegister";
import { AppUtils } from "../../../../utils/appUtils";

const ShalyaChikitsaSewaBibaranForm = () => {
  const [modalTitle, setModalTitle] = useState("शल्य चिकित्सा सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showShalyaChikitsaBibaranModal, setShowShalyaChikitsaBibaranModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [shalyaChikitsaBibaranSewaBibaranTableData, setShalyaChikitsaBibaranSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [showshalyaChikitsaBibaranDeleteModal, setShowShalyaChikitsaBibaranDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [shalyaChikitsaBibaranDeleteId, setShalyaChikitsaBibaranDeleteId] = useState(null);
  const shalyaChikitsaRegisterId = AppUtils.getUrlParam(ID);

  useEffect(() => {
    showShalyaChikitsaBibaranModal && reset();
  }, [reset, showShalyaChikitsaBibaranModal]);

  useEffect(() => {
    register("sewaCode")
  }, [register])

  const openShalyaChikitsaBibaranModal = () => {
    setShowShalyaChikitsaBibaranModal(true);
  };

  const closeShalyaChikitsaBibaranModal = () => {
    setShowShalyaChikitsaBibaranModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitShalyaChikitsaBibaranRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    const url = `${API_URL.shalyaChikitsaService}?shalyaChikitsaServiceRegistrationId=${shalyaChikitsaRegisterId}`;
    AHMIS.post(url, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeShalyaChikitsaBibaranModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getShalyaChikitsaBibaranSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.shalyaChikitsaService}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&shalyaChikitsaServiceRegistrationId=${shalyaChikitsaRegisterId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setShalyaChikitsaBibaranSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const shalyaChikitsaBibaranSewaEdit = (id) => {
    AHMIS.get(API_URL.shalyaChikitsaService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowShalyaChikitsaBibaranModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closeshalyaChikitsaBibaranDeleteModal = () => {
    setShowShalyaChikitsaBibaranDeleteModal(false);
    setShalyaChikitsaBibaranDeleteId(null);
  }

  const onDeleteShalyaChikitsaBibaranRow = (id) => {
    setShowShalyaChikitsaBibaranDeleteModal(true);
    setShalyaChikitsaBibaranDeleteId(id);
  }

  const deleteShalyaChikitsaBibaranSewaRow = (data) => {
    AHMIS.delete(API_URL.shalyaChikitsaService + "/" + shalyaChikitsaBibaranDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowShalyaChikitsaBibaranDeleteModal(false);
          setShalyaChikitsaBibaranDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getShalyaChikitsaBibaranSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="शल्य चिकित्सा सेवा विवरण "
          handleModalOpen={openShalyaChikitsaBibaranModal}
          getRegisterData={getShalyaChikitsaBibaranSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showShalyaChikitsaBibaranModal}
        onModalSubmit={handleSubmit(submitShalyaChikitsaBibaranRegister)}
        onModalClose={closeShalyaChikitsaBibaranModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="Dates"
              label="मिति"
              name="dartaaMiti"
              defaultDate={modalDefaultValues?.dartaaMiti}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="कैफियत"
              size="small"
              variant="outlined"
              name="remarks"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("remarks")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showshalyaChikitsaBibaranDeleteModal}
        closeDeleteModel={closeshalyaChikitsaBibaranDeleteModal}
        deleteRecord={deleteShalyaChikitsaBibaranSewaRow}
      />
      <ShalyaChikitsaBabaranRegister
        onEditRow={shalyaChikitsaBibaranSewaEdit}
        tableData={shalyaChikitsaBibaranSewaBibaranTableData}
        deleteRow={onDeleteShalyaChikitsaBibaranRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  );
};

export default ShalyaChikitsaSewaBibaranForm;