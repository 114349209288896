import { Box, Checkbox, FormControlLabel, Grid, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader';
import { useForm } from 'react-hook-form';
import AHMIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ACUPUNCTURE, ACUPUNCTURE_SEVA_PRAKAAR, ERROR, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import CustomModal from '../../../components/modal/CustomModal';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { ICD_CODE_OPTIONS, MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import { DateUtils } from '../../../utils/dateUtils';
import { AppMisc } from '../../../misc/appMisc';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import AcupunctureRegister from '../../users/components/register/acupuncture-register/AcupunctureRegister';
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect';
import PendingPatientList from '../../../components/pending-patient-list/PendingPatientList';

const AcupunctureForm = () => {
  const [modalTitle, setModalTitle] = useState("अकुपञ्चर सेवा सेवा विवरण नयाँ रेकर्ड थप्नुहोस्।");
  const [showAcupuntureModal, setShowAcupuntureModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [page, setPage] = useState(0);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [totalSize, setTotalSize] = useState();
  const [acupuntureDataTable, setAcupuntureDataTable] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [showAcupuntureDeleteModal, setShowAcupuntureDeleteModal] = useState(false);
  const [acupuntureDeleteId, setAcupuntureDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [pendingPatientList, setPendingPatientList] = useState([])

  const openAcupuntureModal = () => {
    setShowAcupuntureModal(true);
  }

  const closeAcupuntureModal = () => {
    reset();
    setShowAcupuntureModal(false);
    setModalDefaultValues()
  }

  useEffect(() => {
    register("icdCode");
  }, [register]);

  useEffect(() => {
    if (showAcupuntureModal) {
      reset();
    }
  }, [showAcupuntureModal])


  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const getAcupuntureData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.acupunctureSewa}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setAcupuntureDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const submitAcupunctureRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.acupunctureSewa, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeAcupuntureModal();
          setGetTableData(true);
          acupunturePendingPatient()
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getAcupuntureData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const acupuntureEdit = (id) => {
    AHMIS.get(API_URL.acupunctureSewa + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowAcupuntureModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const closeAcupuntureDeleteModal = () => {
    setShowAcupuntureDeleteModal(false);
    setAcupuntureDeleteId(null);
  }

  const onDeleteAcupuntureRow = (id) => {
    setShowAcupuntureDeleteModal(true);
    setAcupuntureDeleteId(id);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const deleteAcupunctureRow = (data) => {
    AHMIS.delete(API_URL.acupunctureSewa + "/" + acupuntureDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowAcupuntureDeleteModal(false);
          setAcupuntureDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChecked = (e) => {
    setValue("genderViolence", e.target?.checked);
  };


  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=7&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  useEffect(() => {
    acupunturePendingPatient()
  }, [])

  const acupunturePendingPatient = () => {
    let url = `${API_URL.pendingPatient}?sewaType=${"7"}&fiscalYear=${DateUtils.getCurrentFiscalYear()}&registerConstant=${ACUPUNCTURE}`;
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setPendingPatientList(jsondata.data);
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="अकुपञ्चर सेवा रजिष्टर"
          handleModalOpen={openAcupuntureModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getAcupuntureData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showAcupuntureModal}
        onModalSubmit={handleSubmit(submitAcupunctureRegister)}
        onModalClose={closeAcupuntureModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            registerConstant={ACUPUNCTURE}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <CustomReactSelect
              label="ICD Code"
              name="icdCode"
              onChange={handleCustomReactSelectChange}
              options={ICD_CODE_OPTIONS}
              defaultValue={modalDefaultValues?.icdCode}
              isMulti
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="निदानात्मक परीक्षण"
              size="small"
              variant="outlined"
              name="labTests"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.labTests}
              {...register("labTests")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="सम्भावित निदान"
              size="small"
              variant="outlined"
              name="provisionalDiagnosis"
              autoComplete="true"
              defaultValue={modalDefaultValues?.provisionalDiagnosis}
              fullWidth
              {...register("provisionalDiagnosis")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="उपचार र सल्लाह"
              size="small"
              variant="outlined"
              name="tratementAndSuggestion"
              autoComplete="true"
              defaultValue={modalDefaultValues?.tratementAndSuggestion}
              fullWidth
              {...register("tratementAndSuggestion")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <CustomReactSelect
              label="अकुपन्चर चिकित्सासेवा प्रकार"
              name="sewaType"
              onChange={handleCustomReactSelectChange}
              options={ACUPUNCTURE_SEVA_PRAKAAR}
              defaultValue={modalDefaultValues?.sewaType}
              creatable
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रेषण भई आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="referredIn"
              autoComplete="true"
              defaultValue={modalDefaultValues?.referredIn}
              fullWidth
              {...register("referredIn")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रेषण भई गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="referredOut"
              autoComplete="true"
              defaultValue={modalDefaultValues?.referredOut}
              fullWidth
              {...register("referredOut")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <Tooltip title="लैङ्गिक हिंसा भए मात्र यो कोठामा चिन्ह लगाउनुहोस ।" placement="top" arrow>
              <FormControlLabel
                label="लैङ्गिक हिंसा"
                control={
                  <Checkbox
                    checked={modalDefaultValues?.genderViolence}
                    name="genderViolence"
                    variant="outlined"
                    onChange={handleChecked}
                    color="primary"
                  />}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CustomModal>
      {
        pendingPatientList.size > 0 &&
        <Box mt={2}>
          <PendingPatientList
            pendingPatientList={pendingPatientList}
            showModal={setShowAcupuntureModal}
            modalDefaultValues={setModalDefaultValues}
            setValue={setValue}
          />
        </Box>
      }
      <DeleteRegisterRecordConformation
        showDeleteModal={showAcupuntureDeleteModal}
        closeDeleteModel={closeAcupuntureDeleteModal}
        deleteRecord={deleteAcupunctureRow}
      />
      <AcupunctureRegister
        onEditRow={acupuntureEdit}
        tableData={acupuntureDataTable}
        deleteRow={onDeleteAcupuntureRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default AcupunctureForm;
