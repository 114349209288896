import { Box, Checkbox, FormControlLabel, Grid, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import { useForm } from 'react-hook-form';
import AHMIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, HOMEOPATHY, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import CustomModal from '../../../components/modal/CustomModal';
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect';
import { SessionStorage } from '../../../utils/storage/sessionStorage';
import { ICD_CODE_OPTIONS, MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms';
import { DateUtils } from '../../../utils/dateUtils';
import { AppMisc } from '../../../misc/appMisc';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation';
import HomeoPathySewaRegister from '../../users/components/register/homeo-pathy-sewa-register/HomeoPathySewaRegister';
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect';

const HomeoPathyForm = () => {

  const [modalTitle, setModalTitle] = useState("होमियोप्याथी सेवा राजिस्टर रेकर्ड थप्नुहोस्।");
  const [showHomeoPathyModal, setShowHomeoPathyModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [homeoPathyDataTable, setHomeoPathyDataTable] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [page, setPage] = useState(0);
  const [palikaOptions, setPalikaOptions] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [showHomeopathyDeleteModal, setShowHomeopathyDeleteModal] = useState(false);
  const [homeoPathyDeleteId, setHomePathyDeleteId] = useState(null);

  useEffect(() => {
    setShowHomeoPathyModal && reset();
  }, [reset, setShowHomeoPathyModal])

  const openHomeopathyModal = () => {
    setShowHomeoPathyModal(true);
  }

  useEffect(() => {
    if (showHomeoPathyModal) {
      reset();
    }
  }, [showHomeoPathyModal])

  const closeHomeopathyModal = () => {
    reset();
    setShowHomeoPathyModal(false);
    setModalDefaultValues()
  }

  useEffect(() => {
    register("icdCode");
  }, [register]);

  const getHomeoPathyData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.homeoPathy}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setHomeoPathyDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value)
  }

  const handleChecked = (e) => {
    setValue("genderViolence", e.target?.checked);
  };

  const submitHomeopathyRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.homeoPathy, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeHomeopathyModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getHomeoPathyData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const HomeopathyEdit = (id) => {
    AHMIS.get(API_URL.homeoPathy + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowHomeoPathyModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const closeHomeoPathyDeleteModal = () => {
    setShowHomeopathyDeleteModal(false);
    setHomePathyDeleteId(null);
  }

  const onDeleteHomeoPathyRow = (id) => {
    setShowHomeopathyDeleteModal(true);
    setHomePathyDeleteId(id);
  }

  const deleteHomeoPathyRow = (data) => {
    AHMIS.delete(API_URL.homeoPathy + "/" + homeoPathyDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowHomeopathyDeleteModal(false);
          setHomePathyDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=9&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])

  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="होमियोप्याथी सेवा राजिस्टर"
          handleModalOpen={openHomeopathyModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getHomeoPathyData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showHomeoPathyModal}
        onModalSubmit={handleSubmit(submitHomeopathyRegister)}
        onModalClose={closeHomeopathyModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            registerConstant={HOMEOPATHY}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="ICD Code"
              name="icdCode"
              onChange={handleCustomReactSelectChange}
              options={ICD_CODE_OPTIONS}
              defaultValue={modalDefaultValues?.icdCode}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="निदानात्मक परीक्षण"
              size="small"
              variant="outlined"
              name="diagnosticTest"
              autoComplete="true"
              defaultValue={modalDefaultValues?.diagnosticTest}
              fullWidth
              {...register("diagnosticTest")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="सम्भावित निदान  "
              size="small"
              variant="outlined"
              name="possibleDiagnosis"
              autoComplete="true"
              defaultValue={modalDefaultValues?.possibleDiagnosis}
              fullWidth
              {...register("possibleDiagnosis")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="उपचार र सल्लाह"
              size="small"
              variant="outlined"
              name="treatmentAndAdvice"
              autoComplete="true"
              defaultValue={modalDefaultValues?.treatmentAndAdvice}
              fullWidth
              {...register("treatmentAndAdvice")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रेषण भई आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="referredIn"
              autoComplete="true"
              defaultValue={modalDefaultValues?.referredIn}
              fullWidth
              {...register("referredIn")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रेषण भई गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="referredOut"
              autoComplete="true"
              defaultValue={modalDefaultValues?.referredOut}
              fullWidth
              {...register("referredOut")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <Tooltip title="लैङ्गिक हिंसा भए मात्र यो कोठामा चिन्ह लगाउनुहोस ।" placement="top" arrow>
              <FormControlLabel
                label="लैङ्गिक हिंसा"
                control={
                  <Checkbox
                    checked={modalDefaultValues?.genderViolence}
                    name="genderViolence"
                    variant="outlined"
                    onChange={handleChecked}
                    color="primary"
                  />}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showHomeopathyDeleteModal}
        closeDeleteModel={closeHomeoPathyDeleteModal}
        deleteRecord={deleteHomeoPathyRow}
      />
      <HomeoPathySewaRegister
        onEditRow={HomeopathyEdit}
        tableData={homeoPathyDataTable}
        deleteRow={onDeleteHomeoPathyRow}
        handleChangePage={handleChangePage}
        page={page}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default HomeoPathyForm
