import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import CustomModal from '../../../components/modal/CustomModal'
import StanpaiAamaTathaShishuSewaRegister from '../../users/components/register/stanpai-aama-tatha-shishu-sewa-register/StanpaiAamaTathaShishuSewaRegister'
import { useForm } from 'react-hook-form'
import { ERROR, PRASUTI_KO_STHAN, PRASUTI_KO_TYPES, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, STANPAIAAMASISHU, SUCCESS } from '../../../utils/constants'
import { AppMisc } from '../../../misc/appMisc'
import AHMIS, { API_URL } from '../../../api/api'
import AddAlertMessage from '../../../components/alert/Alert'
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation'
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect'
import { SessionStorage } from '../../../utils/storage/sessionStorage'
import { INVALID_BLOOD_PRESSURE_MESSAGE, MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms'
import { DateUtils } from '../../../utils/dateUtils'
import NepaliCalender from '../../../components/nepali-calender'
import CustomReactSelect from '../../../components/custom-react-select/CustomReactSelect'

const StanpaiAamatathaShishuSewaForm = () => {
  const [modalTitle, setModalTitle] = useState("स्तनपायी आमा तथा शिशु सेवा रजिष्टर")
  const [showStanpaiAamatathaShishuSewaModal, setStanpaiAamatathaShishuSewaModal] = useState(false)
  const [palikaOptions, setPalikaOptions] = useState([]);
  const districtOptions = AppMisc.getDistrictOptions();
  const [getTableData, setGetTableData] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [showStanpaiAamatathaShishuDeleteModal, setStanpaiAamatathaShishuDeleteModal] = useState(false);
  const [stanpaiAamatathaShishuTableData, setStanpaiAamatathaShishuTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [stanpaiAamatathaShishuDeleteId, setStanpaiAamatathaShishuDeleteId] = useState(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);

  const openStanpaiAamatathaShishuSewaModal = () => {
    setStanpaiAamatathaShishuSewaModal(true)
  }

  const closeStanpaiAamatathaShishuSewaModal = () => {
    setStanpaiAamatathaShishuSewaModal(false)
    reset()
  }

  useEffect(() => {
    if (showStanpaiAamatathaShishuSewaModal) {
      reset()
    }
  }, [showStanpaiAamatathaShishuSewaModal])

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  const handleCustomReactSelectChange = (name, value) => {
    setValue(name, value);
  };

  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  };

  const handleDistrictChange = (name, value, districtOption) => {
    setValue("palikaName", null);
    setValue(name, value);
    districtOption && setPalikaOptions(districtOption.palikas || []);
  };

  const submitStanpaiAamatathaShishuSewa = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.stanpaiAama, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closeStanpaiAamatathaShishuSewaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getStanpaiAamatathaShishuSewa = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.stanpaiAama}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setGetTableData(false);
        setStanpaiAamatathaShishuTableData(jsondata?.data?.objectList || []);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const stanpaiAamatathaShishuSewaEdit = (id) => {
    AHMIS.get(API_URL.stanpaiAama + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setStanpaiAamatathaShishuSewaModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    let districtObject = districtOptions.find(options => options.value === districtName);
    handleDistrictChange("district", districtName, districtObject);
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeStanpaiAamatathaShishuDeleteModal = () => {
    setStanpaiAamatathaShishuDeleteModal(false);
    setStanpaiAamatathaShishuDeleteId(null);
  }

  const onDeleteStanpaiAamatathaShishuRow = (id) => {
    setStanpaiAamatathaShishuDeleteModal(true);
    setStanpaiAamatathaShishuDeleteId(id);
  }

  const deleteStanpaiAamatathaShishuRow = (data) => {
    AHMIS.delete(API_URL.stanpaiAama + "/" + stanpaiAamatathaShishuDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setStanpaiAamatathaShishuDeleteModal(false);
          setStanpaiAamatathaShishuDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getStanpaiAamatathaShishuSewa(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=6&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])


  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="स्तनपायी आमा तथा शिशु सेवा रजिष्टर"
          handleModalOpen={openStanpaiAamatathaShishuSewaModal}
          getRegisterData={getStanpaiAamatathaShishuSewa}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showStanpaiAamatathaShishuSewaModal}
        onModalSubmit={handleSubmit(submitStanpaiAamatathaShishuSewa)}
        onModalClose={closeStanpaiAamatathaShishuSewaModal}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
            registerConstant={STANPAIAAMASISHU}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <NepaliCalender
                id="prasu-dartaa-miti"
                name="prasutiKoMiti"
                label="प्रसुति भएको मिति"
                showDefaultDate
                onChange={handleDateChange}
                defaultDate={modalDefaultValues?.prasutiKoMiti}
              />
              {errors?.prasutiKoMiti && errors.prasutiKoMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="प्रसुति भएको स्थान"
              name="prasutiKoStha"
              options={PRASUTI_KO_STHAN}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.prasutiKoStha}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CustomReactSelect
              label="प्रसुतिको प्रकार"
              name="prasutiKoTypes"
              options={PRASUTI_KO_TYPES}
              onChange={handleCustomReactSelectChange}
              defaultValue={modalDefaultValues?.prasutiKoTypes}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="रक्तचाप (Blood Pressure)"
              size="small"
              variant="outlined"
              name="bloodPressure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPressure}
              {...register("bloodPressure", {
                pattern: /^(0|[1-9][0-9]*)\/(0|[1-9][0-9]*)$/,
                required: true
              })}
            />
            {errors.bloodPressure && errors.bloodPressure.type === "pattern" && <span className="error-message">{INVALID_BLOOD_PRESSURE_MESSAGE}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="तौल (KG)"
              size="small"
              variant="outlined"
              name="weight"
              type="number"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.weight}
              {...register("weight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="मधुमेह"
              size="small"
              variant="outlined"
              name="diabetes"
              fullWidth
              defaultValue={modalDefaultValues?.diabetes}
              {...register("weight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="प्रकृति"
              size="small"
              variant="outlined"
              name="nature"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.nature}
              {...register("nature")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण"
              size="small"
              variant="outlined"
              name="examination"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.examination}
              {...register("examination")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="अन्य रोग"
              size="small"
              variant="outlined"
              name="otherDisease"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.otherDisease}
              {...register("otherDisease")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रेषण भई आएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="referredOut"
              autoComplete="true"
              defaultValue={modalDefaultValues?.referredOut}
              fullWidth
              {...register("referredOut")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="प्रेषण भई गएको संस्थाको नाम"
              size="small"
              variant="outlined"
              name="referredIn"
              autoComplete="true"
              defaultValue={modalDefaultValues?.referredIn}
              fullWidth
              {...register("referredIn")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showStanpaiAamatathaShishuDeleteModal}
        closeDeleteModel={closeStanpaiAamatathaShishuDeleteModal}
        deleteRecord={deleteStanpaiAamatathaShishuRow}
      />
      <StanpaiAamaTathaShishuSewaRegister
        onEditRow={stanpaiAamatathaShishuSewaEdit}
        tableData={stanpaiAamatathaShishuTableData}
        deleteRow={onDeleteStanpaiAamatathaShishuRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  )
}

export default StanpaiAamatathaShishuSewaForm;