import React, { useState } from 'react';
import ReportHeader from '../component/report-header/ReportHeader';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AHMIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import MessageBlock from '../../admin/components/message-block/MessageBlock';

const HomeoPathyReport = () => {
  const [tableData, setTableData] = useState([])

  const getReportData = (dateFrom, dateTo) => {

    const url = `${API_URL.hemeoPathyReport}?fromDate=${dateFrom}&toDate=${dateTo}`;
    AHMIS.get(url).then(response => {
      const jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setTableData(jsondata.data);
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
    });
  };

  return (
    <>
      <ReportHeader title="होमियोप्याथी सेवा प्रतिवेदन"
        getReportData={getReportData}
      />
      {
        tableData && Object.keys(tableData).length > 0 ? (
          <TableContainer classes={{ root: 'fixed-head-table-container' }}>
            <Table classes={{ root: 'table-bordered normal-spacing' }} size="small">
              <TableHead classes={{ root: 'align-center fixed-header header-sm' }}>
                <TableRow>
                  <TableCell colSpan={4}>सेवाको विवरण</TableCell>
                  <TableCell>सेवा संख्या</TableCell>
                </TableRow>
              </TableHead>
              <TableBody classes={{ root: 'align-center' }}>
                <TableRow>
                  <TableCell rowSpan={3}>नया सेवाग्राही संख्या</TableCell>
                  <TableCell >महिला</TableCell>
                  <TableCell colSpan={3}>{tableData?.NEW_PATIENT?.FEMALE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>पुरुष</TableCell>
                  <TableCell colSpan={3}>{tableData?.NEW_PATIENT?.MALE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>अन्य</TableCell>
                  <TableCell colSpan={3}>{tableData?.NEW_PATIENT?.OTHER}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={3}>पुनरावृत्त सेवाग्राहीको संख्या</TableCell>
                  <TableCell >महिला</TableCell>
                  <TableCell colSpan={3}>{tableData?.PREVIOUS_PATIENT?.FEMALE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>पुरुष</TableCell>
                  <TableCell colSpan={3}>{tableData?.PREVIOUS_PATIENT?.MALE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>अन्य</TableCell>
                  <TableCell colSpan={3}>{tableData?.PREVIOUS_PATIENT?.OTHER}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MessageBlock text={REPORT_NOT_DRAWN} />
        )
      }
    </>
  );
};

export default HomeoPathyReport;
