import React from "react"
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

const JesthaNagrikSewaReport = () => {
  return (
    <>
      <TableContainer classes={{ root: "fixed-head-table-container" }}  >
        <Typography variant="body1" className="report-heading">जेष्ठ नागरिक सेवा प्रतिवेदन</Typography>
        <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Jestha Nagrik Sewa">
          <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
            <TableRow>
              <TableCell rowSpan={3}>सेवाको प्रकार अनुसार सेवा लिएको विवरण</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>रसायन औषधि /तेल</TableCell>
              <TableCell colSpan={3}>स्नेहन </TableCell>
              <TableCell colSpan={3}>स्वेदन% </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>महिला </TableCell>
              <TableCell>पुरूष </TableCell>
              <TableCell>अन्य </TableCell>
              <TableCell>महिला </TableCell>
              <TableCell>पुरूष </TableCell>
              <TableCell>अन्य </TableCell>
              <TableCell>महिला </TableCell>
              <TableCell>पुरूष </TableCell>
              <TableCell>अन्य </TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            <TableRow>
              <TableCell>नया</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>पुनरावृत</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>1</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <TableContainer classes={{ root: "fixed-head-table-container" }}  >
          <Typography variant="body1" className="report-heading">उमेर अनुसार सेवा प्रतिबेदन</Typography>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Jestha Nagrik Sewa">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={3}>उमेर समूह वर्ष/सेवा</TableCell>
                <TableCell colSpan={3}>(नयाँ सेवाग्राही मात्र)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>रसायन औषधि /तेल</TableCell>
                <TableCell>स्नेहन </TableCell>
                <TableCell>स्वेदन% </TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell>६० देखि ६९</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>७० देखि ७९</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>८० देखि ८४</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>>=८५ वर्ष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={2}>
        <TableContainer classes={{ root: "fixed-head-table-container" }}  >
          <Typography variant="body1" className="report-heading">सेवा ग्राही अनुसारको विवरण</Typography>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Jestha Nagrik Sewa">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell colSpan={2}>उमेर समूह वर्ष/सेवा</TableCell>
                <TableCell>नया</TableCell>
                <TableCell>पुनरावृत</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell rowSpan={4}>जेष्ठ नागरिक सेवाग्राही संख्या</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पुरुष</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>जम्मा</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default JesthaNagrikSewaReport;