
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import ReportHeader from '../component/report-header/ReportHeader';
import AHMIS, { API_URL } from '../../../api/api';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import MessageBlock from '../../admin/components/message-block/MessageBlock';

const PhysioTherapyReport = () => {
  const [tableData, setTableData] = useState({})

  const getReportData = (dateFrom, dateTo) => {
    const url = `${API_URL.physiotherapReport}?fromDate=${dateFrom}&toDate=${dateTo}`;
    AHMIS.get(url).then(response => {
      const jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setTableData(jsondata.data);
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
    });
  };

  return (
    <>
      <ReportHeader
        title="फिजियोथेरापी प्रतिवेदन"
        getReportData={getReportData}
      />
      {
        tableData && Object.keys(tableData).length > 0 ? (
          <TableContainer classes={{ root: "fixed-head-table-container" }}>
            <Typography variant="body1" className="report-heading">फिजियोथेरापी प्रतिवेदन</Typography>
            <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="physiotherapy-Sewa-Report">
              <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
                <TableRow>
                  <TableCell colSpan={2}> सेवाको विवरण</TableCell>
                  <TableCell>म्यानुअल</TableCell>
                  <TableCell>टेन्स</TableCell>
                  <TableCell>युएसटि</TableCell>
                  <TableCell>आइएफटि</TableCell>
                  <TableCell>मसल स्टीमुलेटर</TableCell>
                  <TableCell>ट्याक्शन</TableCell>
                  <TableCell>अन्य</TableCell>
                </TableRow>
              </TableHead>
              <TableBody classes={{ root: "align-center" }}>
                <TableRow>
                  <TableCell rowSpan={3}>सेवाग्राही संख्या</TableCell>
                  <TableCell>महिला</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.MANUAL_THERAPY}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.TAPES}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.UST}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.IFT}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.MUSCLE_STIMULATOR}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.TRACTION}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.FEMALE?.OTHER}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>पुरुष</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.MANUAL_THERAPY}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.TAPES}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.UST}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.IFT}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.MUSCLE_STIMULATOR}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.TRACTION}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.MALE?.OTHER}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>अन्य</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.MANUAL_THERAPY}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.TAPES}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.UST}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.IFT}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.MUSCLE_STIMULATOR}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.TRACTION}</TableCell>
                  <TableCell>{tableData?.PATIENT_COUNT?.OTHER?.OTHER}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={3}>सेवा संख्या </TableCell>
                  <TableCell>महिला</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.MANUAL_THERAPY}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.TAPES}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.UST}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.IFT}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.MUSCLE_STIMULATOR}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.TRACTION}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.FEMALE?.OTHER}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>पुरुष</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.MANUAL_THERAPY}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.TAPES}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.UST}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.IFT}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.MUSCLE_STIMULATOR}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.TRACTION}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.MALE?.OTHER}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>अन्य</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.MANUAL_THERAPY}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.TAPES}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.UST}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.IFT}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.MUSCLE_STIMULATOR}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.TRACTION}</TableCell>
                  <TableCell>{tableData?.SERVICE_COUNT?.OTHER?.OTHER}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MessageBlock text={REPORT_NOT_DRAWN} />
        )
      }
    </>
  )
}

export default PhysioTherapyReport;
