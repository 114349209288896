import React, { useState } from "react"
import NewOldPatientCount from "../new-old-patient-count/NewOldPatientCount";
import GauGharClinicSewaReport from "../gau-ghar-clinic-sewa-report/GauGharClinicSewaReport";
import TypeOfHealthFacility from "../type-of-health-facility/TypeOfHealthFacility";
import AvailableServices from "../available-services/AvailableServices";
import AHMIS, { API_URL } from "../../../api/api";
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddAlertMessage from "../../../components/alert/Alert";
import { DateUtils } from "../../../utils/dateUtils";
import ReportHeader from "../component/report-header/ReportHeader";
import { Box } from "@mui/material";
import MessageBlock from "../../admin/components/message-block/MessageBlock";

const MonthlyProgressReport = () => {
  const [monthlyProgressData, setMonthlyProgressData] = useState(null);

  const getReportData = (dateFrom, dateTo, healthClientId) => {
    // let nepaliMonths = ["BAISHAKH", "JESTHA", "ASAR", "SHRAWAN", "BHADAU", "ASWIN", "KARTIK", "MANSIR", "POUSH", "MAGH", "FALGUN", "CHAITRA"];
    // const url = `${API_URL.monthlyProgressReport}?dateFrom=${dateFrom}&dateTo=${dateTo}&healthClientId=${healthClientId}&fiscalYear=${DateUtils.getFiscalYearFromDate(DateUtils.getDateFromMilliseconds(dateTo))} &month=${nepaliMonths[parseInt(DateUtils.getSeparatedDateFromMilliseconds(dateFrom).month) - 1]}`;
    const url = `${API_URL.monthlyProgressReport}?dateFrom=${dateFrom}&dateTo=${dateTo}`;
    AHMIS.get(url).then(response => {
      const jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setMonthlyProgressData(jsondata.data);
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
    });
  };

  return (
    <>
      <ReportHeader
        title="मासिक प्रगती प्रतिवेदन फाराम"
        getReportData={getReportData}
      />
      {monthlyProgressData ? (
        <Box>
          <NewOldPatientCount tableData={monthlyProgressData} />
          <GauGharClinicSewaReport />
          <TypeOfHealthFacility />
          <AvailableServices />
        </Box>
      ) : (
        <MessageBlock text={REPORT_NOT_DRAWN} />
      )
      }
    </>
  )
}

export default MonthlyProgressReport;