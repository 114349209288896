import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import ReportHeader from '../component/report-header/ReportHeader';
import AHMIS, { API_URL } from '../../../api/api';
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import AddAlertMessage from '../../../components/alert/Alert';
import MessageBlock from '../../admin/components/message-block/MessageBlock';

const SalyaChikitsaReport = () => {

  const [tableData, setTableData] = useState({})

  const getReportData = (dateFrom, dateTo) => {
    const url = `${API_URL.shalyaChikitsaReport}?fromDate=${dateFrom}&toDate=${dateTo}`;
    AHMIS.get(url).then(response => {
      const jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setTableData(jsondata.data);
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
    });
  };
  return (
    <>
      <ReportHeader
        title="शल्य चिकित्सा सेवा प्रतिवेदन"
        getReportData={getReportData}
      />
      {tableData && Object.keys(tableData).length > 0 ? (
        <TableContainer classes={{ root: "fixed-head-table-container" }}>
          <Typography variant="body1" className="report-heading">शल्य चिकित्सा सेवा प्रतिवेदन</Typography>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="salyachikitsa-Sewa-Report">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell colSpan={2}> सेवाको विवरण</TableCell>
                <TableCell>क्षारसूत्र</TableCell>
                <TableCell>अग्निकर्म</TableCell>
                <TableCell>रक्तमोक्षण</TableCell>
                <TableCell>विशिष्ठिकृत शल्य सेवा</TableCell>
                <TableCell>अन्य शल्य सेवा</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell rowSpan={3}>नया सेवाग्राहीको संख्या</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.FEMALE?.Ksharasutra}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.FEMALE?.Agnikarma}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.FEMALE?.Raktamokshana}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.FEMALE?.Vishishtikrit_Shalya_Sewa}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.FEMALE?.Anya_Shalya_Upachara}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पुरुष</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.MALE?.Ksharasutra}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.MALE?.Agnikarma}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.MALE?.Raktamokshana}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.MALE?.Vishishtikrit_Shalya_Sewa}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.MALE?.Anya_Shalya_Upachara}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.OTHER?.Ksharasutra}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.OTHER?.Agnikarma}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.OTHER?.Raktamokshana}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.OTHER?.Vishishtikrit_Shalya_Sewa}</TableCell>
                <TableCell>{tableData.NEW_PATIENT?.OTHER?.Anya_Shalya_Upachara}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3}>पुनरावृत्त सेवाग्राहीको संख्या </TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Ksharasutra}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Agnikarma}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Raktamokshana}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Vishishtikrit_Shalya_Sewa}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Anya_Shalya_Upachara}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पुरुष</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Ksharasutra}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Agnikarma}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Raktamokshana}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Vishishtikrit_Shalya_Sewa}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.FEMALE?.Anya_Shalya_Upachara}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.OTHER?.Ksharasutra}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.OTHER?.Agnikarma}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.OTHER?.Raktamokshana}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.OTHER?.Vishishtikrit_Shalya_Sewa}</TableCell>
                <TableCell>{tableData.PREVIOUS_PATIENT?.OTHER?.Anya_Shalya_Upachara}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </TableContainer>
      ) : (
        <MessageBlock text={REPORT_NOT_DRAWN} />
      )}
    </>
  )
}

export default SalyaChikitsaReport;
