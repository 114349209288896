import React, { useState } from 'react';
import ReportHeader from '../component/report-header/ReportHeader';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AHMIS, { API_URL } from '../../../api/api';
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import AddAlertMessage from '../../../components/alert/Alert';
import MessageBlock from '../../admin/components/message-block/MessageBlock';

const StanpaiAamaThaShishuSewaReport = () => {
  const [tableData, setTableData] = useState({});

  const getReportData = (dateFrom, dateTo) => {
    const url = `${API_URL.stanpaiAamaThaShishuReport}?fromDate=${dateFrom}&toDate=${dateTo}`;
    AHMIS.get(url)
      .then(response => {
        console.log(response)
        const jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setTableData(jsondata.data);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
      });
  };

  return (
    <>
      <ReportHeader
        title="स्तनपायी आमा तथा शिशु सेवा प्रतिवेदन"
        getReportData={getReportData}
      />
      {Object.keys(tableData).length > 0 ? (
        <TableContainer classes={{ root: "fixed-head-table-container" }}>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell>विवरण</TableCell>
                <TableCell>संख्या</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell>प्रशूति भएको महिला को संख्या</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_STHAN?.TOTAL}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>स्वास्थ्य संस्थामा</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_STHAN?.HEALTH_INSTITUTION}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>घरमा</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_STHAN?.HOME}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_STHAN?.OTHER}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>प्रशूतिको प्रकार</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_STHAN?.TOTAL}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>सामान्य</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_TYPES?.CS}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>शल्यक्रिया</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_TYPES?.OTHER}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell>{tableData?.PRASUTI_KO_TYPES?.OTHER}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>औषधी वितरण</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>शताब्दी १०० ग्राम</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>डासमुल ६० एम एल</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>जाउनो १०० ग्राम</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <MessageBlock text={REPORT_NOT_DRAWN} />
      )}
    </>
  );
};

export default StanpaiAamaThaShishuSewaReport;
