import { CreditCard, Delete, Edit, Person } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { CASTE_CODES, FEMALE, MALE, NO_RECORDS_FOUND, SNEHAN_MAHAL, SWEDEN_CASTLE, VASTI_MAHAL } from "../../../../../utils/constants";
import { AppUtils } from "../../../../../utils/appUtils";
import { AppMisc } from "../../../../../misc/appMisc";
import { NEW_PATIENT, OTHER, PREVIOUS_PATIENT } from "../../../../../utils/constants/forms";
import InfiniteScroll from "react-infinite-scroll-component";

const PanchaKarmaRegister = ({ page, totalSize, ...props }) => {
  const [mainRegisterData, setMainRegisterData] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    if (props.tableData !== null) {
      if (page) {
        setMainRegisterData(prev => {
          return [...prev, ...props.tableData]
        })
      } else {
        tableRef.current.scrollTo(0, 0)
        setMainRegisterData(props.tableData)
      }
    }
  }, [props.tableData])

  const fetchData = () => {
    props.handleChangePage(page + 1);
    props.getTableData(true)
  }

  return (
    <>
      <InfiniteScroll
        dataLength={mainRegisterData?.length}
        next={fetchData}
        hasMore={mainRegisterData?.length !== totalSize}
        loader={<p style={{ textAlign: 'center' }} className="print-none">Loading....</p>}
        scrollableTarget="mul-dartaa-register"
      >
        <TableContainer classes={{ root: "fixed-head-table-container" }} ref={tableRef} >
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="Mul Dartaa Register">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell rowSpan={2}>क्र.सं.</TableCell>
                <TableCell rowSpan={2}>दर्ता मिति</TableCell>
                <TableCell colSpan={2}>दर्ता नम्बर</TableCell>
                <TableCell colSpan={2}>सेवाग्राहीको</TableCell>
                <Tooltip title=
                  {
                    <>
                      {CASTE_CODES.map(caste => (
                        <Typography key={caste.value} variant="caption"> {caste.label} </Typography>
                      ))}
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <TableCell rowSpan={2}>जाती कोड</TableCell>
                </Tooltip>
                <TableCell colSpan={3}>उमेर</TableCell>
                <TableCell colSpan={5}>ठेगाना</TableCell>
                <TableCell rowSpan={2}>राष्ट्रिय परिचयपत्र नम्वर</TableCell>
                <TableCell rowSpan={2}>सम्पर्क नम्बर</TableCell>
                <TableCell colSpan={6}>स्वास्थ्य अवस्था</TableCell>
                <TableCell rowSpan={2}>सेवा थेरापी </TableCell>
                <TableCell colSpan={2}>प्रेषण भई</TableCell>
                <TableCell rowSpan={2} >सम्पादन</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पहिलो पटक</TableCell>
                <TableCell>पुनरावृत </TableCell>
                <TableCell>नाम</TableCell>
                <TableCell>थर</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>पुरुष</TableCell>
                <TableCell>अन्य</TableCell>
                <TableCell>जिल्ला</TableCell>
                <TableCell>नगर/गाउँपालिका</TableCell>
                <TableCell>वडा नं.</TableCell>
                <TableCell>टोल</TableCell>
                <TableCell>अन्य मुलुक</TableCell>
                <TableCell>रक्तचाप</TableCell>
                <TableCell>प्रकृति</TableCell>
                <TableCell>तौल</TableCell>
                <TableCell>रोग</TableCell>
                <TableCell>व्लड सुगर</TableCell>
                <TableCell>परीक्षण</TableCell>
                <TableCell>आएको संस्थाको नाम</TableCell>
                <TableCell>गएको संस्थाको नाम</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              {mainRegisterData?.length > 0 ? (
                mainRegisterData?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{AppUtils.replaceWithNepaliDigit(index + 1)}</TableCell>
                    <TableCell>{AppMisc.getDateLabelFromMillisecond(row?.dartaaMiti)}</TableCell>
                    <TableCell>{row?.patientType === NEW_PATIENT ? row?.mulDartaaNumber && AppUtils.replaceWithNepaliDigit(row.mulDartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientType === PREVIOUS_PATIENT ? row?.mulDartaaNumber && AppUtils.replaceWithNepaliDigit(row.mulDartaaNumber) : "-"}</TableCell>
                    <TableCell>{row?.patientFirstName || "-"}</TableCell>
                    <TableCell>{row?.patientLastName || "-"}</TableCell>
                    <TableCell>{row?.casteCode && AppUtils.replaceWithNepaliDigit(row.casteCode) || "-"}</TableCell>
                    <TableCell>{row?.gender === FEMALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                    <TableCell>{row?.gender === MALE ? row?.age && AppUtils.replaceWithNepaliDigit(row.age) : "-"}</TableCell>
                    <TableCell>{row?.gender === OTHER ? AppUtils.replaceWithNepaliDigit(row?.age) : "-"}</TableCell>
                    <TableCell>{row?.district && AppMisc.getDistrictName(row?.district)}</TableCell>
                    <TableCell>{row?.palikaName && AppMisc.getMunicipalityName(row?.palikaName)}</TableCell>
                    <TableCell>{row?.wardNumber && AppUtils.replaceWithNepaliDigit(row?.wardNumber)}</TableCell>
                    <TableCell>{row.gaunOrTole || "-"}</TableCell>
                    <TableCell>{row?.otherCountry || "-"}</TableCell>
                    <TableCell>{row?.nid || "-"}</TableCell>
                    <TableCell>{row?.phoneNumber && AppUtils.replaceWithNepaliDigit(row?.phoneNumber)}</TableCell>
                    <TableCell>{row.bloodPressure || "-"}</TableCell>
                    <TableCell>{row.nature || "-"}</TableCell>
                    <TableCell>{row.weight || "-"}</TableCell>
                    <TableCell>{row.disease || "-"}</TableCell>
                    <TableCell>{row.bloodSugar || ""}</TableCell>
                    <TableCell>{row.examination || "-"}</TableCell>
                    <TableCell>{row.sewaTherapy || "-"}</TableCell>
                    <TableCell>{row?.referredIn || "-"}</TableCell>
                    <TableCell>{row?.referredOut || "-"}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="edit">
                          <Edit fontSize="small" onClick={() => props.onEditRow(row.id)} />
                        </IconButton>
                        {/* TODO(Krishna) If card is necessaary i will uncomment  */}
                        {/* <Tooltip title="स्वास्थ्य सेवा कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <CreditCard fontSize="small" />
                        </Tooltip> */}
                        <Tooltip title="प्रोफाइल कार्ड हेर्न यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <Box>
                            <a target="_blank" rel="noreferrer" href={`pancharkarma-sewa-bibaran?id=${row.id}`}>
                              <Person fontSize="small" />
                            </a>
                          </Box>
                        </Tooltip>
                        <Tooltip title="रेकर्ड हटाउन यहा क्लिक गर्नुहोस" placement="top" arrow>
                          <IconButton >
                            <Delete fontSize="small" color="secondary" onClick={() => props.deleteRow(row.id)} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="41" size="medium">{NO_RECORDS_FOUND}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}

export default PanchaKarmaRegister
