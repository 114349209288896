import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import ReportHeader from '../component/report-header/ReportHeader';
import AHMIS, { API_URL } from '../../../api/api';
import { ERROR, REPORT_NOT_DRAWN, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import AddAlertMessage from '../../../components/alert/Alert';
import MessageBlock from '../../admin/components/message-block/MessageBlock';

const AcupunctureReport = () => {
  const [tableData, setTableData] = useState({});

  const getReportData = (dateFrom, dateTo) => {
    const url = `${API_URL.acupunctureReport}?fromDate=${dateFrom}&toDate=${dateTo}`;
    AHMIS.get(url)
      .then(response => {
        const jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setTableData(jsondata.data);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: `${SOMETHING_WENT_WRONG}: ${error.message}` });
      });
  };

  return (
    <>
      <ReportHeader title="अकुपञ्चर सेवा प्रतिवेदन"
        getReportData={getReportData} />

      {Object.keys(tableData).length > 0 ? (
        <TableContainer classes={{ root: "fixed-head-table-container" }}>
          <Typography variant="body1" className="report-heading">अकुपञ्चर सेवा प्रतिवेदन</Typography>
          <Table classes={{ root: "table-bordered normal-spacing" }} size="small" aria-label="acupuncture-Sewa-Report">
            <TableHead classes={{ root: "align-center fixed-header header-sm" }}>
              <TableRow>
                <TableCell colSpan={2}>सेवाको विवरण</TableCell>
                <TableCell>अक्यूपंक्चर (सामान्य)</TableCell>
                <TableCell>कपिङ</TableCell>
                <TableCell>मोक्सीबक्सन</TableCell>
                <TableCell>अक्यूप्रेसर</TableCell>
                <TableCell>रक्त मोक्षण(व्लड लेटिंग)</TableCell>
                <TableCell>अक्यूपंक्चर(इलेक्टीकल स्टीमुलेसन)</TableCell>
                <TableCell>अन्य</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: "align-center" }}>
              <TableRow>
                <TableCell rowSpan={3}>नया सेवाग्राही संख्या</TableCell>
                <TableCell>महिला</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.ACUPUNCTURE_SAMANAYA}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.CUPPING}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.MOXIBUSTION}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.ACUPRESSURE}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.RAKTA_MOKSHAN}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.ACUPUNCTURE_ELECTRIC_STIMULATION}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.FEMALE?.ANYA}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>पुरुष</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.ACUPUNCTURE_SAMANAYA}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.CUPPING}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.MOXIBUSTION}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.ACUPRESSURE}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.RAKTA_MOKSHAN}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.ACUPUNCTURE_ELECTRIC_STIMULATION}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.MALE?.ANYA}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>अन्य</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.ACUPUNCTURE_SAMANAYA}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.CUPPING}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.MOXIBUSTION}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.ACUPRESSURE}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.RAKTA_MOKSHAN}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.ACUPUNCTURE_ELECTRIC_STIMULATION}</TableCell>
                <TableCell>{tableData?.NEW_PATIENT?.OTHER?.ANYA}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <MessageBlock text={REPORT_NOT_DRAWN} />
      )}
    </>
  );
};

export default AcupunctureReport;
