import { Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import DeleteRegisterRecordConformation from '../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation'
import PendingPatientList from '../../../components/pending-patient-list/PendingPatientList'
import MulDartaaSelect from '../../../components/mul-dartaa-select/MulDartaaSelect'
import CustomModal from '../../../components/modal/CustomModal'
import RegisterHeader from '../../../components/register-header/RegisterHeader'
import AHMIS, { API_URL } from '../../../api/api'
import { SessionStorage } from '../../../utils/storage/sessionStorage'
import { MUL_DARTA_NUMBERS_LIST } from '../../../utils/constants/forms'
import { useForm } from 'react-hook-form';
import { DateUtils } from '../../../utils/dateUtils';
import AddAlertMessage from '../../../components/alert/Alert';
import { ERROR, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS } from '../../../utils/constants';
import AnatmakTathaAbhiyanamulakaYogRegister from '../../users/components/register/prabardhanatmak-tatha-abhiyanamulaka-yog-register/AnatmakTathaAbhiyanamulakaYogRegister';

const AnatmakTathaAbhiyanamulakaYogForm = () => {
  const [mulDartaaOptions, setMulDartaaOptions] = useState([]);
  const [modalTitle, setModalTitle] = useState("प्रबर्द्धनात्मक तथा अभियानमूलक योग सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्।");
  const [showAnatmakTathaAbhiyanamulakaYogModal, setShowAnatmakTathaAbhiyanamulakaYogModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [getTableData, setGetTableData] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState();
  const [anatmakTathaAbhiyanamulakaYogDataTable, setAnatmakTathaAbhiyanamulakaYogDataTable] = useState([]);
  const [showAnatmakTathaAbhiyanamulakaYogDeleteModal, setShowAnatmakTathaAbhiyanamulakaYogDeleteModal] = useState(false);
  const [anatmakTathaAbhiyanamulakaYogDeleteId, setAnatmakTathaAbhiyanamulakaYogDeleteId] = useState(null);
  const [pendingPatientList, setPendingPatientList] = useState([])

  const openAnatmakTathaAbhiyanamulakaYogModal = () => {
    setShowAnatmakTathaAbhiyanamulakaYogModal(true)
  }

  const closeAnatmakTathaAbhiyanamulakaYog = () => {
    reset();
    setShowAnatmakTathaAbhiyanamulakaYogModal(false);
    setModalDefaultValues()
  }


  const handlePalikaNameChange = (name, value) => {
    setValue(name, value);
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  const submitAnatmakTathaAbhiyanamulakaYogForm = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    AHMIS.post(API_URL.anatmakTathaAbhiyanamulakaYog, data)
      .then((response) => {
        if (response.data.type === "success") {
          closeAnatmakTathaAbhiyanamulakaYog()
          setGetTableData(true)
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };


  const getAnatmakTathaAbhiyanamulakaYogData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.anatmakTathaAbhiyanamulakaYog}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then((response) => {
        let jsondata = response.data;
        setAnatmakTathaAbhiyanamulakaYogDataTable(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  useEffect(() => {
    anatmakTathaAbhiyanamulakaYogPendingPatient()
  }, [])

  const anatmakTathaAbhiyanamulakaYogPendingPatient = () => {
    let url = `${API_URL.getOpdPendingPatient}`;
    AHMIS.get(url).then((response) => {
      let jsondata = response.data;
      setPendingPatientList(jsondata.data)
    })
      .catch((error) => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const anatmakTathaAbhiyanamulakaYogEdit = (id) => {
    AHMIS.get(API_URL.anatmakTathaAbhiyanamulakaYog + "/" + id)
      .then(response => {
        setModalDefaultValues(response?.data?.data);
        setShowAnatmakTathaAbhiyanamulakaYogModal(true);
        if (response.data.district && response.data.palikaName) {
          populateDistrictAndPalikaName(response.data.district, response.data.palikaName);
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const populateDistrictAndPalikaName = (districtName, palikaName) => {
    handlePalikaNameChange("palikaName", palikaName);
  }

  const closeAnatmakTathaAbhiyanamulakaYogDeleteModal = () => {
    setShowAnatmakTathaAbhiyanamulakaYogDeleteModal(false);
    setAnatmakTathaAbhiyanamulakaYogDeleteId(null);
  }

  const onDeleteAnatmakTathaAbhiyanamulakaYogRow = (id) => {
    setShowAnatmakTathaAbhiyanamulakaYogDeleteModal(true);
    setAnatmakTathaAbhiyanamulakaYogDeleteId(id);
  }

  const deleteAnatmakTathaAbhiyanamulakaYogRow = (data) => {
    AHMIS.delete(API_URL.anatmakTathaAbhiyanamulakaYog + "/" + anatmakTathaAbhiyanamulakaYogDeleteId + "?deletedReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowAnatmakTathaAbhiyanamulakaYogDeleteModal(false);
          setAnatmakTathaAbhiyanamulakaYogDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getAnatmakTathaAbhiyanamulakaYogData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const attachMulDartaaOptions = () => {
    let mulDartaaOptions = [];
    AHMIS.get(API_URL.mulDartaaNumber + "?sewaType=1&fiscalYear=" + DateUtils.getCurrentFiscalYear())
      .then(response => {
        let data = response.data;
        SessionStorage.setItem(MUL_DARTA_NUMBERS_LIST, data);
        data.forEach(item => {
          mulDartaaOptions.push({ "value": item.mulDartaaNumber, "label": item.mulDartaaNumber + " (" + item.patientFirstName + " " + item.patientLastName + ")", id: item.id });
        });
        setMulDartaaOptions(mulDartaaOptions);
      })
  }

  useEffect(() => {
    attachMulDartaaOptions();
  }, [])
  return (
    <>
      <Box marginTop={3}>
        <RegisterHeader
          registerTitle="प्रबर्द्धनात्मक तथा अभियानमूलक योग सेवा रजिष्टर"
          handleModalOpen={openAnatmakTathaAbhiyanamulakaYogModal}
          onHeaderParamChange={onHeaderParamChange}
          getRegisterData={getAnatmakTathaAbhiyanamulakaYogData}
          getTableData={getTableData}
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showAnatmakTathaAbhiyanamulakaYogModal}
        onModalSubmit={handleSubmit(submitAnatmakTathaAbhiyanamulakaYogForm)}
        onModalClose={closeAnatmakTathaAbhiyanamulakaYog}
        maxWidth="lg"
        fullScreen
      >
        <Grid container spacing={2} alignItems="center">
          <MulDartaaSelect
            mulDartaaOptions={mulDartaaOptions}
            defaultValues={modalDefaultValues}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            watch={watch}
            handleSelectedDartaaMitiChange={handleDateChange}
          />
        </Grid>
        <Box marginTop={2}>
          <Typography variant="h6">General Examination</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="रक्त चाप"
              size="small"
              variant="outlined"
              name="bloodPresssure"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.bloodPresssure}
              {...register("bloodPresssure")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} style={{ overflowY: 'auto' }}>
            <TextField
              label="FBS"
              size="small"
              variant="outlined"
              name="fbs"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.fbs}
              {...register("fbs")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="तौल/उचाई"
              size="small"
              variant="outlined"
              name="weight"
              autoComplete="true"
              defaultValue={modalDefaultValues?.weight}
              fullWidth
              {...register("weight")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              label="अन्य"
              size="small"
              variant="outlined"
              name="other"
              autoComplete="true"
              defaultValue={modalDefaultValues?.other}
              fullWidth
              {...register("other")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      {/* TODO (krishna):If pending patientList is necessary in future i will uncomment */}
      {/* {
        pendingPatientList.size > 0 &&
        <Box mt={2}>
          <PendingPatientList
            pendingPatientList={pendingPatientList}
            showModal={showAnatmakTathaAbhiyanamulakaYogDeleteModal}
            modalDefaultValues={setModalDefaultValues}
            setValue={setValue}
          />
        </Box>
      } */}
      <DeleteRegisterRecordConformation
        showDeleteModal={showAnatmakTathaAbhiyanamulakaYogDeleteModal}
        closeDeleteModel={closeAnatmakTathaAbhiyanamulakaYogDeleteModal}
        deleteRecord={deleteAnatmakTathaAbhiyanamulakaYogRow}
      />
      <Box mt={2}>
        <AnatmakTathaAbhiyanamulakaYogRegister
          onEditRow={anatmakTathaAbhiyanamulakaYogEdit}
          tableData={anatmakTathaAbhiyanamulakaYogDataTable}
          deleteRow={onDeleteAnatmakTathaAbhiyanamulakaYogRow}
          handleChangePage={handleChangePage}
          page={page}
          totalSize={totalSize}
          getTableData={(value) => setGetTableData(value)}
        />
      </Box>
    </>
  )
}

export default AnatmakTathaAbhiyanamulakaYogForm
