import { useEffect, useState } from "react";
import { AppMisc } from "../../../../misc/appMisc";
import { useForm } from "react-hook-form";
import AHMIS, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { Box, Grid, TextField } from "@mui/material";
import CustomModal from "../../../../components/modal/CustomModal";
import NepaliCalender from "../../../../components/nepali-calender";
import CustomReactSelect from "../../../../components/custom-react-select/CustomReactSelect";
import DeleteRegisterRecordConformation from "../../../../components/delete-register-record-conformation/DeleteRegisterRecordConformation";
import RegisterHeader from "../../../../components/register-header/RegisterHeader";
import { ERROR, ID, REQUIRED_FIELD, ROW_PER_PAGE, SOMETHING_WENT_WRONG, SUCCESS, THERAPY, bahya_Snehana } from "../../../../utils/constants"
import PanchaKarmaBabaranRegister from "../../../users/components/register/panchakarma-sewa-register/helper/PanchaKarmaBabaranRegister";
import { AppUtils } from "../../../../utils/appUtils";

const PanchaKarmaSewaBibaranForm = () => {
  const [modalTitle, setModalTitle] = useState("पञ्चकर्म सेवा रजिष्टर नयाँ रेकर्ड थप्नुहोस्");
  const [showPanchakarmaModal, setShowPanchakarmaModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState();
  const [panchakarmaSewaBibaranTableData, setPanchakarmaSewaBibaranTableData] = useState([]);
  const [getTableData, setGetTableData] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors }, reset, watch } = useForm();
  const [showPanchakarmaDeleteModal, setShowPanchakarmaDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [panchakarmaDeleteId, setPanchakarmaDeleteId] = useState(null);
  const panchakarmaRegisterId = AppUtils.getUrlParam(ID);

  const watchFields = watch("therapy")

  useEffect(() => {
    showPanchakarmaModal && reset();
  }, [reset, showPanchakarmaModal]);

  const openPanchakarmaModal = () => {
    setShowPanchakarmaModal(true);
  };

  const closePanchakarmaModal = () => {
    setShowPanchakarmaModal(false);
    setModalDefaultValues();
    reset();
  };

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  };

  useEffect(() => {
    if (showPanchakarmaModal && !modalDefaultValues?.id) {
      getPanchakarmaServiceTakenCount()
    }
  }, [showPanchakarmaModal, !modalDefaultValues?.id])

  const handleSelectChange = (name, value) => {
    setValue(name, value)
  }

  const submitParchaKarmaSewaBibaranRegister = (data) => {
    if (modalDefaultValues?.id) {
      data.id = modalDefaultValues.id;
    }
    const url = `${API_URL.panchaKarmaRegistrationService}?panchaKarmaServiceRegistrationId=${panchakarmaRegisterId}`;
    AHMIS.post(url, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          closePanchakarmaModal();
          setGetTableData(true);
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };



  const getPanchakarmaSewaBibaranData = (fromDate, toDate, searchParam, healthClientId, pageFromParam) => {
    let url = `${API_URL.panchaKarmaRegistrationService}/fromDateRangeAndPage?fromDate=${fromDate}&toDate=${toDate}&pageNumber=${pageFromParam || (Number(page) + 1)}&rowSize=${ROW_PER_PAGE}&panchaKarmaServiceRegistrationId=${panchakarmaRegisterId}`;
    if (healthClientId) {
      url = url + "&healthClientId=" + healthClientId;
    }
    if (searchParam) {
      url = url + "&searchParam=" + searchParam;
    }
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        setPanchakarmaSewaBibaranTableData(jsondata?.data?.objectList || []);
        setGetTableData(false);
        setTotalSize(jsondata?.data?.size)
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const panchakarmaSewaBibaranEdit = (id) => {
    AHMIS.get(API_URL.panchaKarmaRegistrationService + "/" + id)
      .then(response => {
        setModalDefaultValues(response.data?.data);
        setShowPanchakarmaModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const closePanchakarmaDeleteModal = () => {
    setShowPanchakarmaDeleteModal(false);
    setPanchakarmaDeleteId(null);
  }

  const onDeletePanchakarmaRow = (id) => {
    setShowPanchakarmaDeleteModal(true);
    setPanchakarmaDeleteId(id);
  }

  const deletePanchaKarmaSewaBibaranRow = (data) => {
    AHMIS.delete(API_URL.panchaKarmaRegistrationService + "/" + panchakarmaDeleteId + "?deleteReason=" + data.deletedReason)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setShowPanchakarmaDeleteModal(false);
          setPanchakarmaDeleteId(null);
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
          setGetTableData(true);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const onHeaderParamChange = (fromDate, toDate, searchParam, healthClientId) => {
    setPage(0)
    getPanchakarmaSewaBibaranData(fromDate, toDate, searchParam, healthClientId, 1);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setGetTableData(true);
  };

  const getPanchakarmaServiceTakenCount = () => {
    let url = `${API_URL.serviceTakenCount}?register=${"panchakarmasewaservice"}`;
    AHMIS.get(url)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setValue("service", jsondata.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <Box marginTop={2}>
        <RegisterHeader
          registerTitle="सेवा विवरण"
          handleModalOpen={openPanchakarmaModal}
          getRegisterData={getPanchakarmaSewaBibaranData}
          onHeaderParamChange={onHeaderParamChange}
          getTableData={getTableData}
          fromDateDefaultFormat
          lazyLoading
          showSearchField
        />
      </Box>
      <CustomModal
        title={modalTitle}
        showModal={showPanchakarmaModal}
        onModalSubmit={handleSubmit(submitParchaKarmaSewaBibaranRegister)}
        onModalClose={closePanchakarmaModal}
        maxWidth="lg"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <NepaliCalender
              id="dartaa-miti"
              label="दर्ता मिति"
              name="dartaaMiti"
              defaultDate={modalDefaultValues?.dartaaMiti}
              onChange={handleDateChange}
            />
            {errors?.dartaaMiti && errors.dartaaMiti?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="विवरण"
              size="small"
              variant="outlined"
              name="treatmentDetails"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.treatmentDetails}
              {...register("treatmentDetails")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="जटिलता"
              size="small"
              variant="outlined"
              name="complication"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.complication}
              {...register("complication")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="परीक्षण /सल्लाह"
              size="small"
              variant="outlined"
              name="testing"
              autoComplete="true"
              fullWidth
              defaultValue={modalDefaultValues?.testing}
              {...register("testing")}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <DeleteRegisterRecordConformation
        showDeleteModal={showPanchakarmaDeleteModal}
        closeDeleteModel={closePanchakarmaDeleteModal}
        deleteRecord={deletePanchaKarmaSewaBibaranRow}
      />
      <PanchaKarmaBabaranRegister
        onEditRow={panchakarmaSewaBibaranEdit}
        tableData={panchakarmaSewaBibaranTableData}
        deleteRow={onDeletePanchakarmaRow}
        page={page}
        handleChangePage={handleChangePage}
        totalSize={totalSize}
        getTableData={(value) => setGetTableData(value)}
      />
    </>
  );
};

export default PanchaKarmaSewaBibaranForm;

